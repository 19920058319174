import React, {Fragment, useState } from "react";
import { useSelector } from 'react-redux'
import {SuccessRoadmapActions} from "./SuccessRoadmapActions";
import {SuccessThemeActions} from "./SuccessThemeActions";
import {SuccessSprintActions} from "./SuccessSprintActions";
import {filter} from "lodash"


function SuccessLinks(props) {
  const [selectedTab, setSelectedTab] = useState("Linked Roadmaps");
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
    const {
        success, 
        rows, 
        linkedrows, 
        linkedthemes, 
        linkedsprints, 
        hideRoadmapsSection, 
        showRoadmapsSection, 
        hideInnovationSection, 
        showInnovationSection, 
        hideActionsSection, 
        showActionsSection, 
        roadmapVisible, 
        innovationVisible, 
        actionsVisible, 
        detail, 
        hideDetail, 
        onCellDataChanged,
        toggleSuccessRowModal} = props;

      const linkedrowsfiltered = filter(linkedrows.results, {success: detail.pk})
      const linkedrowsfilteredcount = linkedrowsfiltered.length 
      const linkedthemesfiltered = filter(linkedthemes.results, {success: detail.pk})
      const linkedthemesfilteredcount = linkedthemesfiltered.length 
      const linkedsprintsfiltered = filter(linkedsprints.results, {success: detail.pk})
      const linkedsprintsfilteredcount = linkedsprintsfiltered.length 


    function tabHeader() {
      console.log("Links",linkedthemes, linkedsprints, linkedthemesfiltered, linkedsprintsfiltered )
      return (
        <div key="TabHeader" className="tabs ml-0 pl-0" >
          <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
            <li 
              key="Linked Roadmaps" 
              className={selectedTab === "Linked Roadmaps" ? "is-active" : ""}
              onClick={() => setSelectedTab("Linked Roadmaps")}  
              >           
              <a className="ml-0 pl-0" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>LINKED ROADMAPS ({linkedrowsfilteredcount})</a>
            </li>
            |
            <li
              key="TalentManagement" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
              className={selectedTab === 'Linked Innovation Themes' ? "is-active" : ""}
              onClick={() => setSelectedTab('Linked Innovation Themes')}  
              >       
              <a style={{fontFamily: "Sans-serif", fontSize: "13px"}}>LINKED INNOVATION THEMES  ({linkedthemesfilteredcount})</a>
            </li>
            |
            <li
              key="Metrics" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
              className={selectedTab === 'Linked Actions' ? "is-active" : ""}
              onClick={() => setSelectedTab('Linked Actions')}  
              >       
              <a style={{fontFamily: "Sans-serif", fontSize: "13px"}}>LINKED SPRINT ACTIONS  ({linkedsprintsfilteredcount})</a>
            </li>
          </ul>
          {selectedTab=== "Linked Roadmaps" && <button className="button is-small is-pulled-right mt-1 pt-0 pb-0" style={{borderColor: backgroundcolor, color:backgroundcolor, borderRadius:"5px"}} onClick={() => toggleSuccessRowModal(true, detail.pk, 'Linked Roadmaps' )}>Edit Roadmap Links</button>}
          {selectedTab=== "Linked Innovation Themes" && <button className="button is-small is-pulled-right mt-1 pt-0 pb-0" style={{borderColor: backgroundcolor, color:backgroundcolor, borderRadius:"5px"}} onClick={() => toggleSuccessRowModal(true, detail.pk, 'Linked Innovaton Themes')}>Edit Innovation Links</button>}
          {selectedTab=== "Linked Actions" && <button className="button is-small is-pulled-right mt-1 pt-0 pb-0" style={{borderColor: backgroundcolor, color:backgroundcolor, borderRadius:"5px"}} onClick={() => toggleSuccessRowModal(true, detail.pk, 'Linked Sprints')}>Edit Action Links</button>}
      </div>
      )
    }

   
    return (
        <Fragment>
        <section className="p-0 m-0" style={{ fontFamily: "Gotham Extra"}}>
            <div style={{backgroundColor:backgroundcolor, color: textcolor}}>
              <div className="p-3">
                <p style={{fontSize: "20px", fontWeight: "600", color: "white"}}>{detail.name} </p>
                <p style={{fontSize: "17px",color: "white"}}>{detail.description} </p>      
                </div>
                <div className="pl-3 pt-2 pb-5 " style={{ backgroundColor: "white"}}>
                {tabHeader()}
                </div>
            </div>

            {/* <div className="buttons is-right pr-3">
                <a style={{fontFamily: "Sans-serif", color: "White", fontSize: "15px", fontWeight: "500", color: backgroundcolor}} onClick={hideDetail}>
                {"<< Back"}
                </a>
            </div> */}
          </section>

            
            {selectedTab === "Linked Roadmaps" &&
            <SuccessRoadmapActions 
                roadmapVisible = {roadmapVisible}
                hideRoadmapsSection = {hideRoadmapsSection} 
                showRoadmapsSection = {showRoadmapsSection}
                linkedrows = {linkedrows}
                success_id = {detail.pk}
                onCellDataChanged = {onCellDataChanged}
            />}

            {selectedTab === "Linked Innovation Themes" &&
            <SuccessThemeActions 
                innovationVisible = {innovationVisible}
                hideInnovationSection = {hideInnovationSection} 
                showInnovationSection = {showInnovationSection}
                linkedthemes = {linkedthemes}
                success_id = {detail.pk}
            />}

            {selectedTab === "Linked Actions" &&
            <SuccessSprintActions
                actionsVisible = {actionsVisible}
                hideActionsSection = {hideActionsSection} 
                showActionsSection  = {showActionsSection}
                linkedsprints = {linkedsprints}
                success_id = {detail.pk}
            />}

    </Fragment>
    )
}

export {SuccessLinks};