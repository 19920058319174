import React, {Fragment} from 'react';
import 'bulma/css/bulma.css';

function SuccessModalRowsDetail(props) {
    const {activetab, name, description, handleChangeName, handleChangeDescription} = props;


    function renderDetail() {
        if (activetab !== 'Details') {
            return null;
        }
        return (
            <Fragment>
 
            <div className='field is-horizontal is-align-items-center'>
                <label className='label field-label'>Vision Title</label>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                        {name !== undefined &&
                        <input
                            className='input'
                            type='text'
                            placeholder='Roadmap Title'
                            value={name}
                            onChange={handleChangeName}
                        />}
                    </div>
                </div>
            </div>

            <div className='field is-horizontal is-align-items-center'>
                <label className='label field-label'>Vision Description</label>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                    {description !== undefined &&
                    <textarea className='textarea' value={description} rows='10' onChange={handleChangeDescription}></textarea>}
                    </div>
                </div>
            </div>
 
        </Fragment>
        )
    }

    return (
        <Fragment>
                  {renderDetail()}
        </Fragment>
    );
}

export default SuccessModalRowsDetail;
