import React from "react";
import CompanyHeroSectionOnlyButton from "./CompanyHeroSectionOnlyButton";
import CompanyHeroSectionWithData from "./CompanyHeroSectionWithData";

function CompanyHeroSection(props) {
    const {herotitle, herosubtitle, heroimage, toggleModal} = props;
    const showDetail = herotitle || herosubtitle || heroimage;
    if (showDetail) {
        return <CompanyHeroSectionWithData
                    herotitle={herotitle}
                    herosubtitle={herosubtitle}
                    heroimage={heroimage}
                    toggleModal={toggleModal}
        />;
    } else {
        return <CompanyHeroSectionOnlyButton toggleModal={toggleModal} />;
    }
}

export default CompanyHeroSection;
