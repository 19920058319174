import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TalentPositionService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/talent/position/`;
  }

  async getTalentPosition() {
    const position = await this.getAll();
    return position.results;
  }
}

export const talentpositionService = new TalentPositionService();
