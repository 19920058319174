import React, { useEffect, useState } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../redux/actions/users";
import Moment from "react-moment";

function UserCreationForm(props) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const userError = useSelector((state) => state.users.error);
  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    role: 0,
    password: "",
    password_repeat: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();

  const handleOnChange = (event) => {
    const newUserData = {
      ...userData,
      [event.target.name]: event.target.value,
    };
    setUserData(newUserData);
  };

  const onSave = async () => {
    if (userData.password !== userData.password_repeat) {
      setPasswordError("Passwords must match.");
      return;
    }
    if (userData.password.trim() === "") {
      setPasswordError("Password can't be empty.");
      return;
    }
    setPasswordError("");
    await dispatch(usersActions.createUser(userData, props.onSave));
    setSaved(true);
  };

  if (currentUser.role < 2) {
    return null;
  }

  return (
    <>
      <h1 className="title">Create new user</h1>

      <label className="label">Email</label>
      <div className="field has-addons">
        <div className="control is-expanded has-icons-left has-icons-right">
          <input
            name={"email"}
            className="input"
            type="email"
            value={userData.email}
            onChange={handleOnChange}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"> </i>
          </span>
        </div>
        <div className="control">
    <a className="button is-static">
      @gmail.com
    </a>
  </div>
      </div>



      <div className="field">
        <label className="label">First name</label>
        <div className="control">
          <input
            name={"first_name"}
            className="input"
            type="text"
            value={userData.first_name}
            onChange={handleOnChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Last name</label>
        <div className="control">
          <input
            name={"last_name"}
            className="input"
            type="text"
            value={userData.last_name}
            onChange={handleOnChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Role</label>
        <div className="control">
          <div className="select">
            <select
              value={userData.role}
              name={"role"}
              onChange={handleOnChange}
            >
              <option value={0}>Read only</option>
              <option value={1}>Read and write</option>
              <option value={2}>Administration rights</option>
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">New password</label>
        <p className="control has-icons-left">
          <input
            className="input"
            type="password"
            name={"password"}
            placeholder="New password"
            value={userData.password}
            onChange={handleOnChange}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-lock"> </i>
          </span>
        </p>
      </div>

      <div className="field">
        <label className="label">Repeat new password</label>
        <p className="control has-icons-left">
          <input
            className="input"
            type="password"
            name={"password_repeat"}
            placeholder="Repeat new password"
            value={userData.password_repeat}
            onChange={handleOnChange}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-lock"> </i>
          </span>
        </p>
        {passwordError !== "" && (
          <p className="help is-danger">{passwordError}</p>
        )}
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link" onClick={onSave}>
            Create
          </button>
        </div>
      </div>

      {userError && (
        <div className="notification is-danger">
          <button
            className="delete"
            onClick={() => {
              setSaved(false);
              dispatch(usersActions.clearError());
            }}
          >
            {" "}
          </button>
          {userError}
        </div>
      )}

      {saved && !userError && (
        <div className="notification is-success">
          <button className="delete" onClick={() => setSaved(false)}>
            {" "}
          </button>
          Data saved
        </div>
      )}
    </>
  );
}

export { UserCreationForm };
