import React, { Fragment, useState } from "react";
import "./common/css/aside.css";
import "./common/css/masterstyles.css";
import "./common/css/sprints.css";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../redux/actions/menu";
import { loginActions } from "../redux/actions/login";
//import { aboutModalActions } from "../redux/actions/aboutModal";
//import { AboutModal } from "./About";
import { tenantsService } from "../services/TenantsService";
//import NavBarTitle from "./common/NavBarTitle";
import { useHistory } from "react-router-dom";
//import logo from "./common/images/menulogo.png";
//import hamburgerIcon from "./common/images/hamburger.svg";
import {menuModalActions } from "../redux/actions/menuModal";
import {MenuModal} from "./MenuModal";
import ConfirmLogout from "./../components/common/ConfirmLogout";
import {setColor} from "./../redux/toolkit/features/colors";

function Menu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTenant = useSelector((state) => state.tenants.currentTenant);
  const currentUser = useSelector((state) => state.users.currentUser);
  const activeMenu = useSelector((state) => state.menu.active);
  const [showLogoutModal, setShowLogoutModal] = useState(null)
  //const ipath = tenantsService.logoUrl(currentTenant)

  if (!currentTenant) {
    return null;
  }

  dispatch(setColor({backgroundcolor:currentTenant.backgroundcolor,textcolor:currentTenant.textcolor}))

  function onClickChangeMenu(name) {
    dispatch(menuActions.setActiveMenu(name));
    history.push(`/${name}`);
  }

  function handleOnClickMenu(event) {
    dispatch(menuModalActions.openModal());
  }

  function toggleLogoutModal(open) {
    setShowLogoutModal(open)
  }

  function logout() {
    if (window.confirm("Logout?")) {
      dispatch(loginActions.logout());
    }
  }

  var menucolor='rgb(45,45,45)'

  return (
    <Fragment>
      <nav className="navbar is-fixed-top has-shadow" style={{backgroundColor:'white', height: '3.25rem'}}role="navigation" aria-label="main navigation">
        <div className="navbar-brand">      
          <div className="navbar-item pl-3">
            <div className="centerimage" style={{userSelect: "none"}}>
                {currentTenant && (
                  <img
                    className="menuimage"
                    alt="Company Logo"
                    src={tenantsService.logoUrl(currentTenant)}
                    style={{ maxHeight: "2rem", maxWidth: "180px", width: "auto", height: "auto" }}
                  />
                )}
            </div>
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          </div>
          <div className="navbar-menu">  
          <div className="navbar-end">     
              <div className="navbar-item">
                { activeMenu === "company"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("company")}>Company</a> : <a style={{color:'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("company")}>Company</a>}
              </div>
              <div className="navbar-item pl-2 has-text-white">
                { activeMenu === "success"? <a style={{color: menucolor,caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("success")}>Success Vision</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("success")}>Success Vision</a>}
              </div>
              <div className="navbar-item pl-2 ">
                { activeMenu === "roadmaps"? <a style={{color: menucolor,caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("roadmaps")}>Roadmaps</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("roadmaps")}>Roadmaps</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "sprints"? <a style={{color: menucolor,caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("sprints")}>Sprints</a> : <a style={{color:'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("sprints")}>Sprints</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "innovation"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("innovation")}>Innovation</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("innovation")}>Innovation</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "organization"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("organization")}>People</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("organization")}>People</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "esg"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("esg")}>Stakeholders</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("esg")}>Stakeholders</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "investments"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("investments")}>Investments</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("investments")}>Investments</a>}
              </div>

            <div className="navbar-item has-dropdown is-hoverable pr-3">
              <a className="navbar-item" style={{ fontSize: "1em"}}>
                <i className="fa fa-user" style={{color: 'rgb(90,90,90'}}/>
              </a>
              <div className="navbar-dropdown is-right">
          <a className="navbar-item" onClick={() => onClickChangeMenu("profile")}>
            My Profile
          </a>
          <a className="navbar-item is-selected" onClick={() => onClickChangeMenu("settings")}>
            Settings
          </a>
          <a className="navbar-item" onClick={() => onClickChangeMenu("support")}>
            Contact
          </a>
          <hr className="navbar-divider"/>
          <a className="navbar-item" onClick={() => toggleLogoutModal(true)}>
            Logout
          </a>
          </div>
        </div>
        </div>
        </div>  
      </nav>

      <ConfirmLogout
        showLogoutModal={showLogoutModal}
        toggleModal={toggleLogoutModal} 
      />

    </Fragment>



  );
}

export { Menu };
