import React, { Fragment, useState, useEffect, useContext} from "react";
import { useForm } from "react-hook-form";
import { filter } from "lodash";
import { cellValuesService } from "./../../services/CellValuesService";
import { FormatString } from "./../common/FormatCell";
import { roadmapdatasets } from '../Roadmaps';

function CellValue(props) {
    const {sectionPk, rowPk, year, type, formula, percentages, isbaseline, numericcell, textcell, decimalplaces, mediumalert, severealert} = props;
    const {cellvalues, cagr, growth, baselinedelta} = useContext(roadmapdatasets);
    let initalnumericvalue=null;
    if (numericcell !== null) {
        initalnumericvalue=FormatString(numericcell, type, decimalplaces)
    }
    const [numericvalue, setNumericValue] = useState(initalnumericvalue );   
    const [textvalue, setTextValue] = useState(textcell);   
    // const [hasChanged, setHasChanged] = useState(false);
    // const [data, setData] = useState(null);   
    const [inputcellnumber, setInputCellNumber] = useState(numericcell ? numericcell: null)
    var cagrdata = filter(cagr.results,{year: year, row_id: rowPk})
    let growthdata = filter(growth.results,{year: year, row_id: rowPk})
    let dataarray = filter(cellvalues.results,{year: year, row_id: rowPk})
    let numericValues= (dataarray[0]?.numeric_value || null)
    let baselinedata = filter(baselinedelta,{year: year, row_id: rowPk})
    let cellkey= (dataarray[0]?.pk || null)



        async function handleCellChange(event) {

        if ((event.type==='blur' || event.keyCode === 13)) {
            var cellstring = null;
            if (numericvalue !== null) {
            cellstring = numericvalue.replace(/,/g,"")
            cellstring = cellstring.replace(/%/g,"")
            }

            if (numericcell !== cellstring) {
                let data = filter(cellvalues.results, {year: year, row_id: rowPk});
                if (data.length===0) {
                    await cellValuesService.post({numeric_value: cellstring, year: year, row: rowPk});
                } else {
                    console.log("Cell POst", cellstring, data[0].pk)
                    await cellValuesService.patch(data[0].pk, {numeric_value:cellstring});
                }
            }
            if (cellstring!== "") {
                setNumericValue(FormatString(cellstring, type, decimalplaces))
            }
            
        }
        }

    function handleCellupdate(event) {
        let result=""
        if (!isNaN(Number(event.target.value.replace(/,/g,"")))) {

            let cellstring = event.target.value.replace(/,/g,"")

            switch(type) {
                case null:
                    return ""
                case 'Number':
                    if (cellstring === "") {
                        setNumericValue(null)
                        } else {
                        if (decimalplaces === 0) {
                            result=Number((Math.round(cellstring * 100) / 100).toFixed(0)).toLocaleString('en') 
                            setNumericValue(result)
                        } else {
                            let leftstring=""
                            let rightstring=""
                            if (cellstring.indexOf(".") >= 0) {
                                var parts = cellstring.split('.', 2);
                                leftstring=parts[0]
                                rightstring=("." + parts[1]).substring(0,decimalplaces+1);

                            } else {
                                leftstring=cellstring
                                rightstring=""
                            }
                        result=Number((Math.round(leftstring * 100) / 100).toFixed(0)).toLocaleString('en') + rightstring
                        setNumericValue(result)
                        }
                    }
                    break;
                case 'Percentage':
                    if (cellstring === "") {
                        setNumericValue(null)
                        } else {
                        if (decimalplaces === 0) {
                            result=Number((Math.round(cellstring * 100) / 100).toFixed(0)).toLocaleString('en') 
                            setNumericValue(result)
                        } else {
                            let leftstring=""
                            let rightstring=""
                            if (cellstring.indexOf(".") >= 0) {
                                var parts = cellstring.split('.', 2);
                                leftstring=parts[0]
                                rightstring=("." + parts[1]).substring(0,decimalplaces+1);

                            } else {
                                leftstring=cellstring
                                rightstring=""
                            }
                        result=Number((Math.round(leftstring * 100) / 100).toFixed(0)).toLocaleString('en') + rightstring
                        setNumericValue(result)
                        }
                    }
                    break;
                default: 
                    setNumericValue(cellstring)

        }
    }}
    
    async function handleCellTextChange(event) {

        setTextValue(event.target.value)
        if (event.type==='blur' && event.target.value !== "") {
            let data = filter(cellvalues.results, {year: year, row_id: rowPk});
            if (data.length===0) {
                await cellValuesService.post({text_value: textvalue, year: year, row: rowPk});
            } else {
                await cellValuesService.patch(data[0].pk, {text_value: textvalue});
            }
        }
    }


    function GrowthValue() {
        if (growthdata[0] && type !== 'Text') {
            var growthtextvalue=(Number(growthdata[0].growth).toFixed(2))
        return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} value={growthtextvalue+"%"} readOnly/>
        )
        }
        else {
            return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", pointerEvents: "none"}} value={null} readonly/>
            )
        }

    }

    function CAGRValue() {
        if (cagrdata[0] && type !== 'Text') {
            var cagrtextvalue=(Number(cagrdata[0].growth).toFixed(2))
        return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} value={cagrtextvalue+"%"} readOnly/>
        )
        }
        else {
            return (
                <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", pointerEvents: "none"}} value={null} readonly/>
            )
        }

    }

    function BaselineValue() {
        if ((filter(baselinedelta, {row_id: rowPk})).length !== 0 && isbaseline) {
            if (baselinedata[0]) {
                var deltatextvalue=(Number(baselinedata[0].growth).toFixed(2))

            }
            if (baselinedata[0]) {

            return (
                <Fragment>
                    <input className="mt-2" style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem"}} value={FormatString(baselinedata[0].baseline, type, decimalplaces)} readonly/>
                    <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem"}} value={FormatString(baselinedata[0].difference, type, decimalplaces)} readonly/>
                    <input className="mb-3" style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem"}} value={deltatextvalue+'%'} readonly/>
                </Fragment>
            )
            }
            else {
                return (
                    <Fragment>
                    <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem"}} value={null} readonly/>
                    <input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem"}} value={null} readonly/>
                    <input className="mb-3" style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem"}} value={null} readonly/>
                    </Fragment>
                )
            }
        }
    }


   
    function renderFields() {
        let key = `${rowPk}_${year}`;
        let textValue = null
        let baselinerow = filter(baselinedelta, {year: year, row_id: rowPk})

        let delta = null;
        if (baselinerow.length !== 0) {
            delta=baselinerow[0].growth
        }
        let alertstatus=null;
        if ((severealert < 0 && delta < severealert) || (severealert > 0 && delta > severealert)){
            alertstatus = "high";
        } else if ((mediumalert < 0 && delta < mediumalert) || (mediumalert > 0 && delta > mediumalert)){
            alertstatus = "moderate";
        }

        let rowstyle=null;
        
        if (alertstatus === "high") {
            rowstyle={border:"2px red solid"}
        } 
        
        if (alertstatus === "moderate") {
            rowstyle={border:"2px gold solid"}
        }

        console.log("Formula", formula)

        switch(type) {
            case "Text":
                return (
                    <Fragment>
                        {textcell &&
                            <textarea key={key} className="mb-0 pl-2 pr-2" style={{resize: "none"}} rows="5" value={textvalue} onChange={handleCellTextChange} onBlur={handleCellTextChange}/> 
                        }
                        {!textcell &&
                            <textarea key={key}  className="mb-0 pl-2 pr-2" style={{resize: "none"}} rows="5" value={null} onChange={handleCellTextChange} onBlur={handleCellTextChange}/>
                        }
                    </Fragment>
                )

            case "Number":
                if (formula===null) {
                return (
                    <Fragment>
                        {inputcellnumber &&
                            <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange}/>
                        }
                        {!inputcellnumber &&
                            <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange} />
                        }
                    </Fragment>
                )
            } else {
                return (
                <Fragment>
                {inputcellnumber &&
                    <input key={key} className="mb-0 has-text-centered" type="text" style={{rowstyle, userSelect:"none", pointerEvents: "none", border: "none"}} value={numericvalue}/>
                }
                {!inputcellnumber &&
                    <input key={key} className="mb-0 has-text-centered" type="text" style={{rowstyle, userSelect:"none", pointerEvents: "none", border: "none"}} value={numericvalue}/>
                }
                </Fragment>
                )
            }

                case "Percentage":
                    return (
                        <Fragment>
                            {inputcellnumber &&
                                <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange}/>
                            }
                            {!inputcellnumber &&
                                <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange} />
                            }
                        </Fragment>
                    )                

        } 
      
    }

    return (
        <Fragment>
        <td key={'cellrender'} style={{textAlign:"center"}}>
            {renderFields()}
            {isbaseline && <Fragment>{BaselineValue()} </Fragment>}
            {(percentages && type !== "Text") && <Fragment>{GrowthValue()} {CAGRValue()} </Fragment>}

        </td>

        </Fragment>
    )
}

export { CellValue }; 
