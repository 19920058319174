import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, competencypk: null };

const modalSlice = createSlice({
  name: "competenciesModal",
  initialState,
  reducers: {
    openCompetencyModal(state, action) {
      state.isOpen = true;
      state.competencypk = action.payload;
    },
    closeCompetencyModal(state) {
      state.isOpen = false;
      state.competencypk = null;
    },
  },
});

export const { openCompetencyModal, closeCompetencyModal } = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.competenciesModal;

