import React, { Fragment, useState, useEffect } from "react";
import "../common/css/masterstyles.css";
import { useSelector, useDispatch } from 'react-redux'
import { Idea } from "./Idea";
import {filter} from "lodash"
import {ThemeChart} from "./ThemeChart";
import {openThemeModal} from "./../../redux/toolkit/features/innovation/themeModalToggle";
import { ideaActions } from "./../../redux/actions/idea";
import {fetchAllThemes} from "./../../redux/toolkit/features/themes";


function Theme(props) {
  const { theme, description, pk, targetvalue, units, basevalue,toggleIdeaModal,showtargets, setIdeaRowID} = props;
  const [selectedTab, setSelectedTab] = useState("All");
  const [isAllRowSelected, setIsAllRowSelected] = useState(false);
  const ideas = useSelector((state) => state.ideas.ideas);
  const themes = useSelector((state) => state.themes.entities);
  //const categories = useSelector((state) => state.categories);
  const dispatch = useDispatch(); 
   let ideasfiltered=filter(ideas,{theme: pk})
  var checkboxstatus=[];
  for (let i = 0; i < ideasfiltered.length; i++) {
    checkboxstatus.push(false)
  }

  useEffect(() => {
    dispatch(fetchAllThemes())
}, [dispatch]);

  function charts(checkedarray) {

    let ideasarray=[];
    if (checkedarray) {
    for (let i = 0; i < checkedarray.length; i++) {
      if (checkedarray[i]) {
        ideasarray.push(i)
      }
    }
  }
    //let complist= filter( ideas, (row) => ideasarray.includes(row.pk));
    return (
      <Fragment>
      <div className="mt-5 ml-5 mr-5" style={{backgroundColor:'white'}}>
        <ThemeChart
          ideasfiltered={ideasfiltered}
          basevalue={basevalue}
          targetvalue={targetvalue}
          units={units}
          theme={theme}
          themeModalKey={pk}
          ideasarray={ideasarray}
          idead={ideas}
        />
      </div>

    </Fragment>
    )
  }

  if (!themes) {
    return null;
  }

  return (
    <Fragment>
      <section className='mb-6 pb-6' style={{backgroundColor: 'rgb(240,240,240)', border:'1px rgb(235,235,235) solid', borderRadius: '5px'}}>
        <div style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}}>
          <div className='pt-5 pb-4 pl-5 pr-4' onDoubleClick={() => dispatch(openThemeModal(pk))}>
            {theme}
            <br/>
          </div>
          <div className='pl-5 pr-4' style={{backgroundColor: 'rgb(240,240,240)'}} onDoubleClick={() => dispatch(openThemeModal(pk))}>
            <span style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
            {description}
            </span>
          </div>
        </div>

        <Idea
          ideasfiltered={ideasfiltered}
          themeId={pk}
          units={units}
          isAllRowSelected={isAllRowSelected}
          themeModalKey={pk}
          checkboxstatus={checkboxstatus}
          charts={charts}
          setIdeaRowID={setIdeaRowID}
        />

        {showtargets &&
          charts()
        }
      </section>
    </Fragment>
  );
}

export { Theme };
