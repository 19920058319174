import { userConstants } from "../constants/users";
import { usersService } from "../../services/UsersService";
import { alerts } from "./alerts";

export const usersActions = {
  getCurrentUser,
  saveUser,
  createUser,
  changePassword,
  getUserList,
  clearError,
};

function getCurrentUser() {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await usersService.getCurrentUser();
      dispatch(success(response));
    } catch (err) {
      dispatch(alerts.error(err.toString()));
      dispatch(failure(err.toString()));
    }
  };

  function request() {
    return { type: userConstants.GET_CURRENT_USER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_CURRENT_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_CURRENT_USER_FAILURE, error };
  }
}

function saveUser(id, data, onSave) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const user = await usersService.put(id, data);
      console.log("saved user", user);
      if (onSave) {
        onSave(); // in this case the user is changing another user, the list of users will be retrieved
      } else {
        dispatch(success(user));
      }
    } catch (err) {
      dispatch(failure(err.toString()));
    }
  };

  function request() {
    return { type: userConstants.SAVE_PROFILE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.SAVE_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SAVE_PROFILE_FAILURE, error };
  }
}

function createUser(data, onSave) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const user = await usersService.post(data);
      console.log("created user", user);
      dispatch(success(user));
      if (onSave) {
        onSave(); // in this case the user is creating another user, the list of users will be retrieved
      }
    } catch (err) {
      console.log("User creation error", err.response);
      dispatch(failure(JSON.stringify(err.response.data)));
    }
  };

  function request() {
    return { type: userConstants.CREATE_USER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.CREATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CREATE_USER_FAILURE, error };
  }
}

function changePassword(id, data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const user = await usersService.changePassword(id, data);
      console.log("changed password", user);
      dispatch(success(user));
    } catch (err) {
      dispatch(alerts.error(err.toString()));
      dispatch(failure(err.toString()));
    }
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(user) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function getUserList(queryParams) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const listResponse = await usersService.getAll(queryParams);
      dispatch(success(listResponse));
    } catch (err) {
      dispatch(alerts.error(err.toString()));
      dispatch(failure(err.toString()));
    }
  };

  function request() {
    return { type: userConstants.GET_USER_LIST_REQUEST };
  }
  function success(listResponse) {
    return { type: userConstants.GET_USER_LIST_SUCCESS, listResponse };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_LIST_FAILURE, error };
  }
}

function clearError() {
  console.log("ClearError action");
  return { type: userConstants.CLEAR_USER_ERROR };
}
