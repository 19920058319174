import React, { Fragment, useEffect } from "react";
import {filter} from "lodash"


function SprintRows(props) {
    const {sprintinvestment, sprintinvestmentcell} = props;
   
    return (

        <Fragment>
            {sprintinvestment && sprintinvestment.results.map(sprint => (
                    <tr>
                        <td>{sprint.item}</td>
                    </tr>
                    ))}
            <td>Rows</td>
        </Fragment>


    )
}

export { SprintRows };
