import React, {Fragment, useEffect, useState, useContext} from "react";
import { useSelector, useDispatch } from 'react-redux'
//import * as ReactDOM from 'react-dom';
//import "bulma/css/bulma.css";
import {filter} from "lodash"
//import { useForm } from "react-hook-form"
//import { DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
//import "./../../common/css/index.css"
//import "./../../common/css/material.css"
//import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
//import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { roadmapdatasets } from '../../Roadmaps';
import { closeRoadmapChartModal } from "../../../redux/toolkit/features/roadmaps/chartDetailModal";
import {chartService} from "./../../../services/RoadmapsChartService";


function SectionsModalEditCharts(props) {
    const chartpk = useSelector((state) => state.roadmapChartModal.chartpk)
    const {charts, onChartDataChanged, charttitle, chartsubtitle, chartyaxis, charttype} = props;
    const [chart, setChart] = useState(null);
    //const {sections, rows, categories} = useContext(roadmapdatasets);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showSectionsChartModal = useSelector((state) => state.roadmapChartModal.isOpen)

    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')    
    const [yaxis, setYaxis] = useState('') 
    const [type, setType] = useState('')
    const dispatch = useDispatch();

    async function fetchCharts() {
        if (chartpk !== null) {
            const chart = await chartService.getChartID(chartpk);
            setTitle(chart.chart_title);
            setSubtitle(chart.chart_subtitle)
            setYaxis(chart.chart_yaxislabel)
            setType(chart.chart_type)
            setChart(chart);
        }
    }

    useEffect(() => {
        async function fetchData() {
            await fetchCharts();
        }
        fetchData();
    },[chartpk]);

    console.log("Charts", chart, chartpk)
    let modalClass = "modal";
    if (showSectionsChartModal) {
        modalClass += " is-active";
    }

    function handleChangeChartTitle(event) {
        console.log("Chart Title", event.target.value)
        setTitle(event.target.value);
    }

    function handleChangeChartSubtitle(event) {
        setSubtitle(event.target.value);
    }

    function handleChangeChartType(event) {
        setType(event.target.value);
    }

    function handleChangeChartYAxis(event) {
        setYaxis(event.target.value);
    }

    useEffect(() => {
        setTitle(charttitle!==null?chart.chart_title:'')
      }, [charttitle])

      useEffect(() => {
        setSubtitle(chartsubtitle!==null?chart.chart_subtitle:'')
      }, [chartsubtitle])

      useEffect(() => {
        setYaxis(chartyaxis!==null?chart.chart_yaxislabel:'')
      }, [chartyaxis])

      useEffect(() => {
        setType(charttype!==null?chart.chart_type:'')
      }, [charttype])


    let pulldownoptions = [
        { "Id": "Line", "Type": "Line Chart" },
        { "Id": "Bar", "Type": "Bar Chart" },
        { "Id": "Stacked", "Type": "Stacked Chart" },
        { "Id": "Waterfall", "Type": "Waterfall Chart"}
    ]


    // function buildlist() {
    //     let dataarray=[];
    //     let counter = 1;
    //     for (const row of categories) {
    //         let sectionlist = filter(sections.results,{category:row}) 
    //         if (sectionlist.length !== 0) {
    //             let sectionids = sectionlist.map(r => r.pk)
    //             let rowlist=filter( rows, (row) => sectionids.includes(row.section))
    //             if (rowlist.length !== 0 ){
    //                 let categoryobj={};
    //                 categoryobj['id']=counter;
    //                 categoryobj['name']=row;
    //                 categoryobj['hasChild']=true;
    //                 categoryobj['expanded']=true;
    //                 categoryobj['showCheckbox']=false;         
    //                 dataarray.push(categoryobj)
    //                 let categorycounter = counter
    //                 counter=counter + 1
        
    //                 for (const sectionrow of sectionlist) {
    //                     let rowlist = filter(rows,{section:sectionrow.pk})
    //                     if (rowlist.length!==0) {
    //                         let sectionobj={};
    //                         sectionobj['id']=counter;
    //                         sectionobj['name']=sectionrow.name;
    //                         sectionobj['pid']=categorycounter;
    //                         sectionobj['hasChild']=true;
    //                         sectionobj['expanded']=false;
    //                         dataarray.push(sectionobj)
    //                         let sectioncounter = counter;
    //                         counter=counter + 1


    //                         for (const rowrow of rowlist) {
    //                             let rowobj={};
    //                             rowobj['id']=counter;
    //                             rowobj['name']=rowrow.name;
    //                             rowobj['pid']=sectioncounter;
    //                             rowobj['hasChild']=false;
    //                             rowobj['expanded']=false;
    //                             dataarray.push(rowobj)
    //                             counter=counter + 1
    //                         }
    //                     } 
    //                }
    //             }
    //         }
       
    //     }
    //     return (dataarray)
    // }

    // const fields = { dataSource: buildlist(), value: 'id', parentValue: 'pid', text: 'name', hasChildren: 'hasChild' };
    // const showCheckBox = true;
    // const [treeSettings, setTreeSettings] = useState({
    //     autoCheck: false
    // });



    // useEffect(() => {
    //     setTitle(titlevalue);
    //     setSubtitle(subtitlevalue);
    //     setYaxis(yaxisvalue);
    //     setType(typevalue);
    // }, [titlevalue, subtitlevalue, yaxisvalue, typevalue]);




   

    // const handleChangeChartRows = (args) => {
    //     // update the text and value property values in property panel based on selected item in Dropdown Tree
    //     //setValue(args.value && args.value.length > 0 ? args.value[0] : '');
    //     setText(args.element.value);
    // };

    function modalform() {
        return (
            <Fragment>
                <div className='control-pane'>
                    <div className='control-section'>

                        <p className="pb-1 pl-1" style={{fontSize:"small"}}>Chart Title</p>
                        <input className="input" type="text" value={title} onChange={e => handleChangeChartTitle(e)} />

                        <p className="pb-1 pl-1 pt-3" style={{fontSize:"small"}}>Chart Subtitle</p>
                        <input className="input" type="text" value={subtitle} onChange={e => handleChangeChartSubtitle(e)} />

                        <p className="pb-1 pl-1 pt-3" style={{fontSize:"small"}}>Chart Y-Axis Label</p>
                        <input className="input" type="text" value={yaxis} onChange={e => handleChangeChartYAxis(e)} />

                        <p className="pb-1 pl-1 pt-3" style={{fontSize:"small"}}>Chart Type</p>
                        <select className="select is-fullwidth" value={type} onChange={e => handleChangeChartType(e)}>
                            {type==='' && <option value={'combo'}>{'<Select Chart Type>'}</option>}
                            <option value={'combo'}>Combo Chart</option>
                            <option value={'line'}>Line Chart</option>                          
                            <option value={'stacked'}>Stacked Chart</option>
                            <option value={'waterfall'}>Waterfall Chart</option>                                
                        </select>                  
 

                        {/* <div className="mt-4" style={{fontSize: "14px"}}><b>Chart Rows</b></div>
                        <div className="mt-4">
                            <div className='control-pane dropdowntree-default'>
                                <div className='col-lg-8'>
                                    <div className="content-wrapper">
                                        <DropDownTreeComponent fields={fields} showCheckBox={showCheckBox} mode="Delimiter" placeholder="Select items" popupHeight="200px" treeSettings={treeSettings} change={handleChangeChartRows.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>


          {/* <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Chart Type</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="field is-horizontal is-align-items-center">
                        <div className="select is-flex">
                            {charttype !== undefined &&
                            <select className="select is-fullwidth" value={type} onChange={handleChangeChartType} defaultValue={type}>
                                <option value={'combo'}>Combo Chart</option>
                                <option value={'line'}>Line Chart</option>                          
                                <option value={'stacked'}>Stacked Chart</option>
                                <option value={'waterfall'}>Waterfall Chart</option>                                
                            </select>}
                        </div>
                    </div>
                </div>
            </div>
        </div> */}




        </Fragment>
        )
    }

        if (chart === null) {
            return null;
        }


    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>CHART SETUP</p>
                    <button className="delete" aria-label="close" onClick={() => dispatch(closeRoadmapChartModal())}>
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody" style={{minHeight: "450px"}}>
                  {modalform()}
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    <button
                        style={{borderRadius:"4px"}}
                        className="button is-success is-small"
                        key="save"
                        onClick={async () => {
                            dispatch(closeRoadmapChartModal());
                            onChartDataChanged(chartpk, title, subtitle, yaxis,'line');
                        }}
                    >
                        Save changes
                    </button>
                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeRoadmapChartModal())}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default SectionsModalEditCharts;
