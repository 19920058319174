import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import {filter} from "lodash"
import ConfirmDeleteBox from "./../common/ConfirmDeleteBox"

function CategoryModal(props) {
  const {categories, isCategoryModalOpen, toggleModal, categorymodalkey, onCategoryChange, onCategoryDelete} = props;
  let category=filter(categories,{pk: categorymodalkey})
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showDeleteModal, setShowDeleteModal]= useState(false)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  const namevalue = category[0]?category[0].name:'';
  const descriptionvalue = category[0]?category[0].description:'';

  useEffect(() => {
    setName(namevalue);
    setDescription(descriptionvalue)
  }, [categories, namevalue, descriptionvalue]);

  let modalClass = "modal";
  if (isCategoryModalOpen) {
    modalClass += " is-active";
  }


  function deleteHandler(result){
    if (result) {
      onCategoryDelete(categorymodalkey)
    }
    setShowDeleteModal(false)
  }

  function renderContent() {
    return (
      <Fragment>
        <p className="pb-2 pl-1" style={{fontSize:"12px", fontWeight: "500", userSelect:'none'}}>Category Name:</p>
        <input
          className="input"
          type="text"

          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <p className="pb-2 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500", userSelect:'none'}}>Category Trends:</p>

          <textarea
            className="textarea"
            type="text"
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
      </Fragment>
    );
  }

  return (
    <Fragment>
    <div className={modalClass} key="categoryModal">
      <div className="modal-background" key="modalBackground">
        {" "}
      </div>
      <div className="modal-card" key="modalCard">
        <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor, userSelect:'none'}}>
          <p className="modal-card-title" style={{fontSize:"17px", color: textcolor, userSelect:'none'}}>{categorymodalkey?"EDIT CATEGORY":"NEW CATEGORY"}</p>
          <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
            {" "}
          </button>
        </header>
        <section className="modal-card-body" key="modalBody">
          {renderContent()}
        </section>
        <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
          <button
            style={{borderRadius:"4px"}}
            className="button is-success is-small"
            key="save"
            onClick={async () => {
              await onCategoryChange(categorymodalkey, name, description);
              toggleModal(false);
          }}
          >
            Save changes
          </button>
          <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => toggleModal(false)}>
            Cancel
          </button>
          <span className="is-flex-grow-1" />
          {categorymodalkey && (
            <button
              style={{borderRadius:"4px"}}
              className="button is-danger is-small"
              key="delete"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </button>
          )}
        </footer>
      </div>
    </div>
    <ConfirmDeleteBox 
      showModal={showDeleteModal}
      modaltext={name}
      deleteHandler={deleteHandler}
      />
    </Fragment>

  );
}

export default CategoryModal;
