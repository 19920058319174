import update from 'immutability-helper'
import React, { useCallback, useState, useEffect } from 'react'

import {Card} from './Card.js'
import {successService} from "../../../../services/SuccessService";

export const Container = (props) => {
    const [success, setSuccess] = useState(props.success);
    const {toggleConfirmDeleteModal, toggleSuccessRecordModal, getSuccessOnMainPage} = props;

    useEffect(() => {
        setSuccess(props.success);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setSuccess((prevSuccess) => {
                const newSuccess = update(prevSuccess, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevSuccess[dragIndex]],
                    ],
                });
                const newSuccessPks = newSuccess.map(successvalue => successvalue.pk);
                successService.sortSuccess(newSuccessPks).then(() => getSuccessOnMainPage());
                return newSuccess;
            }
        )
    }, [])

    const renderSuccess = useCallback(
        (successvalue, index) => {
            return (
                <Card
                    key={successvalue.pk}
                    index={index}
                    id={successvalue.pk}
                    text={successvalue.name}
                    moveCard={moveCard}
                    rowPk={successvalue.pk}
                    toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                    toggleSuccessRecordModal={toggleSuccessRecordModal}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{success.map((card, i) => renderSuccess(card, i))}</div>
        </>
    )
}