import React from "react";
import { useSelector } from 'react-redux';
import { RotatingLines } from "react-loader-spinner";

function Spinner() {
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)

    return(
        <div style={{height: '300px', paddingTop: '8em', textAlign: 'center'}}>
        <RotatingLines
          strokeColor={backgroundcolor}
          strokeWidth="4"
          animationDuration="0.75"
          width="56"
          visible={true}
        />
        </div>
    )
}

export default Spinner;
