import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'

import {Card} from './Card.js'
import {valuesService} from "../../../../services/ValuesService";

export const Container = (props) => {
    const [values, setValues] = useState(props.values);
    //const {toggleConfirmDeleteModal, toggleValuesRecordModal, getValuesOnMainPage} = props;

    useEffect(() => {
        setValues(props.values);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setValues((prevValues) => {
                const newValues = update(prevValues, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevValues[dragIndex]],
                    ],
                });
                const newValuesPks = newValues.map(value => value.pk);
                valuesService.sortValues(newValuesPks)//.then(() => getValuesOnMainPage());
                return newValues;
            }
        )
    }, [])

    const renderValue = useCallback(
        (value, index) => {
            return (
                <Card
                    key={value.pk}
                    index={index}
                    id={value.pk}
                    text={value.value}
                    moveCard={moveCard}
                    rowPk={value.pk}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{values.map((card, i) => renderValue(card, i))}</div>
        </>
    )
}