import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'

import {Card} from './Card.js'
import {factsService} from "../../../../services/FactsService";
import {usersActions} from "../../../../redux/actions/users";
import {tenantsActions} from "../../../../redux/actions/tenants";
import {alerts} from "../../../../redux/actions/alerts";

export const Container = (props) => {
    const [facts, setFacts] = useState([]);

    useEffect(() => {
        setFacts(props.facts);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setFacts((prevFacts) => {
                const newFacts = update(prevFacts, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevFacts[dragIndex]],
                    ],
                });
                const newFactsPks = newFacts.map(fact => fact.pk);
                factsService.sortFacts(newFactsPks)//.then(() => getFactsOnMainPage());
                return newFacts;
            }
        )
    }, [])

    const renderFact = useCallback(
        (fact, index) => {
            return (
                <Card
                    key={fact.pk}
                    index={index}
                    id={fact.pk}
                    text={fact.fact}
                    moveCard={moveCard}
                    rowPk={fact.pk}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{facts.map((card, i) => renderFact(card, i))}</div>
        </>
    )
}