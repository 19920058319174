import React, {Fragment } from "react";
import "bulma/css/bulma.css";

function ConfirmDeleteModal(props) {
    const {toggleModal, showModal, defaultbackgroundcolor, defaulttextcolor, extrakey, modaltext, recordkey, onValuesRecordDelete, onFactsRecordDelete, onTimelineRecordDelete, onSuccessRecordDelete, onSprintRecordDelete, onRoadmapsRecordDelete, onRoadmapsSectionDelete, deleterecordsource, onSprintCommentDelete, onSprintWorkersDelete, onSprintSponsorsDelete, onLinkedThemeDeleted, onLinkedSprintDeleted, onLinkedRoadmapDeleted, onIdeasDeleted,onThemeDeleted, onRoadmapTimelineRecordDelete,onRoadmapChartRecordDelete} = props;
    
    console.log("DRS", deleterecordsource)
    let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }
   
    function toggleswitch(source) {
    
        switch(source) {
            case 'values':
                return (
                <Fragment>
                <button
                    className="button is-danger is-small"
                    onClick={async () => {
                        await onValuesRecordDelete(recordkey);
                        toggleModal(false);
                    }}
                >
                    Delete
                 </button>
            </Fragment>
                )
            case 'facts':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onFactsRecordDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                         </button>
                    </Fragment>
                )
            case 'timeline':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onTimelineRecordDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                         </button>
                    </Fragment>
                )
            case 'success':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onSuccessRecordDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )
            case 'sprints':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onSprintRecordDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'roadmaps':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onRoadmapsRecordDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'section':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onRoadmapsSectionDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'sprintcomment':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onSprintCommentDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'sprintworkers':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onSprintWorkersDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'sprintsponsors':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onSprintSponsorsDelete(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'linkedroadmap':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onLinkedRoadmapDeleted(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'linkedtheme':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onLinkedThemeDeleted(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'linkedsprint':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onLinkedSprintDeleted(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

            case 'idea':
                return (
                    <Fragment>
                        <button
                            className="button is-danger is-small"
                            onClick={async () => {
                                await onIdeasDeleted(recordkey);
                                toggleModal(false);
                            }}
                        >
                            Delete
                            </button>
                    </Fragment>
                )

                case 'theme':
                    return (
                        <Fragment>
                            <button
                                className="button is-danger is-small"
                                onClick={async () => {
                                    await onThemeDeleted(recordkey);
                                    toggleModal(false);
                                }}
                            >
                                Delete
                                </button>
                        </Fragment>
                    )

                case 'roadmapgantt':
                    return (
                        <Fragment>
                            <button
                                className="button is-danger is-small"
                                onClick={async () => {
                                    await onRoadmapTimelineRecordDelete(recordkey);
                                    toggleModal(false);
                                }}
                            >
                                Delete
                                </button>
                        </Fragment>
                    )

                    case 'roadmapchart':
                        return (
                            <Fragment>
                                <button
                                    className="button is-danger is-small"
                                    onClick={async () => {
                                        await onRoadmapChartRecordDelete(recordkey);
                                        toggleModal(false);
                                    }}
                                >
                                    Delete
                                    </button>
                            </Fragment>
                        )


          }
    }





    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: defaultbackgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: defaulttextcolor}}>CONFIRM DELETE</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                <section className="modal-card-body" key="modalBody">
                    <p>Are you sure you want to delete item: '{modaltext}' ?</p>

                </section>
                <footer className="modal-card-foot is-flex pt-0 pb-0" key="modalFooter" style={{height:"60px"}}>
                    <nav className="level">
                    <div className="level-right">
                    </div>
                        <div className="level-right">
                            <div className="level-item">
                                <button className="button is-small" onClick={() => toggleModal(false)}>
                                        Cancel
                                </button>
                            </div>
                            <div className="level-item">
                            {toggleswitch(deleterecordsource)}
                             </div>
                        </div>
                    </nav>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default ConfirmDeleteModal;
