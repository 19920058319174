import React, {Fragment, useState} from "react";
import "bulma/css/bulma.css";

function InvestmentDetailModal(props) {
    const {toggleModal, showModal, onDataChanged } = props;

    function handleChangeQuote(event) {
        
    }

     let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader">
                        <p className="modal-card-title">Quote</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        "test"
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter">
                        <button
                            className="button is-success"
                            key="save"
                            onClick={async () => {
                                // await onDataChanged(quote);
                                toggleModal(false);
                            }}
                        >
                            Save changes
                        </button>
                        <button className="button" key="cancel" onClick={() => toggleModal(false)}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default InvestmentDetailModal;
