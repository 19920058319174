import { positionModalConstants } from '../constants/positionModal';

export const positionModalActions = {
    openModal,
    closeModal,
};

function openModal(personYearInfo) {
    console.log("Open modal", personYearInfo);
    return { type: positionModalConstants.OPEN_MODAL, personYear: personYearInfo };
}

function closeModal() {
    return { type: positionModalConstants.CLOSE_MODAL };
}
