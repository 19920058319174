import {React, useState, Fragment, useContext} from 'react';
import { useDispatch } from 'react-redux'
import { Table } from './Table';
import { RoadmapChart } from './Chart';
import { GanttChart} from './GanttChart';
import {filter} from 'lodash'
import { roadmapdatasets } from '../Roadmaps';
import { TableGantt} from './TableGantt';
import { openRoadmapSectionsModal } from '../../redux/toolkit/features/roadmaps/sectionsModal';

function Section(props) {
    const dispatch = useDispatch();
    const {indent, sectionrow, gantt, sectionkey, chart, chartrows, baseline, togglePopupModal} = props;
    const { rows } = useContext(roadmapdatasets);
    const [isGraph, setisGraph] = useState(false);

    const sectionrows = filter(rows, {section: sectionrow.pk}) //All rows in a specifc section
    const ganttrows = filter(gantt.results, {section: sectionrow.pk})

    if(!rows) {
        return null;
    }
   
    function GanttBody() {
      console.log("Gantt ROws",ganttrows,gantt.results)
        if (ganttrows.length!==0) {
            return (
                <Fragment>
                    <TableGantt 
                        sectionpk={sectionrow.pk}
                        sectionrows={sectionrows} 
                        gantt={gantt}
                    /> 

                    <GanttChart 
                        sectionpk={sectionrow.pk}
                        sectionrows={sectionrows} 
                        gantt={gantt}
                        ganttrows={ganttrows}
                    />

                </Fragment>
            )
        } 
    }

    function TableBody() {

        if (sectionrow.length!==0) {
            return (
                
                <Table 
                    key={'table_' + sectionrow.pk} 
                    sectionrows={sectionrows} 
                    sectionPk={sectionrow.pk} 
                    setisGraph={setisGraph} 
                    isGraph={isGraph}
                    chart={chart}
                    chartrows={chartrows}
                    indent={indent}
                    togglePopupModal={togglePopupModal}
                />
            )
        }
    }

    return (
        <section className='mb-6' style={{backgroundColor: 'rgb(238,238,238)'}}>
            <div style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}} onDoubleClick={() => dispatch(openRoadmapSectionsModal(sectionrow.pk))}>
                <div className='pt-5 pb-5 pl-5 pr-5'>
                    {sectionrow.name}<br/>
                </div>
                <div className='pl-5 pr-5' style={{backgroundColor: 'rgb(238,238,238)'}} onDoubleClick={() => dispatch(openRoadmapSectionsModal(sectionrow.pk))}>
                <span style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
                    <p>{sectionrow.narrative}</p> 
                </span>
             </div>
             </div>

             <div className='pt-5 pl-5 pr-5 pb-1'>
                <div className="mb-6">{TableBody()}</div>
                <div className="mb-6">{GanttBody()}</div>
            </div>

            {isGraph &&
            <div className='pt-0 pl-5 pr-5 pb-5'>
                <RoadmapChart 
                    key={'chart_' + sectionrow.pk} 
                    sectionPk={sectionrow.pk} 
                    sectionrows={sectionrows} 
                    chart={chart}
                    chartrows={chartrows}
                />
            </div>
            }
        </section>
    )
}

export { Section };
