import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, sectionpk: null };

const modalSlice = createSlice({
  name: "roadmapSectionsModal",
  initialState,
  reducers: {
    openRoadmapSectionsModal(state, action) {
      state.isOpen = true;
      state.sectionpk = action.payload;
    },
    closeRoadmapSectionsModal(state) {
      state.isOpen = false;
      state.sectionpk = null;
    },
  },
});

export const { openRoadmapSectionsModal, closeRoadmapSectionsModal } = modalSlice.actions;

export default modalSlice.reducer;

//const selectState = (state) => state.themesModal;

// export const selectThemesModalIsOpen = createSelector(
//   selectState,
//   (state) => state.isOpen
// );

// export const selectThemesModalThemepk = createSelector(
//   selectState,
//   (state) => state.themepk
// );
