import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, category: null };

const modalSlice = createSlice({
  name: "commentModal",
  initialState,
  reducers: {
    openForNewComment(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.comment = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingComment(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.comment = action.payload;
    },
  },
});

export const {
  openForNewComment,
  close,
  openForEditingComment,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.commentModal;

export const selectCommentModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectCommentModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectCommentModalComments = createSelector(
  selectState,
  (state) => state.comment
);
