import React, {Fragment, useState} from "react";
import "bulma/css/bulma.css";
import {_} from "lodash"
import {filter} from "lodash"


function TalentModal(props) {
    const { showTalentModal, toggleTalentModal, toggleTalentPositionModal, personyear} = props;
    const tabs = ['Section', 'Table', 'Timeline', 'Charts']
    let modalTitle="Add Talent"
    let modalClass = "modal";
    if (showTalentModal) {
        modalClass += " is-active";
    }

    function TalentRoles(p) {
        return (
            <tr className="ml-4 pl-5" key={"TalentRows_ "+ p.pk} colSpan="2">
            <td  colSpan="2" className="mb-2 ml-5 pl-5" style={{borderTop:"solid 1px grey", borderBottom:"solid 1px grey", borderLeft:"solid 1px grey"}} onClick={() => toggleTalentPositionModal(true, p.pk)}>
            {p.title}
            </td>                 
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px grey", borderRight:"solid 1px grey", width:"25px"}}>
            <button className="delete has-background-danger"></button>
            </td>
        </tr>
        )
    }

   

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader">
                        <p className="modal-card-title">{modalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => toggleTalentModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: "300px"}}>
                        <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                            <tbody>
                        {personyear &&
                        personyear.results.map((p) => (
                            <Fragment>
                        {TalentRoles(p)}
                        </Fragment>
                            ))}
                            </tbody>
                            </table>
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter">
                        <button
                            className="button is-success"
                            key="save"
                            onClick={async () => {
                                toggleTalentModal(false);
                            }}
                        >
                            Save changes
                        </button>
                        <button className="button" key="cancel" onClick={() => toggleTalentModal(false)}>
                            Cancel
                        </button>
                        <button className="button is-pulled-right" key="newchart" onClick={() => toggleTalentModal(false)}>
                            Add Chart
                        </button>
                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default TalentModal;
