import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, company: null };

const modalSlice = createSlice({
  name: "quoteModal",
  initialState,
  reducers: {
    openForNewQuote(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.company = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingQuote(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.company = action.payload;
    },
  },
});

export const {
  openForNewQuote,
  close,
  openForEditingquote,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.quoteModal;

export const selectQuoteModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectQuoteModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);
