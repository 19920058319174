import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";
import {authConfig} from "./helpers";
import axios from "axios";

class SuccessService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/success/`;
    }

    async getSuccess() {
        const successdata = await this.getAll();
        var success=successdata.results
        return success;
    }

    async sortSuccess(successPkList){
        const url = this.listUrl() + "sort-success/";
        const config = authConfig();
        const response = await axios.post(url, {"sorted_pks": successPkList}, config);
        return response.data;
    }
}

export const successService = new SuccessService();