import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TimelineService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/timeline/`;
    }
    async getTimeline() {
        const timelines = await this.getAll();
        return timelines.results;
    }
}

export const timelineService = new TimelineService();
