import React, { Fragment, useEffect, useState, createContext } from "react";
import { useSelector } from "react-redux";
import "./common/css/masterstyles.css";
//import "./common/css/main.css";
import { OrgTable } from "./organization/OrgTable";
import { OrgChart } from "./organization/OrgChart";
//import { PositionModal } from "./organization/PositionModal";
import { OrgMetrics } from "./organization/PeopleMetrics";
import { Talent } from "./organization/Talent";
import { menuActions } from "../redux/actions/menu";
import { useDispatch } from "react-redux";
//import {personService} from "../services/PersonService";
import {talentcompetenciesService} from "../services/TalentCompetenciesService";
import {talentpositionService} from "../services/TalentPositionService";
import {talentcompetencyscoresService} from "../services/TalentCompetencyScoresService";
import {talentrolepersonService} from "../services/TalentRolePersonService";
//import {organizationService} from "../services/OrganizationService";
import {personviewService} from "../services/OrganizationPersonViewService";
import {roleviewService} from "../services/OrganizationRoleViewService";
import {talentrolecompetenciesService} from "../services/TalentRoleCompetenciesService";
import {talentsankeyService} from "../services/TalentSankeyService"
import {tenantsService} from "../services/TenantsService"
import {personYearService} from "../services/PersonYearService";
import {metricsviewService} from "../services/OrganizationMetricsService";
import {metricsgrowthviewService} from "../services/OrganizationMetricsgrowthService";
import { roadmapsrowService } from "../services/RoadmapsRowService";
import { cagrService } from "../services/RoadmapsCAGRService";
import { growthService } from "../services/RoadmapsGrowthService";
import { chartindexviewService } from "../services/OrganizationChartIndexService";
import PeopleModal from "./organization/modals/PeopleModal";
import PeoplesModal from "./organization/modals/PeoplesModal";
import TalentModal from "./organization/modals/TalentModal";
import { Roles } from "./organization/Roles";
import { People } from "./organization/People";
import { Competencies } from "./organization/Competencies";
import TalentPositionModal from "./organization/modals/TalentPositionModal";
import Spinner from "./common/Spinner";
import CompetenciesModal from "./../components/organization/modals/CompetenciesModal";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox";
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import { competenciesActions } from "./../redux/actions/competencies";
import { peopleActions } from "./../redux/actions/people";

export const peopledatasets = createContext();

function OrganizationPage() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Organizational Design");
  const [rolekey, setRolekey] = useState(null);
  // const [years,setYears] = useState(null);
  // const [persons, setPersons] = useState(null);
  const [positions, setPositions] = useState(null);
  const [personview, setPersonView] = useState(null);
  const [roleview, setRoleView] = useState(null);
  const [tenants, setTenants] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [metricsgrowth, setMetricsgrowth] = useState(null);
  const [chartindex, setChartIndex] = useState(null);
  const [rows,setRows] = useState(null);
  const [cagr, setCagr] = useState(null);
  const [growth,setGrowth] = useState(null);
  const [personyear, setPersonYear] = useState(null);
  const [competencies, setComptencies] = useState(null);
  const [competencyscores, setCompetencyscores] = useState(null);
  const [roleperson, setRolePerson] = useState(null);
  const [sankey, setSankey] = useState(null);
  const [roleid, setRoleid]= useState(null);
  const [rolecompetencies, setRoleCompetencies] = useState(null);
  const [isPeopleModalOpen, setIsPeopleModalOpen] = useState(false);
  const [isTalentModalOpen, setIsTalentModalOpen] = useState(false);
  const [isTalentPositionModalOpen, setIsTalentPositionModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);    
 

  async function fetchTalent() {
    // const persons = await personService.getPersons();
    // setPersons(persons);  
    const personview = await personviewService.getAll();
    setPersonView(personview);  
    const positions = await talentpositionService.getAll();
    setPositions(positions);  
    const roleview = await roleviewService.getAll();
    setRoleView(roleview);  
    const personyear = await personYearService.getAll();
    setPersonYear(personyear);  
    const competencies = await talentcompetenciesService.getTalentCompetencies();
    setComptencies(competencies);  
    const competencyscores = await talentcompetencyscoresService.getTalentCompetencyScores();
    setCompetencyscores(competencyscores);  
    const roleperson = await talentrolepersonService.getTalentRolePerson();
    setRolePerson(roleperson);  
    const rolecompetencies = await talentrolecompetenciesService.getTalentRoleCompetencies();
    setRoleCompetencies(rolecompetencies);  
    const sankey = await talentsankeyService.getSankey();
    setSankey(sankey);  
    const metrics = await metricsviewService.getAll();
    setMetrics(metrics);  
    const metricsgrowth = await metricsgrowthviewService.getAll();
    setMetricsgrowth(metricsgrowth);  
    const rows = await roadmapsrowService.getRows();
    setRows(rows);  
    const cagr = await cagrService.getCAGR();
    setCagr(cagr);
    const growth = await growthService.getGrowth();
    setGrowth(growth);
    const chartindex = await chartindexviewService.getAll();
    setChartIndex(chartindex);
    // const years = await organizationService.getYears();
    // setYears(years);  
    const tenants = await tenantsService.getAll();
    setTenants(tenants);  
  }

  useEffect(() => {
    async function fetchData() {
      setIsDataLoading(true);
        await fetchTalent();
      setIsDataLoading(false);
    }

    fetchData();
  }, []);
  
  var minYear = (personyear && personyear.results.length!==0?personyear.results[0].start_year:new Date().getFullYear())
  var maxYear = (personyear && personyear.results.length!==0?personyear.results[0].end_year:minYear+4) 

  async function onConfirmDelete(result) {
    if (result) {
        
        if (recordtype === 'competency') {
            dispatch(competenciesActions.deleteRow(recordkey));
        }
        if (recordtype === 'people') {
          dispatch(peopleActions.deleteRow(recordkey));
      }
    }
    dispatch(closeDeleteModal());
}


  // useEffect(() => {
  //   dispatch(menuActions.setActiveMenu("organization"));
  // }, [dispatch]);

  async function onDataChanged(title, status, person_id, parent_id, compensationbase, compensationsti, compensationlti, compensationbenefits, key) {
    if (key) {
      await personYearService.patch(key, {title: title, status: status, person: 1, parent: 1, businessunit: 1, compensationbase: compensationbase, compensationsti: compensationsti, compensationlti: compensationlti, compensationbenefits: compensationbenefits});
  } else {
       await personYearService.post({title: title, status: status, person: 1, parent: 1, businessunit: 1, compensationbase: compensationbase, compensationsti: compensationsti, compensationlti: compensationlti, compensationbenefits: compensationbenefits});
  }
    await fetchTalent();
  }

  async function onPeopleDataChanged(key, role, rolestart, roleend, base, sti , lti, benefits, currency ){
    if (key) {
      await talentpositionService.patch(Number(key), {title: role, startdate: rolestart, enddate: roleend, compensationbase: base, compensationsti: sti, compensationlti: lti, compensationbenefits: benefits, compensation_currency: currency});
    } else {
       let talentrow = await talentpositionService.post({title: role, startdate: rolestart, enddate: roleend, compensationbase: base, compensationsti: sti, compensationlti: lti, compensationbenefits: benefits, compensation_currency: currency});
       setRoleid(talentrow.pk)
       //await talentpositionService.post({title: role, startdate: rolestart, enddate: roleend, compensationbase: base, compensationsti: sti, compensationlti: lti, compensationbenefits: benefits, compensation_currency: currency});
      }
    await fetchTalent();
  }


  function toggleTalentModal(open) {
    setIsTalentModalOpen(open)
  }

  function togglePeopleModal(open, roleid) {
    setIsPeopleModalOpen(open)
    setRoleid(roleid)
    //setRoleid(15)
  }

  function toggleTalentPositionModal(open, key) {
    setIsTalentPositionModalOpen(open)
    let keyvalue=key
    setRolekey(keyvalue)
  }

  if (!roleview || !personview || !tenants || !personyear || !competencies || !metrics) {
    return <Spinner/>;
  }

  const startmonth = (tenants && tenants.results[0].financialyearstart?tenants.results[0].financialyearstart:1)
  const basecurrency = (tenants && tenants.results[0].basecurrency?tenants.results[0].basecurrency:'USD')


  function renderorgpage() {
    return (
      <peopledatasets.Provider value={{metrics, metricsgrowth, rows, cagr, growth, chartindex, roleview,personview, competencies, rolecompetencies, togglePeopleModal}}>
      <Fragment>
        <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>   
          <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em", userSelect: "none"}}>
            <div style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
                <div className="pr-1">
                  PEOPLE <button className="button is-small is-pulled-right" style={{color: backgroundcolor, borderColor: backgroundcolor, borderRadius:"5px"}}onClick={() => togglePeopleModal(true)}>Add Role</button>
                </div>
                  <div key="TabHeader" className="tabs ml-0 pl-0" >
                    <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
                      <li 
                        key="OrganizationDesign" 
                        className={selectedTab === "Organizational Design" ? "is-active" : ""}
                        onClick={() => setSelectedTab("Organizational Design")}  
                        >           
                        <a className="ml-0 pl-0">ORGANIZATIONAL DESIGN</a>
                      </li>
                      |
                      <li
                        key="TalentManagement" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Talent Management' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Talent Management')}  
                        >       
                        <a>TALENT PIPELINE</a>
                      </li>
                      |
                      <li
                        key="Metrics" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Metrics' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Metrics')}  
                        >       
                        <a >ANALYTICS</a>
                      </li>
                      |
                      <li
                        key="Roles" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Roles' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Roles')}  
                        >       
                        <a >ROLES</a>
                      </li>
                      |
                      <li
                        key="People" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'People' ? "is-active" : ""}
                        onClick={() => setSelectedTab('People')}  
                        >       
                        <a >PEOPLE</a>
                      </li>
                      |
                      <li
                        key="Competencies" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Competencies' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Competencies')}  
                        >       
                        <a >COMPETENCIES</a>
                      </li>
                    </ul>
                </div>
              </div>
             
              {selectedTab === "Organizational Design"  &&
                <Fragment>
                   <OrgChart 
                      roleview={roleview.results} 
                      personview={personview.results} 
                      minYear={minYear} 
                      maxYear={maxYear}
                      startmonth={startmonth}
                      basecurrency={basecurrency}
                      togglePeopleModal={togglePeopleModal}
                    />
  
                   <OrgTable 
                      roleview={roleview.results} 
                      personview={personview.results}                   
                      minYear={minYear} 
                      maxYear={maxYear}
                      startmonth={startmonth}
                    />
                </Fragment>
              }
  
              {selectedTab === "Talent Management"  &&
                <Fragment>
                  <Talent
                    //persons={persons}
                    personyear={personyear}
                    roleperson={roleperson}
                    personview={personview}
                    sankey={sankey}
                    positions={positions}
                    competencies={competencies}
                    toggleTalentModal = {toggleTalentModal}
                    />
                </Fragment>
              }
  
              {selectedTab === "Metrics"  &&
                <Fragment>
                  <OrgMetrics 
                    selectedTab={selectedTab}
                    />
                </Fragment>
              }
  
              {selectedTab === "Roles"  &&
                <Fragment>
                  <Roles positions={positions}
                    />
                </Fragment>
              }
  
              {selectedTab === "People"  &&
                <Fragment>
                  <People personview = {personview}
                    />
                </Fragment>
              } 
  
              {selectedTab === "Competencies"  &&
                <Fragment>
                  <Competencies competencies = {competencies}
                    />
                </Fragment>
              } 
  
            </section>
        </main>
  
        <TalentModal 
          showTalentModal={isTalentModalOpen}
          toggleTalentModal = {toggleTalentModal}
          toggleTalentPositionModal = {toggleTalentPositionModal}
          personyear={personyear}
        />
  
        <PeopleModal 
          showPeopleModal={isPeopleModalOpen}
          togglePeopleModal = {togglePeopleModal}
          personyear={personyear}
          roleid={roleid}
          onPeopleDataChanged={onPeopleDataChanged}
        />

        <CompetenciesModal/>

        <PeoplesModal/>

        <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>
  
        {/* <TalentPositionModal
          showTalentPositionModal={isTalentPositionModalOpen}
          toggleTalentPositionModal = {toggleTalentPositionModal}
          personview={personview}
          positions={positions}
          //persons={persons}
          competencies={competencies}
          rolecompetencies={rolecompetencies}
          roleperson={roleperson}
          rolekey={rolekey}
          onDataChanged={onDataChanged}
        /> */}
  
        {/* <PositionModal /> */}
  
      </Fragment>
      </peopledatasets.Provider>

    )
  }

  return (
   (isDataLoading?<Spinner/>:renderorgpage())
  )
}

export { OrganizationPage };
