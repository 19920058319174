import React, { useEffect, useState } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../redux/actions/users";
import { UserDetailForm } from "../users/UserDetailForm";
import { UserCreationForm } from "../users/UserCreationForm";

function UserManagement(props) {
  const {tenant} = props;
  const listResponse = useSelector((state) => state.users.listResponse);
  const dispatch = useDispatch();
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [creatingUser, setCreatingUser] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    refresh();
  }, []);

  console.log("ListResponse", listResponse);

  function handleChange(event) {
    const filter = event.target.value;
    setFilterText(filter);
    refresh(filter, offset);
  }

  function refresh(text, offset) {
    dispatch(
      usersActions.getUserList({
        limit: limit,
        offset: offset,
        text: text,
      })
    );
    setCreatingUser(false);
  }

  function handleOnClickCreateUser(event) {
    console.log("Create user clicked");
    setSelectedUser(null);
    setCreatingUser(true);
  }

  function onClickUser(user) {
    setCreatingUser(false);
    dispatch(usersActions.clearError());
    setSelectedUser(user);
  }

  function renderUsers() {
    return listResponse.results.map((user) => {
      return (
        <a
          className="panel-block is-flex is-align-items-baseline"
          key={user.username}
        >
          <span className="panel-icon">
            <i className="fas fa-user" aria-hidden="true">
              {" "}
            </i>
          </span>
          <span className="is-flex-grow-1" onClick={() => onClickUser(user)}>
            {user.username}
            <br />
            <small>{user.first_name + " " + user.last_name}</small>
          </span>
        </a>
      );
    });
  }

  return (
    <div className="columns is-left">
      <div className="column is-one-third">
        <p className="title">Users ({listResponse.count})</p>
        <div
          style={{
            display: "flex",
            overflowY: "auto",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <nav className="panel is-flex is-flex-grow-1 is-flex-direction-column">
            <div className="panel-block">
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder="Filter"
                  onChange={handleChange}
                  value={filterText}
                />
                <span className="icon is-left">
                  <i className="fas fa-search" aria-hidden="true">
                    {" "}
                  </i>
                </span>
              </p>
            </div>
            <div className="panel-block is-flex">
              <div className="field is-grouped is-flex-grow-1">
                <nav className="level">
                  <div className="level-left">
                    <p className="level-item">
                      <p className="subtitle is-5">Page {pageNumber}</p>
                    </p>
                    <p className="level-item">
                      {listResponse.previous !== null && (
                        <button
                          className="button is-link"
                          onClick={() => {
                            setOffset(offset - limit);
                            setPageNumber(pageNumber - 1);
                            refresh(filterText, offset - limit);
                          }}
                        >
                          &lt; Previous
                        </button>
                      )}
                    </p>
                    <p className="level-item">
                      {listResponse.next !== null && (
                        <button
                          className="button is-link"
                          onClick={() => {
                            setOffset(offset + limit);
                            setPageNumber(pageNumber + 1);
                            refresh(filterText, offset + limit);
                          }}
                        >
                          Next &gt;
                        </button>
                      )}
                    </p>
                  </div>
                  <div className="right">
                    <div className="level-item">
                      <button
                        className="button is-primary"
                        onClick={handleOnClickCreateUser}
                      >
                        Create user
                      </button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            {renderUsers()}
          </nav>
        </div>
      </div>
      <div className="column">
        {creatingUser && <UserCreationForm onSave={refresh} />}
        {selectedUser && (
          <UserDetailForm 
          user={selectedUser} 
          tenant={tenant}
          onSave={refresh} />
        )}
      </div>
    </div>
  );
}

export { UserManagement };
