import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class RoadmapsRowService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-rows/`;
    }

    async getRows() {
        const rowsdata = await this.getAll();
        var rows = rowsdata.results
        return rows;
    }
}

export const roadmapsrowService = new RoadmapsRowService();
