import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TalentRolePersonService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/talent/roleperson`;
  }

  async getTalentRolePerson() {
    const roleperson = await this.getAll();
    return roleperson;
  }
}

export const talentrolepersonService = new TalentRolePersonService();
