import React, { Fragment, useEffect, useState, useContext } from "react";
import { RenderComments } from "./Comments";
import {filter} from "lodash"
import { sprintdatasets } from '../Sprints';
import { SprintLane } from "./SprintLane";
import { SprintDetail } from "./SprintDetail";
import {sprintService} from "../../services/SprintService";

function Sprint(props) {
  const {handleClickEditTask, toggleSetCommentModal, toggleInvestmentModal} = props; 
  const {sponsorsview, workersview, filteredSprints, registers } = useContext(sprintdatasets);
  
  if (!filteredSprints) {
    return null;
  }

  console.log("Filtered Sprints", filteredSprints)

  return (
    <Fragment>
      <section className="pb-4" style={{paddingLeft: "12%", paddingRight: "12%", userSelect: "none"}}>
                  {filteredSprints && filteredSprints.length !== 0 && 
                      filteredSprints.map((sprint) => (
                              <SprintDetail sprint={sprint}/>
                      ))}
                {filteredSprints.length === 0 && 
                <p style={{fontSize:"16.5px"}}>No Sprints Returned</p>
                }
                
      </section>
    </Fragment>
  );
}

export { Sprint };
