import {createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, ideapk: null, themepk: null };

const modalSlice = createSlice({
  name: "ideaModalToggle",
  initialState,
  reducers: {
    openIdeasModal(state, action) {
      state.isOpen = true;
      state.ideapk = action.payload.ideapk;
      state.themepk = action.payload.themepk;
    },
    closeIdeasModal(state) {
      state.isOpen = false;
      state.ideapk = null;
      state.themepk = null;
    },
  },
});

export const { openIdeasModal, closeIdeasModal } = modalSlice.actions;

export default modalSlice.reducer;
