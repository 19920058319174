import { purposeConstants } from '../constants/purpose';

const initialState = {
  loading: true,
  purpose: {
    pk: null,
    statement: "",
    quote: "",
  },
  error: null,
}

export function purpose(state = initialState, action) {
  switch (action.type) {
    case purposeConstants.GET_PURPOSE_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case purposeConstants.GET_PURPOSE_SUCCESS:
      return {
        ...initialState,
        loading: false,
        purpose: action.purpose,
      };
    case purposeConstants.GET_PURPOSE_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case purposeConstants.PATCH_PURPOSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case purposeConstants.PATCH_PURPOSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case purposeConstants.PATCH_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        purpose: {
          ...state.purpose,
          ...action.purpose,
        }
      };
    default:
      return state
  }
}