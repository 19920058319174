import "regenerator-runtime/runtime.js";
import axios from "axios";
import { authConfig } from "./helpers";
import BaseService from "./BaseService";
import noLogo from "../components/common/images/noLogo.png";

class TenantsService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/tenants/`;
  }

  async getTenant() {
    const tenant = await this.getAll();
    return tenant.results[0];
}

  async getCurrentTenant() {
    const url = this.listUrl() + "current";
    const response = await axios.get(url, authConfig());
    return response.data;
  }

  async uploadLogo(id, file) {
    const url = this.detailUrl(id) + "/upload-logo/";
    const formData = new FormData();
    formData.append("file", file);
    const config = authConfig();
    config.headers["content-type"] = "multipart/form-data";
    const response = await axios.put(url, formData, config);
    return response.data;
  }

  logoUrl(tenant) {
    if (tenant.image) {
      return tenant.image.startsWith("http")
          ? tenant.image
          : BaseService.baseServer + tenant.image;
    }
    return noLogo;
  }
}

export const tenantsService = new TenantsService();
