import React, { Fragment } from "react";
import "../common/css/masterstyles.css";
import {filter} from "lodash"
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip, Highlight, WaterfallSeries, DataLabel, Zoom, Crosshair, LineSeries, ScatterSeries, StripLine } from '@syncfusion/ej2-react-charts';


function ESGChart(props) {
  const { stakeholders, categories, matrices, companymatrix,defaultbackgroundcolor } = props;
  const palette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];





  function scatterchart() {
    console.log("At Scatterchart")
        let scatterarray=[]
        for (const srow of stakeholders) {
          let subarray=[]
        for (const row of categories) {
          let rowobj={};
          let namevalue=srow.name
          rowobj['Category'] = row.name
          let rowvalue=filter(matrices,{category: row.pk, stakeholder: srow.pk})
          rowobj['y'] = (rowvalue[0]?(rowvalue[0].importance)/10:0);
          let company=filter(companymatrix,{category:row.pk})    
          rowobj['x'] = (company[0]?(company[0].importance)/10:0);     
          subarray.push(rowobj)
          }
          scatterarray.push(subarray)
        }

        const marker = { width: 11, height: 12 };
        const primaryyAxis = { title: 'Importance to Stakeholder', minimum: 0, maximum: 10, interval: 1,stripLines: [
          { start: 5, end: 10, isSegmented: true, segmentStart: 0, segmentEnd: 5,
              color: 'rgba(0,0,0, 0.04)'}]  };
        const primaryxAxis = { title: 'Importance to Company', minimum: 0, maximum: 10, interval: 1};
        const legendSettings = { visible: true, position: 'Bottom'};
        var margin= { right:7 };
        const tooltip={ enable: true, format: '<b>Category: ${point.tooltip} </b><br> <b>Importance: ${point.y}</b> ' }
        return <ChartComponent id={'scattercharts'} primaryYAxis={primaryyAxis} primaryXAxis={primaryxAxis} palettes={palette} tooltip={tooltip} margin={margin} legendSettings={legendSettings}  >
        <Inject services={[ScatterSeries, Legend, Tooltip, DataLabel, Category, StripLine]}/>
        <SeriesCollectionDirective>
          {renderScatterChart(scatterarray)}
        </SeriesCollectionDirective>
    </ChartComponent>;
}

function renderScatterChart(scatterarray) {
  const cells = [];
  for (let i = 0; i < stakeholders.length; i++) {
      var rowname = stakeholders[i].name
      cells.push(<SeriesDirective dataSource={scatterarray[i]} xName='x' yName='y' name={rowname} width={4} type='Scatter' tooltipMappingName='Category'
      marker={{
        visible: true, height: 12, width: 12}}
      />);
  }
  return cells;
}

  return (
    <Fragment>
    {categories.length!==0 &&
      <Fragment>
      {scatterchart()}
      </Fragment>
    }
    
    {categories.length===0 &&
    <div className="columns is-flex is-centered is-fullwidth">
    <p className="mt-5" style={{fontSize:"14px"}}><b>No Data</b></p>
    </div>
    }
    </Fragment>
  );
}

export default ESGChart;
