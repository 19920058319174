import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, category: null };

const modalSlice = createSlice({
  name: "taskModal",
  initialState,
  reducers: {
    openForNewTask(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.comment = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingTask(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.comment = action.payload;
    },
  },
});

export const {
  openForNewTask,
  close,
  openForEditingTask,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.taskModal;

export const selectTaskModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectTaskModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectTaskModalTasks = createSelector(
  selectState,
  (state) => state.comment
);
