export const factsConstants = {
    GET_FACTS_REQUEST: 'GET_FACTS_REQUEST',
    GET_FACTS_SUCCESS: 'GET_FACTS_SUCCESS',
    GET_FACTS_FAILURE: 'GET_FACTS_FAILURE',

    POST_FACTS_REQUEST: 'POST_FACTS_REQUEST',
    POST_FACTS_SUCCESS: 'POST_FACTS_SUCCESS',
    POST_FACTS_FAILURE: 'POST_FACTS_FAILURE',

    PATCH_FACTS_REQUEST: 'PATCH_FACTS_REQUEST',
    PATCH_FACTS_SUCCESS: 'PATCH_FACTS_SUCCESS',
    PATCH_FACTS_FAILURE: 'PATCH_FACTS_FAILURE',

    DELETE_FACTS_REQUEST: 'DELETE_FACTS_REQUEST',
    DELETE_FACTS_SUCCESS: 'DELETE_FACTS_SUCCESS',
    DELETE_FACTS_FAILURE: 'DELETE_FACTS_FAILURE',

};
