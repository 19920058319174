import React, { useEffect } from "react";
import "./common/css/masterstyles.css";
import PageTitle from "./common/PageTitle";
import { useDispatch } from "react-redux";
import { menuActions } from "../redux/actions/menu";

function Setup() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("setup"));
  }, [dispatch]);

  return (
    <main>
      <PageTitle title={"Settings"} />
      <p>Application Setup</p>
      <br></br>
      <p>Here we add the application configuration settings:</p>
      <p>- Start and End Year</p>

      <p>- Logo</p>
      <p>- Primary Color</p>
      <button style={{ width: "20px", backgroundColor: "green" }}></button>
      <p> - Secondary Color</p>
      <br></br>
      <p>-Company Name</p>
    </main>
  );
}

export { Setup };
