import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class GanttService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-gantt/`;
    }

    async getGantt() {
        const gantt = await this.getAll();
        console.log("Gant Service", gantt)
        return gantt;
    }
}


export const ganttService = new GanttService();
