import React, {Fragment} from "react";
import { useSelector, useDispatch } from 'react-redux'

function CompanyQuoteSection(props) {
  const { company, toggleModal } = props;
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch(); 


  function renderQuote() {
      if (!company.quote) {
        return (
          <section className="hero is-medium" style={{userSelect: "none", backgroundColor: (backgroundcolor!==null? backgroundcolor: 'drakblue'), color: textcolor}}>
          <div className="level" style={{backgroundColor: "transparent", minHeight: "450px"}}>
          <div className="level-item " style={{userSelect: "none"}}>
          <button className = "button p-4" style={{backgroundColor: "transparent", border: "solid 1px white", color: "white", fontFamily: "Gotham Extra", fontSize: "20px"}} onClick={() => toggleModal(true)}>
            Add Company Quote
            </button>
          </div>
        </div>
        </section>
        )
        } else {
          return (
            <section className="hero is-medium is-info" style={{userSelect: "none", backgroundColor: backgroundcolor, color: textcolor}} onDoubleClick={() => toggleModal(true)}> 
            <div className="hero-body pl-2 pr-0" style={{marginLeft: "17%", marginRight: "17%"}}>
              <div className="columns" style={{ width: "100%" }}>
                <div className="column is-1">
                  <i
                    className="fas fa-quote-left fa-3x"
                    style={{ float: "left" }}
                  >
                    {" "}
                  </i>
                </div>
                <div className="column is-10">
                  <p className="subtitle is-3" style={{ textAlign: "center", fontFamily: "Gotham Extra", fontSize: "42.5px", lineHeight: "1.5em"}}>
                    {company.quote}
                  </p>
                </div>
                <div
                  className="column is-1 is-justify-content-flex-end"

                >
                  <i
                    className="fas fa-quote-right fa-3x is-justify-content-flex-end"
                    style={{ alignContent: "flex-end", float: "right" }}
                  >
                    {" "}
                  </i>
                </div>
              </div>
            </div>
          </section>
          )
        }}


  return <Fragment>{renderQuote()}</Fragment>;
}

export default CompanyQuoteSection;
