import React, {Fragment, useState, useEffect} from "react";
import "bulma/css/bulma.css";
import {filter} from "lodash"
import { useSelector, useDispatch } from 'react-redux'
import { timelinesActions } from "./../../../redux/actions/timelines"
import { openTimelineModal } from "./../../../redux/toolkit/features/company/timelineModal";
import { closeTimelineRecordModal } from "./../../../redux/toolkit/features/company/timelineRecordModal";

function CompanyTimelineRecordModal(props) {
    const timelinerecordkey = useSelector((state) => state.timelineRecordModal.timelinepk);
    const timelines = useSelector((state) => state.timelines.timelines);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showTimelineRecordModal = useSelector((state) => state.timelineRecordModal.isOpen);
    const dispatch = useDispatch();


    let timelinearray=filter(timelines, {pk: timelinerecordkey});
    let timelinevalue= (timelinearray[0]?.event || "")
    const currentyear = (new Date()).getFullYear();
    let timelineyear= (timelinearray[0]?.year || currentyear)
    const [event, setEvent] = useState(timelinevalue);
    const [year, setYear] = useState(timelineyear);

    const years = Array.from(new Array(100),(val, index) => currentyear - index);

    useEffect(() => {
        async function getTimeline() {
          dispatch(timelinesActions.getAll());
        }
        getTimeline();
      },[]);

    var modaltitle='';
    var placeholdertext='';
    if (timelinerecordkey===null) {
        modaltitle="ADD COMPANY MILESTONE"
        placeholdertext="New Milestone"
    } else {
        modaltitle="COMPANY MILESTONE"
    }

    function handleChangeYear(event) {
        setYear(event.target.value);
    }

    function handleChangeEvent(event) {
        setEvent(event.target.value);
    }


     let modalClass = "modal";
    if (showTimelineRecordModal) {
        modalClass += " is-active";
    }

    useEffect(() => {
        setEvent(timelinevalue)
        setYear(timelineyear)
      }, [timelinevalue, timelineyear])

    
    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Year</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <div className="select is-flex">
                                <select
                                    className="select is-fullwidth"
                                    value={year}
                                    onChange={handleChangeYear}
                                >
                                    {years &&
                                    years.map((p, index) => (
                                        <option key={"YearList_"+index} value={p}>
                                            {p}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>
                </div>     
                     

                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Milestone</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="6"
                                placeholder={placeholdertext}
                                value={event}
                                onChange={handleChangeEvent}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => {
                                dispatch(closeTimelineRecordModal());
                                dispatch(openTimelineModal());
                        }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {timelinerecordkey !== null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(timelinesActions.patch(timelinerecordkey,{year:year, event:event}));
                                setEvent("")
                                dispatch(closeTimelineRecordModal());
                                dispatch(openTimelineModal());
                            }}>
                            Save Changes
                        </button>
                        </Fragment>
                        }
                    {timelinerecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(timelinesActions.post({year:year, event:event}));
                                dispatch(closeTimelineRecordModal());
                                dispatch(openTimelineModal());
                            }}>
                            Save Changes
                        </button>
                        </Fragment>
                        }
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => {
                                dispatch(closeTimelineRecordModal());
                                dispatch(openTimelineModal());
                                }}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyTimelineRecordModal;
