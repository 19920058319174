function getImportanceColor(importance) {
  switch (importance) {




    case 0:
      return "#00FF00";
    case 1:
      return "#05FF00";
    case 2:
      return "#0AFF00";
    case 3:
      return "#0FFF00";
    case 4:
      return "#14FF00";
    case 5:
      return "#19FF00";
    case 6:
      return "#1EFF00";
    case 7:
      return "#23FF00";
    case 8:
      return "#28FF00";
    case 9:
      return "#2DFF00";
    case 10:
      return "#32FF00";
    case 11:
      return "#38FF00";
    case 12:
      return "#3DFF00";
    case 13:
      return "#42FF00";
    case 14:
      return "#47FF00";
    case 15:
      return "#4CFF00";
    case 16:
      return "#51FF00";
    case 17:
      return "#56FF00";
    case 18:
      return "#60FF00";
    case 19:
      return "#60FF00";

    case 20:
      return "#65FF00";
    case 21:
      return "#6BFF00";
    case 22:
      return "#70FF00";
    case 23:
      return "#75FF00";
    case 24:
      return "#7AFF00";
    case 25:
      return "#7FFF00";
    case 26:
      return "#84FF00";
    case 27:
      return "#89FF00";
    case 28:
      return "#8EFF00";
    case 29:
      return "#93FF00";
    case 30:
      return "#98FF00";
    case 31:
      return "#9EFF00";
    case 32:
      return "#A3FF00";
    case 33:
      return "#A8FF00";
    case 34:
      return "#ADFF00";
    case 35:
      return "#B2FF00";
    case 36:
      return "#B7FF00";
    case 37:
      return "#BCFF00";
    case 38:
      return "#C1FF00";
    case 39:
      return "#C6FF00";
    case 40:
      return "#CBFF00";
    case 41:
      return "#D1FF00";
    case 42:
      return "#D6FF00";
    case 43:
      return "#DBFF00";
    case 44:
      return "#E0FF00";
    case 45:
      return "#E5FF00";
    case 46:
      return "#EAFF00";
    case 47:
      return "#EFFF00";
    case 48:
      return "#F4FF00";
    case 49:
      return "#F9FF00";
    case 50:
      return "#FEFF00";
    case 51:
      return "#FFF900";
    case 52:
      return "#FFF400";
    case 53:
      return "#FFEF00";
    case 54:
      return "#FFEA00";
    case 55:
      return "#FFE500";
    case 56:
      return "#FFE000";
    case 57:
      return "#FFDB00";
    case 58:
      return "#FFD600";
    case 59:
      return "#FFD100";
    case 60:
      return "#FFCC00";
    case 61:
      return "#FFC600";
    case 62:
      return "#FFC100";
    case 63:
      return "#FFBC00";
    case 64:
      return "#FFB700";
    case 65:
      return "#FFB200";
    case 66:
      return "#FFAD00";
    case 67:
      return "#FFA800";
    case 68:
      return "#FFA300";
    case 69:
      return "#FF9E00";
    case 70:
      return "#FF9900";
    case 71:
      return "#FF9300";
    case 72:
      return "#FF8E00";
    case 73:
      return "#FF8900";
    case 74:
      return "#FF8400";
    case 75:
      return "#FF7F00";
    case 76:
      return "#FF7A00";
    case 77:
      return "#FF7500";
    case 78:
      return "#FF7000";
    case 79:
      return "#FF6B00";
    case 80:
      return "#FF6600";
    case 81:
      return "#FF6000";
    case 82:
      return "#FF5B00";
    case 83:
      return "#FF5600";
    case 84:
      return "#FF5100";
    case 85:
      return "#FF4C00";
    case 86:
      return "#FF4700";
    case 87:
      return "#FF4200";
    case 88:
      return "#FF3D00";
    case 89:
      return "#FF3800";
    case 90:
      return "#FF3300";
    case 91:
      return "#FF2D00";
    case 92:
      return "#FF2800";
    case 93:
      return "#FF2300";
    case 94:
      return "#FF1E00";
    case 95:
      return "#FF1900";
    case 96:
      return "#FF1400";
    case 97:
      return "#FF0F00";
    case 98:
      return "#FF0A00";
    case 99:
      return "#FF0500";
    case 100:
      return "#FF0000";
    default:
      return "#FFFFFF";
  }
}

export { getImportanceColor };
