import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { roadmapsActions } from "../../redux/actions/roadmaps";
import {filter} from "lodash"
import {SprintRows} from "./SprintRows"




function Table(props) {
    console.log("Props", props)
    const {investments, investmentcells, minYear, maxYear, toggleModal, sprintinvestment, sprintinvestmentcell} = props;
    console.log("Investments1", investments, investmentcells )
    const roadmap = useSelector(state => state.roadmaps.roadmap);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(roadmapsActions.get());
    // }, []);

    function renderYearHeaders() {
        const headers = [];
        console.log({ maxYear })
        for (let year = minYear; year <= maxYear; year++) {
            headers.push(<th className={"has-text-centered"} key={year}>{year}</th>);
        }
        return headers;
    }

    function renderHeader(HeaderTitle) {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th key={0} style={{ width: "200px" }}> </th>
                        {renderYearHeaders()}
                    </tr>
                </thead>
            </Fragment>
        )
    }


    function renderCells(pk) {
        console.log("At cells", pk)
        const cells = [];
        for (let year = minYear; year <= maxYear; year++) {
            console.log("Year", year)
            let cellvalue=filter(investmentcells, {row:pk, year: Number(year)})
            console.log("CV", year,minYear,maxYear, cellvalue)
            if (cellvalue && cellvalue.length !==0) {
                cells.push(<td className={"has-text-centered"}>{cellvalue[0].cashcost}</td>);
            } else {
                cells.push(<td className={"has-text-centered"}>0</td>);
            }
        }
        return cells;
    }

    function renderDetail(sectionname) {
        console.log("3", investments)
        return (
            <Fragment>
                <thead>
                    {investments && investments.map(investment => (
                    <tr onClick={() => toggleModal(true)}>
                        <td>{investment.name}</td>
                        {renderCells(investment.pk)}
                    </tr>
                    ))}
                </thead>
            </Fragment>
        )
    }


    console.log("Investments", investments, investmentcells)
    return (

        <Fragment>
            <p className="pb-2" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "18px",lineHeight: "26px"}}>PEOPLE INVESTMENTS</p>
            <table className={"table is-striped is-fullwidth ml-0"} style={{borderTop: "solid 1px grey"}}>
                {renderHeader('People Investments')}
                {renderDetail("People")}
            </table>
            <br></br>

            <p className="pb-2" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "18px",lineHeight: "26px"}}>INNOVATION INVESTMENTS</p>
            <table className={"table is-striped is-fullwidth ml-0"} style={{borderTop: "solid 1px grey"}}>
                {renderHeader('Innovation Investments')}
            </table>
            <br></br>
            
            <p className="pb-2" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "18px",lineHeight: "26px"}}>CAPITAL INVESTMENTS</p>
            <table className={"table is-striped is-fullwidth ml-0"} style={{borderTop: "solid 1px grey"}}>
                {renderHeader('Capital Investments')}
            </table>
            <br></br>

            <p className="pb-2" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "18px",lineHeight: "26px"}}>SPRINT INVESTMENTS</p>
            <table className={"table is-striped is-fullwidth ml-0"} style={{borderTop: "solid 1px grey"}}>
                {renderHeader('Sprint Investments')}
                <SprintRows
                    sprintinvestment={sprintinvestment}
                    sprintinvestmentcell={sprintinvestmentcell}
                />
            </table>
            <br></br>

            <p className="pb-2" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "18px",lineHeight: "26px"}}>OTHER INVESTMENTS</p>
            <table className={"table is-striped is-fullwidth ml-0"} style={{borderTop: "solid 1px grey"}}>
                {renderHeader('Other Investments')}
            </table>
            <br></br>

            <p className="pb-2" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "18px",lineHeight: "26px"}}>TOTAL INVESTMENTS</p>
            <table className={"table is-striped is-fullwidth ml-0"} style={{borderTop: "solid 1px grey"}}>
                {renderHeader('TOTALS')}
            </table>
        </Fragment>


    )
}

export { Table };
