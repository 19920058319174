import React, { useEffect, useState } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../redux/actions/users";
import Moment from "react-moment";

function UserDetailForm(props) {
  const {tenant} = props;
  const [emaildomain, setEmaildomain] = useState("");
  const currentUser = useSelector((state) => state.users.currentUser);
  const userError = useSelector((state) => state.users.error);
  const [userData, setUserData] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    role: 0,
    is_active: true,
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    repeat: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tenant) {
     setEmaildomain(tenant.emaildomain)
    }
   }, [tenant]);

  useEffect(() => {
    if (props.user) {
      setUserData({
        id: props.user.id,
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        email: props.user.email,
        role: props.user.role,
        is_active: props.user.is_active,
      });
      return () => {
        setUserData({
          id: 0,
          first_name: "",
          last_name: "",
          email: "",
          role: 0,
          is_active: true,
        });
      };
    }
  }, [props.user]);

  const handleOnChange = (event) => {
    const newUserData = {
      ...userData,
      [event.target.name]: event.target.value,
    };
    setUserData(newUserData);
  };

  const handleOnChangeIsActive = (event) => {
    const newProfileData = {
      ...userData,
      is_active: event.target.checked,
    };
    setUserData(newProfileData);
  };

  const handleOnChangePassword = (event) => {
    const newPasswordData = {
      ...passwordData,
      [event.target.name]: event.target.value,
    };
    setPasswordData(newPasswordData);
  };

  const onSave = async () => {
    await dispatch(
      usersActions.saveUser(
        props.user.id,
        userData,
        currentUser.id === props.user.id ? null : props.onSave
      )
    );
    setSaved(true);
    props.onSave();
  };

  const onChangePassword = () => {
    if (passwordData.password !== passwordData.repeat) {
      setPasswordError("Passwords must match.");
      return;
    }
    if (passwordData.password.trim() === "") {
      setPasswordError("Password can't be empty.");
      return;
    }
    setPasswordError("");
    dispatch(usersActions.changePassword(props.user.id, passwordData));
    setSaved(true);
  };

  // an admin user can change other users role.
  // an user can't change his/her own role
  const canChangePermissions = currentUser
    ? currentUser.role === 2 && props.user.id !== currentUser.id
    : false;

  return (
    <>
      <>
        <h3 className="title is-4">
          {props.user.username}
          <span className="subtitle is-7">
            {" "}
            Joined on{" "}
            <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">
              {props.user.date_joined}
            </Moment>
          </span>
        </h3>
        <div className="field">
          <label className="label">First name</label>
          <div className="control">
            <input
              name={"first_name"}
              className="input"
              type="text"
              value={userData.first_name}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Last name</label>
          <div className="control">
            <input
              name={"last_name"}
              className="input"
              type="text"
              value={userData.last_name}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <label className="label">Email</label>
        <div className="field has-addons">
          <div className="control is-expanded has-icons-left has-icons-right">
            <input
              name={"email"}
              className="input"
              type="email"
              value={(userData.email).replace('@'+{emaildomain},'')}
              onChange={handleOnChange}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"> </i>
            </span>
          </div>
          <div className="control">
    <a className="button is-static">
      @{emaildomain}
    </a>
  </div>
        </div>


        {canChangePermissions && (
          <div className="field">
            <label className="label">Role</label>
            <div className="control">
              <div className="select">
                <select
                  value={userData.role}
                  name={"role"}
                  onChange={handleOnChange}
                >
                  <option value={0}>Read only</option>
                  <option value={1}>Read and write</option>
                  <option value={2}>Administration rights</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {canChangePermissions && (
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={userData.is_active}
                  name={"is_active"}
                  onChange={handleOnChangeIsActive}
                />{" "}
                Active
              </label>
            </div>
          </div>
        )}
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
        {userError && <div className="notification is-danger">{userError}</div>}

        {saved && !userError && (
          <div className="notification is-success">
            <button className="delete" onClick={() => setSaved(false)}>
              {" "}
            </button>
            Data saved
          </div>
        )}
      </>
      <hr />
      <div>
        <h1 className="title">Change password</h1>
        <div className="field">
          <label className="label">New password</label>
          <p className="control has-icons-left">
            <input
              className="input"
              type="password"
              name={"password"}
              placeholder="New password"
              value={passwordData.password}
              onChange={handleOnChangePassword}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"> </i>
            </span>
          </p>
        </div>
        <div className="field">
          <label className="label">Repeat new password</label>
          <p className="control has-icons-left">
            <input
              className="input"
              type="password"
              name={"repeat"}
              placeholder="Repeat new password"
              value={passwordData.repeat}
              onChange={handleOnChangePassword}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"> </i>
            </span>
          </p>
          {passwordError !== "" && (
            <p className="help is-danger">{passwordError}</p>
          )}
        </div>
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" onClick={onChangePassword}>
              Change password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export { UserDetailForm };
