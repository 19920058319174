import { tenantConstants } from "../constants/tenants";

const initialState = {
  loading: false,
  tenant: {},
  error: null,
};

export function tenants(state = initialState, action) {
  switch (action.type) {
    case tenantConstants.GET_CURRENT_TENANT_REQUEST:
    case tenantConstants.SAVE_CURRENT_TENANT_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case tenantConstants.GET_CURRENT_TENANT_SUCCESS:
    case tenantConstants.SAVE_CURRENT_TENANT_SUCCESS:
      return {
        ...initialState,
        currentTenant: action.tenant,
      };
    case tenantConstants.GET_CURRENT_TENANT_FAILURE:
    case tenantConstants.SAVE_CURRENT_TENANT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}
