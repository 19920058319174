import React,{Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {filter} from 'lodash'
import {Container} from "./table/Container";
import { openRoadmapRowModal } from "../../../redux/toolkit/features/roadmaps/rowDetailModal";


function SectionsModalTable(props) {
    const {rows, toggleConfirmDeleteModal, getFactsOnMainPage} = props;
    const sectionpk = useSelector((state) => state.roadmapSectionsModal.sectionpk)
    console.log("Section PK Table ROws 1", sectionpk)
    const dispatch = useDispatch();

    if(!rows) {
        return null;
    }

    let tablerows=filter(rows, {section: sectionpk});

    console.log("Section PK Table ROws 1", sectionpk, rows, tablerows)

    function formTableIndent(row) {
        if (row.subrow) {
            return (
    
                <tr key={'ModalRows_ '+ row.pk}>
                    <td style={{width:'36px', borderStyle:'none'}}></td>

                    <td  className='mb-2 ml-5 pl-5' style={{borderTop:'solid 1px grey', borderBottom:'solid 1px grey', borderLeft:'solid 1px grey'}} onClick={() => dispatch(openRoadmapRowModal(sectionpk))}>
                    {row.name}
                    </td>
                    <td style={{borderTop:'solid 1px grey', borderBottom:'solid 1px grey', borderRight:'solid 1px grey', width:'25px'}}>
                    <button className='delete has-background-danger'></button>
                    </td>
                </tr> 
            )
        } else {
            return(
                <tr className='ml-4 pl-5' key={'ModalRows_ '+ row.pk} colSpan='2'>
                    <td  colSpan='2' className='mb-2 ml-5 pl-5' style={{borderTop:'solid 1px grey', borderBottom:'solid 1px grey', borderLeft:'solid 1px grey'}} onClick={() => dispatch(openRoadmapRowModal(sectionpk))}>
                    {row.name}
                    </td>                 
                    <td style={{borderTop:'solid 1px grey', borderBottom:'solid 1px grey', borderRight:'solid 1px grey', width:'25px'}}>
                    <button className='delete has-background-danger'></button>
                    </td>
                </tr>
            )
        }
    }
    


    return (

        <Fragment>

        {/* <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
            <tbody>
                {tablerows.map(row => (
                    <Fragment>
                        {formTableIndent(row)}
                    </Fragment>
                ))}
            </tbody>
        </table> */}
        {tablerows.length !== 0 &&
        <Container tablerows={tablerows}
                    toggleConfirmDeleteModal={toggleConfirmDeleteModal} getFactsOnMainPage={getFactsOnMainPage} sectionpk={sectionpk} />}
        {tablerows.length === 0 &&
        <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
        </Fragment>
    )
}


export default SectionsModalTable;
