import React, { Fragment } from "react";
import { Section } from "./Section";

function Roadmap(props) {
    const {indent, sectionsfiltered, gantt, baseline, toggleSectionsModal, chart, chartrows, togglePopupModal} = props;

    function renderSections() {
        return (
            <Fragment>
            { sectionsfiltered && sectionsfiltered.map(sectionrow => (

                <div key={sectionrow.pk}>
                    
                    <Section 
                        key={sectionrow.pk} 
                        sectionrow={sectionrow} //Data for a specific row of a Section
                        gantt={gantt}
                        baseline={baseline}
                        chart={chart}
                        chartrows={chartrows}
                        indent={indent}
                        toggleSectionsModal={toggleSectionsModal}
                        togglePopupModal={togglePopupModal}
                    />

                </div>
        ))}
        </Fragment>
        )
    }

    return (
        <Fragment>

            {renderSections()}

        </Fragment>
    )
}

export { Roadmap };
