import {helpModalConstants} from "../constants/helpModal";

const initialState = {
    isOpen: false,
}

function processOpenHelpModalAction(state, action) {
    console.log("process open help modal action");
    return {
        ...state,
        isOpen: true,
    };
}

function processCloseHelpModalAction(state, action) {
    console.log("process close help modal action");
    return {
        ...state,
        isOpen: false,
    };
}

export function helpModal(state = initialState, action) {
    switch (action.type) {
        case helpModalConstants.OPEN_MODAL:
            return processOpenHelpModalAction(state, action);
        case helpModalConstants.CLOSE_MODAL:
            return processCloseHelpModalAction(state, action);

        default:
            return state;
    }
}
