import React, {Fragment, useState, useContext} from "react";
import "../common/css/dot.css";
import {TalentPosition} from "./TalentPosition";
import { Chart } from "react-google-charts";
import { peopledatasets } from '../OrganizationPage';


function Talent(props) {
    const {persons, personyear, toggleTalentModal, roleperson, sankey, positions, personview, competencies} = props;
    const {defaultbackgroundcolor, defaulttextcolor, lightbackgroundcolor, darkbackgroundcolor} = useContext(peopledatasets);
    const [isTable, setIsTable] = useState(true)
    console.log("Positions", positions)

    function toggleButton() {
        if (isTable) {
            setIsTable(false)
        } else {
            setIsTable(true)
        }
    }

    function renderChart() {
        if (!sankey) {
            return null;
        }
        let sankeydata = [['From', 'To', 'Readiness',{ role: "tooltip", type: "string", p: {html: true}} ]]
        {sankey && sankey.results.map(row => (
            sankeydata.push([row.title,row.candidate, Number(row.score),row.comment])
        ))}
        let chartheight="900px"
        if (sankey && sankey.length !== 0) {
            let chartpixels = sankey.length*100
            if (chartpixels > chartheight) {
                chartheight = chartpixels
            }
        }

        return (
            <Chart className="mt-2"
            width={'100%'}
            height={chartheight}
            chartType="Sankey"
            loader={<div>Loading Chart</div>}
            data={sankeydata}
            options={{
                sankey: {
                    tooltip: {isHtml: true},
                    node: { label: { fontName: "Gotham Extra",
                                     fontSize: 13,
                 } } },

            }}
            //rootProps={{ 'data-testid': '1' }}
            />
        )
    }

    
    return (
        <Fragment>
            <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        TALENT PIPELINE  ({positions.results.length})<button className="mr-3 is-right" onClick={toggleButton}><i class="fas fa-project-diagram" style={{color: 'gray'}}></i></button><br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-4">
                                <label className="checkbox subtitle" style={{fontWeight: "400", fontFamily: "Gotham Extra", fontSize: "16px"}}>
                                    <span className="mr-2">
                                        <small>Show Current</small>
                                    </span>
                                    <input
                                        type="checkbox"
                                    />
                                </label>
                            </div>

                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small"  type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              </section>

            <section className="p-0 m-0" style={{backgroundColor: "rgb(242,242,242)", fontFamily: "Gotham Extra"}}>

                {isTable &&
                    <Fragment>
                        <table className="table is-fullwidth"  >
                        {positions && positions.results.map(row => (
                            <TalentPosition
                                personview={personview}
                                persons={persons}
                                rolekey={row.pk}
                                title={row.title}
                                roleperson={roleperson}
                                toggleTalentModal = {toggleTalentModal}
                                positions = {positions.results}
                                competencies={competencies}
                                />
                        ))}
                        </table>
                </Fragment>
                }

                {!isTable && 
                    renderChart()
                }

            </section>
        </Fragment>
    )
}

export {Talent};