import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class InvestmentService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/sprintinvestment/`;
    }

    async getInvestment() {
        const investment = await this.getAll();
        return investment;
    }
}

export const investmentsprintService = new InvestmentService();