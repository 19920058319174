import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { openValuesModal, closeValuesRecordModal, updateValues, createValues, selectValueById } from "./../../../redux/toolkit/features/values";

function CompanyValuesRecordModal() {
    const valuesrecordkey = useSelector((state) => state.values.valueRecordModalpk);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showValuesRecordModal = useSelector((state) => state.values.isRecordModalOpen);
    const valuebyid = useSelector((state)=>selectValueById(state, valuesrecordkey))  
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const [description, setDescription] = useState("");

    var modaltitle='';
    if (valuesrecordkey===null) {
        modaltitle="NEW COMPANY VALUE"
    } else {
        modaltitle="COMPANY VALUE"
    }

    function handleChangeValues(event) {
        setValue(event.target.value);
    }

    function handleChangeDescription(event) {
        setDescription(event.target.value);
    }

    useEffect(() => {
        if (valuebyid) {
        setValue(valuebyid.value)
        setDescription(valuebyid.description)
        } else {
        setValue("")   
        setDescription("")
        }
      }, [valuebyid])

     let modalClass = "modal";
    if (showValuesRecordModal) {
        modalClass += " is-active";
    }
  
    if (!valuebyid && valuesrecordkey!==null) {
        return null;
    }
    
    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Value</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="1"
                                value={value}
                                onChange={handleChangeValues}
                                />
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Description</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="4"
                                value={description}
                                onChange={handleChangeDescription}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => {
                                dispatch(closeValuesRecordModal());
                                dispatch(openValuesModal());
                        }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                        {valuesrecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(createValues({value: value, description: description }));
                                dispatch(closeValuesRecordModal());
                                dispatch(openValuesModal());
                                setValue("")
                                setDescription("")
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {valuesrecordkey !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(updateValues({id:valuesrecordkey, value: value, description: description }));
                                dispatch(closeValuesRecordModal());
                                dispatch(openValuesModal());
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}


                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => {
                                dispatch(closeValuesRecordModal());
                                dispatch(openValuesModal());
                        }}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyValuesRecordModal;
