import React, {Fragment, useState, useEffect } from "react";
import "bulma/css/bulma.css";
import {filter} from "lodash"

function SuccessModalLinksRoadmaps(props) {
    const [sectionid, setSectionid] = useState(props.sectionid)
    const [rowid, setRowid] = useState(props.rowid)    
    const {categories, sections, rows, updatelinkroadmaps} = props;
    const [isSectionOpened, setIsSectionOpened] = useState(false);
    const [isRowOpened, setIsRowOpened] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [categoryvalue, setCategoryvalue] = useState(null);
    const [sectionlist, setSectionlist] = useState([]);
    const [rowlist, setRowlist] = useState([]);    
    const [sectionvalue, setSectionvalue] = useState(null);
      
    let categorylist = categories.sort();

    useEffect(() => {
        let sectionfilteredarray = filter(sections.results, {category: categoryvalue})
        // let sectionlistarray = [...new Set(sectionfilteredarray.flatMap(({name, pk}) => [[name, pk]]))]
        let sectionlistarray = [...new Set(sectionfilteredarray.flatMap(({name}) => name))]
        //setSectionlist(sectionlistarray); 
        setSectionlist(sectionfilteredarray); 
    }, [categoryvalue]);

    useEffect(() => {
        // let sectionid = filter(sections.results, {name: sectionvalue})
        // console.log("section", sectionid,sectionvalue, sections)
        //if(sectionid.length !== 0) {
        console.log("inpits", sectionid, rows)
        //let rowfilteredarray = filter(rows, {section: Number(sectionvalue), type: "Number"})
        let rowfilteredarray = filter(rows, function(row) {return row.section===Number(sectionid)&& (row.type!=="Text")})

        //let rowlistarray = [...new Set(rowfilteredarray.flatMap(({name}) => [[name]]))]
        setRowlist(rowfilteredarray); 
        console.log("Rows", sectionvalue,rowlist, rowfilteredarray)
    }, [sectionid]);

    function categoryToggle(event) {
        setIsSectionOpened(true)
        setCategoryvalue(event.target.value)
        //setIsRowSelected(false)
    }
      
    function sectionToggle(event) {
        //setIsRowOpened(open)
        setIsRowOpened(true)
        setSectionid(Number(event.target.value))
        console.log("ST", sectionid)
        //setIsRowSelected(false)
    }
      
    function rowToggle(event) {
        setRowid(Number(event.target.value))
        updatelinkroadmaps(sectionid,event.target.value, "Theme")
    }

    function selectRoadmapLink() {
        console.log("RL",rowlist)
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label">Category</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => categoryToggle(event)}>
                            {!isSectionOpened &&
                            <option>{'<Select Category>'}</option>}
                                {categorylist &&
                            categorylist.map((p, index) => (
                            <option key={'categorylist'+index}>
                                {p} 
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            {isSectionOpened && 
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label">Roadmap Section</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => sectionToggle(event)}>
                            {!isRowOpened && <option>{'<Select Category>'}</option>}
                                {sectionlist &&
                            sectionlist.map((p, index) => (
                            <option key={p.pk} value={p.pk}>
                                {p.name} 
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>}
            {isRowOpened && 
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label">Roadmap Row</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => rowToggle(event)}>
                            <option>{'<Select Category>'}</option>
                                {rowlist &&
                            rowlist.map((p) => (
                            <option key={p.pk} value={p.pk}>
                                {p.name} 
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>}     

      
        </Fragment>
        )
    }

 



    return (
        <Fragment>
    
            {selectRoadmapLink()}
        </Fragment>
    );
}

export default SuccessModalLinksRoadmaps
