import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TalentCompetenciesService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/talent/competencies/`;
  }

  async getTalentCompetencies() {
    const competencies = await this.getAll();
    return competencies;
  }
}

export const talentcompetenciesService = new TalentCompetenciesService();
