import {peopleConstants} from '../constants/people';

const initialState = {
    loading: false,
    people: [],
    error: null,
}

export function people(state = initialState, action) {
    switch (action.type) {
        case peopleConstants.GET_PEOPLE_REQUEST:
            return {
                ...initialState,
                loading: true,
            };
        case peopleConstants.GET_PEOPLE_SUCCESS:
            return {
                ...initialState,
                people: action.people,
            };
        case peopleConstants.GET_PEOPLE_FAILURE:
            return {
                ...initialState,
                error: action.error,
            };
        case peopleConstants.POST_PEOPLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case peopleConstants.POST_PEOPLE_SUCCESS:
            const addedPeople = [...state.people];
            addedPeople.push(action.people);
            return {
                ...state,
                people: addedPeople,
            };
        case peopleConstants.POST_PEOPLE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case peopleConstants.DELETE_PEOPLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case peopleConstants.DELETE_PEOPLE_SUCCESS:
            const deletedPeople = [];
            for (const people of state.people) {
                if (people.pk !== action.id) {
                    deletedPeople.push(people);
                }
            }
            return {
                ...state,
                people: deletedPeople,
            };
        case peopleConstants.DELETE_PEOPLE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state
    }
}