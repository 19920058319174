import { sectionsConstants } from '../constants/sections';

const initialState = {
  loading: false,
  sections: [],
  error: null,
}

export function sections(state = initialState, action) {
  switch (action.type) {
    case sectionsConstants.GET_SECTIONS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case sectionsConstants.GET_SECTIONS_SUCCESS:
      return {
        ...initialState,
        sections: action.sections,
      };
    case sectionsConstants.GET_SECTIONS_FAILURE:
      return { 
        ...initialState,
        error: action.error,
      };
    default:
      return state
  }
}