import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, company: null };

const modalSlice = createSlice({
  name: "companyheroModal",
  initialState,
  reducers: {
    openForNewCompanyHero(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.company = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingCompanyHero(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.company = action.payload;
    },
  },
});

export const {
  openForNewCompanyHero,
  close,
  openForEditingCompanyHero,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.companyheroModal;

export const selectCompanyHeroModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectCompanyHeroModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectCompanyHeroModalCompany = createSelector(
  selectState,
  (state) => state.company
);
