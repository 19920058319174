import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import "./common/css/masterstyles.css";
import { Theme } from "./innovation/Theme";
import IdeasModal from "./innovation/IdeasModal";
import ThemesModal from "./innovation/ThemesModal";
import {fetchAllThemes} from "./../redux/toolkit/features/themes";
import {fetchAllIdeas} from "./../redux/toolkit/features/ideas";
import {deleteThemes} from "./../redux/toolkit/features/themes";
import {deleteIdeas} from "./../redux/toolkit/features/ideas";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox"
import { openThemeModal, closeThemeModal } from "./../redux/toolkit/features/innovation/themeModalToggle"
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import Spinner from "./common/Spinner";
import SprintModal from "./sprints/modals/SprintModal";

function Innovation() {
  const themes = Object.values(useSelector((state) => state.themes.entities));
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);   
  const [isDataLoading, setIsDataLoading] = useState(true);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const dispatch = useDispatch();
  
  useEffect(() => {
    async function getThemes() {
      dispatch(fetchAllThemes())
    }
    setIsDataLoading(true);
    getThemes();
    setIsDataLoading(false);
  }, [dispatch]);

  async function onConfirmDelete(result) {
    if (result) {
        
        if (recordtype === 'ideas') {
          dispatch(deleteIdeas(recordkey))
          dispatch(fetchAllIdeas())
        }

        if (recordtype === 'themes') {
          dispatch(deleteThemes(recordkey))
          dispatch(fetchAllThemes())
          dispatch(closeThemeModal())
        }
    }
    dispatch(closeDeleteModal());
}

  function renderinnovationpage() {
    return (
      <Fragment>
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em", userSelect: "none"}}>
        <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em"}}>
          <div style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
          <div className="pr-1 pb-5">
              INNOVATION THEMES <button className="button is-small is-pulled-right" style={{borderColor: backgroundcolor, color: backgroundcolor, borderRadius: "5px"}} onClick={() => dispatch(openThemeModal(null))}>Add Theme</button>
              </div>
            {themes && themes.length === 0 &&
            <p className="pt-6" style={{fontSize: "16.5px"}}>No Innovation Themes Returned</p>}
            </div>


          {themes && themes.length!==0 &&
            themes.map((theme) => (
                    <Theme
                      key={theme.pk}
                      pk={theme.pk}
                      targetvalue={theme.targetvalue}
                      basevalue={theme.basevalue}
                      units={theme.units}
                      showtargets={theme.showtargets}
                      theme={theme.theme}
                      description={theme.description}
                    />
            ))}
        </section>
      </main>

      <IdeasModal/>

      <ThemesModal/>

      <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>

      <SprintModal/>

    </Fragment>

    )
  }

  if (!themes || isDataLoading) {
    return <Spinner/>;
  }

  return (
    (isDataLoading?<Spinner/>:renderinnovationpage())
  );
}

export { Innovation };
