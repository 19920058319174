import {factsConstants} from '../constants/facts';
import {factsService} from "../../services/FactsService";
import {alerts} from "./alerts";

export const factsActions = {
    getAll,
    post,
    deleteRow,
    patch,
};

function getAll() {
    return async dispatch => {
        dispatch(request());
        try {
            const facts = await factsService.getAll();
            dispatch(success(facts.results));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: factsConstants.GET_FACTS_REQUEST } }
    function success(facts) { return { type: factsConstants.GET_FACTS_SUCCESS, facts } }
    function failure(error) { return { type: factsConstants.GET_FACTS_FAILURE, error } }
}

function post(data) {
    return async dispatch => {
        dispatch(request());
        try {
            const fact = await factsService.post(data);
            dispatch(success(fact));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: factsConstants.POST_FACTS_REQUEST } }
    function success(fact) { return { type: factsConstants.POST_FACTS_SUCCESS, fact } }
    function failure(error) { return { type: factsConstants.POST_FACTS_FAILURE, error } }
}

function deleteRow(id) {
    return async dispatch => {
        dispatch(request());
        try {
            await factsService.deleteRow(id);
            dispatch(success(id));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: factsConstants.DELETE_FACTS_REQUEST } }
    function success(id) { return { type: factsConstants.DELETE_FACTS_SUCCESS, id } }
    function failure(error) { return { type: factsConstants.DELETE_FACTS_FAILURE, error } }
}

function patch(pk, data) {
    return async dispatch => {
        dispatch(request());
        try {
            const fact = await factsService.patch(pk, data);
            dispatch(success(fact));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: factsConstants.PATCH_FACTS_REQUEST } }
    function success(fact) { return { type: factsConstants.PATCH_FACTS_SUCCESS, fact } }
    function failure(error) { return { type: factsConstants.PATCH_FACTS_FAILURE, error } }
}