import React, {Fragment, useState, useContext } from "react";
import "bulma/css/bulma.css";
import { successdataset } from "./../../Success";
//import {filter} from "lodash"

function SuccessModalLinksThemes(props) {
    const {toggleSuccessLinksModal, showSuccessLinksModal, linkModaltype,updatelinkthemes } = props;
    const {themes} = useContext(successdataset);
    const [themevalue, setThemeValue] = useState(null)
    let themelist = themes.sort(({theme:a}, {theme:b}) => a-b);
    
    function sectionToggle(event, open) {
        toggleSuccessLinksModal(open,linkModaltype)
        setThemeValue(event.target.value)
        updatelinkthemes(event.target.value)
    }
      
    function selectThemeLink() {
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-left">
                <label className="label field-label">Theme</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => sectionToggle(event, true)}>
                            {themevalue === null && <option>{'<Select Theme>'}</option>}
                                {themelist &&
                                    themelist.map((p) => (
                                    <option key={p.pk} value={p.pk}>
                                        {p.theme} 
                                    </option>
                                ))}

                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
        )
    }

    return (
        <Fragment>
            {selectThemeLink()}
        </Fragment>
    );
}

export default SuccessModalLinksThemes
