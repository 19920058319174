import React, { Fragment } from "react";
import { ThemesList } from "./SuccessThemesList";
import {filter} from "lodash"

function SuccessThemeActions(props) {
    const { innovationVisible, hideInnovationSection, showInnovationSection, linkedthemes, success_id } = props; 
    const linkedthemesfiltered = filter(linkedthemes.results, {success: success_id})
    const linkedthemesfilteredcount = linkedthemesfiltered.length 

    if(linkedthemesfiltered.length !== 0) {
        return (
            
            <Fragment>
                <div className="p-3">
                <ThemesList
                    linkedthemesfiltered = {linkedthemesfiltered}    
                />
                </div>
            </Fragment>

        )
    } else {
        return (
          
            <p className="pl-2"><b>No Linked Innovation Themes Found</b></p>
        
          )
    }
}

export { SuccessThemeActions }