import { menuModalConstants } from '../constants/menuModal';
const initialState = {
    isOpen: false,
}

function processOpenModalAction(state, action) {
    return {
        ...state,
        isOpen: true,
    };
}

function processCloseModalAction(state, action) {
    return {
        ...state,
        isOpen: false,
    };
}

export function menuModal(state = initialState, action) {
    switch (action.type) {
        case menuModalConstants.OPEN_MODAL:
            return processOpenModalAction(state, action);
        case menuModalConstants.CLOSE_MODAL:
            return processCloseModalAction(state, action);

        default:
            return state;
    }
}
