import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeThemeModal } from "./../../redux/toolkit/features/innovation/themeModalToggle";
import { openDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";
import { filter } from "lodash"

function ThemesModal(props) {
  const {themes, dataThemes} = props;
  const showModalThemes = useSelector((state) => state.themeModalToggle.isOpen)  
  const themeModalKey = useSelector((state) => state.themeModalToggle.themepk)
  const [name, setName] = useState('');
  const [description, setDescription] = useState("");
  const [basevalue, setBaseValue] = useState(0);
  const [targetvalue, setTargetValue] = useState(0);
  const [units, setUnits] = useState("");
  const [showtargets, setShowTargets] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  var theme=(filter(themes, {pk: themeModalKey}))
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch(); 

  let modalClass = "modal";
  if (showModalThemes) {
    modalClass += " is-active";
  }

  useEffect(() => {
      setName(theme[0]?theme[0].theme:'');
      setDescription(theme[0]?theme[0].description:'');
      setShowTargets(theme[0]?theme[0].showtargets:false);
      setTargetValue(theme[0]?theme[0].targetvalue:0);    
      setBaseValue(theme[0]?theme[0].basevalue:0); 
      setUnits(theme[0]?theme[0].units:''); 
      setModalTitle(themeModalKey?"EDIT INNOVATION THEME":"NEW INNOVATION THEME" );
  },[theme]);

  useEffect(() => {
    setName(name)
    setDescription(description)
    setShowTargets(showtargets)
    setTargetValue(targetvalue);    
    setBaseValue(basevalue);  
    setUnits(units);
},[name, description, showtargets, targetvalue, basevalue, units]);



  function renderContent() {
    return (
      <Fragment>
        <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>Innovation Theme Title</p>
        <input
            className="input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
        />

        <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Innovation Theme Outcomes</p>
        <textarea
          className="textarea"
          rows="2"
          type="text"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />

        <label className="checkbox pt-4" style={{fontSize:'12px', fontWeight:"500"}}>
          <input className="mr-2" type="checkbox" value={showtargets} checked={showtargets} onChange={e => setShowTargets(e.target.checked)}/>
          Show Theme Measures
        </label>

        {showtargets &&
        <Fragment>
          <div className="columns pt-2">
            <div className="column is-one-third">
              <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Starting Base</p>
              <input
                className="input"
                type="number"
                value={basevalue}
                onChange={e => setBaseValue(e.target.value)}
              />
            </div>
            <div className="column is-one-third">
            <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Target</p>
            <input
              className="input"
              type="number"
              value={targetvalue}
              onChange={e => setTargetValue(e.target.value)}
            />
            </div>
            <div className="column is-one-third">
            <p className="pl-1 pb-1" style={{fontSize:"12px", fontWeight: "500"}}>Units</p>
            <input
              className="input"
              type="text"
              value={units}
              onChange={e => setUnits(e.target.value)}
            />
            </div>
          </div>
        </Fragment>
        }
            
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={modalClass} key="categoryModal">
        <div className="modal-background" key="modalBackground">
          {" "}
        </div>
        <div className="modal-card" key="modalCard">
          <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>{modalTitle}</p>
            <button className="delete" aria-label="close" onClick={() => dispatch(closeThemeModal())}>
              {" "}
            </button>
          </header>
          <section className="modal-card-body" key="modalBody">
            {renderContent()}
          </section>
          <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
            <button
              style={{borderRadius:"4px"}}
              className="button is-success is-small"
              key="save"
              onClick={async () => {
                await dataThemes(themeModalKey, name, description, basevalue, targetvalue, showtargets, units);
                dispatch(closeThemeModal())
            }}
                          >
              Save Changes
            </button>
            <button className="button is-small" style={{borderRadius:"4px"}} key="cancel" onClick={() => dispatch(closeThemeModal())}>
              Cancel
            </button>
            <span className="is-flex-grow-1" />
            {themeModalKey !==null && 
              <button
                style={{borderRadius:"4px"}}
                className="button is-danger is-small"
                key="delete"
                onClick={() => dispatch(openDeleteModal({message: name,recordpk: themeModalKey, recordtype: 'themes'}))}
              >
                Delete
              </button>
          }
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default ThemesModal;
