import axios from "axios";
import {authConfig} from "./helpers";

class BaseService {
    static baseServer = process.env.REACT_APP_API_URL || "http://localhost:8000";
    baseUrl = BaseService.baseServer + "/api"; //TODO: read base url from some kind of config file

    listUrl() {
        return "";
    }

    detailUrl(id) {
        return this.listUrl() + id;
    }

    patchPutUrl(id) {
        return this.detailUrl(id) + "/";
    }

    deleteUrl(id) {
        return this.detailUrl(id) + "/";
    }

    // basic CRUD operations
    async get(id) {
        const url = this.detailUrl(id);
        const response = await axios.get(url, authConfig());
        return response.data;
    }

    async getAll(queryParams = null) {
        // queryparams has to be an object containing the querystring parameters
        // like {sponsor: "Lisa"}
        const url = this.listUrl();
        let config = authConfig()
        if (queryParams) {
            config = {
                ...config,
                params: queryParams,
            }
        }
        const response =  await axios.get(url, config);
        return response.data;
    }

    async patch(id, data) {
        const url = this.patchPutUrl(id);
        const response = await axios.patch(url, data, authConfig());
        return response.data;
    }

    async put(id, data) {
        const url = this.patchPutUrl(id);
        const response = await axios.put(url, data, authConfig());
        return response.data;
    }

    async post(data) {
        const url = this.listUrl();
        const response = await axios.post(url, data, authConfig());
        return response.data;
    }

    async deleteRow(id) {
        const url = this.deleteUrl(id);
        return await axios.delete(url, authConfig());
    }
}

export default BaseService;
