import React, {Fragment, useState, useEffect, useContext } from "react";
import "bulma/css/bulma.css";
import SuccessModalLinksRoadmaps from "./SuccessModalLinksRoadmaps";
import SuccessModalLinksThemes from "./SuccessModalLinksThemes";
import SuccessModalLinksSprints from "./SuccessModalLinksSprints";
import { successdataset } from "./../../Success";
import {filter} from "lodash"

function SuccessModalLinks(props) {
    const {toggleSuccessLinksModal, showSuccessLinksModal, categories, linkModaltype, onThemeLinkChanged, onSprintLinkChanged, defaultbackgroundcolor, defaulttextcolor} = props;
    const {themes, rows, sections, sprints, onLinkChanged} = useContext(successdataset);
    const [isSectionOpened, setIsSectionOpened] = useState(false);
    const [isRowOpened, setIsRowOpened] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [categoryvalue, setCategoryvalue] = useState(null);
    const [sectionvalue, setSectionvalue] = useState(null);
    const [sectionid, setSectionid] = useState(null);
    const [rowid, setRowid] = useState(null);    
    const [themeid, setThemeid] = useState(null);    
    const [sprintid, setSprintid ] = useState(null);    
    var successid=null;
    var modaltitle="Create Link";
    let categorylist = categories.sort();
    let modalClass = "modal";
    if (showSuccessLinksModal) {
        modalClass += " is-active";
    }
   
    var sectionmodallinkid = null;
    var rowmodallinkid = null;    
    useEffect(() => {
        setSectionid(sectionmodallinkid)
        setRowid(rowmodallinkid)     
        console.log("ROWID2",rowmodallinkid,sectionmodallinkid)
    }, []);


    function updatelinkroadmaps(sectionid, rowid) {
        sectionmodallinkid=sectionid
        rowmodallinkid=rowid
        setRowid(rowid)
    }

    function updatelinkthemes(themeid) {
        setThemeid(themeid)
    }

    function updatelinksprints(sprintid) {
        setSprintid(sprintid)
    }

    
    function getLinktype() {
        if (linkModaltype==='roadmaps') {
            modaltitle="Create Roadmap Link"
            return(
                <SuccessModalLinksRoadmaps
                    sections={sections}
                    rows={rows}
                    categories={categories}
                    sectionid={sectionid}
                    rowid={rowid}
                    successid={successid}
                    updatelinkroadmaps={updatelinkroadmaps}

                />
            )
        }

        if (linkModaltype==='themes') {
            modaltitle="Create Innovation Theme Link"
            console.log("At link Themes")
            return(
                <SuccessModalLinksThemes
                    themes={themes}
                    linkModaltype={linkModaltype}
                    showSuccessLinksModal={showSuccessLinksModal}
                    toggleSuccessLinksModal = {toggleSuccessLinksModal}
                    updatelinkthemes={updatelinkthemes}
                /> 

            )
        }

        if (linkModaltype==='sprints') {
            modaltitle="Create Sprint Link"
            return(
                <SuccessModalLinksSprints
                    sprints={sprints}
                    linkModaltype={linkModaltype}
                    showSuccessLinksModal={showSuccessLinksModal}
                    toggleSuccessLinksModal = {toggleSuccessLinksModal}
                    updatelinksprints={updatelinksprints}
                />                    
            )
        }
    }

   

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: defaultbackgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: defaulttextcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => toggleSuccessLinksModal(false)}>
                            {" "}
                        </button>
                    </header>

                <section className="modal-card-body" key="modalBody">
                {getLinktype()}
                </section>

                <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                    <nav className="level">
                    <div className="level-right">
                    </div>
                        <div className="level-left">
                            <div className="level-item">
                                <button className="button is-small" style={{borderRadius:"4px"}} onClick={() => toggleSuccessLinksModal(false)}>
                                        Cancel
                                </button>
                            </div>

                            <div className="level-item">
                                {linkModaltype==="roadmaps" &&
                                <button className="button is-success is-small" style={{color: defaulttextcolor, backgroundColor: defaultbackgroundcolor, borderRadius:"4px"}} onClick={() => onLinkChanged(rowid, successid, 'roadmap')}>
                                    Add
                                </button>}

                                {linkModaltype==="themes" &&
                                <button className="button is-success is-small" style={{color: defaulttextcolor, backgroundColor: defaultbackgroundcolor, borderRadius:"4px"}} onClick={() => onLinkChanged(themeid, successid,'theme')}>
                                    Add
                                </button>}

                                {linkModaltype==="sprints" &&
                                <button className="button is-success is-small" style={{color: defaulttextcolor, backgroundColor: defaultbackgroundcolor, borderRadius:"4px"}} onClick={() => onLinkChanged(sprintid, successid,'sprint')}>
                                    Add
                                </button>}

                            </div>

                        </div>
                    </nav>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default SuccessModalLinks
