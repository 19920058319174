import React, {Fragment } from "react";
import "bulma/css/bulma.css";

function PopupModal(props) {
    const {toggleModal, showModal, description, modaltitle} = props;
    
    let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }
   
    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                        <div className="modal-card" key="modalCard" style={{border:"solid 1px gray", borderRadius:"8px"}}>
                    <header className="modal-card-head m-0 p-3 is-pulled-right" key="modalHeader">
                        <p className='modal-card-title pl-2' style={{fontSize:"17px", fontWeight:"600"}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                <section className="modal-card-body" key="modalBody">
                    <p>{description}</p>

                </section>
             </div>
        </div>

    </Fragment>
    );
}

export default PopupModal;
