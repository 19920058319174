import api from "../api/categories";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const categoriesAdapter = createEntityAdapter({
  selectId: (category) => category.pk,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = categoriesAdapter.getInitialState({
  status: "idle",
});

export const fetchAllCategories = createAsyncThunk("categories/fetch", () =>
  api.fetchAllCategories()
);

export const updateCategory = createAsyncThunk(
  "categories/update",
  api.updateCategory
);

export const createCategory = createAsyncThunk(
  "categories/create",
  api.createCategory
);

export const deleteCategory = createAsyncThunk(
  "categories/delete",
  api.deleteCategory
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCategories.fulfilled, (state, { payload }) => {
        categoriesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCategory.fulfilled, categoriesAdapter.updateOne)
      .addCase(deleteCategory.fulfilled, categoriesAdapter.removeOne)
      .addCase(createCategory.fulfilled, categoriesAdapter.addOne);
  },
});

export default categoriesSlice.reducer;

const selectState = (state) => state.categories;

export const {
  selectAll: selectCategories,
  selectById: selectCategoryById,
} = categoriesAdapter.getSelectors(selectState);

export const selectCategoriesStatus = createSelector(
  selectState,
  (state) => state.status
);
