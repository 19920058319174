import React, {Fragment, useState, useEffect, useContext} from 'react';
import { useSelector } from 'react-redux'
import 'bulma/css/bulma.css';
import { useForm } from 'react-hook-form'
import {filter} from 'lodash'
import {successdataset} from "./../../Success";
import SuccessModalRowsDetail from './SuccessModalRowsDetail'
import SuccessModalRowsRoadmaps from './SuccessModalRowsRoadmaps'
import SuccessModalRowsThemes from './SuccessModalRowsThemes'
import SuccessModalRowsSprints from './SuccessModalRowsSprints'


function SuccessModalRows(props) {
    const {toggleSuccessRowModal, toggleSuccessConfirmModal, toggleSuccessLinksModal, showSuccessRowModal, toggleConfirmDeleteModal, onDataChanged, successkey} = props;
    const {success, themes, rows, sprints, modaltab, linkedrows, linkedsprints, linkedthemes} = useContext(successdataset);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const tabs = ['Details', 'Linked Roadmaps', 'Linked Innovaton Themes', 'Linked Sprints']
    const [activetab, setActivetab] = useState(null);
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')  
    const {reset} = useForm()  

    let modalClass = 'modal';
    if (showSuccessRowModal) {
        modalClass += ' is-active';
    }

    useEffect(() => {
        setActivetab(modaltab);
      }, [modaltab, successkey]);

    let linkedrowsfiltered=filter(linkedrows.results, {success: successkey})
    let rowIds = linkedrowsfiltered.map(r => r.row_id);
    const filteredrows = filter( rows, (row) => rowIds.includes(row.pk));

    let linkedthemesfiltered=filter(linkedthemes.results, {success: successkey})
    let themeIds = linkedthemesfiltered.map(r => r.theme_id);
    const filteredthemes = filter( themes, (theme) => themeIds.includes(theme.pk));

    let linkedsprintsfiltered=filter(linkedsprints.results, {success: successkey})
    let sprintIds = linkedsprintsfiltered.map(r => r.sprint_id);
    const filteredsprints = filter( sprints, (sprint) => sprintIds.includes(sprint.pk));

    let successrow=[]
    if (success) {
        successrow=(filter(success, {pk: successkey}))
    }
    let namevalue= (successrow[0]?.name || '')
    let descriptionvalue= (successrow[0]?.description || '')

    function handleChangeName(event) {
        setName(event.target.value);
      }

    function handleChangeDescription(event) {
        setDescription(event.target.value);
      }

    useEffect(() => {
        setName(namevalue)
      }, [namevalue])

      useEffect(() => {
        setDescription(descriptionvalue)
      }, [descriptionvalue])

    useEffect(() => {
        reset(name)
      }, [name])

    useEffect(() => {
        reset(description)
      }, [description])





    function renderButtons() {

        if (activetab === 'Linked Roadmaps') {
            return (
            <button className='button is-small' key='linkroadmap' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => toggleSuccessLinksModal(true, 'roadmaps')}>
                Link Roadmap
            </button>
            )
        }

        if (activetab === 'Linked Innovaton Themes') {
            return (
            <button className='button is-small' key='linktheme' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => toggleSuccessLinksModal(true, 'themes')}>
                Link Innovation Theme
            </button>
            )
        }

        if (activetab === 'Linked Sprints') {
            return (
            <button className='button is-small' key='linksprint' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => toggleSuccessLinksModal(true, 'sprints')}>
                Link Sprint
            </button>
            )
        }
    }

    function formtabs() {
        return (
            <div className='tabs is-boxed'>
                <ul>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? 'is-active' : ''}
                            key={tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }


    return (
        <Fragment>
        <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
            <div className='modal-background' key='modalBackground'>
                {' '}
            </div>
            <div className='modal-card' key='modalCard'>
                <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>SUCCESS VISION</p>
                    <button className='delete' aria-label='close' onClick={() => toggleSuccessRowModal(false, null, 'Details')}>
                        {' '}
                    </button>
                </header>
                <section className='modal-card-body' key='modalBody' style={{minHeight: '450px'}}>
                {formtabs()}
                    <SuccessModalRowsDetail
                        name={name}
                        description={description}
                        handleChangeName={handleChangeName}
                        handleChangeDescription={handleChangeDescription}
                        activetab={activetab}
                    />

                    {activetab === 'Linked Roadmaps' &&
                    <SuccessModalRowsRoadmaps
                        filteredrows={filteredrows}
                        activetab={activetab}
                        successkey={successkey}
                        toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                    />}

                    {activetab === 'Linked Innovaton Themes' &&
                    <SuccessModalRowsThemes
                        filteredthemes={filteredthemes}
                        successkey={successkey}
                    />}

                    {activetab === 'Linked Sprints' &&
                    <SuccessModalRowsSprints
                        filteredsprints={filteredsprints}
                        successkey={successkey}
                    />}

                </section>
                <footer className='modal-card-foot is-flex pt-0 pb-0' key='modalFooter' style={{height:"60px"}}>
                    <nav className='level'>
                        <div className='level-left'>
                            <div className='level-item'>
                                <button
                                    style={{borderRadius:"4px"}}
                                    className='button is-success is-small'
                                    key='save'
                                    onClick={async () => {
                                        await onDataChanged(name, description, successkey);
                                        toggleSuccessRowModal(false);
                                    }}
                                >
                                    Save Changes
                                </button>
                            </div>
                            <div className='level-item'>
                                <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => toggleSuccessRowModal(false,null,'Details')}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className='level-right'>
                            <div className='level-item'>
                                {successkey !== null &&
                                <Fragment>
                                {renderButtons()}
                                </Fragment>
                                }
                            </div>
                        </div>
                    </nav>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default SuccessModalRows;
