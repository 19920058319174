import { aboutModalConstants } from '../constants/aboutModal';
const initialState = {
    isOpen: false,
}

function processOpenModalAction(state, action) {
    console.log("processOpenModalAction");
    return {
        ...state,
        isOpen: true,
    };
}

function processCloseModalAction(state, action) {
    console.log("processCloseModalAction");
    return {
        ...state,
        isOpen: false,
    };
}

export function aboutModal(state = initialState, action) {
    switch (action.type) {
        case aboutModalConstants.OPEN_MODAL:
            return processOpenModalAction(state, action);
        case aboutModalConstants.CLOSE_MODAL:
            return processCloseModalAction(state, action);

        default:
            return state;
    }
}
