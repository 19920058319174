import React from "react";
import {companyService} from "../../services/CompanyService";

function CompanyHeroSectionWithData(props) {
    const {herotitle, herosubtitle, heroimage, toggleModal} = props;

    let imageURL = companyService.imageUrl({heroimage});
    let backgroundStyle = {backgroundColor: 'blue', userSelect: "none"};
    if (heroimage) {
        backgroundStyle = {
            backgroundImage: 'url(' + imageURL + ')',
            backgroundSize: "cover",
            //backgroundAttachment: "fixed",
            userSelect: "none",
            backgroundPosition: "center"
        };
    }
    let fs=[];


    return (
        <section className="hero is-large" onDoubleClick={() => toggleModal(true)} style={backgroundStyle}>
            <div className="hero-body" onDoubleClick={() => toggleModal(true)}>
                <div className="" style={{marginLeft: "12%"}}>
                    <p className="title" style={{
                        fontWeight: "700",
                        fontFamily: "Gotham Extra",
                        fontSize: "72px",
                        lineHeight: "26px",
                        paddingBottom: "28px",
                        color: "white",
                        userSelect: "none"
                    }}>
                        {herotitle}
                    </p>
                    <p className="subtitle" style={{
                        fontWeight: "700",
                        fontFamily: "Gotham Extra",
                        fontSize: "50px",
                        lineHeight: "26px",
                        paddingTop: "12px",
                        paddingBottom: "28px",
                        color: "white"
                    }}>
                        {herosubtitle}
                    </p>
                </div>
            </div>
           
        </section>
    );
}

export default CompanyHeroSectionWithData;
