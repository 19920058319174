import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class SprintcycleService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/sprintcycle/`;
    }

    async getSprintdate() {
        const sprintdates = await this.getAll();
        let sprintdate;
        if (sprintdates.count === 0) {
            sprintdate = await sprintcycleService.post({ sprintenddate: (new Date()).toLocaleDateString()})
        } else {
            sprintdate = sprintdates.results[0];
        }
        return sprintdate;
    }
}

export const sprintcycleService = new SprintcycleService();
