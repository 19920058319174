import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, comment: null };

const modalSlice = createSlice({
  name: "sectionModal",
  initialState,
  reducers: {
    openForNewSection(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.comment = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingSection(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.name = action.payload;
    },
  },
});

export const {
  openForNewSection,
  close,
  openForEditingSection,
} = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.roadmapsectionModal;


export const selectsectionModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectsectionModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectsectionModalSections = createSelector(
  selectState,
  (state) => state.name
);
