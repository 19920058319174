import React, {Fragment, useState, useEffect} from 'react';


function SuccessYearModal(props) {
    const {successyear, toggleSuccessYearModal, isSuccessYearModalOpen, onDataChanged, defaultbackgroundcolor, defaulttextcolor} = props;
    let modalClass = 'modal';
    if (isSuccessYearModalOpen) {
        modalClass += ' is-active';
    }
   
    const currentyear = (new Date()).getFullYear();
    const years = Array.from(new Array(10),(val, index) => currentyear + index);
    const [year, setYear] = useState(successyear.results[0]?.year || currentyear );

    function handleChangeYear(event) {
        setYear(event.target.value);
    }

    useEffect(() => {
        setYear(year)
      }, [year])


    function renderForm() { 

        return (

        <Fragment>
            <div className="field is-horizontal is-align-items-center">
                    {/* <label className="label field-label">Year</label> */}
                    <div className="field-body">
                        <div className="control">
                            <div className="select">
                                <select
                                    style={{width:"250px"}}
                                    className="select"
                                    value={year}
                                    onChange={handleChangeYear}
                                >
                                    {years &&
                                    years.map((p, index) => (
                                        <option key={"YearList_"+index} value={p}>
                                            {p}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>
                </div>     
            </Fragment>
        )
    }

   


    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard' style={{width: "300px"}}>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: defaultbackgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: defaulttextcolor}}>SET SUCCESS YEAR</p>
                        <button className='delete' aria-label='close' onClick={() => toggleSuccessYearModal(false)}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '50px'}}>
                      
                    {renderForm()}

                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="new"
                            onClick={async () => {
                                await onDataChanged(year);
                                toggleSuccessYearModal(false)
                            }}
                        >
                            Save
                        </button>
                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => toggleSuccessYearModal(false)}>
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessYearModal;
