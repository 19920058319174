import { userConstants } from "../constants/users";

const initialState = {
  loading: false,
  currentUser: {},
  listResponse: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  error: null,
};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_CURRENT_USER_REQUEST:
    case userConstants.SAVE_PROFILE_REQUEST:
    case userConstants.CHANGE_PASSWORD_REQUEST:
    case userConstants.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state,
        listResponse: initialState.listResponse,
        loading: true,
      };
    case userConstants.GET_CURRENT_USER_SUCCESS:
    case userConstants.SAVE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.user,
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listResponse: action.listResponse,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.GET_CURRENT_USER_FAILURE:
    case userConstants.SAVE_PROFILE_FAILURE:
    case userConstants.CHANGE_PASSWORD_FAILURE:
    case userConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state,
        listResponse: initialState.listResponse,
        loading: false,
        error: action.error,
      };
    case userConstants.CLEAR_USER_ERROR:
      console.log("Clean user error");
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
