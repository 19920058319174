import {businessunitModalConstants} from "../constants/businessunitsModal";
const initialState = {
    isOpen: false,
    isCreatingNew: false,
}

function processOpenModalAction(state, action) {
    console.log("processOpenBusinessunitsModalAction");
    return {
        ...state,
        isOpen: true,
        isCreatingNew: false,
    };
}

function processCloseModalAction(state, action) {
    console.log("processCloseBusinessunitsModalAction");
    return {
        ...state,
        isOpen: false,
        isCreatingNew: false,
    };
}

function processOpenModalCreateNew(state, action) {
    console.log("processOpenModalCreateNew");
    return {
        ...state,
        isCreatingNew: true,
    };
}

function processOpenModalCancelCreateNew(state, action) {
    console.log("processOpenModalCancelCreateNew");
    return {
        ...state,
        isCreatingNew: false,
    };
}

function processOpenModalSaveBusinessunitSuccess(state, action) {
    console.log("processOpenModalSaveBusinessunitSuccess");
    return {
        ...state,
        isCreatingNew: false,
    };
}

export function businessunitModal(state = initialState, action) {
    switch (action.type) {
        case businessunitModalConstants.OPEN_MODAL:
            return processOpenModalAction(state, action);
        case businessunitModalConstants.CLOSE_MODAL:
            return processCloseModalAction(state, action);

        case businessunitModalConstants.CREATE_NEW:
            return processOpenModalCreateNew(state, action);
        case businessunitModalConstants.CANCEL_CREATE_NEW:
            return processOpenModalCancelCreateNew(state, action);

        case businessunitModalConstants.SAVE_BUSINESSUNIT_SUCCESS:
            return processOpenModalSaveBusinessunitSuccess(state, action);

        default:
            return state;
    }
}
