import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, peoplepk: null };

const modalSlice = createSlice({
  name: "peopleModal",
  initialState,
  reducers: {
    openPeopleModal(state, action) {
      state.isOpen = true;
      state.peoplepk = action.payload;
    },
    closePeopleModal(state) {
      state.isOpen = false;
      state.peoplepk = null;
    },
  },
});

export const { openPeopleModal, closePeopleModal } = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.peopleModal;

