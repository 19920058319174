import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, sprintpk: null };

const modalSlice = createSlice({
  name: "sprintsModalToggle",
  initialState,
  reducers: {
    openSprintsModal(state, action) {
      state.isOpen = true;
      state.factpk = action.payload;
    },
    closeSprintsModal(state) {
      state.isOpen = false;
      state.factpk = null;
    },
  },
});

export const { openSprintsModal, closeSprintsModal } = modalSlice.actions;

export default modalSlice.reducer;

//const selectState = (state) => state.factsModal;
