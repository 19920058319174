import React, {Fragment, useState, useEffect, useContext} from "react";
import "../common/css/dot.css";
import { peopledatasets } from '../OrganizationPage';
import {filter} from "lodash"


function Roles(props) {
    const {persons, personyear, toggleTalentModal, roleperson, sankey, positions, personview, competencies} = props;
    const {defaultbackgroundcolor, defaulttextcolor, lightbackgroundcolor, darkbackgroundcolor} = useContext(peopledatasets);
    const [selectedTab, setSelectedTab] = useState('All')
    const [isTable, setIsTable] = useState(true)
    const [positionlist, setPositionlist]=useState([])
    console.log("Peoples", personview)

    function toggleButton() {
        if (isTable) {
            setIsTable(false)
        } else {
            setIsTable(true)
        }
    }

    function rolelist(row){
      return (
        <tr>
        <td>{row.title}</td>
        <td>{row.businessunit}</td>
        <td>{row.startdate}</td>
        <td>{row.enddate}</td>
        <td>{row.currency}</td>
        <td>{row.base}</td>
        <td>{row.bonus}</td>
        <td>{row.stock}</td>
        <td><a><i className="far fa-clone mt-1" style={{color:"gray"}} title="Clone Role"></i></a></td>
        <td><a><i className="far fa-trash-alt mt-1" style={{color:"gray"}} title="Delete Role"/></a></td>
        </tr>
      )
    }

    if (!positions) {
      return null
    }

    console.log("Positions", positions)

    return (
        <Fragment>
          <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        ROLE DIRECTORY  ({positions.results.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-4">
                                <label className="checkbox subtitle" style={{fontWeight: "400", fontFamily: "Gotham Extra", fontSize: "16px"}}>
                                    <span className="mr-2">
                                        <small>Show Current</small>
                                    </span>
                                    <input
                                        type="checkbox"
                                    />
                                </label>
                            </div>

                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small"  type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth">
                <tr>
                  <th>Title </th>
                  <th>Business Unit</th>
                  <th>Currency</th>
                  <th>Base</th>
                  <th>Bonus</th>
                  <th>Stock</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th width="30px"></th>
                  <th style={{width:"8px"}} ><a><button className="fas fa-plus-circle"></button></a></th>
                </tr>

              {positions && positions.results.map(row => (
                rolelist(row)
              ))}
              </table>
                


            </section>
        </Fragment>
    )
}

export {Roles};