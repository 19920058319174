import { sprintsConstants } from '../constants/sprints';

const initialState = {
  loading: false,
  sprints: [],
  error: null,
}

export function sprints(state = initialState, action) {
  switch (action.type) {
    case sprintsConstants.GET_SPRINTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sprintsConstants.GET_SPRINTS_SUCCESS:
      return {
        ...state,
        sprints: action.sprints,
      };
    case sprintsConstants.GET_SPRINTS_FAILURE:
      return { 
        ...state,
        error: action.error,
      };
    default:
      return state
  }
}