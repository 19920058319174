import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import "bulma/css/bulma.css";

function CompanyHeroModal(props) {
    const {toggleModal, showModal, onDataChanged, onImageChanged} = props;
    const [herotitle, setHeroTitle] = useState(props.herotitle !== null ? props.herotitle: "");
    const [herosubtitle, setHeroSubtitle] = useState(props.herosubtitle !== null ? props.herosubtitle: "");
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)


    function handleChangeHeroTitle(event) {
        setHeroTitle(event.target.value);
    }

    function handleChangeHeroSubtitle(event) {
        setHeroSubtitle(event.target.value);
    }

    async function onImageFileSelected(event) {
        const files = event.target.files;
        await onImageChanged(files[0]);
        toggleModal(false);
    }

    let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Tagline</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <input
                                className="input"
                                type="text"
                                placeholder="Theme Title"
                                value={herotitle}
                                onChange={handleChangeHeroTitle}
                            />
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Tagline Description</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
              <textarea
                  className="textarea"
                  rows="2"
                  placeholder="Theme Description"
                  value={herosubtitle}
                  onChange={handleChangeHeroSubtitle}
              />
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Image</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <label className="file-label">
                                    <input
                                        className="file-input"
                                        type="file"
                                        name="logo"
                                        accept="image/gif, image/jpeg, image/png"
                                        onChange={async (event) => onImageFileSelected(event)}
                                    />
                                    <span className="button">Upload Image <i className="fas fa-upload ml-3" style={{color:backgroundcolor}}></i></span>
                                </label>
                        </div>

                    </div>
                </div>

        </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY HERO</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await onDataChanged(herotitle, herosubtitle);
                                toggleModal(false);
                            }}
                        >
                            Save Changes
                        </button>
                        <button className="button is-small" key="cancel"  style={{borderRadius:"4px"}} onClick={() => toggleModal(false)}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyHeroModal;
