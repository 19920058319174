import React, {Fragment} from "react";


function CompanyPurposeSectionWithData(props) {
    const {purpose, statement, toggleModal} = props;

    const inlineMode = {
        enable: false,
        onSelection: false
    };


    if (!purpose || !statement) {
        return null;
    }

    return (
            <section style={{
                paddingLeft: "17%",
                paddingRight: "17%",
                backgroundColor: "RGB(240,240,240)",
                paddingTop: "100px",
                paddingBottom: "130px",
                userSelect: "none"
            }} onDoubleClick={toggleModal}>
                <p style={{paddingBottom: "40px"}}>
                    <span style={{fontFamily: "Gotham Extra", fontSize: "59px", lineHeight: "56px"}}>
                        {purpose}
                    </span>
                </p>
                <div className="purposecontainer" style={{textAlign: "justify"}}>
                    <span style={{fontFamily: "Gotham Extra", fontSize: "16.5px", lineHeight: "1.8em"}}>
                        <div dangerouslySetInnerHTML={{__html:statement}}/>
                    </span>
                </div>
            </section>
    );
}

export default CompanyPurposeSectionWithData;
