import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { openTimelineModal, closeTimelineModal } from "./../../redux/toolkit/features/company/timelineModal";
import { timelinesActions } from "./../../redux/actions/timelines";

function CompanyTimelineSection() {
  const timelines = useSelector((state) => state.timelines.timelines);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch(); 


  useEffect(() => {
    dispatch(timelinesActions.getAll());
  }, []);



  function renderTimeline() {
    if (timelines && timelines.length===0) {
      return (
        <section className="mt-6 mb-6" style={{paddingLeft: "17%", paddingRight: "17%", backgroundColor: "White", paddingTop: "80px", paddingBottom: "80px", userSelect: "none"}} >
          <div className="columns is-multiline">
            <div className="column is-one-third" style={{fontFamily: "Gotham Extra", fontSize: "62px", lineHeight: "56px"}}>
              <div className="pr-1">
              OUR HISTORY
              </div>
            </div>
            <div className="column is-one-third pt-0 pr-0 pb-4 pl-4 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px", lineHeight: "0.8em"}}>
              <div className="level" style={{backgroundColor: "transparent", minHeight: "150px",userSelect: "none"}}>
                <div className="level-item" style={{userSelect: "none", minHeight: "150px"}}>
                  <button className = "button p-4" style={{backgroundColor: "transparent", border: "solid 1px darkgrey", fontFamily: "Gotham Extra", fontSize: "20px"}} onDoubleClick={() => dispatch(openTimelineModal())} >
                  Add Company Timeline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    
      } else {
        return (
          <Fragment>
          <section className="section ml-0 mr-0 pl-0 ml-0" style={{marginTop: "60px", marginBottom: "60px", userSelect: "none"}} onDoubleClick={() => dispatch(openTimelineModal())}>
          <p className="pl-0" style={{fontFamily: "Gotham Extra", fontSize: "62px", lineHeight: "56px", marginLeft: "17%"}}>
            OUR HISTORY
          </p>
            <div className="timeline is-centered" style={{marginLeft: "20%", marginRight: "20%"}}>
            <header className="timeline-header">
              <span className="tag is-large" style={{backgroundColor:backgroundcolor, color: textcolor}}>Start</span>
            </header>
          {timelines && timelines.map((timeline) => (
              <Fragment key={"Timeline_fragment_"+timeline.pk}>
              <div className="timeline-item" >
                <div className="timeline-marker"> </div>
                <div className="timeline-content">
                  <p className="heading" style={{ fontSize: "1.1em" }}>
                    {timeline.year}
                  </p>
                  <p style={{ fontSize: "1.1em" }}>{timeline.event}</p>
                </div>
              </div>
              
              </Fragment>
          ))}
              <div className="timeline-header">
              <span className="tag is-large" style={{backgroundColor:backgroundcolor, color: textcolor}} >Today</span>
            </div>
          </div>
          </section>
          </Fragment>
        )
      }
    }


  return <Fragment>




            {renderTimeline()}

            


    
    </Fragment>;
}

export default CompanyTimelineSection;
