import React, { Fragment, useEffect, useState, getState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import {closeIdeasModal} from "./../../redux/toolkit/features/innovation/ideaModalToggle";
//import {updateIdeas} from "./../../redux/toolkit/api/ideas";
import {fetchAllIdeas, updateIdeas,createIdeas} from "./../../redux/toolkit/features/ideas";

function IdeasModal(props) {
  //const {ideas} = props;
  const ideas = useSelector((state) => state.ideas.entities);
  const showModalIdeas = useSelector((state) => state.ideaModalToggle.isOpen)
  const ideaModalKey = useSelector((state) => state.ideaModalToggle.ideapk)
  const themeModalKey = useSelector((state) => state.ideaModalToggle.themepk)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const modaldata = useSelector((state) => state.ideasModal)
  var idea=filter(ideas, {pk:ideaModalKey})
  const [title, setTitle] = useState('')
  const [ideation, setIdeation] = useState('')
  const [impact, setImpact] = useState(0)
  const [cost, setCost] = useState(0)
  const [modaltitle, setModalTitle] = useState('')
  const dispatch = useDispatch(); 

  useEffect(() => {
    async function getIdeas() {
      dispatch(fetchAllIdeas())
      idea=filter(ideas, {pk:ideaModalKey})
    }
    getIdeas();
  },[]);

  useEffect(() => {
    setTitle(idea[0]?idea[0].title:''); 
    setIdeation(idea[0]?idea[0].comment:''); 
    setCost(idea[0]?idea[0].cost:0); 
    setImpact(idea[0]?idea[0].impact:0); 
    setModalTitle(ideaModalKey?"EDIT IDEA":"ADD NEW IDEA" );
  }, [idea]);

  useEffect(() => {
    setTitle(title)
    setIdeation(ideation)
    setCost(cost)
    setImpact(impact)
},[title,ideation, cost, impact]);


  let modalClass = "modal";
  if (showModalIdeas) {
    modalClass += " is-active";
  }

  function renderContent() {
    return (
      <Fragment>
        <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>Idea Title</p>
        <input
            className="input"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
        />

        <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Idea Description</p>
        <input
            className="input"
            type="text"
            value={ideation}
            onChange={(e) => setIdeation(e.target.value)}
        />

      <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Potential Impact</p>
        <input
            className="input"
            type="number"
            value={impact}
            onChange={(e) => setImpact(e.target.value)}
        />

      <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Cost Estimate</p>
        <input
            className="input"
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
        />
      </Fragment>
    );
  }

  if (!themeModalKey || !ideas) {
    return null;
  }

  return (
    <Fragment>
      <div className={modalClass} key="categoryModal">
        <div className="modal-background" key="modalBackground">
          {" "}
        </div>
        <div className="modal-card" key="modalCard">
          <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>{modaltitle}</p>
            <button className="delete" aria-label="close" onClick={() => dispatch(closeIdeasModal())}>
              {" "}
            </button>
          </header>
          <section className="modal-card-body" key="modalBody">
            {renderContent()}
          </section>
          <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
            {ideaModalKey === null &&
              <Fragment>
                <button
                    style={{borderRadius:"4px"}}
                    className="button is-success is-small"
                    key="save"
                    onClick={async () => {
                      dispatch(createIdeas({title: title, comment: ideation, impact: impact, cost: cost, theme: themeModalKey}))

                      dispatch(closeIdeasModal())
                    }}
                >
                    Save Changes
                </button>
              </Fragment>}

            {ideaModalKey !== null &&
              <Fragment>
              <button
                  style={{borderRadius:"4px"}}
                  className="button is-success is-small"
                  key="save"
                  onClick={async () => {
                    dispatch(updateIdeas({id:ideaModalKey, title: title, comment: ideation, impact: impact, cost: cost, theme: themeModalKey}))
                    dispatch(closeIdeasModal())
                  }}
              >
                  Save Changes
              </button>
              </Fragment>}
            <button

            >
              Save changes
            </button>
            <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeIdeasModal())}>
              Cancel
            </button>
            {/* <span className="is-flex-grow-1" />
            {idea && (
              <button
                style={{borderRadius:"4px"}}
                className="button is-danger is-small"
                key="delete"
              >
                Delete
              </button>
            )} */}
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default IdeasModal;
