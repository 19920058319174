import React, { Fragment } from "react";


function PurposePage() {
 

  return (
    <Fragment>
      <p>test</p>


    </Fragment>
  );
}

export { PurposePage };
