import React, {Fragment} from 'react';
import {filter} from 'lodash'


function SectionsModalSection(props) {
    const {name, setName, narrative, setNarrative, category, setCategory, sections, sectionpk } = props;

    var categorylist=[];
    if (sections) {
        categorylist = [...new Set( sections.results.map(obj => obj.category)) ];
        categorylist = categorylist.sort(); 
    }

    let sectionrow=filter(sections.results.rows, {pk: sectionpk});

    function handleChangeSectionTitle(event) {
        setName(event.target.value);
    }

    function handleChangeSectionNarrative(event) {
        setNarrative(event.target.value);
    }

    function handleSelectCategory(event) {
        if (event.target.value !== category) {
            setCategory(event.target.value);
        }
    }

    function handleNewCategory() {
    }


    return (
        <Fragment>
                
            <div className='field is-horizontal is-align-items-center'>
                <label className='label field-label'>Roadmap Title</label>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                    {name !== undefined &&
                        <input
                            className='input'
                            type='text'
                            placeholder='Roadmap Title'
                            value={name}
                            onChange={handleChangeSectionTitle}
                        />}
                    </div>
                </div>
            </div>

            <div className='field is-horizontal is-align-items-center'>
                <label className='label field-label'>Roadmap Narrative</label>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                    {narrative !== undefined &&    
                        <textarea
                            className='textarea'
                            rows='8'
                            placeholder='Roadmap Narrative'
                            value={narrative}
                            onChange={handleChangeSectionNarrative}
                        />}
                    </div>
                </div>
            </div>

            <div className='field is-horizontal is-align-items-center'>
                <label className='label field-label'>Category</label>
                <div className='field-body is-flex'>
                    <div className='control is-flex-grow-1'>
                    {/* {categorystatus &&
                    <input class="input" type="text"  placeholder="New Category"/>} */}
                    {category !== undefined &&
                        <Fragment>
                            <div className='select is-flex'>
                                <select
                                    className='select is-fullwidth'
                                    value={category}
                                    onChange={handleSelectCategory}
                                    onClick={handleNewCategory}
                                >
                                {categorylist &&
                                    categorylist.map((p) => (
                                    <option key={p} value={p}>
                                        {p}
                                    </option>
                                    ))}
                                    <option key='new'>
                                    &lt;New&gt;</option>
                                </select>
                            </div>
                        </Fragment>}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default SectionsModalSection;
