import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, category: null };

const modalSlice = createSlice({
  name: "categoryModal",
  initialState,
  reducers: {
    openForNewCategory(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.category = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingCategory(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.category = action.payload;
    },
  },
});

export const {
  openForNewCategory,
  close,
  openForEditingCategory,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.categoryModal;

export const selectCategoryModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectCategoryModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectCategoryModalCategory = createSelector(
  selectState,
  (state) => state.category
);
