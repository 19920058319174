import React, { Fragment } from "react";
import { useSelector } from 'react-redux'
import {filter} from "lodash"

function SuccessGrid(props) {
    const { success, hideSection, baselinedelta, linkedrows } = props; 
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)


    function renderGrid(successlist) {
        var baselinerows = filter( linkedrows.results, {success: successlist.pk})
        var alertstatussevere = false;
        var alertstatusmedium = false;
        var baselinerowslist = baselinerows.map(r => r.row_id)     
        let alertlist=filter( baselinedelta, (row) => baselinerowslist.includes(row.row_id))
        console.log("Aletrtlist", alertlist)
        var alertstatus=null;
        if (alertlist.length === 0) {
            alertstatus=null;
        } else {
            for (let i = 0, len = alertlist.length; i < len; i++) {
                if ((alertlist[i].baselinealertsevere < 0 && alertlist[i].growth < alertlist[i].baselinealertsevere) || (alertlist[i].baselinealertsevere > 0 && alertlist[i].growth > alertlist[i].baselinealertsevere)){
                    alertstatussevere = true;
                } else if ((alertlist[i].baselinealertmoderate < 0 && alertlist[i].growth < alertlist[i].baselinealertmoderate) || (alertlist[i].baselinealertmoderate > 0 && alertlist[i].growth > alertlist[i].baselinealertmoderate)){
                    alertstatusmedium = true;
                }
            }
        }
        return (
            <Fragment>

                {(alertstatusmedium === true && alertstatussevere === false) &&
                <div className="card mt-0 pt-0 pb-2 is-shadowless" style={{minHeight: "250px", backgroundColor: backgroundcolor, width: "100%", borderLeft: "5px gold solid"}}>
                {renderCard(successlist)}
                </div>}

                {(alertstatussevere === true) &&
                <div className="card mt-0 pt-0 pb-2 is-shadowless" style={{minHeight: "250px", backgroundColor: backgroundcolor, width: "100%", borderLeft: "5px red solid"}}>
                {renderCard(successlist)}
                </div>}

                {(alertstatusmedium === false && alertstatussevere === false) &&
                <div className="card mt-0 pt-0 pb-2 is-shadowless" style={{minHeight: "250px", backgroundColor: backgroundcolor, width: "100%"}}>
                {renderCard(successlist)}
                </div>}

            </Fragment>
        )
    }

    function renderCard(successlist) {
        return (
                <Fragment>
                <header className="card-header is-shadowless">
                    <p className="pl-5 pt-5 pb-0" style={{color:textcolor}}>
                        {successlist.name}
                    </p>
                </header>
                <div className="card-content pt-0" style={{color:textcolor}}>
                    <div className="content pl-0 ml-0" style={{backgroundColor: "transparent"}}><span style={{width:"100%",fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}} dangerouslySetInnerHTML={{
                        __html: (successlist.description || "")
                    }} />
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className="columns is-multiline">
                {success && success.map(successlist => (
                    <div className="column is-one-third pt-0 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "26px",lineHeight: "1em"}} key={successlist.pk} onClick={() => hideSection(successlist)}>
                        {renderGrid(successlist)}
                    </div>
                ))}
            </div>
        </Fragment>
       )
    }

    export { SuccessGrid };