import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { filter } from "lodash";
import { baselineValuesService } from "./../../services/BaselineValuesService";
import { FormatString } from "./../common/FormatCell";

function BaselineValue(props) {
    const {cagr, growth, sectionPk, rowPk, year, baselinevalues, onCellDataChanged, type, formula, baselinedelta, isbaseline, numericcell, textcell, decimalplaces, mediumalert, severealert} = props;
    let initalnumericvalue=null;
    if (numericcell !== null) {
        initalnumericvalue=FormatString(numericcell, type, decimalplaces)
    }
    const [numericvalue, setNumericValue] = useState(initalnumericvalue );   
    const [textvalue, setTextValue] = useState(textcell);   
    //const [hasChanged, setHasChanged] = useState(false);
    //const [data, setData] = useState(null);   
    const [inputcellnumber, setInputCellNumber] = useState(numericcell ? numericcell: null)
    let dataarray = filter(baselinevalues.results,{year: year, row_id: rowPk})
    let numericValues= (dataarray[0]?.numeric_value || null)
    let baselinedata = filter(baselinedelta,{year: year, row_id: rowPk})
    let cellkey= (dataarray[0]?.pk || null)

    async function handleCellChange(event) {

        if ((event.type==='blur' || event.keyCode === 13)) {
            var cellstring = null;
            if (numericvalue !== null) {
            cellstring = numericvalue.replace(/,/g,"")
            cellstring = cellstring.replace(/%/g,"")
            }
            if (numericcell !== cellstring) {
                let data = filter(baselinevalues.results, {year: year, row_id: rowPk});
                if (data.length===0) {
                    await baselineValuesService.post({numeric_value: cellstring, year: year, row: rowPk});
                } else {
                    await baselineValuesService.patch(data[0].pk, {numeric_value:cellstring});
                }
            }
            if (cellstring!== "") {
                setNumericValue(FormatString(cellstring, type, decimalplaces))
            }
            
        }
        }

    function handleCellupdate(event) {
        let result=""
        if (!isNaN(Number(event.target.value.replace(/,/g,"")))) {

            let cellstring = event.target.value.replace(/,/g,"")

            switch(type) {
                case null:
                    return ""
                case 'Number':
                    if (cellstring === "") {
                        setNumericValue(null)
                        } else {
                        if (decimalplaces === 0) {
                            result=Number((Math.round(cellstring * 100) / 100).toFixed(0)).toLocaleString('en') 
                            setNumericValue(result)
                        } else {
                            let leftstring=""
                            let rightstring=""
                            if (cellstring.indexOf(".") >= 0) {
                                var parts = cellstring.split('.', 2);
                                leftstring=parts[0]
                                rightstring=("." + parts[1]).substring(0,decimalplaces+1);

                            } else {
                                leftstring=cellstring
                                rightstring=""
                            }
                        result=Number((Math.round(leftstring * 100) / 100).toFixed(0)).toLocaleString('en') + rightstring
                        setNumericValue(result)
                        }
                    }
                    break;
                case 'Percentage':
                    if (cellstring === "") {
                        setNumericValue(null)
                        } else {
                        if (decimalplaces === 0) {
                            result=Number((Math.round(cellstring * 100) / 100).toFixed(0)).toLocaleString('en') 
                            setNumericValue(result)
                        } else {
                            let leftstring=""
                            let rightstring=""
                            if (cellstring.indexOf(".") >= 0) {
                                var parts = cellstring.split('.', 2);
                                leftstring=parts[0]
                                rightstring=("." + parts[1]).substring(0,decimalplaces+1);

                            } else {
                                leftstring=cellstring
                                rightstring=""
                            }
                        result=Number((Math.round(leftstring * 100) / 100).toFixed(0)).toLocaleString('en') + rightstring
                        setNumericValue(result)
                        }
                    }
                    break;
                default: 
                    setNumericValue(cellstring)

            //setNumericValue(Number(event.target.value.replace(/,/g,"")))

        }
    }}
    

    function renderFields() {
        let key = `${rowPk}_${year}`;
        let textValue = null
        let baselinerow = filter(baselinedelta, {year: year, row_id: rowPk})

        let delta = null;
        if (baselinerow.length !== 0) {
            delta=baselinerow[0].growth
        }
        let alertstatus=null;
        if ((severealert < 0 && delta < severealert) || (severealert > 0 && delta > severealert)){
            alertstatus = "high";
        } else if ((mediumalert < 0 && delta < mediumalert) || (mediumalert > 0 && delta > mediumalert)){
            alertstatus = "moderate";
        }

        let rowstyle=null;
        
        if (alertstatus === "high") {
            rowstyle={border:"2px red solid"}
        } 
        
        if (alertstatus === "moderate") {
            rowstyle={border:"2px gold solid"}
        }



        switch(type) {
            case "Number":
                return (
                    <Fragment>
                        {inputcellnumber &&
                            <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange}/>
                        }
                        {!inputcellnumber &&
                            <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange} />
                        }
                    </Fragment>
                )

                case "Percentage":
                    return (
                        <Fragment>
                            {inputcellnumber &&
                                <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange}/>
                            }
                            {!inputcellnumber &&
                                <input key={key} className="mb-0 has-text-centered" type="text" style={rowstyle} value={numericvalue} onChange={handleCellupdate} onBlur={handleCellChange} onKeyDown={handleCellChange} />
                            }
                        </Fragment>
                    )                

        } 
    }

    return (
        <Fragment>
        <td style={{textAlign:"center"}} key={'test'}>
            {renderFields()}
        </td>

        </Fragment>
    )
}

export { BaselineValue }; 
