import api from "../api/matrices";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const matricesAdapter = createEntityAdapter({
  selectId: (matrix) => matrix.pk,
});

const initialState = matricesAdapter.getInitialState({
  status: "idle",
});

export const fetchAllMatrices = createAsyncThunk("matrices/fetch", () =>
  api.fetchAllMatrices()
);

export const updateMatrix = createAsyncThunk(
  "matrices/update",
  api.updateMatrix
);

export const createMatrix = createAsyncThunk(
  "matrices/create",
  api.createMatrix
);

export const deleteMatrix = createAsyncThunk(
  "matrices/delete",
  api.deleteMatrix
);

const matricesSlice = createSlice({
  name: "matrices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMatrices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllMatrices.fulfilled, (state, { payload }) => {
        matricesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateMatrix.fulfilled, matricesAdapter.updateOne)
      .addCase(deleteMatrix.fulfilled, matricesAdapter.removeOne)
      .addCase(createMatrix.fulfilled, matricesAdapter.addOne);
  },
});

export default matricesSlice.reducer;

const selectState = (state) => state.matrices;

export const {
  selectAll: selectMatrices,
  selectById: selectMatrixById,
} = matricesAdapter.getSelectors(selectState);

export const selectMatricesStatus = createSelector(
  selectState,
  (state) => state.status
);
