import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, rowpk: null };

const modalSlice = createSlice({
  name: "roadmapRowModal",
  initialState,
  reducers: {
    openRoadmapRowModal(state, action) {
      state.isOpen = true;
      state.rowpk = action.payload;
    },
    closeRoadmapRowModal(state) {
      state.isOpen = false;
      state.rowpk = null;
    },
  },
});

export const { openRoadmapRowModal, closeRoadmapRowModal } = modalSlice.actions;

export default modalSlice.reducer;


