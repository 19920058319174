import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import {filter} from "lodash"
import { useForm } from 'react-hook-form'
import SprintModalButtons from "./SprintModalButtons"
import { closeSprintsModal }  from "./../../../redux/toolkit/features/sprints/sprintsModalToggle";

function SprintModal(props) {
    const {toggleSprintModal, onSprintDataChanged, sprints, sponsorsview, workersview, registers, sprintModalKey, toggleConfirmDeleteModal, toggleAddPersonModal} = props;
    const {reset} = useForm()
    const [title, SetTitle] = useState('');
    const [description, SetDescription] = useState('');
    const [actionstatus, SetActionStatus] = useState('');
    const [budget, SetBudget] = useState('No');
    const [sprintlane, SetSprintlane] = useState(false);
    const tabs = ['Sprint', 'Sponsors', 'Team', 'Sprintlane', 'Comments']
    const tabsshort = ['Sprint']
    const [activetab, setActivetab] = useState(tabs[0]);
    const showModalSprints = useSelector((state) => state.sprintsModalToggle.isOpen)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();


    console.log("Innovation Show SPrint Modal",showModalSprints)

    let sprinttitlevalue = "Edit Sprint"
    if (sprintModalKey === undefined) {
        sprinttitlevalue = "Add Sprint"
    }
    const sprinttitle = (sprinttitlevalue)

    let sprintrow=filter(sprints, {pk: sprintModalKey});
    let titlevalue= (sprintrow[0]?.title || '')
    let descriptionvalue= (sprintrow[0]?.description || '')
    let statusvalue= (sprintrow[0]?.action_status || 'In Progress')
    let budgetvalue= (sprintrow[0]?.budget || 'Yes')
    let sprintlanevalue= (sprintrow[0]?.sprintlane || false)    
   
    useEffect(() => {
        SetTitle(titlevalue)
      }, [titlevalue])

    useEffect(() => {
        SetDescription(descriptionvalue)
      }, [descriptionvalue])

    useEffect(() => {
        SetActionStatus(statusvalue)
      }, [statusvalue])

    useEffect(() => {
        SetBudget(budgetvalue)
      }, [budgetvalue])

    useEffect(() => {
        SetSprintlane(sprintlanevalue)
      }, [sprintlanevalue])

    useEffect(() => {
        reset(title)
      }, [title])

    useEffect(() => {
        reset(description)
      }, [description])

    useEffect(() => {
    reset(actionstatus)
    }, [actionstatus])

    useEffect(() => {
        reset(budget)
        }, [budget])

    function handleChangeTitle(event) {
       SetTitle(event.target.value);
    }

    function handleChangeDescription(event) {
        SetDescription(event.target.value);
    }

    function handleChangeStatus(event) {
        SetActionStatus(event.target.value);
    }

    function handleChangeBudget(event) {
        SetBudget(event.target.value);
    }

    function handleChangeSprintlane(event) {
        SetSprintlane(event.target.checked);
    }

     let modalClass = "modal";
    if (showModalSprints) {
        modalClass += " is-active";
    }

    // if (!sponsorsview || !workersview) {
    //     return null;
    // }

    function formtabs() {
        return (
            <div className="tabs is-boxed">
                <ul>
                {sprintModalKey=== undefined &&
                    <Fragment>
                    {tabsshort.map(tab => (
                        <li className ={activetab === tab ? "is-active" : ""}
                            key={"tab_"+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                    </Fragment>
                    }
                    {sprintModalKey!== undefined &&
                    <Fragment>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? "is-active" : ""}
                            key={"tab_"+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                    </Fragment>
                    }
                </ul>
            </div>
        )
    }

    function renderSponsors() {
        
        if (activetab !== 'Sponsors') {
            return null;
        }  
        let sponsorsnames=filter( sponsorsview.results, {sprint_id:sprintModalKey})

        if ((sponsorsnames).length !== 0) {
            return (
                <Fragment>
                    <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                        <tbody>
                            {sponsorsnames.map(row => (
                                <Fragment>
                                    <tr className="ml-4 pl-5" colSpan="2">
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.firstname + ' ' + row.secondname}</td>         
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                                toggleConfirmDeleteModal(true, row.fullname, sprintModalKey, 'sprintsponsors', row.pk) 
                                        }}>
                                        <button className="far fa-trash-alt" style={{color:"gray"}}
                                            ></button>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </Fragment>
                )
            } else {
                return (<p className="pl-4" style={{weight:"700"}}>No Sprint Owners Assigned</p>);
            }
    }


        function renderWorkers() {
        
            if (activetab !== 'Team') {
                return null;
            }  

            let workersnames=filter( workersview.results, {sprint_id:sprintModalKey})

            if ((workersnames).length !== 0) {
                return (
                    <Fragment>
                        <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                            <tbody>
                                {workersnames.map(row => (
                                    <Fragment>
                                        <tr className="ml-4 pl-5" colSpan="2">
                                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.firstname + ' ' + row.secondname}</td>         
                                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                                    toggleConfirmDeleteModal(true, row.fullname, sprintModalKey, 'sprintworkers',row.pk ) 
                                            }}>
                                            <button className="far fa-trash-alt" style={{color:"gray"}}
                                                ></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </Fragment>
                    )
                } else {
                    return (<p className="pl-4" style={{weight:"700"}}>No Team Members Assigned</p>);
                }
            }

            function renderComments() {
        
                if (activetab !== 'Comments') {
                    return null;
                }  

                let registersfiltered = filter(registers, {sprint: sprintModalKey})
                if ((registersfiltered).length !== 0) {
                    return (
                        <Fragment>
                            <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                                <tbody>
                                    {registersfiltered.map(row => (
                                    <Fragment>
                                        <tr className="ml-4 pl-5" colSpan="2">
                                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.comment}</td>         
                                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                                    toggleConfirmDeleteModal(true, row.comment, sprintModalKey, 'sprintcomment', row.pk) 
                                            }}>
                                            <button className="far fa-trash-alt" style={{color:"gray"}}
                                                ></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </Fragment>
                        )
                } else {
                    return (<p className="pl-4" style={{weight:"700"}}>No Comments</p>);
                }
            }

    function renderSprintlane() {

        if (activetab !== 'Sprintlane') {
            return null;
        }  
        return (
            <label className="checkbox pl-4">
                <input className="mr-3" type="checkbox" value={sprintlane} onChange={handleChangeSprintlane}/>
                     Show Sprintlane Chart
                </label>
        )
    }

    function renderForm() {
        
        if (activetab !== 'Sprint') {
            return null;
        }  

        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Title</label>
                        <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <input 
                            type="text" 
                            className="input"
                            value={title}
                            onChange={handleChangeTitle}
                        />
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">        
                    <label className="label field-label">Description</label>        
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <input 
                            type="text" 
                            className="input"
                            value={description}
                            onChange={handleChangeDescription}
                        />
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">   
                    <label className="label field-label">Status</label>     
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">    
                            <div className="select is-flex">
                                <div className="select is-fullwidth">
                                    <select value={actionstatus} onChange={handleChangeStatus}>
                                        <option selected="In Progress">In Progress</option>
                                        <option>Not Started</option>
                                        <option>Completed</option>
                                        <option>Suspended</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">   
                    <label className="label field-label">Budgetted</label>     
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">    
                            <div className="select is-flex">
                                <div className="select is-fullwidth">
                                    <select value={budget} onChange={handleChangeBudget}>
                                        <option value={'Yes'}>Yes</option>
                                        <option value={'No'}>No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>{sprinttitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeSprintsModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '300px'}}>
                        {formtabs()}
                        {renderForm()}
                        {renderSponsors()}
                        {renderWorkers()}
                        {renderSprintlane()}
                        {renderComments()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <nav className='level'>
                                <div className='level-left'>
                                    <div className='level-item'>
                                        <button
                                            style={{borderRadius:"4px"}}
                                            className="button is-success is-small"
                                            key="save"
                                            onClick={async () => {
                                            {sprintModalKey!== undefined &&
                                                toggleSprintModal(false);
                                            }
                                                await onSprintDataChanged(title,description,actionstatus,budget,sprintModalKey, sprintlane );

                                            }}
                                        >
                                            Save changes
                                        </button>
                                    </div>
                                    <div className='level-item'>
                                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeSprintsModal())}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {sprintModalKey &&
                                <div className='level-right'>
                                    <div className='level-item'>
                                    <SprintModalButtons
                                        activetab={activetab}
                                        sprintModalKey={sprintModalKey}
                                        toggleAddPersonModal={toggleAddPersonModal}
                                        toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                                    />
                                    </div>
                                </div>
                                }
                            </nav>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintModal;
