import { newcommentModalConstants } from '../constants/newcommentModal';
const initialState = {
    isOpen: false,
}

function newcommentOpenModalAction(state, action) {
    console.log("processOpenCommentModalAction");
    return {
        ...state,
        isOpen: true,
    };
}

function newcommentCloseModalAction(state, action) {
    console.log("processCloseCommentModalAction");
    return {
        ...state,
        isOpen: false,
    };
}

export function newcommentModal(state = initialState, action) {
    switch (action.type) {
        case newcommentModalConstants.OPEN_MODAL:
            return newcommentOpenModalAction(state, action);
        case newcommentModalConstants.CLOSE_MODAL:
            return newcommentCloseModalAction(state, action);

        default:
            return state;
    }
}
