import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class RoleViewService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roleview/`;
    }

    async getRoleView() {
        const roleview = await this.getAll();
        return roleview.results;
    }
}

export const roleviewService = new RoleViewService();
