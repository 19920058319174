import React, {Fragment} from 'react';
import {filter} from 'lodash'
import {Container} from "./gantt/Container";


function SectionsModalGantt(props) {
    const {gantt, toggleSectionsTimelineModal, toggleConfirmDeleteModal, getFactsOnMainPage, sectionpk} = props;

    var tablerows = filter(gantt.results, {section: sectionpk})

    console.log("Modal Gantt", tablerows, sectionpk, gantt)
    return (
        <Fragment>

        {tablerows.length !== 0 &&
        <Container tablerows={tablerows} toggleSectionsTimelineModal={toggleSectionsTimelineModal}
                    toggleConfirmDeleteModal={toggleConfirmDeleteModal} getFactsOnMainPage={getFactsOnMainPage} sectionpk={sectionpk} />}
        {tablerows.length === 0 &&
        <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}

        {/* {tablerows.map(row => (
            <div key={'ModalTimeline_ '+ row.pk} className='has-background-white-ter m-3 p-2' style={{border: 'solid 1px lightgrey'}} onClick={() => toggleSectionsTimelineModal(true,row,sectionpk)}>{row.taskname}</div>
        ))} */}

        </Fragment>
    )
}
    
export default SectionsModalGantt;
