import React, {Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import { closeDeleteModal } from "./../../../src/redux/toolkit/features/modals/deleteConfirmModal";



function ConfirmDeleteBox(props) {
    const {onConfirmDelete} = props;
    const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen)
    const modalText = useSelector((state) => state.deleteConfirmModal.message)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 



    let modalClass = "modal";
    if (showModalDelete) {
        modalClass += " is-active";
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>CONFIRM DELETE</p>
                        <button className="delete" aria-label="close" onClick={() => {
                            onConfirmDelete(false)
                            dispatch(closeDeleteModal());
                        }}>
                            {" "}
                        </button>
                    </header>
                <section className="modal-card-body" key="modalBody">
                    <p>Are you sure you want to delete item: '{modalText}' ?</p>

                </section>
                <footer className="modal-card-foot is-flex pt-0 pb-0" key="modalFooter" style={{height:"60px"}}>
                    <nav className="level">
                    <div className="level-right">
                    </div>
                        <div className="level-right">
                            <div className="level-item">
                                <button className="button is-small" onClick={() => {
                                    onConfirmDelete(false)
                                    dispatch(closeDeleteModal());
                                }}>
                                        Cancel
                                </button>
                            </div>
                            <div className="level-item">
                            <button
                                    className="button is-danger is-small"
                                    onClick={() => {
                                        onConfirmDelete(true, 'facts', 1);
                                    }}>
                                    Delete
                                    </button>
                             </div>
                        </div>
                    </nav>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default ConfirmDeleteBox;
