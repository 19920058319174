import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, factpk: null };

const modalSlice = createSlice({
  name: "factsModal",
  initialState,
  reducers: {
    openFactsModal(state, action) {
      state.isOpen = true;
      state.factpk = action.payload;
    },
    closeFactsModal(state) {
      state.isOpen = false;
      state.factpk = null;
    },
  },
});

export const { openFactsModal, closeFactsModal } = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.factsModal;

export const selectFactsModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectFactsModalFactpk = createSelector(
  selectState,
  (state) => state.factpk
);
