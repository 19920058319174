import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllValues = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/values`,
    authConfig()
  );
  return response.data.results;
};

export const fetchValues = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/values/${id}`,
    authConfig()
  );
  return response.data;
};

export const createValues = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/values/`,
    data,
    authConfig()
  );
  console.log("API Update", response.data)
  return response.data;
};

export const updateValues = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/values/${data.id}/`,
    data,
    authConfig()
  );
  console.log("API Update", response.data)
  return response.data;
};

export const deleteValues = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/values/${id}`,
    authConfig()
  );
  return response.data;
};

const valuesAPI = {
  fetchAllValues,
  fetchValues,
  createValues,
  updateValues,
  deleteValues,
};
export default valuesAPI;
