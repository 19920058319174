import React, { Fragment, useState, useContext } from 'react';
import { useSelector } from 'react-redux'
import { Row } from './Row';
import {filter} from "lodash";
import { roadmapdatasets } from '../Roadmaps';

function Table(props) {
    const {indent, issuccesspage, sectionrows, chart, sectionPk, togglePopupModal} = props;  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor) 
    const {minYear, maxYear, baselinedelta} = useContext(roadmapdatasets);
    const [isPercentage, setisPercentage] = useState(false);
    const [isBaseline, setisBaseline] = useState(false);
    const [buttonGraphColor, setButtonGraphColor] = useState(backgroundcolor);
    const [buttonPercentColor, setButtonPercentColor] = useState(backgroundcolor);
    const [buttonBaselineColor, setButtonBaselineColor] = useState(backgroundcolor);
    const [buttonGraphWidth, setButtonGraphWidth] = useState('1px');
    const [buttonPercentWidth, setButtonPercentWidth] = useState('1px');
    const [buttonBaselineWidth, setButtonBaselineWidth] = useState('1px');
    const [buttonGraphBorder, setButtonGraphBorder] = useState(textcolor);
    const [buttonPercentBorder, setButtonPercentBorder] = useState(textcolor);
    const [buttonBaselineBorder, setButtonBaselineBorder] = useState(textcolor);

    let baselinerows=filter( baselinedelta,{section_id: sectionPk})
    console.log("Baseline", baselinerows, baselinedelta)
    let baselinerowslength=baselinerows.length

    console.log("Chart Length Table", chart.results.length, chart)
    function renderYearHeaders() {
        const headers = [];
        for (let year = minYear; year <= maxYear; year++) {
            if (year <  new Date().getFullYear()) {
            headers.push(<th className="has-text-centered pt-3" style={{color: textcolor}} key={year}>{year} Actual</th>);
            } else {
                headers.push(<th className={'has-text-centered pt-3'} style={{color: textcolor}} key={year}>{year}</th>);
            }
        }
        return headers;
    }

    function showPercentages() {
        if (isPercentage) {
        setisPercentage(false);
        setButtonPercentColor(backgroundcolor);
        setButtonPercentWidth('1px');
        setButtonPercentBorder(textcolor);
        } else {
        setisPercentage(true)
        setButtonPercentColor(backgroundcolor);
        setButtonPercentWidth('3px');
        setButtonPercentBorder(backgroundcolor);
        }
      }

      function showGraphs() {
        if (props.isGraph) {
        props.setisGraph(false);
        setButtonGraphColor(backgroundcolor);
        setButtonGraphWidth('1px');
        setButtonGraphBorder(textcolor);
        } else {
        props.setisGraph(true);
        setButtonGraphColor(backgroundcolor);
        setButtonGraphWidth('3px');
        setButtonGraphBorder(backgroundcolor);
        }
      }

      function showBaseline() {
        if (isBaseline) {
        setisBaseline(false);
        setButtonBaselineColor(backgroundcolor);
        setButtonBaselineWidth('1px');
        setButtonBaselineBorder(textcolor);
        } else {
        setisBaseline(true)
        setButtonBaselineColor(backgroundcolor);
        setButtonBaselineWidth('3px');
        setButtonBaselineBorder(backgroundcolor);
        }
      }


 

    function renderHeader() {
        if (sectionrows.length > 0) {
            let chartlength=null
            if (chart && chart.results.length !== 0) {
                console.log("At Chart Results")
                chartlength=(filter(chart.results, {section: sectionPk})).length
            }

        return (
            <Fragment>
                <tr key={'Tableheader'+sectionPk.toString()} style={{backgroundColor: backgroundcolor, userSelect:"none"}}>
                    <th colSpan="2">
                        <button className='is-small mr-1 pb-1 pl-3 pr-3' style={{ borderColor: buttonPercentBorder, backgroundColor: buttonPercentColor, color: textcolor, borderRadius: '6px', borderWidth: buttonPercentWidth }} onClick={() => showPercentages()}>%</button> 
                        {chartlength !== 0 && <Fragment><button className='is-small' style={{ borderColor: buttonGraphBorder, backgroundColor: buttonGraphColor, color: textcolor, borderRadius: '6px', borderWidth: buttonGraphWidth }} onClick={() => showGraphs()}><i className='far fa-chart-bar fa-lg'></i></button> </Fragment>}
                        {baselinerowslength !== 0 && <Fragment><button className="is-small" style={{borderColor: buttonBaselineBorder, backgroundColor: buttonBaselineColor, color: textcolor, borderRadius: '6px',borderWidth: buttonBaselineWidth}} onClick={() => showBaseline()}><i className="fas fa-crosshairs fa-lg"></i></button></Fragment>}
                    </th>
                    {renderYearHeaders()}
                    </tr>
            </Fragment>
        )
        }
    }   

    function renderRow(row) {

        return (

            <tr key={'Row'+(row.pk).toString()}>
                <Row 
                    row={row} 
                    sectionPk={props.sectionPk} 
                    percentages={isPercentage}
                    isbaseline={isBaseline}
                    baselinedelta={baselinedelta}
                    indent={indent}
                    togglePopupModal={togglePopupModal}
                    />
            </tr>

        )    
    }

    function renderRows() {
        const rows = [];
        if(issuccesspage) {
            for (const row of sectionrows) {
                rows.push(renderRow(row));
            }
            return rows;
        } else if (sectionrows) {
            for (const row of sectionrows) {
                rows.push(renderRow(row));
            }
            return rows;
        } else {
            return '';
        }
        
    }

    return (
        <Fragment>
        <table id="hovertable" key={'table'+sectionPk.toString()} className={'table is-fullwidth'}>
            <tbody>
            {renderHeader()}
            {renderRows()}
            </tbody>
        </table>
        </Fragment>
    )
}

export { Table };
