import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class LinkedThemesService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/linkedthemes/`;
    }

    async getLinkedTheme() {
        const linkedtheme = await this.getAll();
        return linkedtheme;
    }
}

export const linkedthemesService = new LinkedThemesService();
