import React from 'react';
import {filter, get} from "lodash"
import { getImportanceColor } from './utils';
//import { TooltipComponent } from '@syncfusion/ej2-react-popups';
// import "./../common/css/material.css"


function Row(props) {
  const { category, stakeholders, matrices, companymatrix, toggleModal, toggleCompanyModal, toggleCategoryModal } = props;


  function ratingscale(importance) {
    let rating=null;
    if (importance <= 1.3) {
      rating="NOT IMPORTANT"
    } else if (importance > 1.3 && importance <= 3.0) {
      rating="LITTLE IMPORTANCE"
    } else if (importance > 3.0 && importance <= 5) {
      rating="SOME IMPORTANCE"
    } else if (importance > 5.0 && importance <= 5.9) {
      rating="AVERAGE IMPORTANCE"
    } else if (importance > 5.9 && importance <= 6.6) {
      rating="MODERATELY IMPORTANT"
    } else if (importance > 6.6 && importance <= 8.1) {
      rating="IMPORTANT"
    } else if (importance > 8.1) {
      rating="VERY IMPORTANT"
    }
    return rating;
  }


  function renderCompanyRow() {
    let rowvalue=filter(companymatrix, {category: category.pk}) 
    let importance = rowvalue[0]? rowvalue[0].importance/10 : null;
    return (
      <td style={{ textAlign:'center',
        backgroundColor: getImportanceColor(
          rowvalue[0] ? rowvalue[0].importance : -1
        ), margin:0, padding:0, userSelect:'none'
      }} onClick={() => toggleCompanyModal(true, category.pk )}>
{/*         {rowvalue[0] && rowvalue[0].description!=="" && rowvalue[0].description!==null && 
        <TooltipComponent content={rowvalue[0].description}>
        <p className="pt-3" style={{fontSize:'11px', userSelect:'none'}}>{ratingscale(importance)}</p>
        </TooltipComponent>} */}
        {rowvalue[0] && (rowvalue[0].description==="" || rowvalue[0].description===null) &&
        <p className="pt-3" style={{fontSize:'11px', userSelect:'none'}}>{ratingscale(importance)}</p>
        }
      </td>
    )
  }

  function rendercell(row) {
    let matrixvalue=filter(matrices,{category: category.pk,stakeholder: row})
    let importance = matrixvalue[0]? matrixvalue[0].importance/10 : null;
    
    return (


     <td style={{ textAlign:'center',
        backgroundColor: getImportanceColor(
          matrixvalue[0] ? matrixvalue[0].importance : -1
        ), margin:0, padding:0, userSelect:'none'
      }} onClick={() => toggleModal(true, row,category.pk )}>
{/*         {matrixvalue[0] && matrixvalue[0].description!=="" && matrixvalue[0].description!==null && 
        <TooltipComponent content={matrixvalue[0].description}>
        <p className="pt-3" style={{fontSize:'11px', userSelect:'none'}}>{ratingscale(importance)}</p>
        </TooltipComponent>} */}
        {matrixvalue[0] && (matrixvalue[0].description==="" || matrixvalue[0].description===null) &&
        <p className="pt-3" style={{fontSize:'11px', userSelect:'none'}}>{ratingscale(importance)}</p>
        }
      </td>
    )
  }

  function renderRow() {
    let string=[]
    for (const row in stakeholders) {
      string.push(rendercell(stakeholders[row].pk))
    }
    return string;
  }

  if(!category) {
    return null;
  }

  console.log("Category", category, category.pk)
  return (
    <tr>
      <td>
        <a className='has-text-black' style={{fontWeight:'600', userSelect: 'none'}} onClick={() => toggleCategoryModal(true, category.pk)}>
          {category.name}
        </a>
      </td>
      {renderCompanyRow()}
      {renderRow()}

    </tr>
  );
}

export default Row;
