import React, { Fragment, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {filter} from "lodash"
import { sprintdatasets } from '../Sprints';
import { LayoutAnimation, HierarchicalTree, Diagram, NodeModel, DataBinding, PortConstraints, PortVisibility, DiagramComponent, StackPanel, SnapConstraints, TextElement, Inject, DiagramTools, Snapping, ConnectorConstraints, StrokeStyle } from "@syncfusion/ej2-react-diagrams";


function SprintLane(props) {
  const {} = props; 
  const {sprintid} = props;
  const {sponsorsview, workersview, filteredSprints, registers } = useContext(sprintdatasets);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  let pathData = 'M 120 24.9999 C 120 38.8072 109.642 50 96.8653 50 L 23.135' +
    ' 50 C 10.3578 50 0 38.8072 0 24.9999 L 0 24.9999 C' +
    '0 11.1928 10.3578 0 23.135 0 L 96.8653 0 C 109.642 0 120 11.1928 120 24.9999 Z';
let middle = 0;
let port = [
    { id: 'Port1', offset: { x: 0, y: 0.5 }, visibility: PortVisibility.Connect | PortVisibility.Hover, constraints: PortConstraints.Default | PortConstraints.Draw },
    { id: 'Port2', offset: { x: 0.5, y: 0 }, visibility: PortVisibility.Connect | PortVisibility.Hover, constraints: PortConstraints.Default | PortConstraints.Draw },
    { id: 'Port3', offset: { x: 1, y: 0.5 }, visibility: PortVisibility.Connect | PortVisibility.Hover, constraints: PortConstraints.Default | PortConstraints.Draw },
    { id: 'Port4', offset: { x: 0.5, y: 1 }, visibility: PortVisibility.Connect | PortVisibility.Hover, constraints: PortConstraints.Default | PortConstraints.Draw }
];
 
  let node = [{
    id: 'swimlane',
    shape: {
        type: 'SwimLane',
        orientation: 'Horizontal',
        scrollSettings: { canAutoScroll: false},
        header: {
            annotation: { content: 'SALES PROCESS FLOW CHART', style: { fill: "transparent", color: textcolor } },
            height: 35, style: { fontSize: 11, fill: backgroundcolor},
        },
        lanes: [
            {
                id: 'stackCanvas1',
                header: {
                    annotation: { content: 'Consumer', style: { fontSize: 12, fill: "transparent", color: "black" }  }, width: 45,
                    style: { fill: "transparent"}
                },
                height: 100,
                children: [
                    {
                        id: 'node1',
                        annotations: [
                            {
                                content: 'Consumer learns \n of product',
                                style: { fontSize: 11 }
                            }
                        ],
                        margin: { left: 60, top: 30 },
                        height: 40, width: 100, ports: port
                    },
                    {
                        id: 'node2',
                        shape: { type: 'Flow', shape: 'Decision', borderColor: "green",border:"green" },
                        style: {borderColor: "green", border: "green"},
                        annotations: [
                            {
                                content: 'Does \n Consumer want \nthe product',
                                style: { fontSize: 11 }
                            }
                        ],
                        margin: { left: 200, top: 20 },
                        height: 60, width: 120, ports: port
                    },
                    {
                        id: 'node3',
                        annotations: [
                            {
                                content: 'No sales lead',
                                style: { fontSize: 11 }
                            }
                        ],
                        margin: { left: 370, top: 30 }, shape: { type: 'Path', data: pathData },
                        height: 40, width: 100, ports: port
                    },
                    {
                        id: 'node4',
                        annotations: [
                            {
                                content: 'Sell to consumer',
                                style: { fontSize: 11 }
                            }
                        ],
                        margin: { left: 510, top: 30 },
                        height: 40, width: 100, ports: port
                    }
                ],
            },
            {
                id: 'stackCanvas2',
                header: {
                    annotation: { content: 'Marketing', style: { fontSize: 12, fill: "transparent", color: "black" }  }, width: 45,
                    style: { fill: "transparent"}
                },
                height: 100,
                children: [
                    {
                        id: 'node5',
                        annotations: [{ content: 'Create marketing campaigns' }],
                        margin: { left: 60, top: 20 },
                        height: 40, width: 100, ports: port
                    },
                    {
                        id: 'node6',
                        annotations: [{ content: 'Marketing finds sales leads' }],
                        margin: { left: 210, top: 20 },
                        height: 40, width: 100, ports: port
                    }
                ],
            },
            {
                id: 'stackCanvas3',
                header: {
                    annotation: { content: 'Sales', style: { fontSize: 12, fill: "transparent", color: "black" }  }, width: 45,
                    style: { fill: "transparent"}
                },
                height: 100,
                children: [
                    {
                        id: 'node7',
                        annotations: [{ content: 'Sales receives lead' }],
                        margin: { left: 210, top: 30 },
                        height: 40, width: 100, ports: port
                    }
                ],
            },
            {
                id: 'stackCanvas4',
                header: {
                    annotation: { content: 'Success', style: { fontSize: 12, fill: "transparent", color: "black" }  }, width: 45,
                    style: { fill: "transparent"}
                },
                height: 100,
                children: [
                    {
                        id: 'node8',
                        annotations: [{ content: 'Success helps \n retain consumer \n as a customer' }],
                        margin: { left: 510, top: 20 },
                        height: 50, width: 100, ports: port
                    }
                ],
            },
        ],
    },
    offsetX: 600, offsetY: 218,
    height: 100,
    width: 1200
},
];
//Initializes the connectors for the diagram.
let connectors = [
{
    id: 'connector1', sourceID: 'node1',
    targetID: 'node2'
},
{
    id: 'connector2', sourceID: 'node2',
    targetID: 'node3', annotations: [{ content: 'No', style: { fill: 'white'} }]
},
{
    id: 'connector3', sourceID: 'node4',
    targetID: 'node8'
},
{
    id: 'connector4', sourceID: 'node2',
    targetID: 'node6', annotations: [{ content: 'Yes', style: { fill: 'white' } }]
},
{
    id: 'connector5', sourceID: 'node5',
    targetID: 'node1'
},
{
    id: 'connector6', sourceID: 'node6',
    targetID: 'node7'
},
{
    id: 'connector7', sourcePortID: 'Port1', targetPortID: 'Port3', sourceID: 'node4',
    targetID: 'node7',
},
];

 // initialize Diagram component
   return (
     <DiagramComponent
       className="mb-3"
       id={"container"+sprintid.toString()}
       scrollSettings={{canAutoScroll: true}}
       width={'95%'}
       height={'436px'}
       snapSettings={{ constraints: SnapConstraints.None }}
       nodes={node}
     /> 
   );
}

export { SprintLane };
