import { organizationConstants } from "../constants/organization";

const initialState = {
  loading: true,
  tableData: {
    columns: [],
    rows: [],
    annual_investment_rows: [],
    cumulative_investment_rows: [],
  },
  chartData: null,
  chartCurrentYear: null,
  error: null,
  organization: null,
  titles: [],
  businessunits: [],
  people: [],
  personYear: [],
};

function processGetTableRequest(state, action) {
  return {
    ...state,
    loading: true,
    tableData: {
      columns: [],
      rows: [],
      annual_investment_rows: [],
      cumulative_investment_rows: [],
    },
  };
}

function processGetTableSuccess(state, action) {
  return {
    ...state,
    loading: false,
    tableData: action.tableData,
  };
}

function processGetTableFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

function processGetChartRequest(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function processGetChartSuccess(state, action) {
  return {
    ...state,
    loading: false,
    chartData: action.chartData,
  };
}

function processGetChartFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

function processGetOrganizationRequest(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function processGetOrganizationSuccess(state, action) {
  return {
    ...state,
    loading: false,
    organization: action.organization,
  };
}

function processGetOrganizationFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

function processGetTitlesRequest(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function processGetTitlesSuccess(state, action) {
  return {
    ...state,
    loading: false,
    titles: action.titles,
  };
}

function processGetTitlesFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

function processGetBusinessunitsRequest(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function processGetBusinessunitsSuccess(state, action) {
  return {
    ...state,
    loading: false,
    businessunits: action.businessunits,
  };
}

function processGetBusinessunitsFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

function processGetPeopleRequest(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function processGetPeopleSuccess(state, action) {
  return {
    ...state,
    loading: false,
    people: action.people,
  };
}

function processGetPeopleFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

function processGetPersonYearRequest(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function processGetPersonYearSuccess(state, action) {
  return {
    ...state,
    loading: false,
    personyear: action.personyear,
  };
}

function processGetPersonYearFailure(state, action) {
  return {
    ...state,
    error: action.error,
  };
}

export function organization(state = initialState, action) {
  switch (action.type) {
    case organizationConstants.GET_ORG_TABLE_REQUEST:
      return processGetTableRequest(state, action);
    case organizationConstants.GET_ORG_TABLE_SUCCESS:
      return processGetTableSuccess(state, action);
    case organizationConstants.GET_ORG_TABLE_FAILURE:
      return processGetTableFailure(state, action);

    case organizationConstants.GET_ORG_CHART_REQUEST:
      return processGetChartRequest(state, action);
    case organizationConstants.GET_ORG_CHART_SUCCESS:
      return processGetChartSuccess(state, action);
    case organizationConstants.GET_ORG_CHART_FAILURE:
      return processGetChartFailure(state, action);

    case organizationConstants.GET_ORGANIZATION_REQUEST:
      return processGetOrganizationRequest(state, action);
    case organizationConstants.GET_ORGANIZATION_SUCCESS:
      return processGetOrganizationSuccess(state, action);
    case organizationConstants.GET_ORGANIZATION_FAILURE:
      return processGetOrganizationFailure(state, action);

    case organizationConstants.GET_TITLES_REQUEST:
      return processGetTitlesRequest(state, action);
    case organizationConstants.GET_TITLES_SUCCESS:
      return processGetTitlesSuccess(state, action);
    case organizationConstants.GET_TITLES_FAILURE:
      return processGetTitlesFailure(state, action);

    case organizationConstants.GET_BUSINESSUNITS_REQUEST:
      return processGetBusinessunitsRequest(state, action);
    case organizationConstants.GET_BUSINESSUNITS_SUCCESS:
      return processGetBusinessunitsSuccess(state, action);
    case organizationConstants.GET_BUSINESSUNITS_FAILURE:
      return processGetBusinessunitsFailure(state, action);

    case organizationConstants.GET_PEOPLE_REQUEST:
      return processGetPeopleRequest(state, action);
    case organizationConstants.GET_PEOPLE_SUCCESS:
      return processGetPeopleSuccess(state, action);
    case organizationConstants.GET_PEOPLE_FAILURE:
      return processGetPeopleFailure(state, action);

    case organizationConstants.GET_PERSONYEAR_REQUEST:
      return processGetPersonYearRequest(state, action);
    case organizationConstants.GET_PERSONYEAR_SUCCESS:
      return processGetPersonYearSuccess(state, action);
    case organizationConstants.GET_PERSONYEAR_FAILURE:
      return processGetPersonYearFailure(state, action);

    default:
      return state;
  }
}
