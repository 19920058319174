import React, {Fragment, useState} from "react";
import { useSelector } from 'react-redux'
import "bulma/css/bulma.css";


function SprintDateModal(props) {
    const {toggleModal, showModal, onDataChanged} = props;
    const [datevalue,setDatevalue] = useState(props.sprintdate.sprintenddate);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)


    function handleChangeDate(event) {
        setDatevalue(event.target.value);
    }

     let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={datevalue}
                            onChange={handleChangeDate}
                        />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>SPRINT CYCLE END DATE</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await onDataChanged(datevalue);
                                toggleModal(false);
                            }}
                        >
                            Save changes
                        </button>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => toggleModal(false)}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintDateModal;
