import React, { useEffect, Fragment, useState } from "react";
import "./common/css/masterstyles.css";
import { Table } from "./investment/Table";
import { useDispatch } from "react-redux";
import { roadmapsService } from "../services/RoadmapsService";
import {investmentsService} from "../services/InvestmentsService"
import {investmentcellService} from "../services/InvestmentCellsService"
import InvestmentDetailModal from "./investment/modals/InvestmentDetailModal"
import { menuActions } from "../redux/actions/menu";
import {investmentsprintService} from "../services/SprintInvestmentService";
import {investmentsprintcellService} from "../services/SprintInvestmentCellService";

function Investments() {
  const dispatch = useDispatch();
  const [investments, setInvestments] = useState(null);
  const [investmentcells, setInvestmentcells] = useState(null);
  const [roadmap,setRoadmap] = useState(null);  
  const [isInvestmwentModalOpen, setIsInvestmentModalOpen] = useState(false);
  const [sprintinvestment,setSprintInvestment] = useState(null);
  const [sprintinvestmentcell,setSprintInvestmentCell] = useState(null);



  async function fetchInvestments() {
    const investments = await investmentsService.getInvestments();
    setInvestments(investments);    
    const investmentcells = await investmentcellService.getInvestmentCells();
    setInvestmentcells(investmentcells);    
    const roadmap = await roadmapsService.getRoadmap();
    setRoadmap(roadmap);  
    const sprintinvestment = await investmentsprintService.getInvestment();
    setSprintInvestment(sprintinvestment);  
    const sprintinvestmentcell = await investmentsprintcellService.getInvestmentCell();
    setSprintInvestmentCell(sprintinvestmentcell);  


  }

  useEffect(() => {
    async function fetchData() {
        await fetchInvestments();
    }
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("investments"));
    //dispatch(investmentsActions.getAll());
  }, [dispatch]);

  function toggleEditInvestmentModal(open) {
    setIsInvestmentModalOpen(open)
}

  if (!investments || !investmentcells || !roadmap) {
    return null;
  }

  let minYear=""
  let maxYear=""
  if (roadmap.results.length !== 0) {
    minYear = (roadmap.results[0]).min_year
    maxYear = (roadmap.results[0]).max_year
  } else {
    minYear = new Date().getFullYear() 
    maxYear = new Date().getFullYear()+5 
  }

  console.log("Invest2", investments. investmentcells)
  return (
    <Fragment>
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
      <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em"}}>
          <div>
              <div className="pr-1" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
              INVESTMENTS
              </div>
          <form className="pb-3">
            <p style={{fontWeight: "500",fontFamily: "Gotham Extra",fontSize: "16px",lineHeight: "26px",paddingBottom: "28px"}}>
                Display: {' '}
                <input
                  type="radio"
                  value="Cash Basis"
                  id="cash"
                  name="gender"
                />
                <label htmlFor="male"> Cash Basis </label>
                <input
                  type="radio"
                  value="Accrual Basis"
                  id="female"
                  name="gender"
                />
                <label htmlFor="female"> Accrual Basis</label>
            </p>
          </form>
          <Table
            investments={investments.results}
            investmentcells={investmentcells.results}
            minYear={minYear}
            maxYear={maxYear}
            toggleModal={toggleEditInvestmentModal}
            sprintinvestment={sprintinvestment}
            sprintinvestmentcell={sprintinvestmentcell}
           />
          </div>
        </section>
      </main>

      <InvestmentDetailModal 
        showModal={isInvestmwentModalOpen}
        toggleModal={toggleEditInvestmentModal}
      />

    </Fragment>
  );
}
export { Investments };
