import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class GrowthService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-cellgrowth/`;
    }

    async getGrowth() {
        const growth = await this.getAll();
        return growth;
    }
}


export const growthService = new GrowthService();
