export const titleModalConstants = {
    OPEN_MODAL: "TITLE_MODAL_OPEN",
    CLOSE_MODAL: "TITLE_MODAL_CLOSE",

    CREATE_NEW: "TITLE_MODAL_CREATE_NEW",
    CANCEL_CREATE_NEW: "TITLE_MODAL_CANCEL_CREATE_NEW",

    SAVE_TITLE_REQUEST: "TITLE_MODAL_SAVE_TITLE_REQUEST",
    SAVE_TITLE_SUCCESS: "TITLE_MODAL_SAVE_TITLE_SUCCESS",
    SAVE_TITLE_FAILURE: "TITLE_MODAL_SAVE_TITLE_FAILURE",

};
