import {loginConstants} from '../constants/login';
import {authService} from '../../services/AuthService';
import {alerts} from './alerts';

export const loginActions = {
    login,
    logout,
    clearError,
};

function login(username, password) {
    return async dispatch => {
        dispatch(request({username}));
        const loginResult = await authService.login(username, password);
        if (loginResult === "OK") {
            dispatch(success());
        } else {
            dispatch(failure(loginResult));
            dispatch(alerts.error(loginResult));
        }
    };

    function request() { return {type: loginConstants.LOGIN_REQUEST} }
    function success() { return {type: loginConstants.LOGIN_SUCCESS} }
    function failure(error) { return {type: loginConstants.LOGIN_FAILURE, error} }
}

function logout() {
    authService.logout();
    return {type: loginConstants.LOGOUT};
}

function clearError() {
    return {type: loginConstants.LOGIN_CLEAR_ERROR};
}
