import {behaviorsConstants} from '../constants/behaviors';

const initialState = {
    loading: false,
    behaviors: [],
    error: null,
}

export function behaviors(state = initialState, action) {
    switch (action.type) {
        case behaviorsConstants.GET_BEHAVIORS_REQUEST:
            return {
                ...initialState,
                loading: true,
            };
        case behaviorsConstants.GET_BEHAVIORS_SUCCESS:
            return {
                ...initialState,
                behaviors: action.behaviors,
            };
        case behaviorsConstants.GET_BEHAVIORS_FAILURE:
            return {
                ...initialState,
                error: action.error,
            };
        case behaviorsConstants.POST_BEHAVIOR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case behaviorsConstants.POST_BEHAVIOR_SUCCESS:
            const addedBehaviors = [...state.behaviors];
            addedBehaviors.push(action.behavior);
            return {
                ...state,
                behaviors: addedBehaviors,
            };
        case behaviorsConstants.POST_BEHAVIOR_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case behaviorsConstants.DELETE_BEHAVIOR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case behaviorsConstants.DELETE_BEHAVIOR_SUCCESS:
            const deletedBehaviors = [];
            for (const behavior of state.behaviors) {
                if (behavior.pk !== action.id) {
                    deletedBehaviors.push(behavior);
                }
            }
            return {
                ...state,
                behaviors: deletedBehaviors,
            };
        case behaviorsConstants.DELETE_BEHAVIOR_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state
    }
}