import { createSlice } from "@reduxjs/toolkit";

const initialState = { backgroundcolor: '#1B3A95', textcolor: '#FFFFFF' };

const modalSlice = createSlice({
  name: "colors",
  initialState,
  reducers: {
    setColor(state, action) {
      state.backgroundcolor = action.payload.backgroundcolor;
      state.textcolor = action.payload.textcolor;
    },
  },
});

export const { setColor } = modalSlice.actions;

export default modalSlice.reducer;

