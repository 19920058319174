import { newtaskModalConstants } from '../constants/newtaskModal';
const initialState = {
    isOpen: false,
    personYear: {
        pk: null,
        year: "",
        person: {
            pk: "",
            name: "",
        },
        businessunit: {
            pk: "",
            name: "",
        },
        title: {
            pk: "",
            name: "",
        },
        parent: null,
        is_new: false,
        status: "",
        compensation: "",
    },
}

function newtaskOpenModalAction(state, action) {
    console.log("processOpenPositionModalAction");
    return {
        ...state,
        isOpen: true,
        taskInfo: action.taskInfo,
    };
}

function newtaskCloseModalAction(state, action) {
    console.log("processClosePositionModalAction");
    return {
        ...state,
        isOpen: false,
    };
}

export function newtaskModal(state = initialState, action) {
    switch (action.type) {
        case newtaskModalConstants.OPEN_MODAL:
            return newtaskOpenModalAction(state, action);
        case newtaskModalConstants.CLOSE_MODAL:
            return newtaskCloseModalAction(state, action);

        default:
            return state;
    }
}
