import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class InvestmentCellService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/investments-cell/`;
    }

    async getInvestmentCells() {
        const investmentcells = await this.getAll();
        console.log("ICS", investmentcells)
        return investmentcells;
    }
}

export const investmentcellService = new InvestmentCellService();
