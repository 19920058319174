import React, { Fragment, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrgTableRow } from "./OrgTableRow";
import { organizationActions } from "../../redux/actions/organization";
import CurrencyFormat from "react-currency-format";
import { positionModalActions } from "../../redux/actions/positionModal";
import { peopledatasets } from '../OrganizationPage';
import {filter} from "lodash"


function OrgTable(props) {
  const {minYear, maxYear, roleview, personview,startmonth} = props;
  const {defaultbackgroundcolor, defaulttextcolor, lightbackgroundcolor, darkbackgroundcolor, metrics} = useContext(peopledatasets);
  //const tableData = useSelector((state) => state.organization.tableData);
 // console.log("orgtable tableData", tableData);
 // const dispatch = useDispatch();
  let rowNumber = 0;

  console.log("light", lightbackgroundcolor)
  if(!roleview) {
    return null;
  }

  // useEffect(() => {
  //   dispatch(organizationActions.getOrganizationTable());
  // }, [dispatch]);

  //console.log("Orgdata",tableData.columns)


  function handleClickAddPosition(year) {
    // dispatch(
    //   positionModalActions.openModal({
    //     pk: null,
    //     startyear: year,
    //     person: {
    //       pk: 0,
    //       name: "",
    //     },
    //     businessunit: {
    //       pk: 0,
    //       name: "",
    //     },
    //     title: {
    //       pk: 0,
    //       name: "",
    //     },
    //     parent: null,
    //     is_new: false,
    //     status: "",
    //     compensation: "",
    //   })
    // );
  }

  function renderYearHeaders() {
    const headers = [];
    for (let year = minYear; year <= maxYear; year++
    ) {
      headers.push(
        <th className={"has-text-centered"} style={{color: defaulttextcolor}} key={year}>
          {year}
          {/* <button
            className="is-small is-pulled-right"
            onClick={() => handleClickAddPosition(year)}
          >
            Add
          </button> */}
        </th>
      );
    }
    return headers;
  }


  function summaryrow() {
    return (
      <Fragment>
        <tr>
          {calculateStats()}
        </tr>
      </Fragment>
    )
  }

  function calculateStats() {
    const summaryrow = [];
    for (let year = minYear; year <= maxYear; year++) {
      let metricsyear=filter(metrics.results, {year: year})
      console.log("Metrics Table:", metrics)
      // let sumvalue = 0.0;
      // let headcount = 0.0;
      // var calcstartdate = new Date( startmonth + "/01/"+ year);
      // var calcenddate = new Date()

      // if (startmonth === 1) {
      //   calcenddate = new Date( 12 + "/31/"+ year);
      // } else {
      //   calcenddate = new Date(year, startmonth-1 , 0);
      // }
      
      // for (let row in roleview) {
      //   let totalcompensation = roleview[row].compensationbase + roleview[row].compensationsti + roleview[row].compensationlti + roleview[row].compensationbenefits

      //   if (roleview[row].role_startdate === null || new Date(roleview[row].role_startdate) <= calcstartdate) {
      //     sumvalue = sumvalue + totalcompensation
      //     headcount = headcount + 1.0
      //     console.log("HC1", headcount)
      //   } 

      //   if (new Date(roleview[row].role_startdate) > calcstartdate && new Date(roleview[row].role_startdate) <= calcenddate){
      //     sumvalue = sumvalue + ((Date.parse(calcenddate) - Date.parse(roleview[row].role_startdate)) / 86400000 /365 * totalcompensation) 
      //     headcount = headcount + ((Date.parse(calcenddate) - Date.parse(roleview[row].role_startdate)) / 86400000 / 365)
      //     console.log("HC2", headcount)
      //     }
        

      //   if (new Date(roleview[row].role_enddate) > calcstartdate && new Date(roleview[row].role_enddate) <= calcenddate){
      //     sumvalue = sumvalue - ((Date.parse(calcenddate) - Date.parse(roleview[row].role_enddate)) / 86400000 /365 * totalcompensation) 
      //     headcount = headcount - ((Date.parse(calcenddate) - Date.parse(roleview[row].role_enddate)) / 86400000 / 365)
      //     console.log("HC3", headcount)
      //     }

      //   if ( roleview[row].role_enddate !== null && new Date(roleview[row].role_enddate) < calcstartdate){
      //     let totalcompensation = roleview[row].compensationbase + roleview[row].compensationsti + roleview[row].compensationlti + roleview[row].compensationbenefits
      //     sumvalue = sumvalue - totalcompensation
      //     headcount = headcount - 1.0
      //     console.log("HC4", headcount)
      //     }
        
      //   console.log("Year Compensation", sumvalue, headcount, year)

      summaryrow.push(<td><small>Headcount: <CurrencyFormat value={metricsyear[0] ? metricsyear[0].totalheadcount:0} displayType={'text'} decimalScale={2} thousandSeparator={true}/> <br/>Compensation: <CurrencyFormat value={metricsyear[0] ? metricsyear[0].totalcompensation : 0} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} /> CAD 
      <br/>
      New Hires: <CurrencyFormat value={metricsyear[0] ? metricsyear[0].annualchangeroles : 0} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} /> CAD
      <br/>
      Foreign Exchange: $0<br/>Wage Indexing 3%: $342,000</small></td>)
      console.log("Summary", summaryrow)
    }
    return summaryrow;
  }


  function renderHeader() {
    return (
      <Fragment>
        <thead>
          <tr style={{backgroundColor: darkbackgroundcolor, color: defaulttextcolor, fontWeight: "400"}}>{renderYearHeaders()}</tr>
        </thead>
      </Fragment>

    );
  }

  function renderRow(row) {
    let yearrows = filter(roleview, row => row.role_startdate !== null || row.role_enddate !== null)
    rowNumber++;
    return <Fragment>
      <td className="pl-0 ml-0"><OrgTableRow key={rowNumber} year={row} data={yearrows} rowNumber={rowNumber} /></td></Fragment>
  }

  function renderRows() {
    const rows = [];
    let newrolefiltered = [];
    newrolefiltered=filter(roleview, role=> role.role_startdate !== null )
    for (let year = minYear; year <= maxYear; year++
      ) {
         rows.push(renderRow(year));
    }
    return (
      <Fragment>
      <tr>
      {rows}
      </tr>
      </Fragment>
    )
  }

  function renderAnnualInvestment() {
    const rows = [
      <tr key="annual_inv" style={{lineHeight:"15px", fontSize:"small"}}>
        <td className={"has-text-centered"} colSpan={maxYear-minYear+1}>
          Annual Net Hiring Investment
        </td>
      </tr>,
    ];
    const cells = [];
    for (let year = minYear; year <= maxYear; year++) {
        let investment=filter(roleview, rows => (new Date(rows.role_startdate)).getFullYear() === year);
        let totalinvestmentadd = investment.reduce((a,v) =>  a = a + v.compensationbase +v.compensationsti + v.compensationlti + v.compensationbenefits, 0 )
        let investmentreduction=filter(roleview, rows => (new Date(rows.role_enddate)).getFullYear() === year);
        let totalinvestmentreduce = investmentreduction.reduce((a,v) =>  a = a - v.compensationbase - v.compensationsti - v.compensationlti - v.compensationbenefits, 0 )
        cells.push(
          <td className={"has-text-centered"} key={year}>
            <CurrencyFormat
              value={totalinvestmentadd + totalinvestmentreduce}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </td>
        );
    }
    rows.push(<tr key="annual_inv_val"  style={{lineHeight:"15px", fontSize:"small"}}>{cells}</tr>);
    return rows;
  }

  function renderCumulativeInvestment() {
    const rows = [
      <tr key="cumulative_inv" style={{lineHeight:"15px", fontSize:"smaller"}}>
        <td className={"has-text-centered"} colSpan={maxYear-minYear+1}>
          Cumulative Net Hiring Investment
        </td>
      </tr>,
    ];
    const cells = [];
    var cumulativevalue = 0
    for (let year = minYear; year <= maxYear; year++) {
        let investment=filter(roleview, rows => (new Date(rows.role_startdate)).getFullYear() === year);
        let totalinvestmentadd = investment.reduce((a,v) =>  a = a + v.compensationbase +v.compensationsti + v.compensationlti + v.compensationbenefits, 0 )
        let investmentreduction=filter(roleview, rows => (new Date(rows.role_enddate)).getFullYear() === year);
        let totalinvestmentreduce = investmentreduction.reduce((a,v) =>  a = a - v.compensationbase - v.compensationsti - v.compensationlti - v.compensationbenefits, 0 )
        cumulativevalue = cumulativevalue + totalinvestmentadd + totalinvestmentreduce
        cells.push(
          <td className={"has-text-centered"} key={'year' + year + cumulativevalue}>
            <CurrencyFormat
              value={cumulativevalue}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </td>
        );
      }
    rows.push(<tr key="cumulative_inv_val" style={{lineHeight:"15px", fontSize:"small"}}>{cells}</tr>);
    return rows;
  }

  function renderBody() {
    return (
      <Fragment>
      <tbody>
        <th colSpan={maxYear-minYear+1} style={{textAlign:"center", backgroundColor:"rgb(238,238,238)"}}>Metrics</th>
        {summaryrow()}
        <th colSpan={maxYear-minYear+1} style={{textAlign:"center", backgroundColor:"rgb(238,238,238)"}}>Hiring</th>
        {renderRows()}
        {renderAnnualInvestment()}
        {renderCumulativeInvestment()}
      </tbody>
      </Fragment>
    );
  }

  return (
    <table
      className={"table is-bordered is-fullwidth mb-6"}
      style={{ tableLayout: "fixed", userSelect: "none"}}
    >
      {renderHeader()}
      {renderBody()}
    </table>
  );
}

export { OrgTable };
