import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {companyService} from "../services/CompanyService";
import {timelineService} from "../services/TimelineService";
//import { valuesActions } from "../redux/actions/values";
import { openRefreshValues, deleteValues } from "../redux/toolkit/features/values";
import { factsActions } from "../redux/actions/facts";
import { timelinesActions } from "../redux/actions/timelines";
import "./common/css/main.css";
import "./common/css/masterstyles.css";
import "bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css";
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";

// Import Company Page Sections
import CompanyHeroSection from "./company/CompanyHeroSection";
import CompanyHeroModal from "./company/modals/CompanyHeroModal";
import CompanyPurposeSection from "./company/CompanyPurposeSection";
import CompanyValuesSection from "./company/CompanyValuesSection";
import CompanyFactsSection from "./company/CompanyFactsSection";
import CompanyTimelineSection from "./company/CompanyTimelineSection";
import CompanyQuoteSection from "./company/CompanyQuoteSection";

// Import Modals
import CompanyPurposeModal from "./company/modals/CompanyPurposeModal";
import CompanyQuoteModal from "./company/modals/CompanyQuoteModal";
import CompanyFactsModal from "./company/modals/CompanyFactsModal";
import CompanyValuesModal from "./company/modals/CompanyValuesModal";
import CompanyTimelineModal from "./company/modals/CompanyTimelineModal";
import CompanyFactsRecordModal from "./company/modals/CompanyFactsRecordModal";
import CompanyValuesRecordModal from "./company/modals/CompanyValuesRecordModal";
import CompanyTimelineRecordModal from "./company/modals/CompanyTimelineRecordModal";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox";
import Spinner from "./common/Spinner";


function Company() {
    const [company, setCompany] = useState(null);
    const [isHeroModalOpen, setIsHeroModalOpen] = useState(false);
    const [isPurposeModalOpen, setIsPurposeModalOpen] = useState(false);
    const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
    const dispatch = useDispatch(); 
    const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
    const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);    
    const [isDataLoading, setIsDataLoading] = useState(false);
    
    async function fetchCompany() {
        const company = await companyService.getCurrentCompany();
        setCompany(company);
    }

    useEffect(() => {
        async function fetchData() {
            setIsDataLoading(true);
                await fetchCompany();
            setIsDataLoading(false);
        }
        fetchData();
    },[]);


    async function onConfirmDelete(result) {
        if (result) {
            
            if (recordtype === 'facts') {
                dispatch(factsActions.deleteRow(recordkey));

            }

            if (recordtype === 'values') {
                dispatch(deleteValues(recordkey));
                //dispatch(openRefreshValues());
            }

            if (recordtype === 'timeline') {
                dispatch(timelinesActions.deleteRow(recordkey));
            }
        }
        dispatch(closeDeleteModal());
    }

    function toggleHeroModal(open) {
        setIsHeroModalOpen(open);
    }

    function togglePurposeModal(open) {
        setIsPurposeModalOpen(open);
    }

    function toggleQuoteModal(open) {
        setIsQuoteModalOpen(open);
    }

    async function onCompanyHeroChanged(herotitle, herosubtitle) {
        await companyService.patch(company.pk, {herotitle, herosubtitle});
        await fetchCompany();
    }

    async function onCompanyQuoteChanged(quote) {
        await companyService.patch(company.pk, {quote});
        await fetchCompany();
    }

    async function onCompanyImageChanged(file) {
        const changedCompany = await companyService.uploadImage(company.pk, file);
        setCompany(changedCompany)
    }

    async function onPurposeOrStatementChanged(purpose, statement) {
        await companyService.patch(company.pk, {purpose, statement});
        await fetchCompany();
    }

    if (!company) {
        return <Spinner/>;
    }

    function rendercompanypage() {
        return (
            <main className="has-navbar-fixed-top">
            <CompanyHeroSection
                herotitle={company.herotitle}
                herosubtitle={company.herosubtitle}
                heroimage={company.heroimage}
                companyId={company.pk}
                toggleModal={toggleHeroModal}
            />
            <CompanyPurposeSection
                purpose={company.purpose}
                statement={company.statement}
                toggleModal={togglePurposeModal}
            />
            <CompanyHeroModal
                herotitle={company.herotitle}
                herosubtitle={company.herosubtitle}
                heroimage={company.heroimage}
                showModal={isHeroModalOpen}
                toggleModal={toggleHeroModal}
                onDataChanged={onCompanyHeroChanged}
                onImageChanged={onCompanyImageChanged}
            />
            <CompanyPurposeModal
                purpose={company.purpose}
                statement={company.statement}
                showModal={isPurposeModalOpen}
                toggleModal={togglePurposeModal}
                onDataChanged={onPurposeOrStatementChanged}
            />
            <CompanyValuesSection/>

            <CompanyQuoteSection
                company={company}
                toggleModal={toggleQuoteModal}
            />

            <CompanyTimelineSection/>

            <CompanyFactsSection/>
            
            <CompanyFactsModal/>      

            <CompanyFactsRecordModal/>

            <CompanyQuoteModal
                quote={company.quote}
                showModal={isQuoteModalOpen}
                toggleModal={toggleQuoteModal}
                onDataChanged={onCompanyQuoteChanged}
            />         

            <CompanyValuesModal/>      

            <CompanyValuesRecordModal/>

            <CompanyTimelineModal/>      

            <CompanyTimelineRecordModal/>  

            <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>

        </main>
        )
    }

    return (
        (isDataLoading?<Spinner/>:rendercompanypage())
    )
}

export { Company };
