import React from "react";
import CompanyPurposeSectionWithData from "./CompanyPurposeSectionWithData";
import CompanyPurposeSectionOnlyButton from "./CompanyPurposeSectionOnlyButton";

function CompanyPurposeSection(props) {
    const {purpose, statement, toggleModal} = props;
    if (statement || purpose) {
        return <CompanyPurposeSectionWithData
                    purpose={purpose}
                    statement={statement}
                    toggleModal={toggleModal}
        />;
    } else {
        return <CompanyPurposeSectionOnlyButton toggleModal={toggleModal} />;
    }
}

export default CompanyPurposeSection;
