import React, {Fragment, useState, useEffect, useContext} from "react";
import { useSelector, useDispatch } from 'react-redux'
import "../common/css/dot.css";
import {openPeopleModal} from "./../../redux/toolkit/features/people/peopleModal";
import { peopleActions } from "./../../redux/actions/people";
import { openDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";

//import { peopledatasets } from '../OrganizationPage';
import {filter} from "lodash"


function People(props) {
    //const {persons, personyear, toggleTalentModal, roleperson, sankey, positions, personview, competencies} = props;
    const people = useSelector((state) => state.people.people);
    const [selectedTab, setSelectedTab] = useState('All')
    const [isTable, setIsTable] = useState(true)
    const [positionlist, setPositionlist]=useState([])
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const [textfilter, setfilterquery] = useState('');
    var [filteredPeople] = [];
    const dispatch = useDispatch(); 
  
    useEffect(() => {
        async function getPeople() {
          dispatch(peopleActions.getAll());
        }
        getPeople();
      },[]);
  

    filteredPeople = people.filter((person) => {
        if (person.firstname.toLowerCase().includes(textfilter.toLowerCase()) || person.secondname.toLowerCase().includes(textfilter.toLowerCase())){
          return true;
        }
        return filteredPeople;
      })

    function handleChangeFilter(event) {
        setfilterquery(event.target.value);
    }

    function peoplelist(peoplerow){
      return (
        <tr>
        <td onDoubleClick={() => dispatch(openPeopleModal(peoplerow.pk))}>{peoplerow.firstname} {peoplerow.secondname}</td>
        <td><a onClick={async () => {
                        dispatch(openDeleteModal({message: (peoplerow.firstname + " " + peoplerow.secondname),recordpk: peoplerow.pk, recordtype: 'people'}));
                        }}><i className="far fa-trash-alt mt-1" style={{color:"gray"}}/></a></td>
        </tr>
      )
    }

    if (!people) {
      return null
    }

    return (
        <Fragment>
          <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        PEOPLE DIRECTORY  ({filteredPeople.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input  onChange={handleChangeFilter} className="input is-rounded is-small"  type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth">
                <tr>
                  <th>Name</th>
                  <th style={{width:"8px"}} ><a onClick={() => dispatch(openPeopleModal(null))}><button style={{color: backgroundcolor}} className="fas fa-plus-circle"></button></a></th>
                </tr>

              {filteredPeople && filteredPeople.map(row => (
                peoplelist(row)
              ))}
              </table>
                


            </section>
        </Fragment>
    )
}

export {People};