import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class SprintService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/sprints/`;
    }

    async getSprint() {
        const sprints = await this.getAll();
        return sprints;
    }
}

export const sprintService = new SprintService();