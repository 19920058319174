import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeCompetencyModal } from "./../../../redux/toolkit/features/people/competenciesModal";
import { filter } from "lodash"
import { competenciesActions } from "./../../../redux/actions/competencies";

function CompetenciesModal() {
    const showModalCompetencies = useSelector((state) => state.competenciesModal.isOpen)
    const competencyrecordkey = useSelector((state) => state.competenciesModal.competencypk)
    const competencies = useSelector((state) => state.competencies.competencies);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [competency, setCompetency] = useState(null);
    const [description, setDescription] = useState(null);
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (showModalCompetencies) {
        modalClass += " is-active";
    }

    let competencyarray=filter(competencies, {pk: competencyrecordkey});
    let competencyvalue= (competencyarray[0]?.competency || "")
    let descriptionvalue= (competencyarray[0]?.description || "")   

    useEffect(() => {
        async function getCompetencies() {
          dispatch(competenciesActions.getAll());
        }
        getCompetencies();
      },[showModalCompetencies,closeCompetencyModal]);

      useEffect(() => {
        setCompetency(competencyvalue);
        setDescription(descriptionvalue);
      },[competencyrecordkey, competencies]);


    function handleChangeCompetency(event) {
        setCompetency(event.target.value);
    }

    function handleChangeDescription(event) {
        setDescription(event.target.value);
    }

    if (!competencies) {
        return null;
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Competency</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="1"
                                //placeholder={placeholdertext}
                                value={competency}
                                onChange={handleChangeCompetency}
                                />
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Description</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="4"
                                //placeholder={placeholdertext}
                                value={description}
                                onChange={handleChangeDescription}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        { competencyrecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>EDIT COMPETENCY</p>}
                        { !competencyrecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>NEW COMPETENCY</p>}

                        <button className="delete" aria-label="close" onClick={() => dispatch(closeCompetencyModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {competencyrecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(competenciesActions.post({competency: competency, description: description }));
                                dispatch(competenciesActions.getAll());
                                dispatch(closeCompetencyModal())
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {competencyrecordkey !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(competenciesActions.patch(competencyrecordkey,{competency: competency, description: description }));
                                dispatch(closeCompetencyModal())
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeCompetencyModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompetenciesModal;
