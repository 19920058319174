import React, { Fragment, useContext } from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import { sprintdatasets } from '../Sprints';

function SprintHeader(props) {
    const { 
        sprintdate, 
        onlyOpenTasks, 
        onlyCompletedTasks,
        onlyDiscontinuedTasks,
        onlyMyTasks,
        handleChangeOnlyActive,
        handleChangeOnlyCompleted,
        handleChangeOnlyDiscontinued, 
        handleChangeOnlyMyTasks,
        handleChangeFilter,
        handleClickAddTask,
        handleClickEditDate,
        toggleModal,
    } = props; 
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const {filteredSprints, sprints} = useContext(sprintdatasets);
    const CurrentDate = new Date().toLocaleString() + ""
    const openSprints = sprints.filter(
        (sprint) => sprint.action_status === "Open" || sprint.action_status === "Not Started" || sprint.action_status === "In Progress"
      ).length;
    const completedSprints = sprints.filter(
        (sprint) => sprint.action_status === "Completed"
      ).length;
    const suspendedSprints = sprints.filter(
        (sprint) => sprint.action_status === "Suspended"
      ).length;

    var now = moment();
    var enddate = moment(sprintdate.sprintenddate, 'YYYY-MM-DD');
    let daystogo= Math.floor(enddate.diff(now, 'days'));
      

        return (

                <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em", paddingBottom: "50px", userSelect: "none"}}>
                    <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "32px", lineHeight: "26px",paddingBottom: "28px"}}>
                        <div className="pr-1">
                            SPRINTS<button className="button is-small is-pulled-right" style={{borderColor: backgroundcolor, color: backgroundcolor, borderRadius:"5px"}}onClick={() => handleClickAddTask(true)}>Add Sprint</button><br/><br/>
                        </div>
                        <p style={{fontWeight: "200", fontFamily: "Gotham Extra", fontSize: "16.5px", lineHeight: "16px", paddingBottom: "16px"}}> The next Sprint review is scheduled for {" "}
                            <strong onClick={() => toggleModal(true)}>
                            {" "}
                            <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">
                            {sprintdate?.sprintenddate}
                            </Moment>
                            {"."}
                        </strong> </p>
                    </div>

                    <div className="columns pl-3 pr-3 mb-0" style={{height:"160px"}}>
                        <div className="column is-one-fifth pt-0 pr-0 pl-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px",lineHeight: ".8em"}}>
                            {/* <div className="card mt-0 pt-0 pb-1 is-shadowless" style={{backgroundColor: "rgb(238,238,238)", width: "100%"}}> */}
                            <div className="card mt-0 pt-0 pb-1 is-shadowless" style={{backgroundColor: backgroundcolor, color: textcolor, width: "100%"}}>
                                {(() => {
                                    if (sprintdate?.sprintenddate > CurrentDate) {
                                        return (
                                            <Fragment>
                                                <header className="card-header is-shadowless p-0 m-0">
                                                    <nav className="level m-0 p-0">
                                                        <p className="pl-5 pt-0 mt-0 pb-0 mb-0">
                                                            {daystogo}
                                                        </p>
                                                    </nav>
                                                </header>
                                                <div className="card-content pl-0 pt-0 mt-0" >
                                                    <div className="content mt-0 pt-0" style={{backgroundColor: "transparent"}}><span style={{fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                                                        Days Remaining</span>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        );
                                    } else {
                                        return  (
                                            <Fragment>      
                                                <header className="card-header is-shadowless p-0 m-0">
                                                    <nav className="level m-0 p-0">
                                                        <p className="pl-5 pt-0 mt-0 pb-0 mb-0">
                                                        0
                                                        </p>
                                                    </nav>
                                                </header>
                                                <div className="card-content pl-0 pt-0 mt-0" >
                                                    <div className="content mt-0 pt-0" style={{backgroundColor: "transparent"}}><span style={{fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                                                        Days Remaining<br/>Sprint Cycle Complete </span>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        );
                                    }
                                })()}
                            </div>
                        </div>

                        <div className="column is-one-fifth pt-0 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px",lineHeight: ".8em"}}>
                            <div className="card mt-0 pt-0 pb-1 is-shadowless" style={{backgroundColor: backgroundcolor, width: "100%"}}>
                                <header className="card-header is-shadowless p-0 m-0" >
                                    <nav className="level m-0 p-0">
                                        <p className="pl-5 pt-0 mt-0 pb-0 mb-0" >
                                            <span style={{color: textcolor}}>{sprints.length}</span>
                                        </p>
                                    </nav>
                                </header>
                                <div className="card-content pl-0 pt-0 mt-0" >
                                    <div className="content mt-0 pt-0" style={{backgroundColor: "transparent", color: textcolor}}><span style={{fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                                        Total Sprints </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column is-one-fifth pt-0 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px",lineHeight: ".8em"}}>
                            <div className="card mt-0 pt-0 pb-1 is-shadowless" style={{backgroundColor: backgroundcolor, color: textcolor, width: "100%"}}>
                                <header className="card-header is-shadowless p-0 m-0">
                                    <nav className="level m-0 p-0">
                                        <p className="pl-5 pt-0 mt-0 pb-0 mb-0">
                                        {openSprints}
                                        </p>
                                        <div className="level-right p-0 m-0">
                                            <div className="level-item pr-5 pt-0 pb-0 m-0">
                                                <input 
                                                    type="checkbox"
                                                    checked={onlyOpenTasks}
                                                    onChange={handleChangeOnlyActive}
                                                />
                                            </div>
                                        </div>
                                    </nav>
                                </header>
                                <div className="card-content pl-0 pt-0 mt-0" >
                                    <div className="content mt-0 pt-0" style={{backgroundColor: "transparent"}}><span style={{fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                                        Active Sprints </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column is-one-fifth pt-0 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px",lineHeight: ".8em"}}>
                            <div className="card mt-0 pt-0 pb-1 is-shadowless" style={{backgroundColor: backgroundcolor, color: textcolor, width: "100%"}}>
                                <header className="card-header is-shadowless p-0 m-0">
                                    <nav className="level m-0 p-0">
                                        <p className="pl-5 pt-0 mt-0 pb-0 mb-0">
                                            {completedSprints}
                                        </p>
                                        <div className="level-right p-0 m-0">
                                            <div className="level-item pr-5 pt-0 pb-0 m-0">
                                                <input 
                                                    type="checkbox"
                                                    checked={onlyCompletedTasks}
                                                    onChange={handleChangeOnlyCompleted}
                                                />
                                            </div>
                                        </div>
                                    </nav>
                                </header>
                                <div className="card-content pl-0 pt-0 mt-0" >
                                    <div className="content mt-0 pt-0" style={{backgroundColor: "transparent"}}><span style={{fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                                        Completed Sprints </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column is-one-fifth pt-0 pr-0 is-flex" style={{fontFamily: "Gotham Extra", fontSize: "30px",lineHeight: ".8em"}}>
                            <div className="card mt-0 pt-0 pb-1 is-shadowless" style={{backgroundColor: backgroundcolor, color: textcolor, width: "100%"}}>
                                <header className="card-header is-shadowless p-0 m-0">
                                    <nav className="level m-0 p-0">
                                        <p className="pl-5 pt-0 mt-0 pb-0 mb-0">
                                            {suspendedSprints}
                                        </p>
                                        <div className="level-right p-0 m-0">
                                            <div className="level-item pr-5 pt-0 pb-0 m-0">
                                            <input
                                                type="checkbox"
                                                checked={onlyDiscontinuedTasks}
                                                onChange={handleChangeOnlyDiscontinued}
                                            />
                                            </div>
                                        </div>
                                    </nav>
                                </header>
                                <div className="card-content pl-0 pt-0 mt-0" >
                                    <div className="content mt-0 pt-0" style={{backgroundColor: "transparent"}}><span style={{fontFamily: "Gotham Extra",fontSize: "16.5px",lineHeight: ".8em"}}>
                                        Discountinued Sprints </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <nav className="level pb-0 mb-0 pt-5 mt-0">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        SPRINT ACTIVITIES  ({filteredSprints.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-4">
                                <label className="checkbox subtitle" style={{fontWeight: "400", fontFamily: "Gotham Extra", fontSize: "16px"}}>
                                    <span className="mr-2">
                                        <small>My Tasks</small>
                                    </span>
                                    <input
                                        type="checkbox"
                                        checked={onlyMyTasks}
                                        onChange={handleChangeOnlyMyTasks}
                                    />
                                </label>
                            </div>

                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small" onChange={handleChangeFilter} type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />

                </section>
            )
        }

    export { SprintHeader };