import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TalentSankeyService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/talent/sankey`;
  }

  async getSankey() {
    const sankey = await this.getAll();
    return sankey;
  }
}

export const talentsankeyService = new TalentSankeyService();
