import React, {Fragment} from 'react';
import { useDispatch } from 'react-redux'
import {filter} from 'lodash'
import { openRoadmapChartModal } from "../../../redux/toolkit/features/roadmaps/chartDetailModal";

function SectionsModalCharts(props) {
    const {toggleSectionsChartsModal, toggleConfirmDeleteModal, chart, sectionpk} = props;
    const dispatch = useDispatch();

    let chartlist=filter(chart.results,{section: sectionpk })

    return (
        <Fragment>
             <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
                <tbody>
            {chartlist.map(row => (

                <tr key={'chartrow_'+row} className="ml-4 pl-2">
                <td className="p-0" style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"600", width:"5px"}} onClick={() => toggleSectionsChartsModal(true,row.pk)}>{row.year}</td>
                <td  colSpan="2" className="mb-2 ml-5 pl-2" style={{borderTop:"solid 1px grey", borderBottom:"solid 1px grey"}} onDoubleClick={() => dispatch(openRoadmapChartModal(row.pk))}>
                {row.chart_title}
                </td>                 
                <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                        toggleConfirmDeleteModal(true, row.event, row.pk, 'roadmapchart') 
                }}>
                <button className="far fa-trash-alt" style={{color:"gray"}}
                    ></button>
                </td>
                </tr>
            ))}
            </tbody>
            </table>

        </Fragment>
    )
}


export default SectionsModalCharts;
