import api from "../api/values";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const valuesAdapter = createEntityAdapter({
  selectId: (value) => value.pk,
  sortComparer: (a, b) => a.value.localeCompare(b.value),
});

const initialState = valuesAdapter.getInitialState({
  status: "idle", isModalOpen: false, valueModalpk: null, isRecordModalOpen: false, valueRecordModalpk: null, modalvalues: null, pagevalues:null
});

export const fetchAllValues = createAsyncThunk("values/fetch", () =>
  api.fetchAllValues()
);

export const updateValues = createAsyncThunk("values/update", api.updateValues);

export const createValues = createAsyncThunk("values/create", api.createValues);

export const deleteValues = createAsyncThunk("values/delete", api.deleteValues);

const valuesSlice = createSlice({
  name: "values",
  initialState,
  reducers: {    
    openValuesModal(state, action) {
      state.isModalOpen = true;
      state.valueModalpk = action.payload;
    },
    closeValuesModal(state) {
      state.isModalOpen = false;
      state.valueModalpk = null;
    },
    openValuesRecordModal(state, action) {
      state.isRecordModalOpen = true;
      state.valueRecordModalpk = action.payload;
    },
    closeValuesRecordModal(state) {
      state.isRecordModalOpen = false;
      state.valueRecordModalpk = null;
    },
    openRefreshValues(state) {
      state.pagevalues = true;
    },
    closeRefreshValues(state) {
      state.pagevalues = false;
    },
    openRefreshModal(state) {
      state.modalvalues = true;
    },
    closeRefreshModal(state) {
      state.modalvalues = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllValues.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllValues.fulfilled, (state, { payload }) => {
        valuesAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateValues.fulfilled, valuesAdapter.updateOne)
      .addCase(deleteValues.fulfilled, valuesAdapter.removeOne)
      .addCase(createValues.fulfilled, valuesAdapter.addOne);
  },
});

export const { openValuesModal, closeValuesModal, openValuesRecordModal, closeValuesRecordModal, openRefreshValues, closeRefreshValues, openRefreshModal, closeRefreshModal } = valuesSlice.actions;

export default valuesSlice.reducer;

const selectState = (state) => state.values;

export const {
  selectAll: selectValues,
  selectById: selectValueById,
} = valuesAdapter.getSelectors(selectState);

export const selectValuesStatus = createSelector(
  selectState,
  (state) => state.status
);
