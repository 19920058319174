import React, {Fragment, useContext} from 'react';
import {successdataset} from "./../../Success";
import {filter} from 'lodash'

function SuccessModalRowsRoadmaps(props) {
    const {filteredthemes, successkey} = props;
    const {linkedthemes, toggleConfirmDeleteModal} = useContext(successdataset);

    function deletelink(row, title) {
        let linkrow=filter(linkedthemes.results,{success:successkey, theme_id: Number(row)})
        toggleConfirmDeleteModal(true, title, (linkrow[0]).pk, 'linkedtheme') 
    }

    function renderRoadmaps() {

        if (filteredthemes.length !== 0) {
            return (
                <Fragment>
    
                    <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                        <tbody>
                            {filteredthemes.map(row => (
                                <Fragment>

                                    <tr className="ml-4 pl-5" colSpan="2">
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} >{row.theme}</td>         
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                                deletelink(row.pk, row.theme) 
                                        }}>
                                        <button className="far fa-trash-alt" style={{color:"gray"}}/>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>

            </Fragment>
            )
        } else {

            return (<p className="pl-4 pt-2"><b>No Linked Themes</b></p>)

        }
    }

    return (
        <Fragment>
            {renderRoadmaps()}
        </Fragment>
    );
}

export default SuccessModalRowsRoadmaps;
