import { factsConstants } from '../constants/facts';

const initialState = {
  loading: false,
  facts: [],
  error: null,
}

export function facts(state = initialState, action) {
  switch (action.type) {
    case factsConstants.GET_FACTS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case factsConstants.GET_FACTS_SUCCESS:
      return {
        ...initialState,
        facts: action.facts,
      };
    case factsConstants.GET_FACTS_FAILURE:
      return { 
        ...initialState,
        error: action.error,
      };

      case factsConstants.POST_FACTS_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case factsConstants.POST_FACTS_SUCCESS:
        const addedfacts = [...state.facts];
        addedfacts.push(action.fact);
        return {
            ...state,
            fact: addedfacts,
            facts: action.facts,
        };
    case factsConstants.POST_FACTS_FAILURE:
        return {
            ...state,
            error: action.error,
        };
    case factsConstants.DELETE_FACTS_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case factsConstants.DELETE_FACTS_SUCCESS:
        const deletedFacts = [];
        for (const fact of state.facts) {
            if (fact.pk !== action.id) {
                deletedFacts.push(fact);
            }
        }
        return {
            ...state,
            facts: deletedFacts,
        };
    case factsConstants.DELETE_FACTS_FAILURE:
        return {
            ...state,
            error: action.error,
        };
    default:
        return state
  }
}
