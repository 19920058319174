import React, {Fragment, useContext} from 'react';
import {filter} from 'lodash'
import {successdataset} from "./../../Success";

function SuccessModalRowsRoadmaps(props) {
    const {filteredrows, successkey} = props;
    const {linkedrows, toggleConfirmDeleteModal} = useContext(successdataset);

    function deletelink(row, title) {
        let linkrow=filter(linkedrows.results,{success:successkey, row_id: Number(row)})
        toggleConfirmDeleteModal(true, title, (linkrow[0]).pk, 'linkedroadmap') 
    }

    function renderRoadmaps() {

        if (filteredrows.length !== 0) {
            return (
                <Fragment>
    
                    <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                        <tbody>
                            {filteredrows.map(row => (
                                <Fragment>

                                    <tr className="ml-4 pl-5" colSpan="2">
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', userSelect:"none", pointerEvents: "none"}} >{row.name}</td>         
                                        <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px', userSelect:"none"}} onClick={async () => {
                                                deletelink(row.pk, row.name) 
                                        }}>
                                        <button className="far fa-trash-alt" style={{color:"gray"}}/>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>

            </Fragment>
            )
        } else {

            return (<p className="pl-4 pt-2"><b>No Linked Roadmaps</b></p>)

        }
    }

    return (
        <Fragment>
            {renderRoadmaps()}
        </Fragment>
    );
}

export default SuccessModalRowsRoadmaps;
