import { React, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
//import { closeValuesModal } from "./../../../redux/toolkit/features/company/valuesModal";
//import { openValuesRecordModal } from "./../../../redux/toolkit/features/company/valuesRecordModal";
import { Container } from "./CompanyValues/Container";
//import { valuesActions } from "./../../../redux/actions/values";
import {fetchAllValues, closeValuesModal, openValuesRecordModal } from "./../../../redux/toolkit/features/values";

function CompanyValuesModal() {
    const showModalValues = useSelector((state) => state.values.isModalOpen)
    const values = useSelector((state) => state.values.entities);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (showModalValues) {
        modalClass += " is-active";
    }

    useEffect(() => {
        async function getValues() {
          dispatch(fetchAllValues())
        }
        getValues();
      },[dispatch]);

    if (!values) {
        return null;
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY VALUES</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeValuesModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {values.lengthj !== 0 &&
                    <Container values={Object.values(values)} />}
                    {values.length === 0 && 
                    <p className = "pt-5" style={{fontSize:"16.5px"}}>No Records Returned</p>}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button
                            style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}}
                            className="button is-small"
                            key="new"
                            onClick={async () => {
                                dispatch(openValuesRecordModal(null));
                                dispatch(closeValuesModal());
                            }}
                        >
                            Add New
                        </button>
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeValuesModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyValuesModal;
