import React, { Fragment, useState, useContext } from "react";
import { Register } from "./Registers";
import {filter} from "lodash"
import { sprintdatasets } from '../Sprints';

function RenderComments(props) {
    const {toggleSetCommentModal, sprint} = props;
    const {registers } = useContext(sprintdatasets);
    const [commentsVisible, setcommentsVisible] = useState(false);

    function hideCommentsSection() {
        setcommentsVisible(false);
      }
      
      function showCommentsSection() {
        setcommentsVisible(true);
      }

    const commentlist = filter(registers, {sprint: sprint})
    
    if(commentsVisible) {
    return (
      <Fragment>
        <nav className="level mb-4" style={{height: "15px"}}>
          <div className="level-left">
            <div className="level-item">
              <p className="p-0 pb-0" style={{fontFamily: "Sans-serif", fontSize: "13px"}} onClick={() => hideCommentsSection()}><b>▼ COMMENTS ({commentlist.length})</b></p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <button className="is-pulled-right button is-small mr-4" style={{borderRadius: "10px", backgroundColor: "transparent", borderColor: "rgb(200,200,200)"}} onClick={() => toggleSetCommentModal(true, sprint)}>Add Comment</button>
            </div>
          </div>
        </nav>

      <hr className="pt-0 mt-1 mr-4 mb-4" style={{borderTop: "1px solid gray"}}/>
      {commentlist && commentlist.length!==0 && 
        commentlist.map((register) => (
          <div key={register.pk}>
            <Register key={register.pk} {...register} />
          </div>
        ))}
      {commentlist.length===0 &&
      <p className="mb-4" style={{fontSize:"14px"}}>No Comments</p>}

      </Fragment>
    )
  } else {
    return(
      <Fragment>
        <nav className="level mb-4" style={{height: "15px"}}>
          <div className="level-left">
            <div className="level-item">
              <p className="pl-0 pb-0" style={{fontFamily: "Sans-serif", fontSize: "12px", fontWeight:"500"}} onClick={() => showCommentsSection()}><b>► COMMENTS ({commentlist.length})</b></p>
            </div>
          </div>
        </nav>

    </Fragment>
    )
  }
}

export { RenderComments };
