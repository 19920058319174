import { competenciesConstants } from '../constants/competencies';
import {talentcompetenciesService} from "../../services/TalentCompetenciesService";
import {alerts} from "./alerts";

export const competenciesActions = {
    getAll,
    post,
    deleteRow,
    patch,
};

function getAll() {
    return async dispatch => {
        dispatch(request());
        try {
            const competencies = await talentcompetenciesService.getTalentCompetencies();
            dispatch(success(competencies));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: competenciesConstants.GET_COMPETENCIES_REQUEST } }
    function success(competencies) { return { type: competenciesConstants.GET_COMPETENCIES_SUCCESS, competencies } }
    function failure(error) { return { type: competenciesConstants.GET_COMPETENCIES_FAILURE, error } }
}

function post(data) {
    console.log("At Post")
    return async dispatch => {
        dispatch(request());
        try {
            const competency = await talentcompetenciesService.post(data);
            dispatch(success(competency));
            console.log("At Post1", data)
        } catch (err) {
            console.log("At Post", err)
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: competenciesConstants.POST_COMPETENCY_REQUEST } }
    function success(competency) { return { type: competenciesConstants.POST_COMPETENCY_SUCCESS, competency } }
    function failure(error) { return { type: competenciesConstants.POST_COMPETENCY_FAILURE, error } }
}

function deleteRow(id) {
    return async dispatch => {
        dispatch(request());
        try {
            await talentcompetenciesService.deleteRow(id);
            dispatch(success(id));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: competenciesConstants.DELETE_COMPETENCY_REQUEST } }
    function success(id) { return { type: competenciesConstants.DELETE_COMPETENCY_SUCCESS, id } }
    function failure(error) { return { type: competenciesConstants.DELETE_COMPETENCY_FAILURE, error } }
}

function patch(pk, data) {
    return async dispatch => {
        dispatch(request());
        try {
            const competency = await talentcompetenciesService.patch(pk, data);
            dispatch(success(competency));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: competenciesConstants.PATCH_COMPETENCY_REQUEST } }
    function success(competency) { return { type: competenciesConstants.PATCH_COMPETENCY_SUCCESS, competency } }
    function failure(error) { return { type: competenciesConstants.PATCH_COMPETENCY_FAILURE, error } }
}


