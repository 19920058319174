import React, { useState, useEffect } from "react";
import "../common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { tenantsActions } from "../../redux/actions/tenants";
import { tenantsService } from "../../services/TenantsService";
import BaseService from "../../services/BaseService";

function TenantForm(props) {
  const {tenant, toggleModal, updateEmailOnChange} = props;
  const [emaildomain, setEmaildomain] = useState("");
  const [tenantData, setTenantData] = useState({
    name: tenant ? tenant.name : "",
  });
  const tenantError = useSelector((state) => state.tenants.error);
  const [nameError, setNameError] = useState("");
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
   if (tenant) {
    setEmaildomain(tenant.emaildomain)
   }
  }, [tenant]);

  const handleOnChange = (event) => {
    const newTenantData = {
      ...tenantData,
      [event.target.name]: event.target.value,
    };
    setTenantData(newTenantData);
  };


  function handleEmailOnChange(event) {
    setEmaildomain(event.target.value)
  }


  const onSave = () => {
    if (tenantData.name.trim() === "") {
      setNameError("Name is mandatory");
      return;
    }
    dispatch(tenantsActions.saveTenant(tenant.pk, tenantData));
    setSaved(true);
  };

  async function onLogoFileSelected(event) {
    const files = event.target.files;
    const result = await tenantsService.uploadLogo(tenant.pk, files[0]);
    dispatch(tenantsActions.getCurrentTenant());
  }

  return (
    <>
      {tenantError && (
        <div className="notification is-danger">{tenantError}</div>
      )}

      {saved && !tenantError && (
        <div className="notification is-success">
          <button className="delete" onClick={() => setSaved(false)}>
            {" "}
          </button>
          Data saved
        </div>
      )}

      <section className="section" style={{backgroundColor: "rgb(248, 248, 248)"}}>
        <h1 className="title">Company Information</h1>

        <label className="label">Company Name</label>
        <div className="field is-grouped">
          <div className="control is-expanded">
            <input 
              name={"name"}
              className="input"
              type="text"
              value={tenantData.name}
              onChange={handleOnChange}
            />
          </div>
          {nameError !== undefined && 
          <p className="help is-danger">{nameError}</p>}
          <div className="control">
            <button className="button is-link" onClick={onSave}>
              Save
            </button>
          </div>
        </div>

        <label className="label">Company Email Domain</label>
        <div className="field">
          <div className="field has-addons">
            <p className="control">
              <a className="button is-static">
                @
              </a>
            </p>
            <div className="control is-expanded has-icons-left has-icons-right">
            <input
              name={"email"}
              className="input pl-2"
              type="email"
              value={emaildomain}
              onChange={handleEmailOnChange}
            />
            </div>
          <div className="control pl-3">
            <button className="button is-link" onClick={() => updateEmailOnChange(emaildomain, tenant.pk)}>
              Save
            </button>
          </div>
        </div>
        </div>





      <label className="label">User Authentication Method</label>
      <div className="field is-grouped">
        <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select name="Authenication">
            <option value="Application">Application</option>
            <option value="Google">Google</option>
            <option value="Microsoft">Microsoft</option>
            </select>
          </div>
        </div>
        <div className="control">
            <button className="button is-link">
              Save
            </button>
        </div>
    </div>




    <label className="label">Company Logo</label>
    <div className="field">
          <div className="field has-addons">
         
            <div className="control">
            <div className="file has-name">
                                <label className="file-label">
                                    <input 
                                    className="file-input"
                                    type="file"
                                    name="logo"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={onLogoFileSelected}
                                   />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-upload"></i>
                                            </span>
                                            <span className="file-label">
                                                Upload                                            </span>
                                        </span>
                                </label>
                            </div>
                            </div>
                            <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select name="Authenication">
            <option value="Application">image22.png</option>
            <option value="Google">white_transparent</option>
            <option value="Microsoft">dark100x100</option>
            </select>
          </div>
        </div>
            <div className="control pl-2 pr-1 is-flex">
        {tenant && (
          <img
            style={{marginTop: "auto", marginBottom: "auto"}}
            className="menuimage"
            alt="tenant logo"
            src={
              tenant.image.startsWith("http")
                ? tenant.image
                : BaseService.baseServer + tenant.image
            }
          />
        )}
        </div>
          <div className="control pl-3">
            <button className="button is-link" onClick={() => updateEmailOnChange(emaildomain, tenant.pk)}>
              Save
            </button>
          </div>
        </div>
        </div>



        <label className="label">Company Branding</label>
        <div className="field">
          <div className="field has-addons">
         
            <div className="control">
            <div className="file has-name">
                                <label className="file-label">
                                    <input 
                                    className="file-input"
                                    type="file"
                                    name="logo"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={onLogoFileSelected}
                                   />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                            <i className="fas fa-palette"></i>
                                            </span>
                                            <span className="file-label">
                                                Primary Color                                            </span>
                                        </span>
                                </label>
                            </div>
                            </div>
                            <div className="control">
            <button className="button" style={{backgroundColor: "green"}} onClick={() => toggleModal(true)}>
            </button>
            </div>
                            <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select name="Authenication">
            <option value="Application">image22.png</option>
            <option value="Google">white_transparent</option>
            <option value="Microsoft">dark100x100</option>
            </select>
          </div>
        </div>

        <div className="control">
            <div className="file has-name">
                                <label className="file-label">
                                    <input 
                                    className="file-input"
                                    type="file"
                                    name="logo"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={onLogoFileSelected}
                                   />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                            <i className="fas fa-palette"></i>
                                            </span>
                                            <span className="file-label">
                                                Secondary Color                                            </span>
                                        </span>
                                </label>
                            </div>
                            </div>
                            <div className="control">
            <button className="button" style={{backgroundColor: "yellow"}} onClick={() => toggleModal(true)}>
            </button>
            </div>
                            <div className="control is-expanded">
          <div className="select is-fullwidth is-info">
            <select name="Authenication">
            <option value="Application">none</option>
            <option value="Google">blue</option>
            <option value="Microsoft">new</option>
            </select>
          </div>
        </div>
                 <div className="control pl-3">
            <button className="button is-link" onClick={() => updateEmailOnChange(emaildomain, tenant.pk)}>
              Save
            </button>
          </div>
        </div>
        </div>

     </section>
    </>
  );
}

export { TenantForm };
