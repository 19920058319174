import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "moment-timezone";
import { IdeaRow } from "./IdeaRow";
import {filter} from "lodash"
import { openIdeasModal} from "./../../redux/toolkit/features/innovation/ideaModalToggle";
//import { ideaActions } from "./../../redux/actions/idea";
import {fetchAllIdeas} from "./../../redux/toolkit/features/ideas";
import {selectIdeaById} from "./../../redux/toolkit/features/ideas";

function Idea(props) {
  const {themeId, checkboxstatus, charts, setIdeaRowID} = props;
  const ideas = useSelector((state) => state.ideas.entities);
  const ideastatus = useSelector((state) => state.ideas.status);
  let RowsSelected = [];
  const [impacttotals, setImpactTotals] = useState(0)
  const [costtotals, setCostTotals] = useState(0)
  const [isAllRowSelected, setisAllRowSelected] = useState(false);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch();
  var ideasfiltered=filter(ideas,{theme:themeId})
  console.log("Ideas Status", ideastatus)

  useEffect(() => {
    async function getIdeas() {
      dispatch(fetchAllIdeas())
      ideasfiltered=filter(ideas,{theme:themeId})
    }
    getIdeas();
  },[dispatch]);

useEffect(() => {
}, [isAllRowSelected]);

  function checkall(event) {
    if (event) {
      let impactsum=0
      let costsum=0
      for (let i = 0; i < ideasfiltered.length; i++) {
        checkboxstatus[i]=true;
        impactsum=impactsum+ideasfiltered[i].impact
        costsum=costsum+ideasfiltered[i].cost
      }
      setImpactTotals(impactsum)
      setCostTotals(costsum)
      setisAllRowSelected(true);
    } else {
      for (let i = 0; i < ideasfiltered.length; i++) {
        checkboxstatus[i]=false;
      }
        setImpactTotals(0)
        setCostTotals(0)
        setisAllRowSelected(false);
    }
  }

  function updateCheckbox(e, index) {
    setisAllRowSelected(false);
    checkboxstatus[index]=e;
    let impactsum=0
    let costsum=0
    for (let i = 0; i < ideasfiltered.length; i++) {
      if (checkboxstatus[i]) {
        impactsum=impactsum+ideasfiltered[i].impact
        costsum=costsum+ideasfiltered[i].cost
      }
    }
    setImpactTotals(impactsum)
    setCostTotals(costsum)
    //filterideas(checkboxstatus)
    charts(checkboxstatus)
  }

//   function calcimpacttotals(value) {
//       setImpactTotals(impacttotals+value)
//   }

//   function calccosttotals(value) {
//     setCostTotals(costtotals+value)
// }
  if (!ideas) {
    return null;
  }

  return (
    <Fragment>

      <div className='pl-5 pr-5 pt-5'>
        <table className="table is-fullwidth">
          <tbody>
            <tr style={{backgroundColor: backgroundcolor, color: textcolor }}>
              <th style={{width:"10px",color: textcolor}} ><input type="checkbox" id="vehicle1" name="vehicle1" value={isAllRowSelected} checked={isAllRowSelected}  onChange={(e) => checkall(e.target.checked)}/></th>
              <th style={{color: textcolor}}>Idea</th>
              <th style={{textAlign: "center", width:"100px", color: textcolor}}>{'Impact '}</th>
              <th style={{textAlign: "center", width:"100px", color: textcolor}}>Cost</th>
              <th style={{textAlign: "center", width:"140px", color: textcolor}}>Action Status</th>
              <th style={{width:"8px", color: textcolor}}></th>
              <th style={{width:"8px", color: textcolor}} ><a style={{color: textcolor}} onClick={() => dispatch(openIdeasModal({ideapk:null,themepk:themeId}))}><button className="fas fa-plus-circle"></button></a></th>
            </tr>
            {ideasfiltered &&
            ideasfiltered.map((idea, index) => (
            <Fragment>
              <IdeaRow 
                index={idea.pk} 
                RowsSelected={RowsSelected} 
                themeModalKey={themeId}
                isAllRowSelected={isAllRowSelected}
                checkboxstatus={checkboxstatus}
                updateCheckbox={updateCheckbox}
                setIdeaRowID={setIdeaRowID}
                themeId={themeId}
              />
            </Fragment>
            ))}
            {ideasfiltered.length!==0 &&
            <tr>
              <td colSpan={2} style={{textAlign: "right", fontWeight:600}}>Selected Totals:</td>
              <td style={{textAlign: "center", fontWeight:600}}>{impacttotals.toLocaleString()}</td>
              <td style={{textAlign: "center", fontWeight:600}}>{costtotals.toLocaleString()}</td>
              <td colSpan={2}></td>
            </tr>}
            {ideasfiltered.length===0 &&
            <tr>
              <td colSpan={6} style={{textAlign: "center", fontWeight:400}}>Click <a style={{color: backgroundcolor}} onClick={() => dispatch(openIdeasModal({ideapk:null,themepk:null}))}><i className="fas fa-plus-circle"/></a> to Add New Idea </td>
            </tr>}

          </tbody>
        </table>
      </div>

    </Fragment>
  );
}

export { Idea };
