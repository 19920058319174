import React, { Fragment, useContext, useState } from "react";
import "../common/css/dot.css";
import { Chart } from "react-google-charts";
import { peopledatasets } from '../OrganizationPage';
import {filter} from "lodash"
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, LineSeries,ColumnSeries, StackingColumnSeries } from '@syncfusion/ej2-react-charts';
import {NumericTextBox} from '@syncfusion/ej2-inputs';

function Home() {
  const data = [
    { country: "USA", gold: 50, silver: 70, bronze: 45 },
    { country: "China", gold: 40, silver: 60, bronze: 55 },
    { country: "Japan", gold: 70, silver: 60, bronze: 50 },
    { country: "Australia", gold: 60, silver: 56, bronze: 40 },
    { country: "France", gold: 50, silver: 45, bronze: 35 },
    { country: "Germany", gold: 40, silver: 30, bronze: 22 },
    { country: "Italy", gold: 40, silver: 35, bronze: 37 },
    { country: "Sweden", gold: 30, silver: 25, bronze: 27 }
];
const primaryxAxis = { valueType: 'Category', title: 'Countries' };
const primaryyAxis = { minimum: 0, maximum: 80, interval: 20, title: 'Medals' };
const legendSettings = { visible: true, position: 'Bottom' };
const marker = { visible: true, width: 10, opacity: 0.6, height: 10 };
return <ChartComponent id='charts' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} legendSettings={legendSettings} title='Olympic Medals'>
  <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, LineSeries, Category]}/>
  <SeriesCollectionDirective>
    <SeriesDirective dataSource={data} xName='country' yName='gold' name="Gold" type='Column'>
    </SeriesDirective>
    <SeriesDirective dataSource={data} xName='country' yName='silver' type='Column'>
    </SeriesDirective>
    <SeriesDirective dataSource={data} xName='country' yName='bronze' name="Headcount" type='Line'>
    </SeriesDirective>
  </SeriesCollectionDirective>
</ChartComponent>;
}




function OrgMetrics() {
  const {defaultbackgroundcolor, defaulttextcolor, lightbackgroundcolor, darkbackgroundcolor, metricsgrowth, rows,chartindex, growth, cagr} = useContext(peopledatasets);
  const [headcountradiostatus, setHeadcountradiostatus] = useState(true)
  const [compensationradiostatus, setCompensationradiostatus] = useState(true)

  function toggleHeadcountRadio(status) {
      setHeadcountradiostatus(status)
  }

  function toggleCompensationRadio(status) {
    setCompensationradiostatus(status)
  }



  function Home() {
    let minYear=2024;
    let maxYear=2026;
    let data = [];
    for (let year = minYear; year <= maxYear; year++) {
      let headcountvalue = filter(metricsgrowth.results, {year: year})
      console.log("MG",metricsgrowth, headcountvalue)
      var obj = {};
      obj['year'] = year;
      for (const row of chartindex.results) {
        let rowname = filter(rows, {pk:row.row_id })
        let growthvalue = filter(growth.results, {row_id:row.row_id, year: year })
        obj[rowname[0].name] = growthvalue[0].growth;
      }
      obj["Headcount"] = (headcountvalue[0]?headcountvalue[0].headcountgrowth:null);
      obj["Compensation"] = (headcountvalue[0]?headcountvalue[0].compensationgrowth:null);
      data.push(obj)
    }
    console.log("HomeData:", data)
  const primaryxAxis = { valueType: 'Category', fontWeight: 'bold'};
  const primaryyAxis = { minimum: 0, title: 'Annual Growth %' , labelStyle: { 
    size: '14px', 
    color: 'green', 
    fontFamily: 'Segoe UI', 
    fontWeight: 'normal' 
  }, };
  const legendSettings = { visible: true, position: 'Bottom' };
  const marker = { visible: true, width: 10, opacity: 0.6, height: 10 };
  return (
    <Fragment>
      <div className="p-5">
        <div classname="pl-5 pr-5 pb-6 mb-6" style={{backgroundColor: "white", fontFamily: "Gotham Extra"}}>
        <div className="level m-0 p-0">
                <div className="level-left">
                  <p className="pl-4" style={{fontSize: "22px"}}>Annual Headcount Growth Rate</p>
                </div>
                <div className="level-right pr-5">
                  <div className="control pl-4 pt-3" style={{fontSize:"12px"}}>
                    <label className="radio">
                      <input className="mr-1" type="radio" name="annual" onClick={() => toggleHeadcountRadio(true)} checked={headcountradiostatus===true}/>
                      ANNUAL
                      </label>
                      <label className="radio">
                      <input className="mr-1" type="radio" name="annual" onClick={() => toggleHeadcountRadio(false)} checked={headcountradiostatus===false}/>
                      CAGR
                    </label>
                  </div>
                </div>
              </div>
  <ChartComponent id='charts' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} legendSettings={legendSettings}>
    <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, LineSeries, Category]}/>
    <SeriesCollectionDirective>
      <SeriesDirective dataSource={data} xName='year' yName='Revenue' name="Revenue (CAD)" type='Column'>
      </SeriesDirective>
      <SeriesDirective dataSource={data} xName='year' yName='EBITDA'  name="Profit (CAD)" type='Column'>
      </SeriesDirective>
      <SeriesDirective dataSource={data} xName='year' yName='Antofagasta, Chile' name="Production oz/au" type='Column'>
      </SeriesDirective>
      <SeriesDirective dataSource={data} xName='year' yName='Headcount' name="Headcount" type='Line' marker={marker}>
      </SeriesDirective>
      <SeriesDirective dataSource={data} xName='year' yName='Compensation' name="Compensation" type='Line' marker={marker}>
      </SeriesDirective>
    </SeriesCollectionDirective>
  </ChartComponent>
  </div>
  </div>
  </Fragment>
  )
  }
  


  function renderTest() {

    var headcountgrowth=[]
    var dataarray=[]
    let minYear=2024
    let maxYear=2026
    dataarray.push({ type: 'string', label: 'Year' })
    dataarray.push({ type: 'number', label: 'Headcount' })
    for (const row of chartindex.results) {
      let rowname = filter(rows, {pk:row.row_id })
      dataarray.push({ type: 'number', label: rowname[0].name })
    }
  
    headcountgrowth.push(dataarray)
  
    for (let year = minYear; year <= maxYear; year++) {
      var rowarray=[]
      rowarray.push(year)   
      let headcountvalue = filter(metricsgrowth.results, {year: year})
      if (headcountradiostatus) {
        rowarray.push(headcountvalue[0]?headcountvalue[0].headcountgrowth:null)   
      } else {
        rowarray.push(headcountvalue[0]?headcountvalue[0].totalheadcountcagr:null)   
      }
      for (const row of chartindex.results) {
        if (headcountradiostatus) {
          let growthvalue = filter(growth.results, {row_id:row.row_id, year: year })
          rowarray.push(growthvalue[0].growth)
        } else {
          let growthvalue = filter(cagr.results, {row_id:row.row_id, year: year })
          rowarray.push(growthvalue[0].growth)
        }
      }
      headcountgrowth.push(rowarray)
      }

      const data = [
        { x: '2005', y: 1.2, y1: 0.5, y2: 0.7, y3: -0.8, y4: 1.5 },
        { x: '2006', y: 1, y1: 0.5, y2: 1.4, y3: 0, y4: 2.3 },
        { x: '2007', y: 1, y1: 0.5, y2: 1.5, y3: -1, y4: 2 },
        { x: '2008', y: 0.25, y1: 0.35, y2: 0.35, y3: -.35, y4: 0.1 },
        { x: '2009', y: 0.1, y1: 0.9, y2: -2.7, y3: -0.3, y4: -2.7 },
        { x: '2010', y: 1, y1: 0.5, y2: 0.5, y3: -0.5, y4: 1.8 },
        { x: '2011', y: 0.1, y1: 0.25, y2: 0.25, y3: 0, y4: 2 },
        { x: '2012', y: -0.25, y1: -0.5, y2: -0.1, y3: -0.4, y4: 0.4 },
        { x: '2013', y: 0.25, y1: 0.5, y2: -0.3, y3: 0, y4: 0.9 },
        { x: '2014', y: 0.6, y1: 0.6, y2: -0.6, y3: -0.6, y4: 0.4 },
        { x: '2015', y: 0.9, y1: 0.5, y2: 0, y3: -0.3, y4: 1.3 }
    ];
    const primaryxAxis = { title: 'Years', interval: 1, labelIntersectAction: 'Rotate45', valueType: 'Category' };
    const primaryyAxis = { title: 'Growth', minimum: -3, maximum: 3, interval: 1 };
    const marker = { visible: true, width: 10, opacity: 0.6, height: 10 };
  
    return <ChartComponent id='charts1' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} title='Annual Growth GDP in France'>
      <Inject services={[StackingColumnSeries, LineSeries, Legend, Tooltip, DataLabel, Category]}/>
      <SeriesCollectionDirective>
        <SeriesDirective dataSource={data} xName='x' yName='y' name='Private Consumption' type='StackingColumn'>
        </SeriesDirective>
        <SeriesDirective dataSource={data} xName='x' yName='y1' name='Government Consumption' type='StackingColumn'>
        </SeriesDirective>
        <SeriesDirective dataSource={data} xName='x' yName='y2' name='Investment' type='Column'>
        </SeriesDirective>
        <SeriesDirective dataSource={data} xName='x' yName='y3' name='Net Foreign Trade' type='Column'>
        </SeriesDirective>
        <SeriesDirective dataSource={data} xName='x' yName='y4' name='GDP' type='Line' opacity={0.6} marker={marker}>
        </SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>;
  }

  function renderHeadcountChart() {
    var headcountgrowth=[]
    var dataarray=[]
    let minYear=2024
    let maxYear=2026
    dataarray.push({ type: 'string', label: 'Year' })
    dataarray.push({ type: 'number', label: 'Headcount' })
    for (const row of chartindex.results) {
      let rowname = filter(rows, {pk:row.row_id })
      dataarray.push({ type: 'number', label: rowname[0].name })
    }
  
    headcountgrowth.push(dataarray)
  
    for (let year = minYear; year <= maxYear; year++) {
      var rowarray=[]
      rowarray.push(year)   
      let headcountvalue = filter(metricsgrowth.results, {year: year})
      if (headcountradiostatus) {
        rowarray.push(headcountvalue[0]?headcountvalue[0].headcountgrowth:null)   
      } else {
        rowarray.push(headcountvalue[0]?headcountvalue[0].totalheadcountcagr:null)   
      }
      for (const row of chartindex.results) {
        if (headcountradiostatus) {
          let growthvalue = filter(growth.results, {row_id:row.row_id, year: year })
          rowarray.push(growthvalue[0].growth)
        } else {
          let growthvalue = filter(cagr.results, {row_id:row.row_id, year: year })
          rowarray.push(growthvalue[0].growth)
        }
      }
      headcountgrowth.push(rowarray)
      }



    return (
      <Fragment>

        <div className="p-5">
          <div classname="pl-5 pr-5 pb-6 mb-6" style={{backgroundColor: "white", fontFamily: "Gotham Extra"}}>
            <div className="level m-0 p-0">
                <div className="level-left">
                  <p className="pl-4" style={{fontSize: "22px"}}>Headcount Growth</p>
                </div>
                <div className="level-right pr-5">
                  <div className="control pl-4 pt-3" style={{fontSize:"12px"}}>
                    <label className="radio">
                      <input className="mr-1" type="radio" name="annual" onClick={() => toggleHeadcountRadio(true)} checked={headcountradiostatus===true}/>
                      ANNUAL
                      </label>
                      <label className="radio">
                      <input className="mr-1" type="radio" name="annual" onClick={() => toggleHeadcountRadio(false)} checked={headcountradiostatus===false}/>
                      CAGR
                    </label>
                  </div>
                </div>
              </div>
            <div className="ml-4 mr-4 mb-6 p-4" style={{border: "solid 1px grey"}}>
              <Chart
                width={'100%'}
                height={'400px'}
                chartType="ComboChart"
                loader={<div>Loading Chart</div>}
                data={headcountgrowth}
        
                options={{
                    seriesType: "line",
                    pointSize: 20,
                    series: {0: {type: "line"}, 1: {type: "bars"},2: {type: "bars"},3: {type: "bars"}},
                    legend: { position: 'bottom', textStyle: {fontSize: 12}},
                    vAxis: { title: 'Annual % Change', format:'#%' }

                }}
                rootProps={{ 'data-testid': '1' }}
                />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  
 


  return (
    <Fragment>
  <section style={{backgroundColor: "rgb(242,242,242)", fontFamily: "Gotham Extra"}}>
        <div className="level m-0 p-3" style={{backgroundColor: defaultbackgroundcolor}}>
            <div className="level-left">
                <p className="pl-4" style={{fontSize: "20px", fontWeight: "600", color: defaulttextcolor}}>PEOPLE ANALYTICS</p> 
            </div>
            <div className="level-right">
            <button className="mr-4 is-right"><i class="fas fa-link"></i></button>
            </div>
        </div>
  {Home()}
  {renderTest()}

  
        </section>
        </Fragment>
    )
}

export { OrgMetrics };