import React, { Fragment, useState, useEffect } from "react";
import {Theme} from "../innovation/Theme";
import {themesService} from "../../services/ThemesService"
import {filter} from "lodash"


function ThemesList(props) {
    const {linkedthemesfiltered} = props; 
    const [themes,setThemes] = useState(null);

    async function fetchThemes() {
        const themes = await themesService.getTheme();
        setThemes(themes);    
    }

    useEffect(() => {
        async function fetchData() {
            await fetchThemes();
        }
        fetchData();
    }, []);

    if (!themes) {
        return null
    }

    const rowIds = linkedthemesfiltered.map(r => r.theme_id);
    const filteredthemes = filter( themes, (row) => rowIds.includes(row.pk));

        return (
            <Fragment>
            <table className="table m-0 is-fullwidth">
                <tbody>
                {filteredthemes.map(row => (
                        <Fragment>
                            <Theme 
                                theme={row.theme} 
                                description = {row.description} 
                            />
                        </Fragment>
                    ))}

                </tbody>
            </table>
            </Fragment>
          )
}

export { ThemesList };