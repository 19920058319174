export const timelinesConstants = {
    GET_TIMELINES_REQUEST: 'GET_TIMELINES_REQUEST',
    GET_TIMELINES_SUCCESS: 'GET_TIMELINES_SUCCESS',
    GET_TIMELINES_FAILURE: 'GET_TIMELINES_FAILURE',

    POST_TIMELINES_REQUEST: 'POST_TIMELINES_REQUEST',
    POST_TIMELINES_SUCCESS: 'POST_TIMELINES_SUCCESS',
    POST_TIMELINES_FAILURE: 'POST_TIMELINES_FAILURE',

    DELETE_TIMELINES_REQUEST: 'DELETE_TIMELINES_REQUEST',
    DELETE_TIMELINES_SUCCESS: 'DELETE_TIMELINES_SUCCESS',
    DELETE_TIMELINES_FAILURE: 'DELETE_TIMELINES_FAILURE',

    PATCH_TIMELINES_REQUEST: 'PATCH_TIMELINES_REQUEST',
    PATCH_TIMELINES_SUCCESS: 'PATCH_TIMELINES_SUCCESS',
    PATCH_TIMELINES_FAILURE: 'PATCH_TIMELINES_FAILURE',
};
