import { React, Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closePeopleModal } from "./../../../redux/toolkit/features/people/peopleModal";
import { filter } from "lodash"
import { peopleActions } from "./../../../redux/actions/people";

function PeoplesModal() {
    const showModalPeople = useSelector((state) => state.peopleModal.isOpen)
    const peoplerecordkey = useSelector((state) => state.peopleModal.peoplepk)
    const people = useSelector((state) => state.people.people);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [firstname, setFirstname] = useState(null);
    const [secondname, setSecondname] = useState(null);
    const dispatch = useDispatch(); 

    let modalClass = "modal";
    if (showModalPeople) {
        modalClass += " is-active";
    }


    useEffect(() => {
        async function getPeople() {
          dispatch(peopleActions.getAll());
        }
        getPeople();
      },[closePeopleModal,showModalPeople]);

      useEffect(() => {
        setFirstname(firstnamevalue);
        setSecondname(secondnamevalue);
      },[peoplerecordkey, people]);


    let peoplearray=filter(people, {pk: peoplerecordkey});
    let firstnamevalue= (peoplearray[0]?.firstname || "")
    let secondnamevalue= (peoplearray[0]?.secondname || "")   

    function handleChangeCompetency(event) {
        setFirstname(event.target.value);
    }

    function handleChangeDescription(event) {
        setSecondname(event.target.value);
    }

    if (!people) {
        return null;
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">First Name</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="1"
                                //placeholder={placeholdertext}
                                value={firstname}
                                onChange={handleChangeCompetency}
                                />
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal is-align-items-center">
                    <label className="label field-label">Second Name</label>
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            <textarea 
                                className="textarea"
                                rows="4"
                                //placeholder={placeholdertext}
                                value={secondname}
                                onChange={handleChangeDescription}
                                />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        { peoplerecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>EDIT EMPLOYEE</p>}
                        { !peoplerecordkey &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>NEW EMPLOYEE</p>}

                        <button className="delete" aria-label="close" onClick={() => dispatch(closePeopleModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
                    {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {peoplerecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(peopleActions.post({firstname: firstname, secondname: secondname }));
                                dispatch(peopleActions.getAll());
                                dispatch(closePeopleModal())
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {peoplerecordkey !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(peopleActions.patch(peoplerecordkey,{firstname: firstname, secondname: secondname }));
                                dispatch(closePeopleModal())
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closePeopleModal())}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default PeoplesModal;
