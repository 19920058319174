import {roadmapsConstants} from '../constants/roadmaps';

const initialState = {
    loading: false,
    cellgrowth: [],
    error: null,
}
 
  export function cellgrowth(state = initialState, action) {
    switch (action.type) {
      case roadmapsConstants.GET_CELL_GROWTH_REQUEST:
        return {
          ...initialState,
          loading: true,
        };
      case roadmapsConstants.GET_CELL_GROWTH_SUCCESS:
        return {
          ...initialState,
          cellgrowth: action.cellgrowth,
        };
      case roadmapsConstants.GET_CELL_GROWTH_FAILURE:
        return { 
          ...initialState,
          error: action.error,
        };
      default:
        return state
    }
  }