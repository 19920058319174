import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllMatrices = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/matrices`,
    authConfig()
  );
  return response.data;
};

export const fetchMatrix = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/matrices/${id}`,
    authConfig()
  );
  return response.data;
};

export const createMatrix = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/matrices/`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateMatrix = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/matrices/${data.id}/`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteMatrix = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/matrices/${id}`,
    authConfig()
  );
  return response.data;
};

const matricesAPI = {
  fetchAllMatrices,
  fetchMatrix,
  createMatrix,
  updateMatrix,
  deleteMatrix,
};
export default matricesAPI;
