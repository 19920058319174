import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class SponsorService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/sponsors/`;
    }

    async getSponsors() {
        const sponsors = await this.getAll();
        return sponsors;
    }
}

export const sponsorService = new SponsorService();