import { menuConstants } from '../constants/menu';

export function menu(state = { active: "company" }, action) {
  switch (action.type) {
    case menuConstants.SET_ACTIVE_MENU:
      return {
        active: action.menu,
      };
    default:
      return state
  }
}