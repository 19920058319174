import {titleModalConstants} from "../constants/titleModal";
const initialState = {
    isOpen: false,
    isCreatingNew: false,
}

function processOpenModalAction(state, action) {
    console.log("processOpenPositionModalAction");
    return {
        ...state,
        isOpen: true,
        isCreatingNew: false,
    };
}

function processCloseModalAction(state, action) {
    console.log("processClosePositionModalAction");
    return {
        ...state,
        isOpen: false,
        isCreatingNew: false,
    };
}

function processOpenModalCreateNew(state, action) {
    console.log("processOpenModalCreateNew");
    return {
        ...state,
        isCreatingNew: true,
    };
}

function processOpenModalCancelCreateNew(state, action) {
    console.log("processOpenModalCancelCreateNew");
    return {
        ...state,
        isCreatingNew: false,
    };
}

function processOpenModalSaveTitleSuccess(state, action) {
    console.log("processOpenModalSaveTitleSuccess");
    return {
        ...state,
        isCreatingNew: false,
    };
}

export function titleModal(state = initialState, action) {
    switch (action.type) {
        case titleModalConstants.OPEN_MODAL:
            return processOpenModalAction(state, action);
        case titleModalConstants.CLOSE_MODAL:
            return processCloseModalAction(state, action);

        case titleModalConstants.CREATE_NEW:
            return processOpenModalCreateNew(state, action);
        case titleModalConstants.CANCEL_CREATE_NEW:
            return processOpenModalCancelCreateNew(state, action);

        case titleModalConstants.SAVE_TITLE_SUCCESS:
            return processOpenModalSaveTitleSuccess(state, action);

        default:
            return state;
    }
}
