import { companyConstants } from '../constants/company';

const initialState = {
  loading: false,
  company: {},
  error: null,
}

export function company(state = initialState, action) {
  switch (action.type) {
    case companyConstants.GET_COMPANY_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case companyConstants.GET_COMPANY_SUCCESS:
      return {
        ...initialState,
        company: action.company,
      };
    case companyConstants.GET_COMPANY_FAILURE:
      return { 
        ...initialState,
        error: action.error,
      };
    default:
      return state
  }
}