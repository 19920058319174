import React, {Fragment, useState, useEffect} from "react";
import "bulma/css/bulma.css";
import {filter} from "lodash"
//import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'
import { factsActions } from "./../../../redux/actions/facts"
import { openFactsModal } from "./../../../redux/toolkit/features/company/factsModal";
import { closeFactsRecordModal } from "./../../../redux/toolkit/features/company/factsRecordModal";

function CompanyFactsRecordModal() {
    const tenant = useSelector((state) => state.tenants.currentTenant);
    const facts = useSelector((state) => state.facts.facts);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showFactsRecordModal = useSelector((state) => state.factsRecordModal.isOpen);
    const factsrecordkey = useSelector((state) => state.factsRecordModal.factpk);
    //const {reset} = useForm();
    const dispatch = useDispatch();

    let factsarray=filter(facts, {pk: factsrecordkey});
    let factsfact= (factsarray[0]?.fact || "")
    const [fact, setFact] = useState(factsfact);

    useEffect(() => {
        async function getFacts() {
          dispatch(factsActions.getAll());
        }
        getFacts();
      },[]);

      useEffect(() => {
        setFact(factsfact)
      }, [factsfact])


     let modalClass = "modal";
      if (showFactsRecordModal) {
          modalClass += " is-active";
      }

    var modaltitle='';
    var placeholdertext='';
    if (factsrecordkey===null) {
        modaltitle="NEW COMPANY FACT"
        placeholdertext="Company Fact"
    } else {
        modaltitle="COMPANY FACT"
    }

    function handleChangeFact(event) {
        setFact(event.target.value);
    }



    if(!tenant || !facts) {
        return null;
    }
 
    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">
                            {fact !== undefined &&
                            <textarea 
                                className="textarea"
                                rows="6"
                                placeholder={placeholdertext}
                                value={fact}
                                onChange={handleChangeFact}
                                />}
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => {
                                dispatch(closeFactsRecordModal());
                                dispatch(openFactsModal());
                        }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    {factsrecordkey === null &&
                    <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(factsActions.post({fact: fact}));
                                dispatch(closeFactsRecordModal());
                                dispatch(openFactsModal());
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}

                        {factsrecordkey !== null &&
                        <Fragment>
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(factsActions.patch(factsrecordkey,{fact: fact}));
                                setFact("")
                                dispatch(closeFactsRecordModal());
                                dispatch(openFactsModal());
                            }}
                        >
                            Save Changes
                        </button>
                        </Fragment>}
                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                            onClick={() => {
                                dispatch(closeFactsRecordModal());
                                dispatch(openFactsModal());
                                }}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default CompanyFactsRecordModal;
