import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, timelinepk: null };

const modalSlice = createSlice({
  name: "timelineRecordModal",
  initialState,
  reducers: {
    openTimelineRecordModal(state, action) {
      state.isOpen = true;
      state.timelinepk = action.payload;
    },
    closeTimelineRecordModal(state) {
      state.isOpen = false;
      state.timelinepk = null;
    },
  },
});

export const {
  openTimelineRecordModal,
  closeTimelineRecordModal,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.timelinesRecordModal;

export const selectTimelineRecordModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectTimelineRecordModalFactpk = createSelector(
  selectState,
  (state) => state.timelinepk
);
