import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  isCreatingNew: true,
  matrix: null,
  stakeholderId: null,
  categoryId: null,
};

const modalSlice = createSlice({
  name: "matrixModal",
  initialState,
  reducers: {
    openForNew(state, action) {
      console.log("openForNew", action);
      state.isOpen = true;
      state.isCreatingNew = true;
      state.matrix = null;
      state.categoryId = action.payload.categoryId;
      state.stakeholderId = action.payload.stakeholderId;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditing(state, action) {
      console.log("openForEditing", action);
      state.isOpen = true;
      state.isCreatingNew = false;
      state.matrix = action.payload.matrixValue;
      state.categoryId = action.payload.categoryId;
      state.stakeholderId = action.payload.stakeholderId;
    },
  },
});

export const { openForNew, close, openForEditing } = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.matrixModal;

export const selectMatrixModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectMatrixModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectMatrixModalMatrix = createSelector(
  selectState,
  (state) => state.matrix
);

export const selectMatrixModalCategoryId = createSelector(
  selectState,
  (state) => state.categoryId
);

export const selectMatrixModalStakeholderId = createSelector(
  selectState,
  (state) => state.stakeholderId
);
