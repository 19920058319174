import React, {Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeRoadmapTimelineModal } from "../../../redux/toolkit/features/roadmaps/timelineDetailModal";
import { closeRoadmapRowModal } from "../../../redux/toolkit/features/roadmaps/rowDetailModal";


function SectionsModalEditRows(props) {
    const {toggleSectionsRowModal, onRowDataChanged, tablerowname, tablerowtype, tablerowindent, tablerownotes, tablerowsubrow, tablerowdecimalplaces} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showSectionsRowModal = useSelector((state) => state.roadmapRowModal.isOpen)
    const rowpk = useSelector((state) => state.roadmapRowModal.rowpk)
    const [name, setName] = useState(null);
    const [type, setType] = useState(null);
    const [indent, setIndent] = useState(null);
    const [notes, setNotes] = useState(null);
    const [subrow, setSubrow] = useState(null);
    const [decimalplaces, setDecimalplaces] = useState(0);
    const dispatch = useDispatch();

    function handleChangeTitle(event) {
        setName(event.target.value);
    }

    function handleChangeRowtype(event) {
        setType(event.target.value);
    }

    function handleChangeIndent(event) {
        setIndent(event.target.value);
    }


    function handleChangeNotes(event) {
        setNotes(event.target.value);
    }

    function handleChangeDecimalplaces(event) {
        setDecimalplaces(event.target.value);
    }

    function handleChangeSubrow(event) {
        setSubrow(event.target.value);
    }

    let modalClass = "modal";
    if (showSectionsRowModal) {
        modalClass += " is-active";
    }

    useEffect(() => {
      }, [rowpk])

    useEffect(() => {
        setName(tablerowname!==null?tablerowname:'')
      }, [tablerowname])


    useEffect(() => {
        setNotes(tablerownotes!==null?tablerownotes:'')
      }, [tablerownotes])

    useEffect(() => {
        setType(tablerowtype!==null?tablerowtype:'test')
      }, [tablerowtype])

    useEffect(() => {
        setIndent(tablerowindent!==null?tablerowindent:false)
      }, [tablerowindent])

      useEffect(() => {
        setDecimalplaces(tablerowdecimalplaces!==null && tablerowtype==='Number'?tablerowdecimalplaces:0)
      }, [tablerowdecimalplaces])

    useEffect(() => {
        setSubrow(tablerowsubrow)
      }, [tablerowsubrow])



    function modalform() {

        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Title</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    {name !== undefined &&
                    <input
                        className="input"
                        type="text"
                        placeholder="Roadmap Title"
                        value={name}
                        onChange={handleChangeTitle}
                    />}
                </div>
            </div>
        </div>

        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Notes</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    {notes !== undefined &&
                    <textarea
                        className="textarea"
                        rows='5'
                        placeholder="Notes"
                        value={notes}
                        onChange={handleChangeNotes}
                    />}
                </div>
            </div>
        </div>

        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Type</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" value={type} onChange={handleChangeRowtype}>
                            <option>Text</option>   
                            <option>Number</option>
                            <option>Percent</option>                            
                            <option>Header</option>   
                            {/* <option>Formula</option> */}

      
                        </select>
                    </div>
                </div>
            </div>
        </div>

        {type === 'Number' &&
        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Decimal Places</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" value={decimalplaces} onChange={handleChangeDecimalplaces}>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option> 
                            <option value={5}>5</option>                
                        </select>
                    </div>
                </div>
            </div>
        </div>}

        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Indented</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                {indent!==undefined &&
                <input type="checkbox" style={{transform: "scale(1.5)",marginLeft:"4px"}} value={indent} checked={indent} onChange={handleChangeIndent}/>}
                </div>
            </div>
        </div>

      

        </Fragment>
        )
    }

    if (name === null || notes === null || type === null || decimalplaces === null || indent === null) {
        return null
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>ROW DETAILS</p>
                    <button className="delete" aria-label="close" onClick={() => dispatch(closeRoadmapRowModal())}>
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody" style={{minHeight: "330px"}}>
                  {modalform()}





                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter">
                    <button
                        className="button is-success"
                        key="save"
                        onClick={async () => {
                            await onRowDataChanged(rowpk, name, type, indent, decimalplaces, rowpk, notes);
                            dispatch(closeRoadmapRowModal());
                        }}
                    >
                        Save changes
                    </button>
                    <button className="button" key="cancel" onClick={() => dispatch(closeRoadmapRowModal())}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default SectionsModalEditRows;
