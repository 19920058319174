import React from "react";


function FormatString(cellString, type, decimalplaces) {
    let cellValue = null
    if (cellString !== null) {
            cellValue = cellString.toString().replace(/,/g,"") 
    } 

    let result=""
    switch(type) {
        case null:
            return cellString
        case 'Number':
            if (decimalplaces !== 0 || decimalplaces !== null) {
                result= Number(Number(cellValue).toFixed(decimalplaces)).toLocaleString("en-US", { maximumFractionDigits: decimalplaces, minimumFractionDigits: decimalplaces });
                return result 
            } else {
                result=Number((Math.round(cellValue * 100) / 100).toFixed(0)).toLocaleString('en') 
                return result 
            }
        case 'Percentage':
            result= Number(cellValue).toFixed(decimalplaces).toLocaleString("en-US", { maximumFractionDigits: decimalplaces, minimumFractionDigits: decimalplaces })+"%";
            return result 
        //default: 
            //return cellValue.replace( /^[-+]?[0-9]+\.[^0-9]+$/, '')
    }
}


export { FormatString }; 
