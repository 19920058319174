import {roadmapsConstants} from '../constants/roadmaps';

const initialState = {
    loading: false,
    cellcagr: [],
    error: null,
}
 
  export function cellcagr(state = initialState, action) {
    switch (action.type) {
      case roadmapsConstants.GET_CELL_CAGR_REQUEST:
        return {
          ...initialState,
          loading: true,
        };
      case roadmapsConstants.GET_CELL_CAGR_SUCCESS:
        console.log("CAGR Case")
        return {
          ...initialState,
          cellcagr: action.cellcagr,
        };
      case roadmapsConstants.GET_CELL_CAGR_FAILURE:
        return { 
          ...initialState,
          error: action.error,
        };
      default:
        return state
    }
  }