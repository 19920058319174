import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TalentRoleCompetenciesService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/talent/rolecompetencies`;
  }

  async getTalentRoleCompetencies() {
    const rolecompetencies = await this.getAll();
    return rolecompetencies;
  }
}

export const talentrolecompetenciesService = new TalentRoleCompetenciesService();
