import React, {Fragment} from "react";
import CurrencyFormat from 'react-currency-format';
import {useDispatch} from "react-redux";
import {positionModalActions} from "../../redux/actions/positionModal";
import Moment from "react-moment";
import {filter} from "lodash"

function OrgTableRow(props) {
    const {year, data} = props;
    const dispatch = useDispatch();
    //console.log((new Date(data[0].role_startdate).getFullYear()), data[0].role_startdate, year)
    let newroles = filter(data, datarow =>  ((new Date(datarow.role_startdate)).getFullYear() === year || (new Date(datarow.role_enddate)).getFullYear() === year));
    let cellNumber = 0;

    function openPositionModal(cell) {
        dispatch(positionModalActions.openModal(cell));
    }

    function renderCellValue(cell) {
        cellNumber++; 
        let startdate = new Date(cell.role_startdate)
        let enddate = new Date(cell.role_enddate)
        let totalcompensation = cell.compensationbase + cell.compensationsti + cell.compensationlti + cell.compensationbenefits
        if (!cell) {
            return <td key={cellNumber}> </td>;
        }
        let tag = null;
        if (cell.status === "Approved") {
            tag = <span className="is-pulled-right"><input type="checkbox" style={{backgroundColor: "lightgreen", color: 'white'}} checked /></span>;
        } else if (cell.status === "Pending") {
            tag = <span className="is-pulled-right"><input type="checkbox"/></span>;
        }

        if (new Date(startdate).getFullYear() === year) {
            return (
                <table className = "table is-full-width ml-0 mb-0" style={{border: "none", backgroundColor:"transparent"}}>
                <tr key={props.data.pk}> 
                <td style={{border: "none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} key={cellNumber}>
                    <a style={{color: "black", fontWeight:"600", fontSize: "13px"}} onClick={() => openPositionModal(cell)}>{cell.role}</a> {tag}
                    <br />
                    <small>Start Date: <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">{startdate}</Moment></small>
                    <br />
                    <small>Total Compensation: <CurrencyFormat value={totalcompensation} displayType={'text'} thousandSeparator={true} prefix={'$'} /> {cell.compensation_currency} </small>
                </td>
                </tr>
                </table>
            );
        }

        if (new Date(enddate).getFullYear() === year) {
            return (
                <table className = "table is-full-width ml-0 mb-0" style={{border: "none", backgroundColor: "transparent"}}>
                <tr key={props.data.pk}> 
                <td style={{border: "none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} key={cellNumber}>
                    <a style={{color: "black", fontWeight:"600", fontSize: "13px"}} onClick={() => openPositionModal(cell)}>{cell.role}</a> {tag}
                    <br />
                    <small>End Date: <Moment format="MMMM DD, YYYY" tz="America/Los_Angeles">{enddate}</Moment></small>
                    <br />
                    <small>Total Compensation: {"("}<CurrencyFormat value={totalcompensation} displayType={'text'} thousandSeparator={true} prefix={'$'} />{")"} {cell.compensation_currency} </small>
                </td>
                </tr>
                </table>
            );
        }
    }

    return (

        <Fragment>
            {newroles &&
                      newroles.map((row) => (
                    renderCellValue(row)
                      ))}
             </Fragment>
    );
}

export {OrgTableRow};
