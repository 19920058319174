import React, {Fragment, useState, useEffect} from 'react';
import 'bulma/css/bulma.css';
import { SketchPicker } from "react-color";


function ColorModal(props) {
    const {toggleModal, showModal} = props;
    const [sketchPickerColor, setSketchPickerColor] = useState({
        r: "241",
        g: "112",
        b: "19",
        a: "1",
      });

    let modalClass = 'modal';
    if (showModal) {
        modalClass += ' is-active';
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card m-0 p-0' key='modalCard' style={{width:"420px"}}>
                <header className="modal-card-head" key="modalHeader">
                        <p className="modal-card-title">Color Picker</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                   <section className='modal-card-body m-0 p-1' style={{overflow: "hidden"}} key='modalBody'>
                   <SketchPicker
                   className="p-0 m-0"
                   disableAlpha={true}
                   width="100%"
                    onChange={(color) => {
                        setSketchPickerColor(color.rgb);
                     }}
                     color={sketchPickerColor}/>

                   </section>
                    <footer className='modal-card-foot is-flex' style={{height: "50px"}}key='modalFooter'>
                        <nav className='level'>
                            <div className='level-right'>
                                <div className='level-item'>
                                    <button
                                        className='button is-small is-info'
                                        key='save'
                                        onClick={async () => {

                                        }}
                                    >
                                        Update
                                    </button>
                                </div>
                                <div className='level-item'>
                                    <button className='button is-small' key='cancel' onClick={() => toggleModal(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default ColorModal;
