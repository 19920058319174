import React, { Fragment, useEffect, useState } from "react";
//import "bulma/css/bulma.css";
import {filter} from "lodash"

function MatrixModal(props) {
  const{defaulttextcolor,defaultbackgroundcolor, toggleModal, isMatrixModalOpen,  isCompanyModalOpen, matrices, companymatrix, stakeholdermodalkey, categorymodalkey, onMatrixChange, onCompanyChange} = props;
  
  let matrix=[];
  if(!isCompanyModalOpen) {
    matrix=filter(matrices,{category: categorymodalkey, stakeholder:stakeholdermodalkey})
  } else {
    matrix=filter(companymatrix,{category: categorymodalkey})
  }

  const [importance, setImportance] = useState(0);
  const [description, setDescription] = useState("");
  const importancevalue = matrix[0]?matrix[0].importance:0;
  const descriptionvalue = matrix[0]?matrix[0].description:'';

  useEffect(() => {
    setImportance(importancevalue);
    setDescription(descriptionvalue)
  }, [matrices, importancevalue, descriptionvalue, isCompanyModalOpen]);

 
  let modalClass = "modal";
  if (isMatrixModalOpen) {
    modalClass += " is-active";
  }


  function renderContent() {
    return (
      <Fragment>

        <p className="pb-2 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>{isCompanyModalOpen?'Company Importance Score:': 'Stakeholder Importance Score:'}</p>
        <input 
          type="range" 
          min="1" 
          max="100" 
          value={importance} 
          className="slider p-0 m-0" 
          id="myRange" 
          style={{height: '15px', backgroundColor:defaultbackgroundcolor, width:'100%'}} 
          onChange={e => setImportance(e.target.value)}/>
          <div className="columns pt-0 pb-3 m-0" style={{fontSize:"11px", userSelect: 'none'}}>
            <div className="column is-one-half pt-0 pl-1 m-0">Not Important</div>
            <div className="column is-one-half has-text-right pt-0 m-0">Extremely Important</div>
          </div>

          <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>{isCompanyModalOpen?'Comments:': 'Emerging Trends:'}</p>
          <textarea
            className="textarea"
            type="textarea"
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
      </Fragment>
    );
  }

  return (
    <div className={modalClass} key="matrixModal">
      <div className="modal-background" key="modalBackground">
        {" "}
      </div>
      <div className="modal-card" key="modalCard" style={{width:'500px'}}>
        <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: defaultbackgroundcolor}}>
          <p className="modal-card-title" style={{fontSize:"17px", color: defaulttextcolor}}>{isCompanyModalOpen?'SET COMPANY IMPORTANCE':'SET STAKEHOLDER IMPORTANCE'}</p>
          <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
            {" "}
          </button>
        </header>
        <section className="modal-card-body" key="modalBody">
          {renderContent()}
        </section>
        <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

          {!isCompanyModalOpen &&
          <Fragment>
          <button
            className="button is-success is-small"
            style={{borderRadius:"4px"}}
            key="save"
            
            onClick={async () => {
              await onMatrixChange(matrix[0]?matrix[0].pk:null,importance, description, stakeholdermodalkey, categorymodalkey);
              toggleModal(false);
          }}
          >
            Save changes
          </button>
          </Fragment>
          }

          {isCompanyModalOpen &&
          <Fragment>
          <button
            className="button is-success is-small"
            style={{borderRadius:"4px"}}
            key="save"
            
            onClick={async () => {
              await onCompanyChange(matrix[0]?matrix[0].pk:null,importance, description, categorymodalkey);
              toggleModal(false);
          }}
          >
            Save changes
          </button>
          </Fragment>
          }




          <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => toggleModal(false)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default MatrixModal;
