import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import "bulma/css/bulma.css";

function CompanyPurposeModal(props) {
  const {toggleModal, showModal, onDataChanged} = props;
  const [purpose, setPurpose] = useState(props.purpose);
  const [statement, setStatement] = useState(props.statement);
  const tenant = useSelector((state) => state.tenants.currentTenant);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  function handleChangePurpose(event) {
    setPurpose(event.target.value);
  }

  function handleChangeStatement(event) {
    setStatement(event.target.value);
  }

  let modalClass = "modal";
  if (showModal) {
    modalClass += " is-active";
  }

  function renderForm() {
    return (
        <Fragment>
          <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Story Title</label>
            <div className="field-body is-flex">
              <div className="control is-flex-grow-1">
                <input
                    className="input"
                    type="text"
                    placeholder="Title"
                    value={purpose}
                    onChange={handleChangePurpose}
                />
              </div>
            </div>
          </div>

          <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Story</label>
            <div className="field-body is-flex">
              <div className="control is-flex-grow-1">
              <textarea
                  className="textarea"
                  rows="11"
                  placeholder="Story"
                  value={statement}
                  onChange={handleChangeStatement}
              />
              </div>
            </div>
          </div>
        </Fragment>
    );
  }

  return (
      <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
          <div className="modal-background" key="modalBackground">
            {" "}
          </div>
          <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
              <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>COMPANY STORY</p>
              <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                {" "}
              </button>
            </header>
            <section className="modal-card-body" key="modalBody" style={{minHeight: '200px'}}>
              {renderForm()}
            </section>
            <footer className="modal-card-foot is-flex" key="modalFooter"  style={{height:"60px"}}>
              <button
                  style={{borderRadius:"4px"}}
                  className="button is-success is-small"
                  key="save"
                  onClick={async () => {
                    await onDataChanged(purpose, statement);
                    toggleModal(false);
                  }}
              >
                Save changes
              </button>
              <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => toggleModal(false)}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      </Fragment>
  );
}

export default CompanyPurposeModal;
