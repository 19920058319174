import "regenerator-runtime/runtime.js";
import axios from "axios";
import { authConfig } from "./helpers";
import BaseService from "./BaseService";

class ChartService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-chart/`;
    }

    async getChart() {
        const chart = await this.getAll();
        return chart.results;
    }

    async getChartID(id, data) {
        const url = this.detailUrl(id);
        const response = await axios.post(url, data, authConfig());
        return response.data;
      }
}


export const chartService = new ChartService();
