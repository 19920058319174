import React from 'react';
//import '../common/css/masterstyles.css';
import Header from './Header';
import Row from './Row';

function Matrix(props) {
  const { stakeholders, categories, matrices, companymatrix, toggleModal, toggleCategoryModal, toggleStakeholderModal, toggleCompanyModal } = props;

  return (
    <table className='table is-fullwidth is-hoverable is-bordered'>
      <Header 
        stakeholders={stakeholders} 
        toggleStakeholderModal={toggleStakeholderModal}
      />


      {categories.map((row) => (
        <Row
          category={row}
          stakeholders={stakeholders}
          matrices={matrices}
          companymatrix={companymatrix}
          toggleModal={toggleModal}
          toggleCategoryModal={toggleCategoryModal}
          toggleCompanyModal={toggleCompanyModal}
        />
      ))}
    </table>
  );
}

export default Matrix;
