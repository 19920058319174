export const organizationConstants = {
    GET_ORG_TABLE_REQUEST: 'GET_ORG_TABLE_REQUEST',
    GET_ORG_TABLE_SUCCESS: 'GET_ORG_TABLE_SUCCESS',
    GET_ORG_TABLE_FAILURE: 'GET_ORG_TABLE_FAILURE',

    GET_ORG_CHART_REQUEST: 'GET_ORG_CHART_REQUEST',
    GET_ORG_CHART_SUCCESS: 'GET_ORG_CHART_SUCCESS',
    GET_ORG_CHART_FAILURE: 'GET_ORG_CHART_FAILURE',

    GET_ORGANIZATION_REQUEST: 'GET_ORGANIZATION_REQUEST',
    GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
    GET_ORGANIZATION_FAILURE: 'GET_ORGANIZATION_FAILURE',

    GET_TITLES_REQUEST: 'GET_TITLES_REQUEST',
    GET_TITLES_SUCCESS: 'GET_TITLES_SUCCESS',
    GET_TITLES_FAILURE: 'GET_TITLES_FAILURE',

    GET_BUSINESSUNITS_REQUEST: 'GET_BUSINESSUNITS_REQUEST',
    GET_BUSINESSUNITS_SUCCESS: 'GET_BUSINESSUNITS_SUCCESS',
    GET_BUSINESSUNITS_FAILURE: 'GET_BUSINESSUNITS_FAILURE',

    GET_PEOPLE_REQUEST: 'GET_PEOPLE_REQUEST',
    GET_PEOPLE_SUCCESS: 'GET_PEOPLE_SUCCESS',
    GET_PEOPLE_FAILURE: 'GET_PEOPLE_FAILURE',

    GET_PERSONYEAR_REQUEST: 'GET_PERSONYEAR_REQUEST',
    GET_PERSONYEAR_SUCCESS: 'GET_PERSONYEAR_SUCCESS',
    GET_PERSONYEAR_FAILURE: 'GET_PERSONYEAR_FAILURE',

    SAVE_PERSON_YEAR_REQUEST: 'SAVE_PERSON_YEAR_REQUEST',
    SAVE_PERSON_YEAR_SUCCESS: 'SAVE_PERSON_YEAR_SUCCESS',
    SAVE_PERSON_YEAR_FAILURE: 'SAVE_PERSON_YEAR_FAILURE',

};
