import React from "react";

function CompanyPurposeSectionOnlyButton(props) {
    const {toggleModal} = props;
    return (
        <section style={{
            paddingLeft: "17%",
            paddingRight: "17%",
            backgroundColor: "RGB(240,240,240)",
            paddingTop: "100px",
            paddingBottom: "130px",
            userSelect: "none"
        }}>
            <div className="level" style={{backgroundColor: "transparent", minHeight: "350px"}}>
                <div className="level-item " style={{userSelect: "none", minHeight: "350px"}}>
                    <button className="button p-4" style={{
                        backgroundColor: "transparent",
                        border: "solid 1px darkgrey",
                        fontFamily: "Gotham Extra",
                        fontSize: "20px"
                    }} onClick={toggleModal}>
                        Add Company Growth Story
                    </button>
                </div>
            </div>
        </section>
    );
}

export default CompanyPurposeSectionOnlyButton;
