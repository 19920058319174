import React, { Fragment, useEffect, useState, createContext } from "react";
import "./common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../redux/actions/menu";
import { Sprint } from "./sprints/Sprint";
import {SprintHeader} from "./sprints/SprintHeader";
import SprintDateModal from "./sprints/modals/SprintDateModal";
import SprintCommentModal from "./sprints/modals/SprintCommentModal";
import SprintModal from "./sprints/modals/SprintModal";
import SprintInvestmentModal from "./sprints/modals/SprintInvestmentModal";
import SprintAddPersonModal from "./sprints/modals/SprintAddPersonModal";
import ConfirmDeleteModal from "./common/ConfirmDeleteModal";
import {sprintcycleService} from "../services/SprintcycleService";
import {personviewService} from "../services/OrganizationPersonViewService"
import {sponsorService} from "../services/SprintSponsorsService"
import {workersService} from "../services/SprintWorkersService"
import {sponsorViewService} from "../services/SprintSponsorsViewService"
import {workersViewService} from "../services/SprintWorkersViewService"
import {sprintService} from "../services/SprintService"
import {registersService} from "../services/RegistersService"
import {roadmapsService} from "../services/RoadmapsService";
import {investmentsprintService} from "../services/SprintInvestmentService";
import {investmentsprintcellService} from "../services/SprintInvestmentCellService";
import SetColors from "./common/SetColors";
import moment from 'moment'
import {filter} from "lodash"
import { RotatingLines } from "react-loader-spinner";
import Spinner from "./common/Spinner";

export const sprintdatasets = createContext();

function Sprints() {

  const dispatch = useDispatch();
  const [onlyMyTasks, setOnlyMyTasks] = useState(false);
  const [onlyOpenTasks, setOnlyOpenTasks] = useState(true);
  const [onlyCompletedTasks, setOnlyCompletedTasks] = useState(true);
  const [onlyDiscontinuedTasks, setOnlyDiscontinuedTasks] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [textfilter, setfilterquery] = useState('');
  const [sprints,setSprint] = useState(null);
  const [sponsors,setSponsors] = useState(null);
  const [workers,setWorkers] = useState(null);
  const [registers,setRegister] = useState(null);
  const [personview, setPersonView] = useState(null);
  const [roadmap,setRoadmap] = useState(null);
  const [sprintdate,setSprintdate] = useState(null);
  const [sprintinvestment,setSprintInvestment] = useState(null);
  const [sprintinvestmentcell,setSprintInvestmentCell] = useState(null);
  const [workersview, setWorkersView] = useState(null);
  const [sponsorsview, setSponsorsView] = useState(null);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isSprintModalOpen, setIsSprintModalOpen] = useState(false);
  const [isSprintInvestmentModalOpen, setIsSprintInvestmentModalOpen] = useState(false);
  const [isSprintAddPersonModalOpen, setIsSprintAddPersonModalOpen] = useState(false);
  const [sprintAddPersonModalType, setSprintAddPersonModalType] = useState(false);  
  const [sprintModalKey, setSprintModalKey] = useState(null);
  const [sprintCommentModalKey, setSprintCommentModalKey] = useState(null);
  const [sprintinvestmentKey, setSprintInvestmentKey] = useState(null);
  const [sprintAddpersonModalKey, setSprintAddpersonModalKey] = useState(null);
  const [sprintModalFilteredData, setSprintModalFilteredData]= useState(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);    
  const [deletemodaltext, setDeleteModalText] = useState(null);  
  const [deleterecordkey, setDeleteRecordKey] = useState(null);  
  const [deleterecordsource, setDeleteRecordSource] = useState(null);  
  const [isDataLoading, setIsDataLoading] = useState(false);


  var [filteredSprints] = [];

  async function fetchSprints() {
    const sprintdate = await sprintcycleService.getSprintdate();
    setSprintdate(sprintdate);    
    const sprints = await sprintService.getSprint();
    setSprint(sprints);    
    const sponsors = await sponsorService.getSponsors();
    setSponsors(sponsors);  
    const workers = await workersService.getWorkers();
    setWorkers(workers);  
    const registers = await registersService.getRegister();
    setRegister(registers);    
    const roadmap = await roadmapsService.getRoadmap();
    setRoadmap(roadmap);  
    const personview = await personviewService.getAll();
    setPersonView(personview);          
    const sponsorsview = await sponsorViewService.getAll();
    setSponsorsView(sponsorsview);  
    const workersview = await workersViewService.getAll();
    setWorkersView(workersview);  
    const sprintinvestment = await investmentsprintService.getInvestment();
    setSprintInvestment(sprintinvestment);  
    const sprintinvestmentcell = await investmentsprintcellService.getInvestmentCell();
    setSprintInvestmentCell(sprintinvestmentcell);  
}



  useEffect(() => {


    async function fetchData() {
      setIsDataLoading(true);
      await fetchSprints();
      setIsDataLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("sprints"));
  }, [dispatch]);


  function Loader() {
    return (
      <div style={{height: '300px', paddingTop: '8em', textAlign: 'center'}}>
      <RotatingLines
        strokeColor="blue"
        strokeWidth="4"
        animationDuration="0.75"
        width="56"
        visible={true}
      />
      </div>
    )
  }


  let colorarray = SetColors('#FFFFFF', '#000000');
  var defaultbackgroundcolor = null;
  var defaulttextcolor = null;
  if (colorarray !== null) {
    defaulttextcolor =colorarray[0];
    defaultbackgroundcolor = colorarray[1];
  }

//Filter States

  function handleChangeOnlyMyTasks(event) {
    setOnlyMyTasks(event.target.checked);
  }

  function handleChangeOnlyCompleted(event) {
    setOnlyCompletedTasks(event.target.checked);
  }

  function handleChangeOnlyActive(event) {
    setOnlyOpenTasks(event.target.checked);
  }

  function handleChangeOnlyDiscontinued(event) {
    setOnlyDiscontinuedTasks(event.target.checked);
  }

  const handleChangeFilter = (e) => {
    setfilterquery(e.target.value)
  }

//Toggle Modals

  function toggleSetDateModal(open) {
    setIsDateModalOpen(open);
  }

  function toggleInvestmentModal(open, key) {
    let keyvalue=key
    setSprintInvestmentKey(keyvalue)
    setIsSprintInvestmentModalOpen(open)
  }

  function toggleAddPersonModal(open, key, type) {
    //await fetchSprints();
    let keyvalue=key
    setSprintAddpersonModalKey(keyvalue)
    let typevalue=type    
    setSprintAddPersonModalType(typevalue)
    setIsSprintAddPersonModalOpen(open)
  }

  function toggleSetCommentModal(open, key) {
    setIsCommentModalOpen(open);
    let keyvalue=key
    setSprintCommentModalKey(keyvalue)
   }

  function toggleSprintModal(open, key) {
    console.log("Sprint Modal Key", key)
    setIsSprintModalOpen(open);
    let keyvalue=key
    setSprintModalKey(keyvalue)
  }

  function toggleConfirmDeleteModal(open, messagetext, key, source, pk) {
    if (open === true) {
    setDeleteRecordKey(pk)
    setDeleteModalText(messagetext)
    setDeleteRecordSource(source)
    setSprintModalKey(key)
    }
    setIsConfirmDeleteModalOpen(open);
  }

  //API Commits

  async function onSprintDateChanged(sprintdatevalue) {
    await sprintcycleService.patch(sprintdate.pk, {sprintenddate: sprintdatevalue});
    await fetchSprints();
  }

  async function onSprintCommentChanged(sprintcommentvalue, key, sprintid) {
    const currentDate = new Date()
    const datelog = moment(currentDate, 'MM/DD/YYYY')
    if (key) {
      await registersService.patch(key, {comment: sprintcommentvalue, date: currentDate});
  } else {
      let username = currentUser.first_name + ' ' + currentUser.last_name
      await registersService.post({username: username, comment: sprintcommentvalue,date: currentDate,sprint: sprintid});
  }
    await fetchSprints();
  }

  async function onSprintDataChanged(title, description, action_status, budget, key, sprintlane) {
    if (key) {
      await sprintService.patch(key, {title: title, description: description, action_status: action_status, budget: budget, sprintlane: sprintlane});
  } else {
      let sprintrow = await sprintService.post({title: title, description: description, action_status: action_status, budget: budget, sprintlane: sprintlane});
      setSprintModalKey(sprintrow.pk)
  }
    await fetchSprints();
  }

  async function onSprintInvestmentChanged(item, sprint_id, key) {
    console.log("At Sprint Investment Change", item, sprint_id, key)
    if (key) {
      await investmentsprintService.patch(key, {item: item , sprint: sprint_id});
  } else {
      await investmentsprintService.post({item: null , sprint: sprint_id});
  }
    await fetchSprints();
  }

  async function onSprintDelete(key) {
    await sprintService.deleteRow(key);
    toggleSprintModal(false,null)
    await fetchSprints();
  }

  async function onSprintCommentDelete(key) {
    await registersService.deleteRow(key);
    setIsConfirmDeleteModalOpen(false)
    await fetchSprints();
  }

  async function onSprintInvestmentDelete(key) {
    await investmentsprintService.deleteRow(key);
    await fetchSprints();
  }


  async function onSprintSponsorsDelete(key) {
    console.log("SSD")
    await sponsorService.deleteRow(deleterecordkey);
    setIsConfirmDeleteModalOpen(false)
    await fetchSprints();
  }

  async function onAddSponsor(personid, key) {
    console.log("At Add Sponsor",personid,key )
    await sponsorService.post({person_id: personid, sprint: key});
    await fetchSprints();
  }

  async function onAddWorker(personid, key) {
    await workersService.post({person_id: personid, sprint: key});
    await fetchSprints();
  }

  async function onSprintWorkersDelete(key) {
    await workersService.deleteRow(deleterecordkey);
    setIsConfirmDeleteModalOpen(false)
    await fetchSprints();
  }

  if (!sprints || !registers || !sprintdate || !personview || !sponsorsview || !workersview || !defaulttextcolor) {
    return <Spinner/>;
  }
  
  let minYear=""
  let maxYear=""
  if (roadmap.results.length !== 0) {
    minYear = (roadmap.results[0]).min_year
    maxYear = (roadmap.results[0]).max_year
  } else {
    minYear = new Date().getFullYear() 
    maxYear = new Date().getFullYear()+5 
  }


  filteredSprints = sprints.filter((sprint) => {
    let sponsorsviewlist = filter(sponsorsview.results,{sprint:sprint.pk})
    let workersviewlist = filter(workersview.results,{sprint:sprint.pk})
    var sponsorNames = sponsorsviewlist.map((sponsor) => sponsor.fullname);
    var sponsorlist = sponsorsviewlist.map((sponsor) => sponsor.fullname).join(', ')
    var workerlist = workersviewlist.map((sponsor) => sponsor.fullname).join(', ')
    if (sprint.title.toLowerCase().includes(textfilter.toLowerCase()) || sprint.description.toLowerCase().includes(textfilter.toLowerCase()) || sponsorlist.toLowerCase().includes(textfilter.toLowerCase()) || workerlist.toLowerCase().includes(textfilter.toLowerCase())){
      if (onlyMyTasks) {
        if (sponsorNames.includes(currentUser.first_name + " " + currentUser.last_name)) {

          if (onlyDiscontinuedTasks) {
            if (sprint.action_status === "Suspended") {
              return true;
            }
          }

          if (onlyCompletedTasks) {
            if (sprint.action_status === "Completed") {
              return true;
            }
          }

          if (onlyOpenTasks) {
            if (sprint.action_status === "Open" || sprint.action_status === "Not Started" || sprint.action_status === "In Progress") {
              return true;
            }
          }
        }
      } else 
      {
        if (onlyDiscontinuedTasks) {
          if (sprint.action_status === "Suspended") {
            return true;
          }
        }

        if (onlyCompletedTasks) {
          if (sprint.action_status === "Completed") {
            return true;
          }
        }

        if (onlyOpenTasks) {
          if (sprint.action_status === "Open" || sprint.action_status === "Not Started" || sprint.action_status === "In Progress") {
            return true;
          }
        }
      }
    
    };
    return filteredSprints;
    }
  );


  function rendersprintpage() {
    return (
      <sprintdatasets.Provider value={{filteredSprints, sprints, workersview, sponsorsview, registers, defaultbackgroundcolor, defaulttextcolor, sprintinvestment, sprintinvestmentcell}}>
      <Fragment>
        <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
  
          <SprintHeader 
            sprintdate = { sprintdate }
            onlyOpenTasks = {onlyOpenTasks}
            onlyCompletedTasks = { onlyCompletedTasks }
            onlyDiscontinuedTasks = { onlyDiscontinuedTasks }
            onlyMyTasks = { onlyMyTasks }
            handleChangeOnlyActive = { handleChangeOnlyActive }
            handleChangeOnlyCompleted = { handleChangeOnlyCompleted }
            handleChangeOnlyDiscontinued = { handleChangeOnlyDiscontinued }
            handleChangeOnlyMyTasks = { handleChangeOnlyMyTasks }
            handleChangeFilter = { handleChangeFilter }
            handleClickAddTask = { toggleSprintModal }
            toggleModal={toggleSetDateModal}
          />
  
          <Sprint 
            handleClickEditTask = { toggleSprintModal }
            toggleSetCommentModal = {toggleSetCommentModal}
            toggleInvestmentModal = {toggleInvestmentModal}
          />
  
          <SprintDateModal
            sprintdate={sprintdate}
            showModal={isDateModalOpen}
            toggleModal={toggleSetDateModal}
            onDataChanged={onSprintDateChanged}
            defaultbackgroundcolor={defaultbackgroundcolor}
            defaulttextcolor={defaulttextcolor}
          />
  
          <SprintCommentModal
            sprintcomment={registers}
            showModal={isCommentModalOpen}
            sprintCommentModalKey={sprintCommentModalKey}
            toggleModal={toggleSetCommentModal}
            onSprintCommentChanged={onSprintCommentChanged}
            defaultbackgroundcolor={defaultbackgroundcolor}
            defaulttextcolor={defaulttextcolor}
          />
  
          <SprintModal
            sprints={sprints}
            sprintModalFilteredData={sprintModalFilteredData}
            sponsors={sponsors}
            workers={workers}
            sponsorsview={sponsorsview}
            workersview={workersview}
            registers={registers}
            personview={personview}
            sprintModalKey={sprintModalKey}
            showModal={isSprintModalOpen}
            toggleSprintModal={toggleSprintModal}
            toggleConfirmDeleteModal={toggleConfirmDeleteModal}
            onSprintDataChanged={onSprintDataChanged}
            toggleAddPersonModal={toggleAddPersonModal}
            defaultbackgroundcolor={defaultbackgroundcolor}
            defaulttextcolor={defaulttextcolor}
          />
  
          <SprintInvestmentModal
            minYear={minYear}
            maxYear={maxYear}
            sprintinvestmentKey={sprintinvestmentKey}
            showModal={isSprintInvestmentModalOpen}
            toggleModal={toggleInvestmentModal}
            defaultbackgroundcolor={defaultbackgroundcolor}
            defaulttextcolor={defaulttextcolor}
            sprintinvestment={sprintinvestment}
            sprintinvestmentcell={sprintinvestmentcell}
            onSprintInvestmentChanged={onSprintInvestmentChanged}
            onSprintInvestmentDelete={onSprintInvestmentDelete}
          />
  
          <SprintAddPersonModal
            showModal={isSprintAddPersonModalOpen}
            toggleModal={toggleAddPersonModal}
            onAddSponsor={onAddSponsor}
            onAddWorker={onAddWorker}
            sprintkey={sprintAddpersonModalKey}
            sprintAddPersonModalType={sprintAddPersonModalType}
            defaultbackgroundcolor={defaultbackgroundcolor}
            defaulttextcolor={defaulttextcolor}
            sponsorsview={sponsorsview}
            workersview={workersview}
          />
  
          <ConfirmDeleteModal
            modaltext={deletemodaltext}
            showModal={isConfirmDeleteModalOpen}
            recordkey={sprintModalKey}
            extrakey={sprintModalKey}
            toggleModal={toggleConfirmDeleteModal}
            onSprintRecordDelete={onSprintDelete}
            onSprintCommentDelete={onSprintCommentDelete}
            onSprintSponsorsDelete={onSprintSponsorsDelete}
            onSprintWorkersDelete={onSprintWorkersDelete}
            deleterecordsource={deleterecordsource}
            defaultbackgroundcolor={defaultbackgroundcolor}
            defaulttextcolor={defaulttextcolor}
          />
  
        </main>
      </Fragment>
      </sprintdatasets.Provider>
    )
  }

  return (
   (isDataLoading?<Spinner/>:rendersprintpage())
  );
}

export { Sprints };
