import React, {Fragment, useState, createContext} from "react";
import "bulma/css/bulma.css";
import { sprintdatasets } from '../../Sprints';


function SprintCommentModal(props) {
    const {toggleModal, showModal, onDataChanged, onSprintCommentChanged, sprintcomment, sprintCommentModalKey } = props;
    const [commentvalue,setCommentvalue] = useState(props.sprintcomment.comment);

    function handleChangeComment(event) {
        setCommentvalue(event.target.value);
    }

     let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }

    function renderForm() {
        return (
            <Fragment>
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-body is-flex">
                        <div className="control is-flex-grow-1">

                        <textarea 
                        className="textarea" 
                        placeholder="Add Comment" 
                        rows="10"
                        value={commentvalue}
                        onChange={handleChangeComment}
                        />
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader">
                        <p className="modal-card-title">Comment</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter">
                        <button
                            className="button is-success"
                            key="save"
                            onClick={async () => {
                                await onSprintCommentChanged(commentvalue, null, sprintCommentModalKey);
                                toggleModal(false);
                            }}
                        >
                            Save changes
                        </button>
                        <button className="button" key="cancel" onClick={() => toggleModal(false)}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintCommentModal;
