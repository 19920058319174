import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { openIdeasModal} from "./../../redux/toolkit/features/innovation/ideaModalToggle";
import { openSprintsModal} from "./../../redux/toolkit/features/sprints/sprintsModalToggle";
import { openDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";
import {fetchAllIdeas} from "./../../redux/toolkit/features/ideas";
import {filter} from "lodash"

function IdeaRow(props) {
  const {themeId, RowsSelected, index, checkboxstatus, updateCheckbox} = props;
  const ideas = useSelector((state) => state.ideas.entities);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  //const [isChecked, setIsChecked] = useState(checkboxstatus?checkboxstatus[index]:false);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

  var idea=(filter(ideas,{pk:index}))[0]

  function totalcheckbox(e) {
   updateCheckbox(e, index)
   //setIsChecked(e);
  }
   
    useEffect(() => {

  }, [checkboxstatus]);

  useEffect(() => {
    async function getIdeas() {
      dispatch(fetchAllIdeas())
      idea=filter(ideas,{pk:index})
    }
    getIdeas();
  },[dispatch]);

  if (!idea || !index) {
    return null;
  }


  return (

      <Fragment>
        <tr key={'row_'+index}>
          <td><input type="checkbox" id="vehicle1" name="vehicle1" value={isChecked} checked={isChecked}  onChange={(e) => totalcheckbox(e.target.checked)} /></td>
          {!isDetailVisible &&
          <td onClick={() => setIsDetailVisible(true)}><span style={{fontWeight:500}}><span style={{fontFamily: "Sans-serif", fontSize: "13px"}}>► </span>{idea.title}</span></td>
          }
          {isDetailVisible &&
          <td onClick={() => setIsDetailVisible(false)}><span style={{fontWeight:500}}><span style={{fontFamily: "Sans-serif", fontSize: "13px"}}>▼ </span>{idea.title}</span><br/><p className="pl-4" style={{fontSize:'14.5px'}}>{idea.comment}</p></td>
          }
          <td style={{textAlign: "center"}}>{idea.impact!==null?idea.impact:'No Value'}</td>
          <td style={{textAlign: "center"}}>{idea.cost!==null?idea.cost:'No Value'}</td>
          {idea.sprint_id !== null ?
          <td style={{textAlign: "center"}}>Actioned</td> :
          <td style={{textAlign: "center"}}><button onClick={() => dispatch(openSprintsModal(null))}  className="button is-small">Action</button></td>}
          <td onClick={() => dispatch(openIdeasModal({ideapk:idea.pk, themepk:themeId}))} className="pl-0 pr-0"><i className="far fa-edit mt-1" style={{color:"gray"}}/></td>
          <td onClick={() => dispatch(openDeleteModal({message: idea.title,recordpk: idea.pk, recordtype: 'ideas'}))}><i className="far fa-trash-alt mt-1" style={{color:"gray"}}/></td>
        </tr>
    </Fragment>
    )
  }
export { IdeaRow };
//.toLocaleString()
