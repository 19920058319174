import React, {Fragment} from "react";
import {SprintList} from "./SuccessSprintList";
import {filter} from "lodash"

function SuccessSprintActions(props) {
    const { hideActionsSection, showActionsSection, linkedsprints, success_id } = props; 
    const linkedsprintsfiltered = filter(linkedsprints.results, {success: success_id})
    const linkedsprintsfilteredcount = linkedsprintsfiltered.length 

    if(linkedsprintsfiltered.length !== 0) {
        return (
            
            <Fragment>
                <SprintList
                    linkedsprintsfiltered = {linkedsprintsfiltered}    
                />
            </Fragment>

        )
    } else {
        return (
          
            <p className="pl-2"><b>No Linked Sprint Actions Found</b></p>
        
          )
    }
}

export { SuccessSprintActions }