import "regenerator-runtime/runtime.js";
import axios from "axios";
import BaseService from "./BaseService";

class AuthService extends BaseService {
    postUrl() {
        return `${this.baseUrl}/token/`;
    }

    async login(username, password) {
        const data = {username, password};
            const response = await axios.post(this.postUrl(), data).catch(err => err);
            if(response instanceof Error) {
                if (!response.response) {
                    return "Network error, can't access the backend!";
                }
                if (response.response.status === 401) {
                    return "Wrong username or password";
                } else {
                    return "Unexpected error " + response.response.status;
                }
            } else {
                localStorage.setItem("access_token", response.data.access);
                localStorage.setItem("refresh_token", response.data.refresh);
                return "OK"
            }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    }

    isLoggedIn() {
        return localStorage.getItem("access_token");
    }
}

export const authService = new AuthService();
