import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class InvestmentsService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/investments/`;
    }

    async getInvestments() {
        const investments = await this.getAll();
        return (investments);
    }
}

export const investmentsService = new InvestmentsService();
