import {competenciesConstants} from '../constants/competencies';

const initialState = {
    loading: false,
    competencies: [],
    error: null,
}

export function competencies(state = initialState, action) {
    switch (action.type) {
        case competenciesConstants.GET_COMPETENCIES_REQUEST:
            return {
                ...initialState,
                loading: true,
            };
        case competenciesConstants.GET_COMPETENCIES_SUCCESS:
            return {
                ...initialState,
                competencies: action.competencies,
            };
        case competenciesConstants.GET_COMPETENCIES_FAILURE:
            return {
                ...initialState,
                error: action.error,
            };
        case competenciesConstants.POST_COMPETENCY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case competenciesConstants.POST_COMPETENCY_SUCCESS:
            const addedCompetencies = [...state.competencies];
            addedCompetencies.push(action.competency);
            return {
                ...state,
                competencies: addedCompetencies,
            };
        case competenciesConstants.POST_COMPETENCY_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case competenciesConstants.DELETE_COMPETENCY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case competenciesConstants.DELETE_COMPETENCY_SUCCESS:
            const deletedCompetencies = [];
            for (const competency of state.competencies) {
                if (competency.pk !== action.id) {
                    deletedCompetencies.push(competency);
                }
            }
            return {
                ...state,
                competencies: deletedCompetencies,
            };
        case competenciesConstants.DELETE_COMPETENCY_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state
    }
}