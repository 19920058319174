import React, {Fragment, useState, useEffect, useContext} from 'react';
import { useSelector } from 'react-redux'
//import 'bulma/css/bulma.css';
import {Container} from "./SuccessOrder/Container";
import { successdataset } from "./../../Success"


function SuccessModal(props) {
    const {toggleSuccessModal,toggleSuccessRowModal, toggleSuccessConfirmModal, showSuccessModal, getSuccessOnMainPage} = props;
    const {success} = useContext(successdataset);
    const [modaltab, setModalTab] = useState(useContext(successdataset))
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)

    let modalClass = 'modal';
    if (showSuccessModal) {
        modalClass += ' is-active';
    }
   
    useEffect(() => {
        setModalTab('Details');
      }, [modaltab]);

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard'>
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>SUCCESS VISION</p>
                        <button className='delete' aria-label='close' onClick={() => toggleSuccessModal(false)}>
                            {' '}
                        </button>
                    </header>
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '330px'}}>
                      
                    <Container success={success} toggleSuccessRecordModal={toggleSuccessRowModal}
                                   toggleConfirmDeleteModal={toggleSuccessConfirmModal} getSuccessOnMainPage={getSuccessOnMainPage} />

                    </section>
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                        <button
                            style={{color: textcolor, backgroundColor: backgroundcolor, borderRadius:"4px"}}
                            className="button is-small"
                            key="new"
                            onClick={async () => {
                                toggleSuccessRowModal(true,null)
                                toggleSuccessModal(false);
                            }}
                        >
                            Add New
                        </button>
                        <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => toggleSuccessModal(false)}>
                            Cancel
                        </button>

                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SuccessModal;
