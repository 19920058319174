import React, { useState, useEffect } from "react";
import "./common/css/masterstyles.css";
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from "../redux/actions/login";


function Login(props) {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.login.loggingIn);
    const loginError = useSelector(state => state.login.loginError);
    const loginErrorResult = useSelector(state => state.login.error);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loginActions.logout());
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (username && password) {
            dispatch(loginActions.login(username, password));
        }
    }

    function clearError() {
        dispatch(loginActions.clearError())
    }

    function renderLoginError() {
        if (!loginError) {
            return null;
        }
        console.log("Error:" + loginErrorResult);
        return (
            <div className="notification is-warning">
                <button className="delete" onClick={clearError}> </button>
                {loginErrorResult}
            </div>
        );
    }

    return (
        <section className="hero is-light is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-5-tablet is-4-desktop is-3-widescreen">
                            <form className="box">
                                <div className="field">
                                    <label htmlFor="" className="label">Email</label>
                                    <div className="control has-icons-left">
                                        <input type="text" name="username" onChange={handleChange}
                                            value={username} placeholder="e.g. admin"
                                            className="input" required />
                                        <span className="icon is-small is-left">
                                            <i className="fa fa-user"> </i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="label">Password</label>
                                    <div className="control has-icons-left">
                                        <input type="password" name="password" onChange={handleChange}
                                            value={password} placeholder="*******" className="input"
                                            required />
                                        <span className="icon is-small is-left">
                                            <i className="fa fa-lock"> </i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <button className="button is-success" onClick={handleSubmit} disabled={loggingIn}>
                                        Login
                                    </button>
                                </div>
                            </form>
                            {renderLoginError()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export { Login };
