import {timelinesConstants} from '../constants/timelines';
import {timelineService} from "../../services/TimelineService";
import {alerts} from "./alerts";

export const timelinesActions = {
    getAll,
    post,
    deleteRow,
    patch,
};

function getAll() {
    return async dispatch => {
        dispatch(request());
        try {
            const timelines = await timelineService.getAll();
            dispatch(success(timelines.results));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: timelinesConstants.GET_TIMELINES_REQUEST } }
    function success(timelines) { return { type: timelinesConstants.GET_TIMELINES_SUCCESS, timelines } }
    function failure(error) { return { type: timelinesConstants.GET_TIMELINES_FAILURE, error } }
}

function post(data) {
    return async dispatch => {
        dispatch(request());
        try {
            const timeline = await timelineService.post(data);
            dispatch(success(timeline));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: timelinesConstants.POST_TIMELINES_REQUEST } }
    function success(timeline) { return { type: timelinesConstants.POST_TIMELINES_SUCCESS, timeline } }
    function failure(error) { return { type: timelinesConstants.POST_TIMELINES_FAILURE, error } }
}

function deleteRow(id) {
    return async dispatch => {
        dispatch(request());
        try {
            await timelineService.deleteRow(id);
            dispatch(success(id));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: timelinesConstants.DELETE_TIMELINES_REQUEST } }
    function success(id) { return { type: timelinesConstants.DELETE_TIMELINES_SUCCESS, id } }
    function failure(error) { return { type: timelinesConstants.DELETE_TIMELINES_FAILURE, error } }
}

function patch(pk, data) {
    return async dispatch => {
        dispatch(request());
        try {
            const timeline = await timelineService.patch(pk, data);
            dispatch(success(timeline));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: timelinesConstants.PATCH_TIMELINES_REQUEST } }
    function success(timeline) { return { type: timelinesConstants.PATCH_TIMELINES_SUCCESS, timeline } }
    function failure(error) { return { type: timelinesConstants.PATCH_TIMELINES_FAILURE, error } }
}
