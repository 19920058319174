import React, {Fragment, useState } from "react";
//import "bulma/css/bulma.css";


function SuccessModalLinksSprints(props) {
    const {toggleSuccessLinksModal, showSuccessLinksModal, sprints, linkModaltype, updatelinksprints} = props;
    const [sprintvalue, setSprintValue] = useState(null)
    var sprintslist = sprints.sort();
    let modalClass = "modal";
    if (showSuccessLinksModal) {
        modalClass += " is-active";
    }
   
    function sprintToggle(event, open) {
        toggleSuccessLinksModal(open, linkModaltype)
        setSprintValue(Number(event.target.value))
        updatelinksprints(event.target.value)
    }
      
    function selectSprintLink() {
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label pt-2">Sprint</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" onChange={(event) => sprintToggle(event, true)}>
                        {sprintvalue === null && <option>{'<Select Sprint>'}</option>}
                            {sprintslist &&
                        sprintslist.map((p) => (
                        <option key={p.pk} value={p.pk}>
                            {p.title} 
                            </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
        )
    }





    return (
        <Fragment>
    
            {selectSprintLink()}
        </Fragment>
    );
}

export default SuccessModalLinksSprints
