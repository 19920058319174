import { peopleConstants } from '../constants/people';
import {personnameService} from "../../services/PersonNameService";
import {alerts} from "./alerts";

export const peopleActions = {
    getAll,
    post,
    deleteRow,
    patch,
};

function getAll() {
    console.log("At People Action")
    return async dispatch => {
        dispatch(request());
        try {
            const people = await personnameService.getPersons();
            console.log("At People Action", people)
            dispatch(success(people.results));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: peopleConstants.GET_PEOPLE_REQUEST } }
    function success(people) { return { type: peopleConstants.GET_PEOPLE_SUCCESS, people } }
    function failure(error) { return { type: peopleConstants.GET_PEOPLE_FAILURE, error } }
}

function post(data) {
    return async dispatch => {
        dispatch(request());
        try {
            const people = await personnameService.post(data);
            dispatch(success(people));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: peopleConstants.POST_PEOPLE_REQUEST } }
    function success(people) { return { type: peopleConstants.POST_PEOPLE_SUCCESS, people } }
    function failure(error) { return { type: peopleConstants.POST_PEOPLE_FAILURE, error } }
}

function deleteRow(id) {
    return async dispatch => {
        dispatch(request());
        try {
            await personnameService.deleteRow(id);
            dispatch(success(id));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: peopleConstants.DELETE_PEOPLE_REQUEST } }
    function success(id) { return { type: peopleConstants.DELETE_PEOPLE_SUCCESS, id } }
    function failure(error) { return { type: peopleConstants.DELETE_PEOPLE_FAILURE, error } }
}

function patch(pk, data) {
    return async dispatch => {
        dispatch(request());
        try {
            const people = await personnameService.patch(pk, data);
            dispatch(success(people));
        } catch (err) {
            dispatch(alerts.error(err.toString()));
            dispatch(failure(err.toString()));
        }
    };

    function request() { return { type: peopleConstants.PATCH_PEOPLE_REQUEST } }
    function success(people) { return { type: peopleConstants.PATCH_PEOPLE_SUCCESS, people } }
    function failure(error) { return { type: peopleConstants.PATCH_PEOPLE_FAILURE, error } }
}


