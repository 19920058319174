import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, timelinepk: null };

const modalSlice = createSlice({
  name: "timelineModal",
  initialState,
  reducers: {
    openTimelineModal(state, action) {
      state.isOpen = true;
      state.timelinepk = action.payload;
    },
    closeTimelineModal(state) {
      state.isOpen = false;
      state.timelinepk = null;
    },
  },
});

export const { openTimelineModal, closeTimelineModal } = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.timelineModal;

export const selectTimelineModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectTimelineModalTimelinepk = createSelector(
  selectState,
  (state) => state.timelinepk
);
