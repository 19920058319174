import React, {Fragment, useState, useEffect, useLayoutEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {_} from 'lodash'
import {filter} from 'lodash'
import { useForm } from 'react-hook-form'
import SectionsModalSection from "./SectionsModalSection";
import SectionsModalButtons from "./SectionsModalButtons";
import SectionsModalTable from "./SectionsModalTable";
import SectionsModalCharts from "./SectionsModalCharts";
import SectionsModalGantt from "./SectionsModalGantt";
import ConfirmDeleteBox from "./../../../components/common/ConfirmDeleteBox"
import { closeRoadmapSectionsModal } from '../../../redux/toolkit/features/roadmaps/sectionsModal';

function SectionsModal(props) {
    const tabs = ['Section', 'Table', 'Timeline', 'Charts']
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showSectionsModal = useSelector((state) => state.roadmapSectionsModal.isOpen)
    const sectionpk = useSelector((state) => state.roadmapSectionsModal.sectionpk)
    const [showDeleteModal, setShowDeleteModal]= useState(false)
    const tabsshort = ['Section']
    const [activetab, setActivetab] = useState(tabs[0]);
    const {toggleSectionsRowModal, toggleSectionsTimelineModal, toggleSectionsChartsModal,toggleConfirmDeleteModal, showSectionsRowModal, onDataChanged, onGanttDataChanged, sections, rows, gantt, chart, onRoadmapSectionDelete} = props;
    const [name, setName] = useState('');
    const [narrative, setNarrative] = useState('');
    const [category, setCategory] = useState('');
    const {reset} = useForm()
    const dispatch = useDispatch();

    let modalClass = 'modal';
    if (showSectionsModal) {modalClass += ' is-active'}

    let modalTitle=null;
    if (sectionpk) { modalTitle='EDIT ROADMAP'} else {modalTitle='ADD ROADMAP'}
   
    let sectionrow=filter(sections.results, {pk: sectionpk});
    let namevalue= (sectionrow[0]?.name || '')
    let narrativevalue= (sectionrow[0]?.narrative || '')
    let categoryvalue= (sectionrow[0]?.category || '')

    useEffect(() => {
        setName(namevalue)
      }, [namevalue])

    useEffect(() => {
        setNarrative(narrativevalue)
      }, [narrativevalue])

    useEffect(() => {
        setCategory(categoryvalue)
      }, [categoryvalue])

    useEffect(() => {
        reset(name)
      }, [name])

    useEffect(() => {
        reset(narrative)
      }, [narrative])

    useEffect(() => {
    reset(category)
    }, [category])


    function deleteHandler(result){
        if (result) {
            onRoadmapSectionDelete(sectionpk)
        }
        setShowDeleteModal(false)
        dispatch(closeRoadmapSectionsModal())
      }

    function formtabs() {
        return (
            <div className='tabs is-boxed'>
                <ul>
                {sectionpk===undefined &&
                <Fragment>
                    {tabsshort.map(tab => (
                        <li className ={activetab === tab ? 'is-active' : ''}
                            key={'tab_'+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                </Fragment>
                }
                {sectionpk !==undefined &&
                <Fragment>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? 'is-active' : ''}
                            key={'tab_'+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                    </Fragment>
                }
                </ul>
            </div>
        )
    }


    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard'>
                    
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title' style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeRoadmapSectionsModal())}>
                            {' '}
                        </button>
                    </header>
                    
                    <section className='modal-card-body' key='modalBody' style={{minHeight: '450px'}}>
                        {formtabs()}

                        {activetab === 'Section' && 
                            <SectionsModalSection
                                name={name}
                                setName={setName}
                                narrative={narrative}
                                setNarrative={setNarrative}
                                category={category}
                                setCategory={setCategory}
                                sections={sections}
                                sectionpk={sectionpk}
                            />
                        }

                        {activetab === 'Table' && 
                            <SectionsModalTable
                                toggleSectionsRowModal={toggleSectionsRowModal}
                                toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                                rows={rows}
                                sectionpk={sectionpk}
                            />
                        }

                        {activetab === 'Timeline' && 
                            <SectionsModalGantt
                            toggleSectionsTimelineModal={toggleSectionsTimelineModal}
                            toggleConfirmDeleteModal={toggleConfirmDeleteModal}                            
                                gantt={gantt}
                                sectionpk={sectionpk}
                            />
                        }

                        {activetab === 'Charts' && 
                            <SectionsModalCharts
                                toggleSectionsChartsModal={toggleSectionsChartsModal}
                                chart={chart}
                                sectionpk={sectionpk}
                                toggleConfirmDeleteModal={toggleConfirmDeleteModal}     
                            />
                        }

                    </section>
                    
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                        <nav className='level'>
                            <div className='level-left'>
                                <div className='level-item'>
                                {category !== "" &&                                     
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className='button is-success is-small'
                                        key='save'
                                        onClick={async () => {
                                            {sectionpk !== undefined &&
                                                dispatch(closeRoadmapSectionsModal())}
                                            await onDataChanged(sectionpk, name, narrative, category);


                                        }}
                                    >
                                
                                        Save Changes
                                    </button>}
                                </div>
                                <div className='level-item'>
                                    <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeRoadmapSectionsModal())}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className='level-right'>
                                <div className='level-item'>

                                    <SectionsModalButtons
                                        activetab={activetab}
                                        name={name}
                                        sectionpk={sectionpk}
                                        toggleSectionsRowModal={toggleSectionsRowModal}
                                        toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                                        toggleSectionsTimelineModal={toggleSectionsTimelineModal}
                                        toggleSectionsChartsModal={toggleSectionsChartsModal}
                                        onRoadmapSectionDelete={onRoadmapSectionDelete}
                                        setShowDeleteModal={setShowDeleteModal}
                                    />

                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>
            <ConfirmDeleteBox 
                showModal={showDeleteModal}
                modaltext={name}
                deleteHandler={deleteHandler}
            />
        </Fragment>
    );
}

export default SectionsModal;
