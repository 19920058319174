import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import "bulma/css/bulma.css";
import { closeRoadmapTimelineModal } from "../../../redux/toolkit/features/roadmaps/timelineDetailModal";

function SectionsModalEditTimeline(props) {
    const {onGanttDataChanged, gantttaskname, ganttstartdate, ganttenddate, ganttduration, ganttpercentcomplete, ganttdependencies, ganttpk} = props;
    const dispatch = useDispatch();
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showSectionsTimelineModal = useSelector((state) => state.roadmapTimelineModal.isOpen)
    const sectionpk = useSelector((state) => state.roadmapTimelineModal.sectionpk)
    const tabs=["Row Details", "Predecessors"]
    const [activetab, setActivetab] = useState(tabs[0]);

    let modalClass = "modal";
    if (showSectionsTimelineModal) {
        modalClass += " is-active";
    }

    const [taskname, setTaskname] = useState(gantttaskname)
    const [startdate, setStartdate] = useState(ganttstartdate)
    const [enddate, setEnddate] = useState(ganttenddate)
    const [duration, setDuration] = useState(ganttduration)
    const [percentcomplete, setPercentcomplete] = useState(ganttpercentcomplete)
    const [dependencies, setDependencies] = useState(ganttdependencies)



    useEffect(() => {
        setTaskname(gantttaskname)
      }, [gantttaskname])

    useEffect(() => {
        setStartdate(ganttstartdate)
      }, [ganttstartdate])

    useEffect(() => {
        setEnddate(ganttenddate)
      }, [ganttenddate])

    useEffect(() => {
        setDuration(ganttduration)
      }, [ganttduration])

    useEffect(() => {
        setPercentcomplete(ganttpercentcomplete)
      }, [ganttpercentcomplete])

    useEffect(() => {
        setDependencies(ganttdependencies)
      }, [ganttdependencies])


    function handleTaskname(event) {
        setTaskname(event.target.value)
    }

    function handleStartdate(event) {
        setStartdate(event.target.value)
    }

    function handleEnddate(event) {
        setEnddate(event.target.value)
    }

    function handlePercentcomplete(event) {
        setPercentcomplete(event.target.value)
    }

    function handleDuration(event) {
        setDuration(event.target.value)
    }

    function formtabs() {
        return (
            <div className='tabs is-boxed'>
                <ul>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? 'is-active' : ''}
                            key={'tab_'+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }


    function modalform() {
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Row Title</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <input
                        className="input"
                        type="text"
                        placeholder="Title"
                        value={taskname}
                        onChange={handleTaskname}
                    />
                </div>
            </div>
        </div>

        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Start Date</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                <input 
                    className="input" 
                    type="date" 
                    placeholder="" 
                    value={startdate}
                    onChange={handleStartdate}/>
                </div>
            </div>
        </div>

        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">End Date</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                <input 
                    className="input" 
                    type="date" 
                    value={enddate}
                    onChange={handleEnddate}/>                    
                </div>
            </div>
        </div>

        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Duration</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                <input 
                    className="input" 
                    type="text" 
                    value={duration}
                    onChange={handleDuration}/>
                </div>
            </div>
        </div>
      
        <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">% Complete</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                <input className="input" type="text"/>
                </div>
            </div>
        </div>
        </Fragment>
        )
    }

    function predecessorForm() {
        return(
            <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Predecessors</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-multiple is-flex is-fullwidth">
                        <select multiple size="2">
                            <option>None</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option> 
                            <option>5</option>                
                        </select>
                    </div>
                </div>
            </div>
        </div>
        )
    }
   
    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>TIMELINE ROW</p>
                    <button className="delete" aria-label="close" onClick={() => dispatch(closeRoadmapTimelineModal())}>
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody" style={{minHeight: "330px"}}>
                  {formtabs()}
                  {activetab==='Row Details' && modalform()}
                  {activetab==='Predecessors' && predecessorForm()}


                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                    <button
                        style={{borderRadius:"4px"}}
                        className="button is-success is-small"
                        key="save"
                        onClick={async () => {
                            dispatch(closeRoadmapTimelineModal())
                            onGanttDataChanged(ganttpk, taskname, startdate, enddate, duration, percentcomplete, dependencies, sectionpk)
                        }}
                    >
                        Save changes
                    </button>
                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} onClick={() => dispatch(closeRoadmapTimelineModal())}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default SectionsModalEditTimeline;
