import React, { Fragment, useState, useContext } from "react";
import { CellValue } from "./CellValue";
import {filter} from "lodash"
import { roadmapdatasets } from '../Roadmaps';

function Row(props) {
    const {indent, row, percentages, isbaseline, togglePopupModal} = props;
    const [indentonestatus, setIndentonestatus] = useState(true)
    const {minYear, maxYear, cellvalues, baselinedelta} = useContext(roadmapdatasets);
    const [rowsymbol, setRowSymbol] = useState(false);

    if (!cellvalues) {
        return null;
    }

       function renderCellValue(year) {
        if (!cellvalues) {
            return null;
        }

        let data = filter(cellvalues.results, {year: year, row_id: row.pk});
        let numericcell = (data.length !== 0 ? data[0].numeric_value : null)
        let textcell = (data.length !== 0 ? data[0].text_value : null)
        return (
            <CellValue 
                key={`${row.pk}_${year}`} 
                sectionPk={props.sectionPk} 
                rowPk={row.pk} 
                numericcell={numericcell}
                textcell={textcell}
                isbaseline={isbaseline}
                year={year} 
                type={row.type} 
                mediumalert={row.baselinealertmoderate}
                severealert={row.baselinealertsevere}
                decimalplaces={row.decimalplaces}
                formula={row.formula} 
                percentages={percentages}
            />
        )
    }

    function renderRowCellValues() {
        const cells = [];
        for (let year = minYear; year <= maxYear; year++) {
            cells.push(renderCellValue(year));
        }
        return cells;
    }


    function buildRows() {
        const rowindent = indent.results.filter(parent => parent.parent_id === props.data.pk);
        if (row.level===null || row.level === 0) {
            return (
                <Fragment>
                    {/* <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}} key={"table_"+row.pk}>{row.name}{renderBaseline()}{renderGrowth()} </th> */}
                    <td  key={"table_"+row.pk} style={{width:"350px"}}>
                        <input className="ml-2" style={{width:"100%", border:"none", fontFamily: "Sans-serif", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontWeight:"600", overFlow: "visible"}} value={row.name} readOnly/>
                    {renderBaseline()}{renderGrowth()}
                    </td>
                    {(row.description !== null && row.description !== "") && <th onClick={() => togglePopupModal(true,row.description,row.name)} ><i className="fas fa-info-circle"></i></th>}
                    {(row.description === null || row.description === "") && <th></th>}
                {renderRowCellValues()}
                </Fragment>
            )
        }

        if (row.level === 1) {
            return (
                <Fragment>
                    {/* <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}} key={"table_"+row.pk}>{row.name}{renderBaseline()}{renderGrowth()} </th> */}
                    <td  key={"table_"+row.pk} style={{width:"350px"}}>
                        <input className="ml-4" style={{width:"100%", border:"none", fontFamily: "Sans-serif", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontWeight:"400", overFlow: "visible"}} value={row.name} readOnly/>
                    {renderBaseline()}{renderGrowth()}
                    </td>
                    {(row.description !== null && row.description !== "") && <th onClick={() => togglePopupModal(true,row.description,row.name)} ><i className="fas fa-info-circle"></i></th>}
                    {(row.description === null || row.description === "") && <th></th>}
                {renderRowCellValues()}
                </Fragment>
            )
        }


        // if (row.type==='Header') {
        //     let colspanvalue = maxYear-minYear + 2
        //     return (
        //         <Fragment>
        //             {rowsymbol && <td className="pt-3" colSpan={colspanvalue} style={{fontFamily: "Sans-serif", fontWeight:"600"}} key={"table_"+row.pk}>{'► ' + row.name}</td>}
        //             {!rowsymbol && <td className="pt-3" colSpan={colspanvalue} style={{fontFamily: "Sans-serif", fontWeight:"600"}} key={"table_"+row.pk}>{'▼ ' + row.name}</td>}                    
        //         </Fragment>
        //     )
        // } else if (!row.subrow) {
        //     if (rowindent.length !== 0) {
        //         return (
        //             <Fragment>
        //             {/* <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}} key={"table_"+row.pk}>▼ {row.name}{renderBaseline()}{renderGrowth()} </th> */}
        //             <td  key={"table_"+row.pk} style={{width:"350px"}}>
        //                 <input className="ml-2" style={{width:"100%", border:"none", fontFamily: "Sans-serif", backgroundColor: "transparent", userSelect:"none", fontWeight:"600", overFlow: "visible"}} value={"▼ "+row.name} readOnly/>
        //             {renderBaseline()}{renderGrowth()}
        //             </td>
        //             {(row.description !== null && row.description !== "") && <th onClick={() => togglePopupModal(true,row.description,row.name)} ><i className="fas fa-info-circle"></i></th>}
        //             {(row.description === null || row.description === "") && <th></th>}
        //         {renderRowCellValues()}
        //         </Fragment>
        //         )
        //     } else {
        //         if (indentonestatus){
        //         return (
        //             <Fragment>
        //             {/* <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}} key={"table_"+row.pk}>{row.name}{renderBaseline()}{renderGrowth()} </th> */}
        //             <td  key={"table_"+row.pk} style={{width:"350px"}}>
        //                 <input className="ml-2" style={{width:"100%", border:"none", fontFamily: "Sans-serif", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontWeight:"600", overFlow: "visible"}} value={row.name} readOnly/>
        //             {renderBaseline()}{renderGrowth()}
        //             </td>
        //             {(row.description !== null && row.description !== "") && <th onClick={() => togglePopupModal(true,row.description,row.name)} ><i className="fas fa-info-circle"></i></th>}
        //             {(row.description === null || row.description === "") && <th></th>}
        //         {renderRowCellValues()}
        //         </Fragment>
        //         )
        //     }
        //     }
        //     } else {
        //         if (indentonestatus && row.subrow) {
        //         return (
        //             <Fragment>
        //             {/* <td className="pl-5 pt-3" style={{fontFamily: "Sans-serif", fontSize: "12px"}} key={"table_"+row.pk}> */}
        //             <td  key={"table_"+row.pk} style={{width:"350px"}}>
        //                 <input className="ml-2" style={{width:"100%",border:"none",fontFamily: "Sans-serif", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontWeight:"600", overFlow: "visible"}} value={row.name} readOnly/>
        //             {renderBaseline()}{renderGrowth()}
        //             </td>
        //             {(row.description !== null && row.description !== "") && <th onClick={() => togglePopupModal(true,row.description,row.name)} ><i className="fas fa-info-circle"></i></th>}
        //             {(row.description === null || row.description === "") && <th></th>}
        //         {renderRowCellValues()}
        //         </Fragment>
        //         )
        //     }
        // }
    }


    function renderBaseline() {
        if ((filter(baselinedelta, {row_id: row.pk})).length !== 0 && isbaseline) {
            return (
                <Fragment>
                <input className="pl-3 mt-2" style={{border:"none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.8rem", fontWeight:"600", width: "100%"}} value='Baseline' readOnly/>
                <input className="pl-3" style={{border:"none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.8rem", width: "100%"}} value='Delta ∆' readOnly/>
                <input className="pl-3 mb-3" style={{border:"none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.8rem", width: "100%"}} value='Delta %' readOnly/>
            </Fragment>
            )
        } 
    }


    function renderGrowth() {
        if (props.percentages) {
            return (
                <Fragment>
                    <input className="pl-3" style={{border:"none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.8rem", fontWeight:"600", width: "100%"}} value='Annual Growth' readOnly/>
                    <input className="pl-3" style={{border:"none", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontSize: "0.8rem", fontWeight:"600", width: "100%"}} value='CAGR' readOnly/>
                </Fragment>
            )
        } else {
            return;

        }
    }

    return (
        <Fragment>

            {buildRows()}
        </Fragment>
    );
}

export { Row };
