import React, {Fragment, useState, useContext, useEffect} from "react";
import { useSelector } from 'react-redux'
import { peopledatasets } from '../../OrganizationPage';
import {filter} from "lodash"
import SelectPeopleModal from "./PeopleModalSelectPeople";


function PeopleModal(props) {
    const { showPeopleModal, togglePeopleModal, roleid, onPeopleDataChanged,  toggleConfirmDeleteModal} = props;
    const {roleview, personview, competencies, rolecompetencies} = useContext(peopledatasets);
    const tabs = ['Role', 'Reporting', 'People', 'Compensation', 'Competencies'];
    const tabsshort = ['Role', 'Compensation'];
    const [activetab, setActivetab] = useState(tabs[0]);
    const [role, setRole] = useState(null);  
    const [rolestartdate, setRoleStartDate] = useState(null);    
    const [roleenddate, setRoleEndDate] = useState(null);    
    const [compensationcurrency, setCompensationCurrency] = useState('CAD');    
    const [compensationbase, setCompensationBase] = useState(null);    
    const [compensationSTI, setCompensationSTI] = useState(null);    
    const [compensationLTI, setCompensationLTI] = useState(null);    
    const [compensationbenefits, setCompensationBenefits] = useState(null);     
    const rolerow = filter(roleview.results, {role_id: roleid});
    const rolevalue= rolerow.length!==0?rolerow[0].role:null;
    const rolestartdatevalue= rolerow.length!==0?rolerow[0].role_startdate:null;
    const roleenddatevalue= rolerow.length!==0?rolerow[0].role_enddate:null;
    const rolecompensationbasevalue= rolerow.length!==0?rolerow[0].compensationbase:null;
    const rolecompensationstivalue= rolerow.length!==0?rolerow[0].compensationsti:null;
    const rolecompensationltivalue= rolerow.length!==0?rolerow[0].compensationlti:null;
    const rolecompensationbenefitsvalue= rolerow.length!==0?rolerow[0].compensationbenefits:null;
    const rolecompensationcurrencyvalue= rolerow.length!==0?rolerow[0].compensation_currency:null;
    const [isSelectPeopleModal, setIsSelectPeopleModal] = useState(false);    
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)

    useEffect(() => {
      setRole(rolevalue);
      setRoleStartDate(rolestartdatevalue)
      setRoleEndDate(roleenddatevalue)
      setCompensationBase(rolecompensationbasevalue)
      setCompensationSTI(rolecompensationstivalue)
      setCompensationLTI(rolecompensationltivalue)
      setCompensationBenefits(rolecompensationbenefitsvalue)
      setCompensationCurrency(rolecompensationcurrencyvalue)

      }, [roleid, rolevalue, rolestartdatevalue,roleenddatevalue, rolecompensationbasevalue, rolecompensationstivalue, rolecompensationltivalue, rolecompensationbenefitsvalue, rolecompensationcurrencyvalue]);

    let modalTitle=(!roleid?"ADD ROLE":"EDIT ROLE")
    let modalClass = "modal";
    if (showPeopleModal) {
        modalClass += " is-active";
    }

    function toggleSelectPeopleModal(open) {
        setIsSelectPeopleModal(open)
    }

    function onChangeRole(event) {
        console.log("at Change role", event.target.value)
        setRole(event.target.value)
    }

    function onChangeRoleStartDate(event) {
        setRoleStartDate(event.target.value)
    }

    function onChangeRoleEndDate(event) {
        setRoleEndDate(event.target.value)
    }

    function onChangeCompensationCurrency(event) {
        setCompensationCurrency(event.target.value)
    }

    function onChangeCompensationBase(event) {
        setCompensationBase(event.target.value)
    }

    function onChangeCompensationSTI(event) {
        setCompensationSTI(event.target.value)
    }

    function onChangeCompensationLTI(event) {
        setCompensationLTI(event.target.value)
    }

    function onChangeCompensationBenefits(event) {
        setCompensationBenefits(event.target.value)
    }

    function formtabs() {
                return (
                    <div className="tabs is-boxed">
                        <ul>
                            {roleid !== undefined &&
                            <Fragment>
                            {tabs.map(tab => (
                                <li className ={activetab === tab ? "is-active" : ""}
                                    key={"tab_"+tab}
                                    onClick={() => setActivetab(tab)}
                                    style={{color:"grey"}}
                                >
                                <a>{tab}</a>
                                </li>
                            ))}
                            </Fragment>
                            }
                            {roleid === undefined &&
                            <Fragment>
                            {tabsshort.map(tab => (
                                <li className ={activetab === tab ? "is-active" : ""}
                                    key={"tab_"+tab}
                                    onClick={() => setActivetab(tab)}
                                    style={{color:"grey"}}
                                >
                                <a>{tab}</a>
                                </li>
                            ))}
                            </Fragment>
                            }
                        </ul>
                    </div>
                )
            }
    

    function renderrole() {

        return(
            <Fragment>
            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">Role Title</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            className="input"
                            value={role}
                            onChange={event => onChangeRole(event)}
                            />
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">Start Date</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={rolestartdate}
                            onChange={onChangeRoleStartDate}
                            />
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">End Date</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="date" 
                            className="input"
                            value={roleenddate}
                            onChange={onChangeRoleEndDate}
                            />
                    </div>
                </div>
            </div>
            </Fragment>
        )
    }

    function rendercompensation() {

        return (
            <Fragment>

            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">Currency</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <select 
                            className="select"
                            style={{width:'100%'}}
                            value={compensationcurrency}
                            onChange={onChangeCompensationCurrency}
                            >
                            <option>CAD</option>
                            <option>USD</option>
                            <option>EUR</option>
                            <option>GBP</option>

                        </select>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center" >
                <label className="label field-label">Total</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            style={{borderWidth:0, boxShadow: "none", pointerEvents: "none"}}
                            readOnly
                            className="input"
                            value={Number(compensationbase) + Number(compensationSTI) + Number(compensationLTI) + Number(compensationbenefits)}
                            />
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">Base</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="number"
                            className="input"
                            value={compensationbase}
                            onChange={onChangeCompensationBase}
                            />
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">Short Term Incentive</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="number"
                            className="input"
                            value={compensationSTI}
                            onChange={onChangeCompensationSTI}
                            />
                    </div>
                </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
            <label className="label field-label">Long-term Incentive</label>
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <input 
                        type="number"
                        className="input"
                        value={compensationLTI}
                        onChange={onChangeCompensationLTI}
                        />
                </div>
            </div>
            </div>

            <div className="field is-horizontal is-align-items-center">
                <label className="label field-label">Benefits</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <input 
                            type="number"
                            className="input"
                            value={compensationbenefits}
                            onChange={onChangeCompensationBenefits}
                            />
                    </div>
                </div>
            </div>
            </Fragment>
        )
    }

    function renderreporting() {
        let reportingrow=filter(roleview.results, o => (o.role_id === roleid && o.parent_id !== null)) 
        if (reportingrow.length !== 0) {
            return (
                <Fragment>
                <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                    <tbody>
                    {reportingrow.map((row, index) => (
                    <Fragment>
                        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"600"}} ><p>{row.parent}<span className="pl-2" style={{fontWeight:400, fontSize:"smaller"}}>{reportingtenure(index)}</span></p></td>     
                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                    toggleConfirmDeleteModal(true, row.competency, row.pk, 'competency') 
                            }}>
                            <button className="far fa-trash-alt" style={{color:"gray"}}
                                ></button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
                </tbody>
                </table>
                </Fragment>
            )
        } else {
            return (
                <p className="pl-4" style={{fontWeight:'500', fontSize:'15px'}}>No Manager Assigned</p>
            )
        }
    }

    function reportingtenure(rolenumber) {
        let tenure=null
        if (rolerow[rolenumber].reporting_startdate !== null || rolerow[rolenumber].reporting_enddate!== null) {
            tenure=' ['
            if (rolerow[rolenumber].reporting_startdate !== null) {
                tenure=tenure + ' Starts: ' +  (rolerow[rolenumber].reporting_startdate).toString()
            }
            if (rolerow[rolenumber].reporting_enddate !== null) {
                tenure=tenure + ' Ends: ' +  (rolerow[rolenumber].reporting_enddate).toString()
            }
            tenure=tenure +' ]'
        }
        return(tenure)
    }

    function renderpeople() {
        let peoplerow=filter(personview.results,{position_id: roleid})
        let tenure=null
        if (peoplerow.length !== 0) {
            if (peoplerow[0].startdate !== null || peoplerow[0].enddate!== null) {
                tenure=' ['
                if (peoplerow[0].startdate !== null) {
                    tenure=tenure + ' Starts: ' +  (peoplerow[0].startdate).toString()
                }
                if (peoplerow[0].enddate !== null) {
                    tenure=tenure + ' Ends: ' +  (peoplerow[0].enddate).toString()
                }
                tenure=tenure +' ]'
            }
        }
        if (peoplerow.length !== 0) {
            return (
                <Fragment>
                <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                    <tbody>
                    {peoplerow.map(row => (
                    <Fragment>
                        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"600"}} ><p>{row.firstname + ' ' + row.secondname}<span className="pl-2" style={{fontWeight:400, fontSize:"smaller"}}>{tenure}</span></p></td>     
                            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                    toggleConfirmDeleteModal(true, row.competency, row.pk, 'competency') 
                            }}>
                            <button className="far fa-trash-alt" style={{color:"gray"}}
                                ></button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
                </tbody>
                </table>
                </Fragment>
            )
        } else {
            return (
                <p className="pl-4" style={{fontWeight:'500', fontSize:'15px'}}>No Employee Assigned</p>
            )
        }
    }

    function rendercompetencies() {
        let rolecompetencyrow=filter(rolecompetencies,{role_id: roleid})
        let competency_ids = rolecompetencyrow.map(r => r.competency_id)
        let competenciesrow=filter( competencies, (row) => competency_ids.includes(row.pk))
        console.log("Competency", rolecompetencyrow, competency_ids, competenciesrow, rolecompetencies, competencies)
        if (competenciesrow.length !== 0) {
            return (
                <Fragment>
                    <table className='table is-fullwidth' style={{borderCollapse: 'separate', borderSpacing: '0 10px'}}>
                        <tbody>
                        {competenciesrow && competenciesrow.map(row => (
                            <Fragment>
                                <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
                                    <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', fontWeight:"600"}} >{row.competency}</td>     
                                    <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                                            toggleConfirmDeleteModal(true, row.competency, row.pk, 'competency') 
                                    }}>
                                    <button className="far fa-trash-alt" style={{color:"gray"}}
                                        ></button>
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                        </tbody>
                    </table>
                </Fragment>
            )
        } else {
            return (
                <p className="pl-4" style={{fontWeight:'500', fontSize:'15px'}}>No Competencies Assigned</p>
            )
        }
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard">
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className="delete" aria-label="close" onClick={() => { togglePeopleModal(false); setActivetab(tabs[0]); }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{minHeight: "450px"}}>
                        {formtabs()}
                        {activetab==='Role' && renderrole()}
                        {activetab==='Compensation' && rendercompensation()}
                        {activetab==='Reporting' && renderreporting()}
                        {activetab==='Competencies' && rendercompetencies()}
                        {activetab==='People' && renderpeople()}                        

                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <nav className='level'>
                            <div className='level-left'>
                                <div className='level-item'>
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className="button is-success is-small"
                                        key="save"
                                        onClick={async () => {
                                            await onPeopleDataChanged(roleid,role,rolestartdate,roleenddate,compensationbase,compensationSTI, compensationLTI, compensationbenefits, compensationcurrency );
                                            togglePeopleModal(false);
                                            //(roleid && roleid!== null && togglePeopleModal(false));
                                            //setActivetab(tabs[0]);
                                        }}
                                    >
                                        Save changes
                                    </button>
                                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                                        onClick={async () => {
                                        togglePeopleModal(false);
                                        setActivetab(tabs[0]);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className='level-right'>
                                <div className='level-item'>
                                    {activetab==="Reporting" && <button className="button is-pulled-right is-small" key="newreport" style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} >
                                        Add Reporting
                                    </button>}
                                    {activetab==="Competencies" && <button className="button is-pulled-right is-small" key="newcompetency" style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} >
                                        Assign Competency
                                    </button>}
                                    {activetab==="People" && <button className="button is-pulled-right is-small" key="newemployee" onClick={() => toggleSelectPeopleModal(true)} style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} >
                                        Assign Employee
                                    </button>}
                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>

        <SelectPeopleModal 
            isSelectPeopleModal={isSelectPeopleModal}
            toggleSelectPeopleModal={toggleSelectPeopleModal}
        />

        </Fragment>


    );
}

export default PeopleModal;
