import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, sectionpk: null };

const modalSlice = createSlice({
  name: "roadmapTimelineModal",
  initialState,
  reducers: {
    openRoadmapTimelineModal(state, action) {
      state.isOpen = true;
      state.sectionpk = action.payload;
    },
    closeRoadmapTimelineModal(state) {
      state.isOpen = false;
      state.sectionpk = null;
    },
  },
});

export const { openRoadmapTimelineModal, closeRoadmapTimelineModal } = modalSlice.actions;

export default modalSlice.reducer;


