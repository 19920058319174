import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class WorkersViewService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/workersview/`;
    }

    async getWorkersView() {
        const workersview = await this.getAll();
        return workersview;
    }
}

export const workersViewService = new WorkersViewService();