import { timelinesConstants } from '../constants/timelines';

const initialState = {
  loading: false,
  timelines: [],
  error: null,
}

export function timelines(state = initialState, action) {
  switch (action.type) {
    case timelinesConstants.GET_TIMELINES_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case timelinesConstants.GET_TIMELINES_SUCCESS:
      return {
        ...initialState,
        timelines: action.timelines,
      };
    case timelinesConstants.GET_TIMELINES_FAILURE:
      return { 
        ...initialState,
        error: action.error,
      };
      case timelinesConstants.POST_TIMELINES_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case timelinesConstants.POST_TIMELINES_SUCCESS:
        const addedTimelines = [...state.timelines];
        addedTimelines.push(action.timeline);
        return {
            ...state,
            timelines: addedTimelines,
        };
    case timelinesConstants.POST_TIMELINES_FAILURE:
        return {
            ...state,
            error: action.error,
        };
    case timelinesConstants.DELETE_TIMELINES_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case timelinesConstants.DELETE_TIMELINES_SUCCESS:
        const deletedTimelines = [];
        for (const timeline of state.timelines) {
            if (timeline.pk !== action.id) {
                deletedTimelines.push(timeline);
            }
        }
        return {
            ...state,
            timelines: deletedTimelines,
        };
    case timelinesConstants.DELETE_TIMELINES_FAILURE:
        return {
            ...state,
            error: action.error,
        };
      
    default:
        return state
  }
}

