import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class ThemesService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/themes/`;
    }

    async getTheme() {
        const themes = await this.getAll();
        return themes;
    }
}

export const themesService = new ThemesService();
