import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";
import {authConfig} from "./helpers";
import axios from "axios";

class SuccessYearService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/successyear/`;
    }

    async getSuccess() {
        const successdata = await this.getAll();
        var successyear=successdata.results
        return successyear;
    }
 
}

export const successyearService = new SuccessYearService();