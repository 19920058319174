import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, valuepk: null };

const modalSlice = createSlice({
  name: "valuesModal",
  initialState,
  reducers: {
    openValuesModal(state, action) {
      state.isOpen = true;
      state.valuepk = action.payload;
    },
    closeValuesModal(state) {
      state.isOpen = false;
      state.valuepk = null;
    },
  },
});

export const { openValuesModal, closeValuesModal } = modalSlice.actions;

export default modalSlice.reducer;

const selectState = (state) => state.valuesModal;

export const selectValuesModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectValuesModalFactpk = createSelector(
  selectState,
  (state) => state.valuepk
);
