import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class TalentCompetencyScoresService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/talent/competencyscores`;
  }

  async getTalentCompetencyScores() {
    const competencyscores = await this.getAll();
    return competencyscores;
  }
}

export const talentcompetencyscoresService = new TalentCompetencyScoresService();
