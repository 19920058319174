import React, {Fragment, useState, useContext, useEffect} from "react";
import { peopledatasets } from '../../OrganizationPage';
import {filter} from "lodash"


function SelectPeopleModal(props) {
    const { isSelectPeopleModal, toggleSelectPeopleModal, roleid, onPeopleDataChanged,  toggleConfirmDeleteModal} = props;
    const {defaultbackgroundcolor, defaulttextcolor, lightbackgroundcolor, darkbackgroundcolor, roleview, personview, competencies, rolecompetencies} = useContext(peopledatasets);
    const [role, setRole] = useState(null);  
    const [rolestartdate, setRoleStartDate] = useState(null);    
    const [roleenddate, setRoleEndDate] = useState(null);    
    const [compensationcurrency, setCompensationCurrency] = useState('CAD');    
    const [compensationbase, setCompensationBase] = useState(null);    
    const [compensationSTI, setCompensationSTI] = useState(null);    
    const [compensationLTI, setCompensationLTI] = useState(null);    
    const [compensationbenefits, setCompensationBenefits] = useState(null);     
    const rolerow = filter(roleview.results, {role_id: roleid});
    const rolevalue= rolerow.length!==0?rolerow[0].role:null;

    useEffect(() => {
      setRole(rolevalue);
  
      }, [rolevalue]);

    let modalClass = "modal";
    if (isSelectPeopleModal) {
        modalClass += " is-active";
    }

    function onChangeRole(event) {
        setRole(event.target.value)
    }

    function renderlist() {

        return(
            <Fragment>


        <p className="pb-1 pl-1" style={{fontSize:"12px", fontWeight: "500"}}>Employee:</p>
        <div className="field has-addons">
            <div className="control is-expanded">
                <div className="select is-fullwidth">
                    <select  className="select" style={{width:"100%"}}
                            onChange={event => onChangeRole(event)}
                            >
                          { personview && personview.results.map(row => (
                            <option value={row.pk}>{row.firstname + ' ' + row.secondname}</option>
                            ))}
                    </select>
                </div>
             </div>
                <div className="control">
                    <button className="button is-pulled-right ml-2">New</button>
                </div>
                        </div>


        <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>Start Date In Role:</p>
        <input
            className="input"
            type="date"
            value={rolestartdate}
            onChange={(e) => setRoleStartDate(e.target.value)}
        />

        <p className="pb-1 pl-1 pt-2" style={{fontSize:"12px", fontWeight: "500"}}>End Date In Role:</p>
        <input
            className="input"
            type="date"
            value={roleenddate}
            onChange={(e) => setRoleEndDate(e.target.value)}
        />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard" style={{width:"400px"}}>
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: defaultbackgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: defaulttextcolor}}>ASSIGN EMPLOYEE</p>
                        <button className="delete" aria-label="close" onClick={() => { toggleSelectPeopleModal(false); }}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody">
                      {renderlist()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <nav className='level'>
                            <div className='level-left'>
                                <div className='level-item'>
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className="button is-success is-small"
                                        key="save"
                                        onClick={async () => {
                                            await onPeopleDataChanged(roleid,role,rolestartdate,roleenddate,compensationbase,compensationSTI, compensationLTI, compensationbenefits, compensationcurrency );
                                            toggleSelectPeopleModal(false);
                                        }}
                                    >
                                        Save changes
                                    </button>
                                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                                        onClick={async () => {
                                        toggleSelectPeopleModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </footer>
                </div>
            </div>

        </Fragment>
    );
}

export default SelectPeopleModal;
