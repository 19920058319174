import React, { Fragment, useEffect, useState, useContext } from "react";
import { RenderComments } from "./SuccessSprintComments";
import {filter} from "lodash"
import { successdataset } from '../Success';

function Sprint(props) {
  const {toggleSetCommentModal, toggleInvestmentModal, filteredSprints} = props; 
  const {sponsorsview, workersview} = useContext(successdataset);
  
  console.log("At Success Sprint", filteredSprints)
  function renderSprint(sprint) {
    let sponsorsnames=filter(sponsorsview.results, {sprint:sprint.pk})
    let workersnames=filter(workersview.results, {sprint:sprint.pk})
    return (
      <tr style={{backgroundColor: "rgb(244, 244, 244)"}} key={"sprint_" + sprint.pk}>
        <td className="pl-2 pt-3" style={{borderBottom: "solid 1px rgb(222, 222, 222)", borderTopLeftRadius: "10px",  borderBottomLeftRadius: "10px", width:"40px", borderTop: "solid 1px rgb(222, 222, 222)",borderLeft: "solid 1px rgb(222, 222, 222)" }} ><i className="fas fa-fw fa-running" style={{fontSize: "20px"}}></i></td>
        <td className="pt-2" style={{borderBottom: "solid 1px rgb(222, 222, 222)", borderTop: "solid 1px rgb(222, 222, 222)",borderRight: "solid 1px rgb(222, 222, 222)",fontFamily: "Gotham Extra", borderTopRightRadius: "10px",borderBottomRightRadius: "10px"}}>
          <p style={{fontSize:"22px", fontWeight:"600"}}>{sprint.title}</p>
          <p className="pt-2 mb-4" style={{fontSize:"17px"}}>{sprint.description}</p>
        <table>
          <tbody>
            <tr>
              <td className="pr-4">Owner/s:</td>
                <td>            
                  {sponsorsnames && (sponsorsnames).length !== 0 && 
                  sponsorsnames.map((sponsor) => sponsor.fullname ).join(", ")}
                  
                  {sponsorsnames && (sponsorsnames).length === 0 && 
                  "None Assigned"}
                </td>
            </tr>
            <tr>
              <td className="pr-5">Team Members: </td>
              <td>
                {workersnames && workersnames.length !== 0 &&
                workersnames.map((worker) => worker.fullname).join(", ")}

                {workersnames && workersnames.length === 0 &&
                "None Assigned"}          
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>{sprint.action_status}</td>
            </tr>
            <tr>
              <td>Budgeted:</td>
              <td>{sprint.budget}</td>
            </tr>
            {sprint.budget === "No" && 
            <tr>
              <td >Total Investment:</td>
              <td>$1,200,000 <span onClick={() => toggleInvestmentModal(true, sprint.pk)}>( <a><u>detail</u></a> )</span></td>
            </tr>}
          </tbody>
        </table>
        <br/>

        <RenderComments
          sprint={sprint.pk}
          toggleSetCommentModal={toggleSetCommentModal}
        />
        
    </td>
      </tr>
    )
  }

  if (!filteredSprints) {
    return null;
  }

  return (
    <Fragment>
      <section className="m-0 p-0" style={{paddingBottom: "50px", userSelect: "none"}}>
        <div className="pb-4 m-0 pl-0 pr-0">
            <table className="p-0 m-0" style={{width:"100%", borderSpacing: "0 15px", borderCollapse: "separate"}}>
                <tbody>
                  {filteredSprints.length !== 0 && 
                  <Fragment>
                  {filteredSprints &&
                      filteredSprints.map((sprint) => (
                        renderSprint(sprint)
                      ))}
                  </Fragment>}
                {filteredSprints.length === 0 && 
                <p style={{fontSize:"16.5px"}}>No Sprints Returned</p>
                }
                </tbody>
            </table>
        </div>
      </section>
    </Fragment>
  );
}

export { Sprint };
