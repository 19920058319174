import {useEffect, useState} from "react";
import {tenantsService} from "../../services/TenantsService"

function SetColor(lightColor, darkColor) {
    const [tenants, setTenants] = useState(null);

    async function fetchTenant() {
        const tenants = await tenantsService.getAll();
        setTenants(tenants);  
    }

    useEffect(() => {
        async function fetchData() {
            await fetchTenant();
        }
        fetchData();
      }, []);

    if (!tenants) {
        return null;
    }


    function getTintedColor(color, v) {
        if (color.length >6) { color= color.substring(1,color.length)}
        var rgb = parseInt(color, 16); 
        var r = Math.abs(((rgb >> 16) & 0xFF)+v); if (r>255) r=r-(r-255);
        var g = Math.abs(((rgb >> 8) & 0xFF)+v); if (g>255) g=g-(g-255);
        var b = Math.abs((rgb & 0xFF)+v); if (b>255) b=b-(b-255);
        r = Number(r < 0 || isNaN(r)) ? 0 : ((r > 255) ? 255 : r).toString(16); 
        if (r.length === 1) r = '0' + r;
        g = Number(g < 0 || isNaN(g)) ? 0 : ((g > 255) ? 255 : g).toString(16); 
        if (g.length === 1) g = '0' + g;
        b = Number(b < 0 || isNaN(b)) ? 0 : ((b > 255) ? 255 : b).toString(16); 
        if (b.length === 1) b = '0' + b;
        return "#" + r + g + b;
    } 

    var bgColor=(tenants.results)[0].primarycolor?(tenants.results)[0].primarycolor:'#003399'
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
        return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    let lightercolor = getTintedColor(bgColor,40)
    let darkercolor = getTintedColor(bgColor,-40)
    return [(L > 0.179) ? darkColor : lightColor, bgColor, lightercolor, darkercolor];
    }


export default SetColor;
