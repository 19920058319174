import {loginConstants} from "../constants/login";

let access_token = localStorage.getItem("access_token");
const initialState = access_token ? { loggedIn: false, loginError: false, error: null, access_token} : {};

export function login(state = initialState, action) {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loginError: true,
        error: action.error,
      };
    case loginConstants.LOGIN_CLEAR_ERROR:
      return {};
    case loginConstants.LOGOUT:
      return {};
    default:
      return state
  }
}