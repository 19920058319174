import React, { Fragment } from "react";
import { useSelector } from "react-redux"; 
import Moment from "react-moment";
import {filter} from 'lodash'

function TableGantt(props) {
    const { sectionrows, gantt, sectionpk} = props;
    let sectiongantt=filter(gantt.results,{section: sectionpk})

    function renderGanttHeader() {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Activity</th>
                        <th>StartDate</th>
                        <th>End Date</th>
                        <th>Duration (Days)</th>
                        <th>Percent Complete</th>
                        <th>Dependencies</th>
                    </tr>
                </thead>
            </Fragment>
        )
    }

    function renderRow(row) {
        if (row.percentcomplete === null) {
            var percentcomplete = '0%'
        } else {
            var percentcomplete = row.percentcomplete.toString()+'%'
        }

        return (
        <tr key={"GanttRow_"+row.pk}>
        <td>{row.order}</td>
        <td>{row.taskname}</td>
        {row.startdate !== null && <td><Moment className="p-0 m-0" format="MMMM DD, YYYY">{row.startdate}</Moment></td>}
        {row.startdate === null && <td></td>}
        {row.enddate !== null && <td><Moment className="p-0 m-0" format="MMMM DD, YYYY">{row.enddate}</Moment></td>}
        {row.enddate === null && <td></td>}
        <td>{row.duration}</td>
        <td>{percentcomplete}</td>
        <td>{row.dependencies}</td>

    </tr>
        )
    }

    function renderRows() {
        const rows = [];
        for (const row of sectiongantt) {
            rows.push(renderRow(row));
        }
        return rows;
    }

    function renderGanttBody() {
        
        return (
            <tbody>
                {renderRows()}
            </tbody>
        )
    }


    return (
        <table className={"table is-striped is-fullwidth"}>
            {renderGanttHeader()}
            {renderGanttBody()}           
        </table>
    )
}

export { TableGantt };
