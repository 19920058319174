import { sprintcycleConstants } from '../constants/sprintcycle';

const initialState = {
  loading: false,
  sprints: [],
  error: null,
  sprintcycle: {},
}

export function sprintcycle(state = initialState, action) {
  switch (action.type) {
    case sprintcycleConstants.GET_SPRINTCYCLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case sprintcycleConstants.GET_SPRINTCYCLE_SUCCESS:
      return {
        ...state,
        sprintcycle: action.sprintcycle,
      };
    case sprintcycleConstants.GET_SPRINTCYCLE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state
  }
}