import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class LinkedRoadmapsService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/linkedroadmaps/`;
    }

    async getLinkedRow() {
        const linkedrow = await this.getAll();
        return linkedrow;
    }
}

export const linkedroadmapsService = new LinkedRoadmapsService();
