import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class ChartIndexViewService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/talent/chartindex/`;
    }

    async getMetricsView() {
        const chartindexview = await this.getAll();
        return chartindexview.results;
    }
}

export const chartindexviewService = new ChartIndexViewService();
