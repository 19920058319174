export const userConstants = {
  GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
  GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_FAILURE: "GET_CURRENT_USER_FAILURE",

  SAVE_PROFILE_REQUEST: "SAVE_PROFILE_REQUEST",
  SAVE_PROFILE_SUCCESS: "SAVE_PROFILE_SUCCESS",
  SAVE_PROFILE_FAILURE: "SAVE_PROFILE_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  GET_USER_LIST_REQUEST: "GET_USER_LIST_REQUEST",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "GET_USER_LIST_FAILURE",

  CLEAR_USER_ERROR: "CLEAR_USER_ERROR",

  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
};
