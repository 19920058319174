import React, { useState, useEffect } from "react";
import PageTitle from "./common/PageTitle";
import { useSelector } from "react-redux";
import { usersService } from "../services/UsersService";
import { TenantForm } from "./settings/TenantForm";
import { UserManagement } from "./settings/UserManagement";
import { tenantsService } from "../services/TenantsService";
import ColorModal from "./settings/modals/ColorModal";


function Settings() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentTenant = useSelector((state) => state.tenants.currentTenant);
  const [showModal, setShowModal] = useState(false)
  const isAdmin = usersService.isAdmin(currentUser);
  const [selectedTab, setSelectedTab] = useState("company");
  const [tenant,setTenant] = useState(null);

  async function fetchTenant() {
    const tenant = await tenantsService.getTenant();
    setTenant(tenant);  
  }

  useEffect(() => {
      async function fetchData() {
          await fetchTenant();
      }
      fetchData();
  }, []);

  function toggleModal(open) {
    setShowModal(open)
  }

  async function updateEmailOnChange(domain, key) {
    await tenantsService.patch(key, {emaildomain:domain});
    await fetchTenant();
  }


  if (!isAdmin) {
    return null;
  }
  return (
    <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
    <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em"}}>
      <div style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
          <div className="pr-1">
          SETTINGS
          </div>

        <div className="tabs ml-0 pl-0" >
          <ul className="pl-0 ml-0" style={{border: "none", fontFamily: "Gotham Extra",fontSize: "13px"}}>
            <li
              className={selectedTab === "company" ? "is-active" : ""}
              onClick={() => setSelectedTab("company")}
            >
              <a className="ml-0 pl-0">APPLICATION SETTINGS</a>
            </li>
            |
            <li
              className={selectedTab === "users" ? "is-active" : ""}
              onClick={() => setSelectedTab("users")}
            >
              <a>USER SETTINGS</a>
            </li>
          </ul>
        </div>

        {selectedTab === "company" && 
          <TenantForm 
            tenant={tenant}
            toggleModal={toggleModal}
            updateEmailOnChange ={updateEmailOnChange}
             />}
        
        {selectedTab === "users" && 
          <UserManagement 
          tenantname={currentTenant}
          tenant={tenant} />}

      </div>
      </section>
      
      <ColorModal
        showModal={showModal}
        toggleModal={toggleModal}
      />
    </main>
  );
}

export { Settings };
