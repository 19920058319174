import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class WorkersService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/workers/`;
    }

    async getWorkers() {
        const workers = await this.getAll();
        return workers;
    }
}

export const workersService = new WorkersService();