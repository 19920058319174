import React, { Fragment, useEffect, useState, useContext } from "react";
import { RenderComments } from "./Comments";
import {filter} from "lodash"
import { sprintdatasets } from '../Sprints';
import { SprintLane } from "./SprintLane";
import {sprintService} from "../../services/SprintService";

function SprintDetail(props) {
  const {sprint} = props; 
  const {sponsorsview} = useContext(sprintdatasets);
  const [status,setStatus] = useState('');
  const [budgetted,setBudget] = useState(sprint.budget);

  async function onChangeStatus(e, key) {
    setStatus(e.target.value)
    console.log("Status Set",e.target.value )
    await sprintService.patch(key, {action_status: e.target.value});
  }

  async function onChangeBudget(e, key) {
    setBudget(e.target.value)
    await sprintService.patch(key, {budget: e.target.value});
  }

  useEffect(() => {
    setStatus(sprint.action_status)
  }, [status, sprint ])

  useEffect(() => {
    setBudget(budgetted)
  }, [budgetted])

  let sponsorsnames=filter(sponsorsview.results, {sprint_id:sprint.pk})
  console.log(sprint.title,sprint.action_status, status)

  if (!sprint) {
    return null;
  }

  return (
    <Fragment>
    <div className="mb-4 pl-5 pr-5" style={{border: "1px,solid,rgb(230,230,230)", borderRadius: "8px", backgroundColor: "rgb(242,242,242)"}}>
    <table className="pt-0 mt-0" style={{borderSpacing: "0 7px", borderCollapse: "separate"}}>
    <tbody>
    <tr style={{backgroundColor: "rgb(244, 244, 244)"}} key={"sprint_titke" + sprint.pk}>
      <td><span style={{fontSize: "14px", fontWeight: "700"}}>Sprint Title:</span></td>
      <td><span style={{fontWeight:"500"}}>{sprint.title}</span></td>
    </tr>
    <tr style={{backgroundColor: "rgb(244, 244, 244)"}} key={"sprint_objective" + sprint.pk}>
      <td className="pr-3"><p style={{fontSize: "14px", fontWeight: "700"}}>Objective/s:</p></td>
      <td><span style={{fontWeight:"400"}}>{sprint.description}</span></td>
    </tr>
          <tr>
            <td className="pr-4"><p style={{fontSize: "14px", fontWeight: "700"}}>Sprint Owner/s:</p></td>
              <td>            
                {sponsorsnames && (sponsorsnames).length !== 0 && 
                sponsorsnames.map((sponsor) => sponsor.fullname ).join(", ")}
                
                {sponsorsnames && (sponsorsnames).length === 0 && 
                "None Assigned"}
              </td>
          </tr>
          <tr>
            <td><p style={{fontSize: "14px", fontWeight: "700"}}>Status:</p></td>
            <td>
            <div className="select is-small pt-0 pb-0 mt-0 mb-0" style={{backgroundColor:"transparent"}}>
                <select key={"sprint_select" + sprint.pk} className="pt-0 pb-0 mt-0 mb-0"style={{backgroundColor:"transparent"}} onChange={(e) => onChangeStatus(e,sprint.pk)} value={status}>
                  <option value="Not Started">Not Started</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Suspended">Suspended</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td><p style={{fontSize: "14px", fontWeight: "700"}}>Budgeted:</p></td>
            <td>        
              <div className="select is-small pt-0 pb-0 mt-0 mb-0" style={{backgroundColor:"transparent"}}>
                <select key={"budget_select" + sprint.pk} className="pt-0 pb-0 mt-0 mb-0"style={{backgroundColor:"transparent"}} onChange={(e) => onChangeBudget(e,sprint.pk)} value={budgetted}>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </td>
          </tr>
          {budgetted === "No" && 
          <tr>
            <td ><p style={{fontSize: "14px", fontWeight: "700"}}>Investment:</p></td>
            {/* <td>$1,200,000 <span onClick={() => toggleInvestmentModal(true, sprint.pk)}>( <a><u>detail</u></a> )</span></td> */}
            <td>$1,200,000 <span>( <a><u>detail</u></a> )</span></td>
          </tr>}
        </tbody>
      </table>
      <br/>
      {sprint.sprintlane &&
      <SprintLane sprintid = {sprint.pk} />
      }
      <RenderComments
        sprint={sprint.pk}
        //toggleSetCommentModal={toggleSetCommentModal}
      />
      </div>
      <br/>
      </Fragment>
    )
  }

export { SprintDetail };
