import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, chartpk: null };

const modalSlice = createSlice({
  name: "roadmapChartModal",
  initialState,
  reducers: {
    openRoadmapChartModal(state, action) {
      state.isOpen = true;
      state.chartpk = action.payload;
    },
    closeRoadmapChartModal(state) {
      state.isOpen = false;
      state.chartpk = null;
    },
  },
});

export const { openRoadmapChartModal, closeRoadmapChartModal } = modalSlice.actions;

export default modalSlice.reducer;


