import React, { Fragment } from "react";
import Moment from 'react-moment';
import 'moment-timezone';

function Register(props) {
    return (
        <Fragment>
            <p className="ml-0" key={"Register_" + props.pk}>
                <span className="pr-2"style={{ fontFamily: "Sans-serif", fontWeight: 600, fontSize:"15px" }}>{props.username} </span>
                <span style={{ fontSize:"14px" }}>
                    <Moment format='DD MMMM YYYY' tz='America/Los_Angeles'>
                        {props.date}
                    </Moment>
                    </span>
            </p>
            <p className="ml-0" style={{ fontWeight: 500, fontSize:"16px" }}>{props.comment}</p>
            <br />
        </Fragment>
    );
}

export { Register };
