import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'

const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '0rem',
    backgroundColor: 'white',
    cursor: 'move',
}

export const Card = ({id, text, index, moveCard, rowPk, sectionpk, toggleConfirmDeleteModal, toggleSectionsRowModal}) => {
    const ref = useRef(null)
    const [{handlerId}, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{isDragging}, drag] = useDrag({
        type: 'card',
        item: () => {
            return {id, index}
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        //<div className={"notification"} ref={ref} style={{cursor: "move", opacity}} data-handler-id={handlerId}
        <div className="p-2 mb-2" ref={ref} style={{cursor: "move", opacity, border: "solid 1px gray", borderRadius: '5px', textAlign: 'justify'}} data-handler-id={handlerId} onDoubleClick={() => toggleSectionsRowModal(true, rowPk, sectionpk)}>
                <nav className="navbar m-0 p-0" style={{minHeight: 'unset', height:'auto !important'}}>
                <div className="navbar-start pl-3" >
                {text}
                    </div>
                    <div className="navbar-end pl-5" onClick={async () => {
                toggleConfirmDeleteModal(true, text, rowPk, 'timeline');
            }}>
                    <i className="far fa-trash-alt is-pulled-right mt-1" style={{color:"gray"}}  />
                    </div>
                </nav>
         
           
        </div>
    )
}