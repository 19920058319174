import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class MetricsViewService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/talent/metrics/`;
    }

    async getMetricsView() {
        const metricsview = await this.getAll();
        return metricsview.results;
    }
}

export const metricsviewService = new MetricsViewService();
