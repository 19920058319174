import React, {useEffect, useState} from "react";
import "./components/common/css/masterstyles.css";
import {Menu} from "./components/Menu";
import {PurposePage} from "./components/PurposePage";
import {Success} from "./components/Success";
import {Roadmaps} from "./components/Roadmaps";
import {Sprints} from "./components/Sprints";
import {Company} from "./components/Company";
import {Innovation} from "./components/Innovation";
import {Investments} from "./components/Investments";
import {Login} from "./components/Login";
import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "./redux/actions/users";
import {tenantsActions} from "./redux/actions/tenants";
import {tenantsService} from "./services/TenantsService";
import {alerts} from "./redux/actions/alerts";
import {OrganizationPage} from "./components/OrganizationPage";
import {Support} from "./components/Support";
import {Setup} from "./components/Setup";
import {Profile} from "./components/Profile";
import {Settings} from "./components/Settings";
import {Esg} from "./components/Esg";
import {Home} from "./components/Home";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {HTML5Backend} from 'react-dnd-html5-backend'
import {DndProvider} from 'react-dnd'
import {authService} from "./services/AuthService";

function App() {
    const alert = useSelector((state) => state.alerts);
    const loggedIn = useSelector((state) => authService.isLoggedIn());
    const dispatch = useDispatch();

    useEffect(() => {
        if (loggedIn) {
            dispatch(usersActions.getCurrentUser());
            dispatch(tenantsActions.getCurrentTenant());
            dispatch(alerts.clear());
        }
    }, [loggedIn, dispatch]);




    function renderNotification() {
        if (alert.type === "danger") {
            return (
                <div className="notification is-danger" style={{paddingTop: "3.5em"}}>
                    <button className="delete" onClick={() => alerts.clear()}>
                        {" "}
                    </button>
                    {alert.message}
                </div>
            );
        }
        return null;
    }

    if (!loggedIn) {
        return <Login/>;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                className="container is-fluid"
                style={{paddingLeft: 0, paddingRight: 0}}
            >
                <Router>
                    <Menu/>
                    {renderNotification()}
                    <Switch>
                        <Route exact path="/home">
                            <Home/>
                        </Route>
                        <Route exact path="/company">
                            <Company/>
                        </Route>
                        <Route exact path="/purpose">
                            <PurposePage/>
                        </Route>
                        <Route exact path="/success">
                            <Success/>
                        </Route>
                        <Route exact path="/roadmaps">
                            <Roadmaps/>
                        </Route>
                        <Route exact path="/sprints">
                            <Sprints/>
                        </Route>
                        <Route exact path="/investments">
                            <Investments/>
                        </Route>
                        <Route exact path="/innovation">
                            <Innovation/>
                        </Route>
                        <Route exact path="/organization">
                            <OrganizationPage/>
                        </Route>
                        <Route exact path="/support">
                            <Support/>
                        </Route>
                        <Route exact path="/setup">
                            <Setup/>
                        </Route>
                        <Route exact path="/profile">
                            <Profile/>
                        </Route>
                        <Route exact path="/settings">
                            <Settings/>
                        </Route>
                        <Route exact path="/esg">
                            <Esg/>
                        </Route>
                        <Route exact path="/">
                            <Company/>
                        </Route>
                    </Switch>
                </Router>
            </div>
        </DndProvider>
    );
}

export {App};
