import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class InvestmentCellService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/sprintinvestmentcell/`;
    }

    async getInvestmentCell() {
        const investmentcell = await this.getAll();
        return investmentcell;
    }
}

export const investmentsprintcellService = new InvestmentCellService();