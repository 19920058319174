export const peopleConstants = {
    GET_PEOPLE_REQUEST: 'GET_PEOPLE_REQUEST',
    GET_PEOPLE_SUCCESS: 'GET_PEOPLE_SUCCESS',
    GET_PEOPLE_FAILURE: 'GET_PEOPLE_FAILURE',

    POST_PEOPLE_REQUEST: 'POST_PEOPLE_REQUEST',
    POST_PEOPLE_SUCCESS: 'POST_PEOPLE_SUCCESS',
    POST_PEOPLE_FAILURE: 'POST_PEOPLE_FAILURE',

    DELETE_PEOPLE_REQUEST: 'DELETE_PEOPLE_REQUEST',
    DELETE_PEOPLE_SUCCESS: 'DELETE_PEOPLE_SUCCESS',
    DELETE_PEOPLE_FAILURE: 'DELETE_PEOPLE_FAILURE',

    PATCH_PEOPLE_REQUEST: 'PATCH_PEOPLE_REQUEST',
    PATCH_PEOPLE_SUCCESS: 'PATCH_PEOPLE_SUCCESS',
    PATCH_PEOPLE_FAILURE: 'PATCH_PEOPLE_FAILURE',
};
