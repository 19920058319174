import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class IdeasService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/ideas/`;
    }

    async getIdea() {
        const ideas = await this.getAll();
        return ideas;
    }
}

export const ideasService = new IdeasService();
