import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class PersonViewService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/personview/`;
    }

    async getPersons() {
        const persons = await this.getAll();
        return persons;
    }
}

export const personviewService = new PersonViewService();
