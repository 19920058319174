import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllCategories = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/categories`,
    authConfig()
  );
  console.log("all categories", response.data);
  return response.data;
};

export const fetchCategory = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/categories/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCategory = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/categories/`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCategory = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/categories/${data.id}/`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCategory = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/categories/${id}`,
    authConfig()
  );
  return response.data;
};

const categoriesAPI = {
  fetchAllCategories,
  fetchCategory,
  createCategory,
  updateCategory,
  deleteCategory,
};
export default categoriesAPI;
