export const sprintsConstants = {
    GET_SPRINTS_REQUEST: 'GET_SPRINTS_REQUEST',
    GET_SPRINTS_SUCCESS: 'GET_SPRINTS_SUCCESS',
    GET_SPRINTS_FAILURE: 'GET_SPRINTS_FAILURE',

    POST_SPRINTS_REQUEST: 'POST_SPRINTS_REQUEST',
    POST_SPRINTS_SUCCESS: 'POST_SPRINTS_SUCCESS',
    POST_SPRINTS_FAILURE: 'POST_SPRINTS_FAILURE',

    DELETE_SPRINTS_REQUEST: 'DELETE_SPRINTS_REQUEST',
    DELETE_SPRINTS_SUCCESS: 'DELETE_SPRINTS_SUCCESS',
    DELETE_SPRINTS_FAILURE: 'DELETE_SPRINTS_FAILURE',

    PATCH_SPRINTS_REQUEST: 'PATCH_SPRINTS_REQUEST',
    PATCH_SPRINTS_SUCCESS: 'PATCH_SPRINTS_SUCCESS',
    PATCH_SPRINTS_FAILURE: 'PATCH_SPRINTS_FAILURE',
};
