import React, { Fragment, useEffect, useState, createContext } from 'react';
import './common/css/main.css';
import './common/css/masterstyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { menuActions } from '../redux/actions/menu';
import { SuccessLinks } from './success/SuccessLinks';
import { SuccessGrid } from './success/SuccessGrid';
import { successService } from '../services/SuccessService';
import { successyearService } from '../services/SuccessYearService';
import { sectionsService } from '../services/SectionsService';
import { roadmapsrowService } from '../services/RoadmapsRowService';
import { linkedroadmapsService } from '../services/LinkedRoadmapsService';
import { linkedthemesService } from '../services/LinkedThemesService';
import { linkedsprintsService } from '../services/LinkedSprintsService';
import {registersService} from "../services/RegistersService"
import {sponsorViewService} from "../services/SprintSponsorsViewService"
import {workersViewService} from "../services/SprintWorkersViewService"
import SuccessModal from './success/modals/SuccessModal';
import SuccessYearModal from './success/modals/SuccessYearModal';
import SuccessModalRows from './success/modals/SuccessModalRows';
import ConfirmDeleteModal from './common/ConfirmDeleteModal';
import SuccessModalLinks from './success/modals/SuccessModalLinks';
import {themesService} from '../services/ThemesService'
import {sprintService} from '../services/SprintService'
import {baselineValuesService} from '../services/BaselineValuesService'
import { baselineDeltaService } from "../services/BaselineDeltaService";
import {filter} from 'lodash'
import { RotatingLines } from "react-loader-spinner";
import Spinner from "./common/Spinner";

export const successdataset = createContext();

function Success() {

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(null);
  const [successyear, setSuccessYear] = useState(null);
  const [themes,setThemes] = useState(null);
  const [detail, setDetail] = useState(null);
  const [baseline, setBaseline] = useState(null);
  const [baselinedelta,setBaselineDelta] = useState(null);
  const [registers,setRegister] = useState(null);
  const [roadmapVisible, setroadmapVisible] = useState(true);
  const [innovationVisible, setinnovationVisible] = useState(true);
  const [actionsVisible, setactionsVisible] = useState(true);
  const [isGridVisible, setIsGridVisible] = useState(true);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [sections,setSection] = useState(null);
  const [rows,setRows] = useState(null);
  const [sprints,setSprint] = useState(null);
  const [workersview, setWorkersView] = useState(null);
  const [sponsorsview, setSponsorsView] = useState(null);
  const [linkedrows,setLinkedRows] = useState(null);
  const [linkedthemes,setLinkedThemes] = useState(null);
  const [linkedsprints,setLinkedSprints] = useState(null);
  const [linkedmodaltype,setLinkedModaltype] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSuccessYearModalOpen, setIsSuccessYearModalOpen] = useState(false);
  const [isSuccessRowModalOpen, setIsSuccessRowModalOpen] = useState(false);
  //const [isSuccessConfirmModalOpen, setIsSuccessConfirmModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);    
  const [isSuccessLinksModalOpen, setIsSuccessLinksModalOpen] = useState(false);
  const [sectionkey,setSectionkey] = useState(null);
  const [successkey,setSuccesskey] = useState(null);
  const [deletemodaltext, setDeleteModalText] = useState(null);  
  const [deleterecordkey, setDeleteRecordKey] = useState(null);  
  const [deleterecordsource, setDeleteRecordSource] = useState(null);  
  const [modaltab, setModalTab] = useState('Details'); 
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const [isDataLoading, setIsDataLoading] = useState(false);


  async function getSuccess() {
    const success = await successService.getSuccess();
    setSuccess(success);  
} 

  async function fetchSuccess() {
    await getSuccess();
    const successyear = await successyearService.getAll();
    setSuccessYear(successyear);    
    const themes = await themesService.getTheme();
    setThemes(themes);    
    const sprints = await sprintService.getSprint();
    setSprint(sprints);    
    const sections = await sectionsService.getSections();
    setSection(sections);  
    const rows = await roadmapsrowService.getRows();
    setRows(rows)
    const linkedrows = await linkedroadmapsService.getLinkedRow();
    setLinkedRows(linkedrows)
    const linkedthemes = await linkedthemesService.getLinkedTheme();
    setLinkedThemes(linkedthemes)
    const linkedsprints = await linkedsprintsService.getLinkedSprint();
    setLinkedSprints(linkedsprints)
    const baseline = await baselineValuesService.getCells();
    setBaseline(baseline);     
    const baselinedelta = await baselineDeltaService.getCells();
    setBaselineDelta(baselinedelta);     
    const sponsorsview = await sponsorViewService.getAll();
    setSponsorsView(sponsorsview);  
    const workersview = await workersViewService.getAll();
    setWorkersView(workersview);  
    const registers = await registersService.getRegister();
    setRegister(registers); 
  }

  useEffect(() => {
      async function fetchData() {
        setIsDataLoading(true);
        await fetchSuccess();
        setIsDataLoading(false);
      }

      fetchData();
  }, []);


  useEffect(() => {
    dispatch(menuActions.setActiveMenu('success'));
  }, );

  function toggleSuccessModal(open, row, successid) {
    setIsSuccessModalOpen(open);
    setSuccesskey(successid)
    setModalTab('Details')

  }



  function toggleSuccessYearModal(open) {
    setIsSuccessYearModalOpen(open);
  }


  function toggleConfirmDeleteModal(open, messagetext, key, source) {
    console.log("Deleytemodal", open, messagetext, key, source )
    setDeleteRecordKey(key)
    setIsConfirmDeleteModalOpen(open);
    setDeleteModalText(messagetext)
    setDeleteRecordSource(source)
}

  function toggleSuccessLinksModal(open, type) {
    console.log("LMT",type)
    setIsSuccessLinksModalOpen(open);
    let linktype=type
    setLinkedModaltype(linktype)
    console.log("LMT", type)
  }

  function toggleSuccessRowModal(open, success_id, tab) {
    let key=success_id
    setSectionkey(key)
    let tabvalue=tab
    if (open===false) {
      setModalTab('Details')
    }else {
    setModalTab(tabvalue)
    }
    setIsSuccessRowModalOpen(open);
  }

  function hideSection(success) {
    setIsGridVisible(false);
    setIsDetailVisible(true);
    setDetail(success);
   }

  function hideDetail(success) {
    setIsDetailVisible(false);
    setIsGridVisible(true);
  }

  function hideRoadmapsSection() {
    setroadmapVisible(false);
  }

  function showRoadmapsSection() {
    setroadmapVisible(true);
  }

  function hideInnovationSection() {
    setinnovationVisible(false);
  }

  function showInnovationSection() {
    setinnovationVisible(true);
  }

  function hideActionsSection() {
    setactionsVisible(false);
  }

  function showActionsSection() {
    setactionsVisible(true);
  }

  async function onSuccessRecordChanged(name,description,key) {
    if (key) {
      await successService.patch(key, {name: name, description: description});
    } else {
        await successService.post({name: name, description: description});
    }
    await fetchSuccess();
  }

  async function onSuccessYearChanged(year) {
      if (successyear.results.length !== 0) {
      await successyearService.patch(successyear.results[0].pk, {year: year});
    } else {
      await successyearService.post({year: year});
    }
    await fetchSuccess();
  }

  async function onLinkChanged(rowid, successid, type) {
    if (type==="roadmap") {
      let sectionid=filter(rows,{pk: Number(rowid)})
      await linkedroadmapsService.post({success: detail.pk, row_id: rowid, section_id: (sectionid[0]).section});
    }
    if (type==="theme") {
      console.log("At theme Add", successid, rowid, detail)
      await linkedthemesService.post({success: detail.pk, theme_id: rowid});
    }
    if (type==="sprint") {
      console.log("At sprint Add", successid, Number(rowid))
      await linkedsprintsService.post({success: detail.pk, sprint_id: Number(rowid)});
    }



    await fetchSuccess();
    toggleSuccessLinksModal(false)
  }

   async function onSuccessRecordDeleted(key) {
    await successService.deleteRow(key);
    await fetchSuccess();
  }

  async function onLinkedRoadmapDeleted(key) {
    await linkedroadmapsService.deleteRow(key);
    await fetchSuccess();
  }

  async function onLinkedThemeDeleted(key) {
    console.log("LTD:", key)
    await linkedthemesService.deleteRow(key);
    await fetchSuccess();
  }

  async function onLinkedSprintDeleted(key) {
    await linkedsprintsService.deleteRow(key);
    await fetchSuccess();
  }

  async function onCellDataChanged(key,value,rowid,year) {
    if (key) {
      await baselineValuesService.patch(key, {year: year, numeric_value: value, row_id: rowid});
    } else {
        await baselineValuesService.post({year: year, numeric_value: value, row: rowid});
    }
    await fetchSuccess();
  }


  function toggleGrid() {
    setIsGridVisible(true);
    setIsDetailVisible(false);
  }

  useEffect(() => {
    renderSuccessGrid();
  }, [isGridVisible]);

  if (!success || !rows || !linkedrows || !linkedthemes || !linkedsprints || !sections || !successyear) {
    return <Spinner/>;
  }


  const categories = [...new Set(sections.results.flatMap(({category}) => category))]; 

  function renderSuccessGrid() {
    if (isGridVisible) {
      return (

        <SuccessGrid 
          success = {success}
          baselinedelta = {baselinedelta}
          linkedrows = {linkedrows}
          hideSection = {hideSection}
        />
      ) 
    }
  }

  function renderSuccessSection(success) {
    if (!isDetailVisible) {
      return null;
    }

    return (

    <SuccessLinks 
      success = {success}
      rows = {rows} 
      linkedrows = {linkedrows}
      linkedthemes = {linkedthemes}
      linkedsprints = {linkedsprints}
      hideRoadmapsSection = {hideRoadmapsSection}
      showRoadmapsSection = {showRoadmapsSection}
      hideInnovationSection = {hideInnovationSection}
      showInnovationSection = {showInnovationSection}
      hideActionsSection = {hideActionsSection}
      showActionsSection = {showActionsSection}
      hideDetail = {hideDetail}
      roadmapVisible = {roadmapVisible}
      innovationVisible = {innovationVisible}
      actionsVisible = {actionsVisible}
      detail = {detail}
      onCellDataChanged = {onCellDataChanged}
      toggleSuccessRowModal = {toggleSuccessRowModal}
      />
    )
  }

  if (!success) {
    return <Spinner/>;
  }

  function rendersuccesspage() {
    return (
<successdataset.Provider value={{success, themes, rows, sprints, sections, modaltab, sponsorsview, workersview, registers, linkedrows, linkedthemes, linkedsprints, toggleConfirmDeleteModal, onLinkChanged}}>
    <Fragment>
      <main className='has-navbar-fixed-top'>
        <section style={{paddingLeft :'12%',paddingRight: '12%',marginTop: '5.5em', userSelect: 'none'}}>
          <div style={{fontWeight: '600',fontFamily: 'Gotham Extra',fontSize: '32px',lineHeight: '26px',paddingBottom: '28px'}}>
            <div className="pr-0 mr-0">
              SUCCESS VISION {isDetailVisible && <a style={{fontFamily: "Sans-serif", fontSize: "15px", fontWeight: "500", color: backgroundcolor}} className="is-pulled-right" onClick={() => toggleGrid()}>{"<< Back"}</a>}
              {(!isDetailVisible && successyear.results.length !== 0) &&<button className="button is-small is-pulled-right mr-0" style={{borderColor:backgroundcolor, color: backgroundcolor, borderRadius:"5px"}} onClick={() => toggleSuccessModal(true)}>Edit</button>}
            </div>
            {successyear.results.length !== 0 &&
            <span style={{fontFamily: 'Gotham Extra',fontSize: '16px',fontWeight: '500'}}>BY END OF YEAR <button className= "m-0 p-0" style={{color:"black", fontFamily: 'Gotham Extra',fontSize: '16px',fontWeight: '500'}} onClick={() => toggleSuccessYearModal(true)}>{successyear.results[0].year}</button> OUR SUCCESS WILL REFLECT: </span>}
            {successyear.results.length === 0 &&
            <span style={{fontFamily: 'Gotham Extra',fontSize: '16px',fontWeight: '500'}}>BY END OF YEAR <button className= "button mt-1 pt-0 pb-0" style={{color:"black", fontFamily: 'Gotham Extra',fontSize: '11.5px',fontWeight: '500'}} onClick={() => toggleSuccessYearModal(true)}>Add Year</button> OUR SUCCESS WILL REFLECT: </span>}


          </div>

          {success.length !== 0 &&
            <Fragment>
              {renderSuccessGrid()}
              {renderSuccessSection()}
            </Fragment>
          }

          {success.length === 0 &&
            <Fragment>
                  <button className = "button p-3 mt-4" style={{backgroundColor: "transparent", border: "solid 1px darkgrey", fontFamily: "Gotham Extra", fontSize: "16px"}} onClick={() => toggleSuccessRowModal(true)} >
                    Add Success Vision
                  </button>
            </Fragment>
          }

          <SuccessModal
            showSuccessModal={isSuccessModalOpen}
            toggleSuccessModal={toggleSuccessModal}
            toggleSuccessRowModal={toggleSuccessRowModal}
            toggleSuccessConfirmModal={toggleConfirmDeleteModal}
            getSuccessOnMainPage={getSuccess}
          />

          <SuccessYearModal
            successyear={successyear}
            toggleSuccessYearModal={toggleSuccessYearModal}
            isSuccessYearModalOpen={isSuccessYearModalOpen}
            onDataChanged={onSuccessYearChanged}
          />

          <SuccessModalRows
            showSuccessRowModal={isSuccessRowModalOpen}
            toggleSuccessRowModal={toggleSuccessRowModal}
            toggleSuccessConfirmModal={toggleConfirmDeleteModal}
            toggleSuccessLinksModal={toggleSuccessLinksModal}
            linkedrows={linkedrows}
            linkedsprints={linkedsprints}
            linkedthemes={linkedthemes}
            successkey={sectionkey}
            onDataChanged={onSuccessRecordChanged}
            toggleConfirmDeleteModal={toggleConfirmDeleteModal}
            />

          <ConfirmDeleteModal 
            showModal={isConfirmDeleteModalOpen}
            toggleModal={toggleConfirmDeleteModal}
            modaltext={deletemodaltext}
            onSuccessRecordDelete={onSuccessRecordDeleted}
            deleterecordsource={deleterecordsource}
            recordkey={deleterecordkey}
            onLinkedRoadmapDeleted = {onLinkedRoadmapDeleted}
            onLinkedThemeDeleted = {onLinkedThemeDeleted}
            onLinkedSprintDeleted = {onLinkedSprintDeleted}

          />

          <SuccessModalLinks
            showSuccessLinksModal={isSuccessLinksModalOpen}
            toggleSuccessLinksModal={toggleSuccessLinksModal}
            categories={categories}
            linkModaltype={linkedmodaltype}
            onLinkChanged={onLinkChanged}
          />

        </section>
      </main >
    </Fragment >
    </successdataset.Provider>
    )
  }


  return (
    (isDataLoading?<Spinner/>:rendersuccesspage())
  );
}


export { Success };
