import React, {Fragment, useState, useEffect} from "react";
import "../common/css/dot.css";
import {TalentDots} from "./TalentDots";
import {filter} from "lodash"
import {talentcompetenciesService} from "../../services/TalentCompetenciesService";
import {talentcompetencyscoresService} from "../../services/TalentCompetencyScoresService";
import {talentrolepersonService} from "../../services/TalentRolePersonService";
import {talentrolecompetenciesService} from "../../services/TalentRoleCompetenciesService";


function TalentPosition(props) {
    const {persons ,personname, title, talentNames, toggleTalentModal, rolekey, roleperson, positions, personview} = props;
    const [filteredrows, setFilteredrows] = useState(null);
    const [filteredcompetencies, setFilteredcompetencies] = useState(null);
    const [isTalentVisible, setIsTalentVisible] = useState(false);
    const [recordlength, setRecordlength] = useState(0)
    const [competencies, setComptencies] = useState(props.competencies);
    const [competencyscores, setCompetencyscores] = useState(null);
    const [rolecompetencies, setRoleCompetencies] = useState(null);
    
    async function fetchTalent() {
        const competencyscores = await talentcompetencyscoresService.getTalentCompetencyScores();
        setCompetencyscores(competencyscores);  
        const rolecompetencies = await talentrolecompetenciesService.getTalentRoleCompetencies();
        setRoleCompetencies(rolecompetencies);  
      }
    
      useEffect(() => {
        async function fetchData() {
            await fetchTalent();
        }
    
        fetchData();
      }, []);

      useEffect(() => {
        
        let rolepersonfilteredlength=(filter(roleperson, {role: rolekey})).length
        setRecordlength(rolepersonfilteredlength)

        let rolepersonfiltered=(filter(roleperson, {role: rolekey}))
        let personIds = rolepersonfiltered.map(r => r.person)
        let rolepersonnames=filter( personview.results, (row) => personIds.includes(row.person_id))
        setFilteredrows(rolepersonnames)

        let rolecompfiltered=filter(rolecompetencies, {role_id: rolekey})
        let rolecompIds = rolecompfiltered.map(r => r.competency_id);
        let complist= filter( competencies, (row) => rolecompIds.includes(row.pk));
        setFilteredcompetencies(complist)

        console.log("RP",rolekey, rolecompfiltered, rolecompIds, complist, competencies)

        }, [roleperson, rolecompetencies]);

        if (!competencies || !rolecompetencies || !competencyscores) {
            return null;
        }


    function ReturnCompetencyScores(personID,rowpk) {
        const competencyscoresvalues=filter(competencyscores, {competency_id:rowpk, people_id: personID, role_id: rolekey})
        const comptencyvalue=competencyscoresvalues.map(r => r.score);
        const comment=competencyscoresvalues.map(r => r.comment);
        console.log("Comment", comptencyvalue, comment, competencyscoresvalues)
        return (
            <Fragment>
                <TalentDots
                    score={comptencyvalue[0]}
                   />
                <td className="pl-5">{comment[0]}</td>
            </Fragment>
        )
    }

    function ReturnCompetencies(competency) {
        return (
            <Fragment>
                <td className="pl-6" style={{width:"190px"}}>{competency.competency}</td>
            </Fragment>
        )
    }

    function GetCompetencies(personID) {

        return (
            <Fragment>
                {filteredcompetencies.map(row => (
                    <Fragment>
                        <tr>
                            {ReturnCompetencies(row)}
                            {ReturnCompetencyScores(personID, row.pk)}
                    </tr>
                    </Fragment>
                ))}
            </Fragment>
        )
    }

    function GetRoles() {
        return (
            <Fragment>
                {filteredrows.map(row => (
                    GetCandidates(row)
                ))}
             </Fragment>
        )

    }

    function GetCandidates(row) {
        return (
            <Fragment>
                <tr>
                    <td className="pl-5" colSpan='7'>{row.firstname + ' ' + row.secondname}</td>
                </tr>
                {GetCompetencies(row.pk)} 
            </Fragment>
        )    
    }   

    function getTalentNames() {
        return (
            <th className="pl-3" colSpan='5'> {talentNames}</th>
        )
    }


    if (isTalentVisible) {
        return (
            <Fragment>

                    <tr key={title} onDoubleClick={() => toggleTalentModal(true)}>
                        <th onClick={() => setIsTalentVisible(false)} colSpan='7'
                            >▼ {title} ({recordlength})
                        </th>
                    </tr>
                    {GetRoles()}

            </Fragment>
        );
    } else {
        return (
            <Fragment>

                    <tr key={title} onClick={() => setIsTalentVisible(true)}>
                        <th colSpan='7'>► {title} ({recordlength})
                        </th>
                    </tr>
              </Fragment>
        );
    }
}

export {TalentPosition};