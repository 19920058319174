import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, stakeholder: null };

const modalSlice = createSlice({
  name: "stakeholderModal",
  initialState,
  reducers: {
    openForNewStakeholder(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.stakeholder = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditing(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.stakeholder = action.payload;
    },
  },
});

export const { openForNewStakeholder, close, openForEditing } = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.stakeholderModal;

export const selectStakeholdersModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectStakeholdersModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectStakeholdersModalStakeholder = createSelector(
  selectState,
  (state) => state.stakeholder
);
