import React, {Fragment, useState, useEffect} from "react";
import "bulma/css/bulma.css";
import {personviewService} from "./../../../services/OrganizationPersonViewService"
import {filter} from "lodash"


function SprintAddPersonModal(props) {
    const {toggleModal, showModal, onAddSponsor, onAddWorker, sprintkey, sprintAddPersonModalType, defaultbackgroundcolor, defaulttextcolor, sponsorsview, workersview} = props;
    const [personview, setPersonView] = useState(null);
    const [personrowid, setPersonRowid] = useState(null);    

    let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }

    async function fetchPersons() {
        const personview = await personviewService.getAll();
        setPersonView(personview);  
      }

    useEffect(() => {
        async function fetchData() {
            await fetchPersons();
        }
    
        fetchData();
      }, []);

    if (!personview || !sprintkey || !sponsorsview) {
        return null;
    }

    function onSelectChange(event) {
        setPersonRowid(event.target.value)
    }

    function renderForm() {
        let options=[]
        let selectlist=[]
        if (sprintAddPersonModalType === "Sponsors") {
            let sponsorsnames=filter( sponsorsview.results, {sprint_id:sprintkey})        
            var sponsorsnameslist = sponsorsnames.map(r => r.person_id)     
            selectlist = filter( personview.results, (row) => !sponsorsnameslist.includes(row.person_id))
        } else {
            let workersnames=filter( workersview.results, {sprint_id:sprintkey})        
            var workersnameslist = workersnames.map(r => r.person_id)     
            selectlist = filter( personview.results, (row) => !workersnameslist.includes(row.person_id))
        }


        for(let i=0; i < (selectlist).length; i++) {
            options.push({value: (selectlist[i]).pk, label: (selectlist[i]).firstname + ' ' + (selectlist[i]).secondname})
        }

        return (
            <Fragment>
                    <div className="select is-fullwidth">
                            <select 
                                 defaultValue={(personview.results[0]).value}
                                 onChange={onSelectChange}
                            >  
                                <option value="" disabled selected hidden>&lt;Select Option&gt;</option>
                                {options.map(row => (
                                    <option value={row.value}>{row.label}</option>
                                ))}
                            </select>
                    </div>

            </Fragment>
        );
    }


    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                
                <div className="modal-card" key="modalCard" style={{width:"500px"}}>
                <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: defaultbackgroundcolor}}>
                        
                        {sprintAddPersonModalType === "Sponsors" &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: defaulttextcolor}}>Sponsor Name</p>}

                        {sprintAddPersonModalType === "Workers" &&
                        <p className="modal-card-title" style={{fontSize:"17px", color: defaulttextcolor}}>Team Member Name</p>}

                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" style={{height:"180px"}}>
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                    {sprintAddPersonModalType === "Sponsors" &&
                        <button style={{backgroundColor: defaultbackgroundcolor, color: defaulttextcolor}}
                            className="button is-small"
                            key="save"
                            onClick={async () => {
                                await onAddSponsor(personrowid, sprintkey);
                                toggleModal(false);
                            }}
                        >
                            Add
                        </button>}

                        {sprintAddPersonModalType === "Workers" &&
                        <button style={{backgroundColor: defaultbackgroundcolor, color: defaulttextcolor}}
                            className="button is-small"
                            key="save"
                            onClick={async () => {
                                await onAddWorker(personrowid, sprintkey);
                                toggleModal(false);
                            }}
                        >
                            Add
                        </button>}                       
                        
                        
                        <button className="button is-small" key="cancel" onClick={() => toggleModal(false)}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintAddPersonModal;