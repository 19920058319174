import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class LinkedSprintsService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/linkedsprints/`;
    }

    async getLinkedSprint() {
        const linkedsprint = await this.getAll();
        return linkedsprint;
    }
}

export const linkedsprintsService = new LinkedSprintsService();
