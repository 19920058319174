import React, { useEffect, Fragment, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import {filter} from "lodash"
import { roadmapdatasets } from '../Roadmaps';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip, Highlight, WaterfallSeries, DataLabel, Zoom, Crosshair, LineSeries } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';


function RoadmapChart(props) {
    const palette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]
    const { sectionrows, sectionPk, chart, chartrows} = props;
    const {rows, minYear, maxYear, cellvalues, growth, cagr} = useContext(roadmapdatasets);
    const [chartid, setChartid] = useState(null);
    const [chartstatus, setChartStatus] = useState(null);
    var chartidvalue = null;
    var chartstatusvalue = null;
    if (chart !== null) {
        var chartlistarray = filter(chart.results, {section: sectionPk})
        if (chartlistarray.length!==0) {
            chartidvalue=chartlistarray[0].pk
            chartstatusvalue = chartlistarray[0].chart_type
        }
    }
    const [chart_title, setTitle] = useState(chartlistarray[0].chart_title);
    const [chart_subtitle, setSubTitle] = useState(chartlistarray[0].chart_subtitle);
    const [chart_yaxis, setYaxis] = useState(chartlistarray[0].chart_yaxislabel);


    useEffect(() => {
        setChartid(chartidvalue)
        setChartStatus(chartstatusvalue)
      }, [chartidvalue])

    if (!chart) {
        return null;
    }

    function stackchart() {
        if (chartstatus==="Stack") {
            let chartdatarowsarray = filter(chartrows.results, {chart_id: chartid})
            let dataarray=[]

            for (const row of chartdatarowsarray) {
                var stackeddata=[]
                for (let year = minYear; year <= maxYear; year++) {
                    let chartrow = filter(cellvalues.results, {row_id: row.row_id, year: year})
                    var stackedobj={}
                    stackedobj['x']=year;
                    stackedobj['y']=chartrow[0].numeric_value;
                    stackeddata.push(stackedobj)
                }
                dataarray.push(stackeddata)
            }

            const onChartLoad = (args) => {
                let chart = document.getElementById('charts');
                chart.setAttribute('title', '');
            };

            const axisLabelRender = (args) => {
                args.text = args.text.replace("0000000", "0M").replace("000000", "M");
            };

            return (
                <ChartComponent id='charts' style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }} palettes={palette} primaryXAxis={{ majorGridLines: { width: 0 }, minorGridLines: { width: 0 }, majorTickLines: { width: 0 }, minorTickLines: { width: 0 }, interval: 1, lineStyle: { width: 0 }, labelIntersectAction: 'Rotate45', valueType: 'Category' }} primaryYAxis={{ title: 'Vehicles Production (In Millions)', lineStyle: { width: 0 }, majorTickLines: { width: 0 }, majorGridLines: { width: 1 }, minorGridLines: { width: 1 }, minorTickLines: { width: 0 }, labelFormat: '{value}' }} width={Browser.isDevice ? '100%' : '75%'} chartArea={{ border: { width: 0 } }} loaded={onChartLoad.bind(this)} tooltip={{ enable: true }} axisLabelRender={axisLabelRender.bind(this)}>
                    <Inject services={[StackingColumnSeries, Category, Legend, Tooltip, Highlight]}/>
                    <SeriesCollectionDirective>
                        {renderstackchart(dataarray, chartdatarowsarray)}
                        {/* <SeriesDirective dataSource={dataarray[0]} xName='x' yName='y' name='General Motors' columnWidth={1} columnSpacing={0} opacity={0.85} border={ "none" } type='StackingColumn'/>
                        <SeriesDirective dataSource={dataarray[1]} xName='x' yName='y' name='Honda' columnWidth={1} columnSpacing={0} opacity={0.85} border={ "none" } type='StackingColumn'/>
                        <SeriesDirective dataSource={dataarray[2]} xName='x' yName='y' name='Suzuki' columnWidth={1} columnSpacing={0} opacity={0.85} border={{ width: 0, color: "transparent" }} type='StackingColumn'/>
                        <SeriesDirective dataSource={dataarray[3]} xName='x' yName='y' name='BMW' columnWidth={1} columnSpacing={0} opacity={0.85} border={{ width: 0, color: "transparent" }} type='StackingColumn'/> */}
                    </SeriesCollectionDirective>
                </ChartComponent>
            )
        }
    };
    
    function renderstackchart(dataarray, chartdatarowsarray) {
        const cells = [];
        for (let i = 0; i < chartdatarowsarray.length; i++) {
            var rowname = (filter(rows, {pk: chartdatarowsarray[i].row_id}))[0].name
            cells.push(<SeriesDirective dataSource={dataarray[i]} xName='x' yName='y' name={rowname} columnWidth={1} columnSpacing={0} opacity={0.85} border={ "none" } type='StackingColumn'/>);
        }
        return cells;
    }


    function ganttchart() {
        if (props.sectiongantt === true) {
            let data=[[
                    { type: 'string', label: 'Task ID' },
                    { type: 'string', label: 'Task Name' },
                    { type: 'date', label: 'Start Date' },
                    { type: 'date', label: 'End Date' },
                    { type: 'number', label: 'Duration' },
                    { type: 'number', label: 'Percent Complete' },
                    { type: 'string', label: 'Dependencies' },
                ]]
            for (const row of sectionrows) {

                if (row.gantt_enddate) {
                var datarow=[(row.order).toString(),row.name,new Date(row.gantt_startdate), new Date(row.gantt_enddate),row.gantt_duration * 86400000,row.gantt_percentcomplete, row.gantt_dependencies]
            } else if (!row.gantt_enddate) { 
                var datarow=[(row.order).toString(),row.name,,,row.gantt_duration * 86400000,row.gantt_percentcomplete, row.gantt_dependencies]
            } else {

                var datarow=[(row.order).toString(),row.name,new Date(row.gantt_startdate),,row.gantt_duration * 86400000,row.gantt_percentcomplete, row.gantt_dependencies]
            }
            data.push(datarow);
        }
            return (
                <Chart
                width={'100%'}
                chartType="Gantt"
                loader={<div>Loading Chart</div>}
                data={data}
                rootProps={{ 'data-testid': '1' }}
            />

            )
        }
    }


    function waterfallchart() {
        if (chartstatus==="Waterfall") {
            let startvalue=0;
            let endvalue=0;
            let chartdatarowsarray = filter(chartrows.results, {chart_id: chartid});
            let waterfallarray=[];

            for (const row of chartdatarowsarray) {
                let startvaluearray = filter(cellvalues.results, {year: minYear,row_id:row.row_id})
                let endvaluearray = filter(cellvalues.results, {year: maxYear,row_id:row.row_id})

                if (startvaluearray.length !== 0) {
                startvalue = startvalue + startvaluearray[0].numeric_value 
                }
                if (endvaluearray.length !== 0) {
                    endvalue = endvalue + endvaluearray[0].numeric_value 
                }
            }
    
            waterfallarray.push({x: minYear.toString(), y: startvalue})
            for (const row of chartdatarowsarray) {
                let waterfallobj={};
                let cellvalue=[];
                let rowname = (filter(rows, {pk: row.row_id}))[0].name
                cellvalue = filter(cellvalues.results, {year: minYear,row_id:row.row_id})
                let baseyear=cellvalue[0].numeric_value    
                cellvalue = filter(cellvalues.results, {year:maxYear,row_id:row.row_id})
                let finalyear=cellvalue[0].numeric_value     
                waterfallobj['x']=rowname;
                waterfallobj['y']=finalyear-baseyear;
                waterfallarray.push(waterfallobj)
            }

            waterfallarray.push({x: maxYear.toString()})

            const primaryxAxis = { valueType: 'Category', majorGridLines: { width: 0 }, plotOffset: 20 };
            const primaryyAxis = { title: chart_yaxis };
            const marker = { dataLabel: { visible: true, font: { color: '#ffffff' } } };
            const connector = { color: '#000000', width: 1};
            const tooltip = { enable: true, shared: false };
            const legendSettings = { visible: false };
            return (
                <ChartComponent id='charts1' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={tooltip} legendSettings={legendSettings} title={chart_title}>
                    <Inject services={[WaterfallSeries, Category, Tooltip, Zoom, Crosshair, Legend, DataLabel]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={waterfallarray} xName='x' yName='y' name='USA' type='Waterfall' sumIndexes={[5]} marker={marker} connector={connector} columnWidth={0.9} negativeFillColor='#e56590'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>)
        }
    };

    function linechart() {
        if (chartstatus==="Line") {
            let chartdatarowsarray = filter(chartrows.results, {chart_id: chartid});
            var linedata=[];
            for (const row of chartdatarowsarray) {
                let linearray=[];
                for (let year = minYear; year <= maxYear; year++) {
                    let lineobj={};
                    let cellvalue = filter(cellvalues.results, {year:year,row_id:row.row_id})
                    lineobj['x']=year;
                    if (cellvalue.length !== 0) {
                    lineobj['y']=cellvalue[0].numeric_value;
                    } else {
                        lineobj['y']=null;
                    }
                    linearray.push(lineobj);
                }
                linedata.push(linearray);
            }


            const primaryxAxis = { title:'Years', interval: 1};
            const primaryyAxis = { title: chart_yaxis};
            const marker = { visible: true, width: 10, opacity: 1, height: 10 };

            return (
                <ChartComponent id='chartsline' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} palettes={palette} title={chart_title}>
                    <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]}/>
                    <SeriesCollectionDirective>
                        {renderlinechart(chartdatarowsarray, marker, linedata)}
                    </SeriesCollectionDirective>
                </ChartComponent>
            );
        }
    };

    function renderlinechart(chartdatarowsarray, marker, linedata) {
        const cells = [];
        for (let i = 0; i < chartdatarowsarray.length; i++) {
            var rowname = (filter(rows, {pk: chartdatarowsarray[i].row_id}))[0].name
            cells.push(<SeriesDirective dataSource={linedata[i]} xName='x' yName='y' name={rowname} type='Line' width={4} opacity={0.6} marker={marker}/>);
        }
        return cells;
    }



    function displayChart(event) {
        setChartid(Number(event.target.value))
        let chartdata = filter(chartlistarray, {pk: Number(event.target.value)})
        setTitle(chartdata[0].chart_title)
        setSubTitle(chartdata[0].chart_subtitle)
        setYaxis(chartdata[0].chart_yaxislabel)
        setChartStatus(chartdata[0].chart_type)
    }

    function chartdisplay() {
            return (
            <Fragment>
                <div className="pl-4 pt-5">
                    <label style={{fontSize:"14px", fontWeight: 600}}>Displaying Chart: </label>
                    {chartlistarray !== undefined &&
                    <select 
                        className="mb-4 ml-2 pl-2 pr-3"  
                        onChange={(event) => displayChart(event)}
                    >
                        {chartlistarray && chartlistarray.map(chartheader => (
                            <option id={chartheader.pk} key={chartheader.pk} value={chartheader.pk}>{chartheader.chart_type + " Chart: " + chartheader.chart_title}</option>
                        ))}
                    </select>}
                </div>
                </Fragment>
            )
    }



    return (
        <Fragment>
            <div className="pb-3" style={{backgroundColor: "white"}}>
            {chartdisplay()} 
            <div className="ml-4 mr-4 mb-4" style={{border:"1px Gainsboro solid"}}>
            {linechart()}
            {waterfallchart()}  
            {stackchart()}   
            </div>
            </div>
        </Fragment>
    )
}

export { RoadmapChart };
