import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, company: null };

const modalSlice = createSlice({
  name: "purposeModal",
  initialState,
  reducers: {
    openForNewPurpose(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.company = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingpurpose(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.company = action.payload;
    },
  },
});

export const {
  openForNewPurpose,
  close,
  openForEditingpurpose,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.purposeModal;

export const selectPurposeModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectPurposeModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);
