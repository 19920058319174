import {roadmapsConstants} from '../constants/roadmaps';
import set from 'immutable-set';

const initialState = {
    loading: false,
    roadmap: {
        pk: null,
        name: "",
        min_year: null,
        max_year: null,
    },
    sections: [],
    rows: {},
    cellValues: {},
    cellValuesPerRowNumber: {},
    chartData: {},
    error: null,
}

export function roadmaps(state = initialState, action) {
    switch (action.type) {
        case roadmapsConstants.GET_ROADMAP_REQUEST:
            return {
                ...initialState,
                loading: true,
            };
        case roadmapsConstants.GET_ROADMAP_SUCCESS:
            const roadmap = {
                pk: action.roadmap.pk,
                name: action.roadmap.name,
                minYear: action.roadmap.min_year,
                maxYear: action.roadmap.max_year,
            }
            const sections = [];
            const rows = {};
            const cellValues = {};
            const cellValuesPerRowNumber = {};
            console.log("on reducer");
            for (const section of action.roadmap.sections) {
                sections.push({pk: section.pk, name: section.name, narrative: section.narrative, category: section.category, order: section.order, ganttchart: section.ganttchart, linechart: section.linechart, showtotal: section.showtotal, waterfallchart: section.waterfallchart, stackchart: section.stackchart})
                let rowNumber = 1;
                for (const row of section.rows) {
                    if (!(section.pk in rows)) {
                        rows[section.pk.toString()] = [{pk: row.pk, name: row.name, formula: row.formula, order: row.order, type: row.type, cagr: row.cagr, subrow: row.subrow, gantt_startdate: row.gantt_startdate, gantt_enddate: row.gantt_enddate, gantt_duration: row.gantt_duration, gantt_percentcomplete: row.gantt_percentcomplete, gantt_dependencies: row.gantt_dependencies}]
                    } else {
                        rows[section.pk.toString()].push({pk: row.pk, name: row.name, formula: row.formula, order: row.order, type: row.type, cagr: row.cagr, subrow: row.subrow, gantt_startdate: row.gantt_startdate, gantt_enddate: row.gantt_enddate, gantt_duration: row.gantt_duration, gantt_percentcomplete: row.gantt_percentcomplete, gantt_dependencies: row.gantt_dependencies})
                    }
                    const cellsPerYear = {};
                    for (let year = roadmap.minYear; year <= roadmap.maxYear; year++) {
                        cellsPerYear[year.toString()] = {
                            pk: null,
                            row: row.pk,
                            year: year,
                            numeric_value: "",
                            text_value: ""
                        };
                    }
                    for (const cellValue of row.cell_values) {
                        cellsPerYear[cellValue.year.toString()] = {
                            pk: cellValue.pk,
                            row: row.pk,
                            year: cellValue.year,
                            numeric_value: cellValue.numeric_value,
                            text_value: ""
                        };
                    }
                    cellValues[row.pk.toString()] = cellsPerYear;
                    cellValuesPerRowNumber[rowNumber] = cellsPerYear;
                    rowNumber++;
                }
            }
            console.log("calculated", roadmap, "sections", sections, "rows", rows, "cellValues", cellValues, "chartData", action.roadmap.chart_data);
            return {
                roadmap: roadmap,
                sections: sections,
                rows: rows,
                cellValues: cellValues,
                cellValuesPerRowNumber: cellValuesPerRowNumber,
                chartData: action.roadmap.chart_data,
            };
        case roadmapsConstants.GET_ROADMAP_FAILURE:
            return {
                ...initialState,
                error: action.error,
            };
        case roadmapsConstants.POST_CELL_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case roadmapsConstants.POST_CELL_VALUE_SUCCESS:
            const addedCellValue = action.cellValue;
            console.log("addedCellValue", addedCellValue);
            const addedState = set(state, ["cellValues", addedCellValue.row.toString(), addedCellValue.year.toString()], {
                pk: addedCellValue.pk,
                row: addedCellValue.row,
                year: addedCellValue.year,
                numeric_value: addedCellValue.numeric_value,
                text_value: ""
            });
            console.log("added state", addedState);
            return addedState;
        case roadmapsConstants.POST_CELL_VALUE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case roadmapsConstants.DELETE_CELL_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case roadmapsConstants.DELETE_CELL_VALUE_SUCCESS:
            console.log("delete cellValue", action);
            const removedState = set(state, ["cellValues", action.row.toString(), action.year.toString()], {
                pk: null,
                row: action.row,
                year: action.year,
                numeric_value: "",
                text_value: ""
            });
            console.log("removedState", removedState);
            return removedState;
        case roadmapsConstants.DELETE_CELL_VALUE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case roadmapsConstants.GET_CHART_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case roadmapsConstants.GET_CHART_DATA_SUCCESS:
            const updatedChartData = action.chartData;
            const section = action.section;
            console.log("updatedChartData", updatedChartData);
            console.log("sectionPk", section);
            return {
                ...state,
                chartData: {...state.chartData, [section.toString()]: updatedChartData},
            };
        case roadmapsConstants.GET_CHART_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state
    }
}
