import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, valuepk: null };

const modalSlice = createSlice({
  name: "valuesRecordModal",
  initialState,
  reducers: {
    openValuesRecordModal(state, action) {
      state.isOpen = true;
      state.valuepk = action.payload;
    },
    closeValuesRecordModal(state) {
      state.isOpen = false;
      state.valuepk = null;
    },
  },
});

export const {
  openValuesRecordModal,
  closeValuesRecordModal,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.valuesRecordModal;

export const selectValuesRecordModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectValuesRecordModalFactpk = createSelector(
  selectState,
  (state) => state.valuepk
);
