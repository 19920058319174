export const competenciesConstants = {
    GET_COMPETENCIES_REQUEST: 'GET_COMPETENCIES_REQUEST',
    GET_COMPETENCIES_SUCCESS: 'GET_COMPETENCIES_SUCCESS',
    GET_COMPETENCIES_FAILURE: 'GET_COMPETENCIES_FAILURE',

    POST_COMPETENCY_REQUEST: 'POST_COMPETENCY_REQUEST',
    POST_COMPETENCY_SUCCESS: 'POST_COMPETENCY_SUCCESS',
    POST_COMPETENCY_FAILURE: 'POST_COMPETENCY_FAILURE',

    DELETE_COMPETENCY_REQUEST: 'DELETE_COMPETENCY_REQUEST',
    DELETE_COMPETENCY_SUCCESS: 'DELETE_COMPETENCY_SUCCESS',
    DELETE_COMPETENCY_FAILURE: 'DELETE_COMPETENCY_FAILURE',

    PATCH_COMPETENCY_REQUEST: 'PATCH_COMPETENCY_REQUEST',
    PATCH_COMPETENCY_SUCCESS: 'PATCH_COMPETENCY_SUCCESS',
    PATCH_COMPETENCY_FAILURE: 'PATCH_COMPETENCY_FAILURE',
};
