export const behaviorsConstants = {
    GET_BEHAVIORS_REQUEST: 'GET_BEHAVIORS_REQUEST',
    GET_BEHAVIORS_SUCCESS: 'GET_BEHAVIORS_SUCCESS',
    GET_BEHAVIORS_FAILURE: 'GET_BEHAVIORS_FAILURE',

    POST_BEHAVIOR_REQUEST: 'POST_BEHAVIOR_REQUEST',
    POST_BEHAVIOR_SUCCESS: 'POST_BEHAVIOR_SUCCESS',
    POST_BEHAVIOR_FAILURE: 'POST_BEHAVIOR_FAILURE',

    DELETE_BEHAVIOR_REQUEST: 'DELETE_BEHAVIOR_REQUEST',
    DELETE_BEHAVIOR_SUCCESS: 'DELETE_BEHAVIOR_SUCCESS',
    DELETE_BEHAVIOR_FAILURE: 'DELETE_BEHAVIOR_FAILURE',

    PATCH_BEHAVIOR_REQUEST: 'PATCH_BEHAVIOR_REQUEST',
    PATCH_BEHAVIOR_SUCCESS: 'PATCH_BEHAVIOR_SUCCESS',
    PATCH_BEHAVIOR_FAILURE: 'PATCH_BEHAVIOR_FAILURE',
};
