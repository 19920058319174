import React, {Fragment, useState} from "react";
import "../common/css/dot.css";


function TalentDots(props) {
    const {score} = props;
    const [dotsValue, setDotsValue] = useState(score);
    
    function toggleDots(dotvalue) {
        setDotsValue(dotvalue)
    }

    function renderDotsSwitch() {
        switch(dotsValue) {
            case undefined:
                return (
                <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(1)}><p className="greydot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(4)}><p className="greydot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greydot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="greydot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="greydot" /></td>
                </Fragment>)
            case 0:
                return (
                <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(1)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(4)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)
            case 1:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(2)}><p className="bluedot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(4)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)
            case 2:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)
            case 3:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(4)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)            
            case 4:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(5)}><p className="bluedot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)              
            case 5:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 6:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(7)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 7:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(8)}><p className="bluedot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 8:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 9:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(10)}><p className="dot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 10:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(11)}><p className="bluedot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 11:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="dot" /></td>
                </Fragment>)  
            case 12:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(13)}><p className="dot" /></td>
                </Fragment>)  
            case 13:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(14)}><p className="bluedot" /></td>
                </Fragment>)  
            case 14:
                return (
                    <Fragment>
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(0)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(3)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(6)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(9)}><p className="greendot" /></td> 
                    <td className="pl-0 pr-0 ml-0 mr-0" style={{width:"3px"}} onClick={() => toggleDots(12)}><p className="greendot" /></td>
                </Fragment>)  
 
        }
      }


        return (
            <Fragment>
                {renderDotsSwitch()}
            </Fragment>
        )
}

export {TalentDots};