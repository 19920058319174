import { helpModalConstants } from '../constants/helpModal';

export const helpModalActions = {
    openModal,
    closeModal,
};

function openModal() {
    console.log("Open help modal");
    return { type: helpModalConstants.OPEN_MODAL };
}

function closeModal() {
    return { type: helpModalConstants.CLOSE_MODAL };
}
