import { linkedroadmapsConstants } from '../constants/linkedroadmaps';

const initialState = {
  loading: false,
  linkedroadmaps: [],
  error: null,
}

export function linkedroadmaps(state = initialState, action) {
  switch (action.type) {
    case linkedroadmapsConstants.GET_LINKEDROADMAPS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case linkedroadmapsConstants.GET_LINKEDROADMAPS_SUCCESS:
      return {
        ...initialState,
        linkedroadmaps: action.linkedroadmaps,
      };
    case linkedroadmapsConstants.GET_LINKEDROADMAPS_FAILURE:
      return { 
        ...initialState,
        error: action.error,
      };
    default:
      return state
  }
}