import React from 'react';
import { useSelector } from "react-redux";

function Header(props) {
  const {stakeholders, toggleStakeholderModal} = props;
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  return (
    <tr className='th' style={{backgroundColor: backgroundcolor}}>
      <td> </td>
      <td style={{textAlign: 'center', fontWeight:'600', color:textcolor, userSelect: 'none'}}>Company</td>
      {stakeholders.map((stakeholder) => (
        <td key={stakeholder.name} style={{textAlign: 'center', fontWeight:'600'}}>
          <a
            style={{color:textcolor, userSelect: 'none'}}
            onClick={() => toggleStakeholderModal(true, stakeholder.pk)}
          >
            {stakeholder.name}
          </a>
        </td>
      ))}
    </tr>
  );
}

export default Header;
