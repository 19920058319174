import React from "react";
import "../common/css/masterstyles.css";
import { useDispatch } from "react-redux";
import { helpModalActions } from "../../redux/actions/helpModal";

function PageTitle(props) {
  const dispatch = useDispatch();

  function openHelpModal() {
    console.log("Open modal");
    dispatch(helpModalActions.openModal());
  }

  return (
    <nav className="level">
      <div className="level-item"></div>
      <div className="level-right">
        <div className="level-item" onClick={openHelpModal}>
          <i
            className="far fa-question-circle"
            style={{ marginRight: "20px", marginTop: "3px" }}
          >
            {" "}
          </i>
        </div>
      </div>
    </nav>
  );
}

export default PageTitle;
