import React from "react";
import { UserDetailForm } from "./users/UserDetailForm";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../redux/actions/users";

function Profile() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  function refresh() {
    dispatch(usersActions.getCurrentUser());
  }

  return (
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
      <section style={{paddingLeft: "12%", paddingRight: "12%", marginTop: "2em"}}>

      <div className="pr-1" style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
              USER PROFILE
              </div>
        <UserDetailForm user={currentUser} onSave={refresh} />

      </section>
    </main>
  );
}

export { Profile };
