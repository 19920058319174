 import React from 'react';
 import { useSelector, useDispatch } from 'react-redux'
 import { openRoadmapChartModal } from "../../../redux/toolkit/features/roadmaps/chartDetailModal";
 import { openRoadmapTimelineModal } from "../../../redux/toolkit/features/roadmaps/timelineDetailModal";
 import { openRoadmapRowModal } from "../../../redux/toolkit/features/roadmaps/rowDetailModal";

function SectionsModalButtons(props) {
    const {activetab, setShowDeleteModal} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const sectionpk = useSelector((state) => state.roadmapSectionsModal.sectionpk)
    const dispatch = useDispatch();

    function renderButtons() {

        if (activetab === 'Table') {
            return (
            <button className='button is-small' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} key='linkroadmap' onClick={() => dispatch(openRoadmapRowModal(sectionpk))}>
                Add Row
            </button>
            )
        }

        if (activetab === 'Timeline') {
            return (
            <button className='button is-small' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} key='linktheme' onClick={() => dispatch(openRoadmapTimelineModal(sectionpk))}>
                Add Timeline
            </button>
            )
        }

        if (activetab === 'Charts') {
            return (
            <button className='button is-small' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} key='linksprint'  onClick={() => dispatch(openRoadmapChartModal(sectionpk))}>
                AddChart
            </button>
            )
        }

        if (activetab === 'Section') {
            return (
            <button className='button is-danger is-small' style={{borderRadius:"4px"}} key='delete' onClick={() => setShowDeleteModal(true)}>
                Delete Roadmap
            </button>
            )
        }
    }

    return renderButtons()
 }

export default SectionsModalButtons;
