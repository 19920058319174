import React, { useEffect, Fragment, useState, createContext } from "react";
import "./common/css/masterstyles.css";
import PopupModal from "./roadmaps/modals/PopupModal";
import SectionsModal from "./roadmaps/modals/SectionsModal";
import SectionsModalEditRows from "./roadmaps/modals/SectionsModalEditRows";
import SectionsModalEditTimeline from "./roadmaps/modals/SectionsModalEditTimeline";
import SectionsModalEditCharts from "./roadmaps/modals/SectionsModalEditCharts";
import { useDispatch, useSelector} from "react-redux";
import { menuActions } from "../redux/actions/menu";
import { Roadmap } from "./roadmaps/Roadmap";
import { sectionsService } from "../services/SectionsService";
import { roadmapsService } from "../services/RoadmapsService";
import { roadmapsrowService } from "../services/RoadmapsRowService";
import { cellValuesService } from "../services/CellValuesService";
import { baselineValuesService } from "../services/BaselineValuesService";
import { baselineDeltaService } from "../services/BaselineDeltaService";
import { chartService } from "../services/RoadmapsChartService";
import { chartrowsService } from "../services/RoadmapsChartRowsService";
import { cagrService } from "../services/RoadmapsCAGRService";
import { growthService } from "../services/RoadmapsGrowthService";
import { rowindentService } from "../services/RoadmapsIndentService";
import { ganttService } from "../services/RoadmapsGanttService";
import ConfirmDeleteModal from "./common/ConfirmDeleteModal";
import { openRoadmapSectionsModal } from "../redux/toolkit/features/roadmaps/sectionsModal";
//import SetColors from "./common/SetColors";
import {filter} from "lodash"
import { RotatingLines } from "react-loader-spinner";
import Spinner from "./common/Spinner";

export const roadmapdatasets = createContext();

function Roadmaps() {
  const dispatch = useDispatch();
  const [isSectionsModalOpen, setIsSectionsModalOpen] = useState(false);
  const [isSectionsRowModalOpen, setIsSectionsRowModalOpen] = useState(false);
  const [isSectionsTimelineModalOpen, setIsSectionsTimelineModalOpen] = useState(false);
  const [isSectionsChartsModalOpen, setIsSectionsChartsModalOpen] = useState(false);
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
  const [sections,setSection] = useState(null);
  const [roadmap,setRoadmap] = useState(null);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  // const [modalrowdata, setModalRowdata] = useState(null);
  // const [modalsectiondata, setModalSectiondata] = useState(null);
  const [rows,setRows] = useState(null);
  const [gantt, setGantt] = useState(null);
  const [cellvalues,setCellValues] = useState(null);
  const [baselinevalues,setBaseline] = useState(null);
  const [baselinedelta,setBaselineDelta] = useState(null);
  const [cagr, setCagr] = useState(null);
  const [growth,setGrowth] = useState(null);
  const [indent,setIndent] = useState(null);
  const [chart,setChart] = useState(null);
  const [chartrows,setChartrows] = useState(null);
  const [description,setDescription] = useState(null);
  const [modaltitle,setModalTitle] = useState(null);
  const [selectedTab, setSelectedTab] = useState("All");
  const [modalrow, setModalRow] = useState([])
  const [modaltimeline, setModalTimeline] = useState(null)
  const [modalcharts, setModalCharts] = useState(null)
  const [sectionpk, setSectionpk] = useState(null)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);    
  const [deletemodaltext, setDeleteModalText] = useState(null);  
  const [deleterecordkey, setDeleteRecordKey] = useState(null);  
  const [deleterecordsource, setDeleteRecordSource] = useState(null);  
  // Table Row Variables
  const [tablerowname, setTableRowName] = useState(null);  
  const [tablerowtype, setTableRowType] = useState(null);  
  const [tablerowindent, setTableRowIndent] = useState(null);  
  const [tablerownotes, setTableRowNotes] = useState(null);  
  const [tablerowsubrow, setTableRowSubrow] = useState(null);   
  const [tablerowdecimalplaces, setTableRowDecimalplaces] = useState(null);   
  const [rowpk, setRowpk] = useState(null);    
  // Gantt Variables
  const [gantttaskname, setGanttTaskname] = useState(null);  
  const [ganttstartdate, setGanttStartdate] = useState(null); 
  const [ganttenddate, setGanttEnddate] = useState(null); 
  const [ganttduration, setGanttDuration] = useState(null); 
  const [ganttpercentcomplete, setGanttPercentComplete] = useState(null); 
  const [ganttdependencies, setGanttDependencies] = useState(null);   
  const [ganttpk, setGanttpk] = useState(null);    
  // Chart Variables
  const [chartpk, setChartpk] = useState(null);  
  const [charttitle, setCharttitle] = useState(null);  
  const [chartsubtitle, setChartsubtitle] = useState(null); 
  const [chartyaxis, setChartyaxis] = useState(null); 
  const [charttype, setCharttype] = useState(null); 
  const [isDataLoading, setIsDataLoading] = useState(false);


  async function fetchRoadmaps() {
    const cellvalues = await cellValuesService.getCells();
    setCellValues(cellvalues);  
    const sections = await sectionsService.getSections();
    setSection(sections);  
    const roadmap = await roadmapsService.getRoadmap();
    setRoadmap(roadmap);  
    const rows = await roadmapsrowService.getRows();
    setRows(rows);  
    const gantt = await ganttService.getAll();
    setGantt(gantt);  
    const cagr = await cagrService.getCAGR();
    setCagr(cagr);
    const growth = await growthService.getGrowth();
    setGrowth(growth);
    const indent = await rowindentService.getRowIndent();
    setIndent(indent);     
    const chart = await chartService.getAll();
    setChart(chart);    
    const chartrows = await chartrowsService.getAll();
    setChartrows(chartrows);    
    const baseline = await baselineValuesService.getCells();
    setBaseline(baseline);   
    const baselinedelta = await baselineDeltaService.getCells();
    setBaselineDelta(baselinedelta);     
  }

  useEffect(() => {
      async function fetchData() {
        setIsDataLoading(true);
          await fetchRoadmaps();
        setIsDataLoading(false);
      }

      fetchData();
  }, []);


  useEffect(() => {
    async function fetchData() {
      setIsDataLoading(true);
      await fetchRoadmaps();
    setIsDataLoading(false);
    }

    fetchData();
}, [selectedTab]);


  useEffect(() => {
    dispatch(menuActions.setActiveMenu("roadmaps"));
  }, [dispatch]);

  function toggleSectionsModal(open,sectionid) {
    setIsSectionsModalOpen(open);
    setSectionpk(sectionid)
    if(sectionid) {
      // setModalSectiondata(filter(sections.results,{pk: sectionid}))
      // setModalRowdata(filter(rows,{section: sectionid}))

    }
  }
  
  async function onGanttDataChanged(key, taskname, startdate, enddate, duration, percentcomplete, dependencies, sectionid) {
    if (key !== null && key) {
      await ganttService.patch(key, {taskname: taskname, startdate: startdate, enddate: enddate, duration: duration, percentcomplete: percentcomplete, dependencies: dependencies});
    } else{
      await ganttService.post({taskname: taskname, startdate: startdate, enddate: enddate, duration: duration, percentcomplete: percentcomplete, dependencies:dependencies, order: gantt.results.length, section: sectionid});
    }
    await fetchRoadmaps();
}

  async function onCellDataChanged(key,cellvalue, row, year) {
      if (key !== null) {
        await cellValuesService.patch(key, {numeric_value: cellvalue});
      } else{
        await cellValuesService.post({numeric_value: cellvalue, year: year, row: row});
      }
      await fetchRoadmaps();
  }

  async function onChartDataChanged(key, chart_title, chart_subtitle, chart_yaxis, chart_type) {
    if (key !== null) {
      await chartService.patch(key, {chart_type: chart_type, chart_title: chart_title, chart_subtitle: chart_subtitle, chart_yaxislabel:chart_yaxis, section: sectionpk});
    } else {
      await chartService.post({chart_type: chart_type, chart_title: chart_title, chart_subtitle: chart_subtitle, chart_yaxislabel:chart_yaxis, section: sectionpk});
    }
    await fetchRoadmaps();
  }

  async function onRowDataChanged(key,name,type, subrow, decimalplace, sectionid, description ) {
    if (key !== null) {
      await roadmapsrowService.patch(key, {name: name,type: type, subrow: subrow, type: type, description: description, decimalplaces: decimalplace});
    } else {
      if (type===null) {type='Text'}  
      await roadmapsrowService.post({name: name, type: type, subrow: subrow, section: sectionid, description: description, decimalplaces: decimalplace});
    }
    await fetchRoadmaps();
  }

  async function onRoadmapDelete(key) {
    await roadmapsrowService.deleteRow(key);
    //toggleSectionsModal(false,null)
    await fetchRoadmaps();
  }

  async function onRoadmapSectionDelete(key) {
    await sectionsService.deleteRow(key);
    //toggleSectionsModal(false,null)
    await fetchRoadmaps();
  }


  async function onTimelineRecordDelete(key) {
    await ganttService.deleteRow(key);
    //toggleSectionsModal(false,null)
    await fetchRoadmaps();
  }

  function togglePopupModal(open, text, title) {
    setIsPopupModalOpen(open)
    setDescription(text)
    setModalTitle(title)
  }

  function toggleSectionsGanttModal(open, row, section) {
    setSectionpk(section)
    setIsSectionsTimelineModalOpen(true)
  }

  function toggleSectionsRowModal(open, row, section) {
    let sectionrow=row
    setRowpk(sectionrow);
    //setSectionpk(section)
    setIsSectionsRowModalOpen(open);
    if (open===true && row !== null) {
      let rowname = (filter(rows,{pk:row})[0]).name
      let rowtype = (filter(rows,{pk:row})[0]).type
      let rowindent = (filter(rows,{pk:row})[0]).subrow
      let rownotes = (filter(rows,{pk:row})[0]).description
      let rowsubrow = (filter(rows,{pk:row})[0]).indent
      let rowdecimalplaces = (filter(rows,{pk:row})[0]).description
      setTableRowName(rowname);
      setTableRowType(rowtype);
      setTableRowIndent(rowindent);
      setTableRowNotes(rownotes);
      setTableRowDecimalplaces(rowdecimalplaces);
      setTableRowSubrow(rowsubrow);
    } else {
      setTableRowName(null);
      setTableRowType(null);
      setTableRowIndent(null);
      setTableRowNotes(null);
      setTableRowDecimalplaces(null);
      setTableRowSubrow(null);
    }
  }

  function toggleSectionsTimelineModal(open, row) {
    console.log("At Toggle timeline")
    setModalTimeline(row);
    setIsSectionsTimelineModalOpen(open);
  }

  function toggleSectionsChartsModal(open, row) {
    setIsSectionsChartsModalOpen(open);
    let chartpkvalue = null;
    let charttitlevalue = null;
    let chartsubtitlevalue = null;
    let charttypevalue = null;
    let chartyaxisvalue = null;
    // console.log("Chart", chart)
    // if (open===true && row) {
    //   console.log("ROW",row)
    //   chartpkvalue = row;
    //   charttitlevalue = (filter(chart,{pk:row})[0]).chart_title;
    //   chartsubtitlevalue = (filter(chart,{pk:row})[0]).chart_subtitle;
    //   charttypevalue = (filter(chart,{pk:row})[0]).chart_type;
    //   chartyaxisvalue = (filter(chart,{pk:row})[0]).chart_yaxislabel;
    // } 
      setChartpk(chartpkvalue);
      setCharttitle(charttitlevalue);
      setChartsubtitle(chartsubtitlevalue);
      setChartyaxis(chartyaxisvalue);
      setCharttype(charttypevalue);
    }

  function toggleConfirmDeleteModal(open, messagetext, key, source) {
    console.log("At Delete RM")
    setDeleteRecordKey(key)
    setIsConfirmDeleteModalOpen(open);
    setDeleteModalText(messagetext)
    setDeleteRecordSource(source)
}

  if (!sections || !growth || !cagr || !indent || !rows || !roadmap || !cellvalues || !baselinedelta) {
    return <Spinner/>;
  }


  async function onSectionDataChanged(key, sectionname, sectionnarrative, sectioncategory) {
    console.log("Section POST", sectioncategory,sectionname,sectionnarrative  )
    if (key) {
      await sectionsService.patch(key, {name:sectionname, narrative: sectionnarrative, category: sectioncategory});
      setIsSectionsModalOpen(false);
  } else {
      await  sectionsService.post({name:sectionname, narrative: sectionnarrative, category: sectioncategory, roadmap: 1, order: 16});
      setIsSectionsModalOpen(false);
  }
    await fetchRoadmaps();
}

function Loader() {
  return (
    <div style={{height: '300px', paddingTop: '8em', textAlign: 'center'}}>
    <RotatingLines
      strokeColor="blue"
      strokeWidth="4"
      animationDuration="0.75"
      width="56"
      visible={true}
    />
    </div>
  )
}

  let minYear=""
  let maxYear=""
  if (roadmap.results.length !== 0) {
    minYear = (roadmap.results[0]).min_year
    maxYear = (roadmap.results[0]).max_year
  } else {
    minYear = new Date().getFullYear() 
    maxYear = new Date().getFullYear()+5 
  }

  const categories = [...new Set(sections.results.flatMap(({category}) => category))]; 
  let sectionfiltered = []
  if (selectedTab === "All") {
    sectionfiltered = sections.results
  } else {
    sectionfiltered = filter(sections.results, {category: selectedTab})
  }


  function renderroadmapspage() {
    return (
<roadmapdatasets.Provider value={{sections, rows, cellvalues, baselinedelta, cagr, growth, minYear, maxYear, onCellDataChanged}}>
    <Fragment>
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em", userSelect: "none"}}>   
        <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
          <div style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
            <div className="pr-1">
              ROADMAPS <button className="button is-small is-pulled-right" style={{borderColor: backgroundcolor, color: backgroundcolor, borderRadius: "5px"}} onClick={() => dispatch(openRoadmapSectionsModal())}>Add Roadmap</button>
            </div>


            {sections.results.length !== 0 &&
            <Fragment>
            <div key="TabHeader" className="tabs ml-0 pl-0" >
              <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
                <li 
                  key="Categories_All" 
                  className={selectedTab === "All" ? "is-active" : ""}
                  onClick={() => setSelectedTab("All")}  
                  >           
                  <a className="ml-0 pl-0">ALL</a>
                </li>
                {categories && categories.map((categories, index) => (
                  <Fragment>
                    |
                <li
                key={"Categories_"+categories.pk} style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                className={selectedTab === categories ? "is-active" : ""}
                onClick={() => setSelectedTab(categories)}  
                >       
                  <a>{categories.toUpperCase()}</a>
                </li>
                </Fragment>
            ))}
              </ul>
            </div>
            </Fragment>}
          </div>

          {sections.results.length === 0 &&
          <p className="pt-6" style={{fontSize: "16.5px"}}>No Roadmaps Returned</p>}

        <Roadmap 
            sectionsfiltered={sectionfiltered} // Section viewset filtered by Tab category
            gantt={gantt}
            chart={chart}
            chartrows={chartrows}
            indent={indent}
            toggleSectionsModal={toggleSectionsModal}
            // onCellDataChanged={onCellDataChanged}
            togglePopupModal={togglePopupModal}
        />

        <SectionsModal
            sections={sections}
            rows={rows}
            gantt={gantt}
            chart={chart}
            sectionpk={sectionpk}
            showSectionsModal={isSectionsModalOpen}
            toggleSectionsModal={toggleSectionsModal}
            showSectionsRowModal={isSectionsRowModalOpen}
            toggleSectionsRowModal={toggleSectionsRowModal}
            toggleSectionsGanttModal={toggleSectionsGanttModal}
            showSectionsTimelineModal={isSectionsTimelineModalOpen}
            toggleSectionsTimelineModal={toggleSectionsTimelineModal}
            showSectionsChartsModal={isSectionsChartsModalOpen}
            toggleSectionsChartsModal={toggleSectionsChartsModal}
            toggleConfirmDeleteModal={toggleConfirmDeleteModal}
            onDataChanged={onSectionDataChanged}
            onGanttDataChanged={onGanttDataChanged}
            onRoadmapSectionDelete={onRoadmapSectionDelete}
         
            />

        <SectionsModalEditRows
            rows={rows}
            rowpk={rowpk}
            sectionpk={sectionpk}
            showSectionsRowModal={isSectionsRowModalOpen}
            toggleSectionsRowModal={toggleSectionsRowModal}
            onRowDataChanged={onRowDataChanged}
            toggleConfirmDeleteModal={toggleConfirmDeleteModal}
            tablerowname={tablerowname}
            tablerowtype={tablerowtype}
            tablerowindent={tablerowindent}
            tablerownotes={tablerownotes}
            tablerowsubrow={tablerowsubrow}
            tablerowdecimalplaces={tablerowdecimalplaces}
            />

        <SectionsModalEditTimeline
            isSectionsTimelineModalOpen={isSectionsTimelineModalOpen}
            toggleSectionsTimelineModal={toggleSectionsTimelineModal}
            onGanttDataChanged={onGanttDataChanged}
            sectionpk={sectionpk}
            gantttaskname={gantttaskname}
            ganttstartdate={ganttstartdate}
            ganttenddate={ganttenddate}
            gantttduration={ganttduration}
            ganttdependencies={ganttdependencies}
            />

        <SectionsModalEditCharts
            chartpk={chartpk}
            charttitle={charttitle}
            chartsubtitle={chartsubtitle}
            chartyaxis={chartyaxis}
            charttype={charttype}
            charts={chart}
            rows={rows}
            sectionpk={sectionpk}
            showSectionsChartsModal={isSectionsChartsModalOpen}
            toggleSectionsChartsModal={toggleSectionsChartsModal}
            onChartDataChanged={onChartDataChanged}
            />

          <PopupModal
            description={description}
            modaltitle={modaltitle}
            showModal={isPopupModalOpen}
            toggleModal={togglePopupModal}
            />

        </section>
      </main>

      <ConfirmDeleteModal
          modaltext={deletemodaltext}
          showModal={isConfirmDeleteModalOpen}
          recordkey={deleterecordkey}
          toggleModal={toggleConfirmDeleteModal}
          onRoadmapsRecordDelete={onRoadmapDelete}
          onTimelineRecordDelete={onTimelineRecordDelete}
          deleterecordsource={deleterecordsource}
        />

    </Fragment>
    </roadmapdatasets.Provider>
    )
  }

  return (
    (isDataLoading?<Spinner/>:renderroadmapspage())
  )
}


export { Roadmaps };
