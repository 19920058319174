import React, {Fragment } from "react";
import { useSelector } from 'react-redux'
import "bulma/css/bulma.css";
import { useDispatch} from "react-redux";
import { loginActions } from "./../../redux/actions/login";
import SetColors from "./SetColors";


function ConfirmLogout(props) {
    const {toggleModal, showLogoutModal} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const dispatch = useDispatch();
    
    let modalClass = "modal";
    if (showLogoutModal) {
        modalClass += " is-active";
    }
   
    function logout() {

          dispatch(loginActions.logout());
      }


    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>CONFIRM LOGOUT</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                <section className="modal-card-body" key="modalBody">
                    <p>Are you sure you want to Logout? </p>

                </section>
                <footer className="modal-card-foot is-flex pt-0 pb-0" key="modalFooter">
                    <nav className="level">
                        <div className="level-right">
                             <div className="level-item">
                                <button
                                    style={{color: textcolor, backgroundColor: backgroundcolor, borderRadius:"4px"}}
                                    className="button is-small"
                                    onClick={async () => {
                                        logout()
                                        toggleModal(false);
                                    }}
                                    >
                                    Log Out
                                </button>
                            </div>
                            <div className="level-item">
                                <button className="button is-small" style={{borderRadius:"4px"}} onClick={() => toggleModal(false)}>
                                        Cancel
                                </button>
                            </div>
                        </div>
                    </nav>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export default ConfirmLogout;
