import "regenerator-runtime/runtime.js";
import axios from "axios";
import {authConfig} from "./helpers";
import BaseService from "./BaseService";

class SectionsService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-sections/`;
    }

    async getSections() {
        const sections = await this.getAll();
        
        // let sections;
        // if (section.count === 0) {
        //     sections = await sectionsService.post({ name: "", narrative: "", category: ""})
        // } else {
        //     sections = section;
        // }
        return sections;
    }


    async getChartData(id) {
        const url = this.detailUrl(id) + "/chart";
        const response = await axios.get(url, authConfig());
        return response.data;
    }
}

export const sectionsService = new SectionsService();
