import { successConstants } from '../constants/success';

const initialState = {
  loading: false,
  success: [],
  error: null,
}

export function success(state = initialState, action) {
  switch (action.type) {
    case successConstants.GET_SUCCESS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case successConstants.GET_SUCCESS_SUCCESS:
      return {
        ...initialState,
        success: action.success,
      };
    case successConstants.GET_SUCCESS_FAILURE:
      return { 
        ...initialState,
        error: action.error,
      };
    default:
      return state
  }
}