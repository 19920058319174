import React, { Fragment, useState, useEffect, useContext } from "react";
import {Sprint} from ".//SuccessSprint";
import {sprintService} from "../../services/SprintService";
import {registersService} from "../../services/RegistersService";
import {filter} from "lodash";
import { successdataset } from "./../Success";


function SprintList(props) {
    const {linkedsprintsfiltered} = props; 
    const {workersview, sponsorsview} = useContext(successdataset);
    const [sprints, setSprint] = useState(null);
    const [registers, setRegister] = useState(null);

    async function fetchSprints() {
        const sprints = await sprintService.getSprint();
        setSprint(sprints);    
        const registers = await registersService.getRegister();
        setRegister(registers);    
    }

    useEffect(() => {
        async function fetchData() {
            await fetchSprints();
        }
        fetchData();
    }, []);

    if (!sprints || !registers) {
        return null
    }

    const rowIds = linkedsprintsfiltered.map(r => r.sprint_id);
    const filteredsprints = filter( sprints, (row) => rowIds.includes(row.pk));
    
        return (
            <Fragment>
            {/* <table className="table m-0 is-fullwidth">
                <tbody> */}
                            <Sprint 
                                filteredSprints={filteredsprints} 
                                // registers={registers}
                                // workersview={workersview}
                                // sponsorsview={sponsorsview}
                            />
                {/* </tbody>
            </table> */}
            </Fragment>
          )
}

export { SprintList }

