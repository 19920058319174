import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'

import {Card} from './Card.js'
import {valuesService} from "../../../../services/ValuesService";

export const Container = (props) => {
    const [rows, setRows] = useState(props.tablerows);
    const {toggleConfirmDeleteModal, toggleSectionsRowModal, getValuesOnMainPage, tablerows, sectionpk} = props;

    useEffect(() => {
        setRows(props.tablerows);
    }, [props]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setRows((prevValues) => {
                const newValues = update(prevValues, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevValues[dragIndex]],
                    ],
                });
                const newValuesPks = newValues.map(value => value.pk);
                valuesService.sortValues(newValuesPks).then(() => getValuesOnMainPage());
                return newValues;
            }
        )
    }, [])

    const renderValue = useCallback(
        (row, index) => {
            return (
                <Card
                    key={row.pk}
                    sectionpk={sectionpk}
                    index={index}
                    id={row.pk}
                    text={row.name}
                    moveCard={moveCard}
                    rowPk={row.pk}
                    toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                    toggleSectionsRowModal={toggleSectionsRowModal}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{tablerows.map((card, i) => renderValue(card, i))}</div>
        </>
    )
}