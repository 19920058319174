import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class SponsorViewService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/sponsorsview/`;
    }

    async getSponsorsView() {
        const sponsorsview = await this.getAll();
        return sponsorsview;
    }
}

export const sponsorViewService = new SponsorViewService();