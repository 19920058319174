import React from 'react';
import { useSelector } from 'react-redux'


function SprintModalButtons(props) {
    const {activetab, sprintModalKey, toggleConfirmDeleteModal, toggleAddPersonModal} = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)


    function renderButtons() {

        if (activetab === 'Comments') {
            return (
                <button className='button is-small' key='comment' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}}>
                    Add Comment
                </button>
                )
            }

        if (activetab === 'Sponsors') {
            return (
            <button className='button is-small' key='addsponsor' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => toggleAddPersonModal(true, sprintModalKey, "Sponsors")}>
                Add Sponsor
            </button>
            )
        }

        if (activetab === 'Team') {
            return (
            <button className='button is-small' key='linktheme' style={{backgroundColor: backgroundcolor, color: textcolor, borderRadius:"4px"}} onClick={() => toggleAddPersonModal(true, sprintModalKey, "Workers")}>
                Add Team Member
            </button>
            )
        }

        if (activetab === 'Sprint') {
            return (
            <button className='button is-danger is-small' key='delete' style={{borderRadius:"4px"}} onClick={() => toggleConfirmDeleteModal(true, 'test', sprintModalKey , 'sprints')}>
                Delete Sprint
            </button>
            )
        }

        if (activetab === 'Sprintlane') {
            return (
            null
            )
        }
    }

    return renderButtons()
}


export default SprintModalButtons;
