import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, factpk: null };

const modalSlice = createSlice({
  name: "factsRecordModal",
  initialState,
  reducers: {
    openFactsRecordModal(state, action) {
      state.isOpen = true;
      state.factpk = action.payload;
    },
    closeFactsRecordModal(state) {
      state.isOpen = false;
      state.factpk = null;
    },
  },
});

export const {
  openFactsRecordModal,
  closeFactsRecordModal,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.factsRecordModal;

export const selectFactsRecordModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectFactsRecordModalFactpk = createSelector(
  selectState,
  (state) => state.factpk
);
