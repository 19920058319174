import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class PersonYearService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/organization/`;
    }

    async getPersonYear() {
        const personyear = await this.getAll();
        return personyear;
    }
}

export const personYearService = new PersonYearService();
