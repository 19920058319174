import React, { useEffect } from "react";
import "./common/css/masterstyles.css";
import PageTitle from "./common/PageTitle";
import { useDispatch } from "react-redux";
import { menuActions } from "../redux/actions/menu";

function Support() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("support"));
  }, [dispatch]);

  return (
    <main>
      <PageTitle title={"Contact"} />
      <p>Contact details for support to be added here:</p>
      <p>1800 Number</p>
      <p>Email</p>
      <p>Contact Hours</p>
    </main>
  );
}

export { Support };
