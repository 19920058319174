export const businessunitModalConstants = {
    OPEN_MODAL: "BUSINESSUNIT_MODAL_OPEN",
    CLOSE_MODAL: "BUSINESSUNIT_MODAL_CLOSE",

    CREATE_NEW: "BUSINESSUNIT_MODAL_CREATE_NEW",
    CANCEL_CREATE_NEW: "BUSINESSUNIT_MODAL_CANCEL_CREATE_NEW",

    SAVE_BUSINESSUNIT_REQUEST: "BUSINESSUNIT_MODAL_SAVE_BUSINESSUNIT_REQUEST",
    SAVE_BUSINESSUNIT_SUCCESS: "BUSINESSUNIT_MODAL_SAVE_BUSINESSUNIT_SUCCESS",
    SAVE_BUSINESSUNIT_FAILURE: "BUSINESSUNIT_MODAL_SAVE_BUSINESSUNIT_FAILURE",

};
