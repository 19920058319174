export const roadmapsConstants = {
    GET_ROADMAP_REQUEST: 'GET_ROADMAP_REQUEST',
    GET_ROADMAP_SUCCESS: 'GET_ROADMAP_SUCCESS',
    GET_ROADMAP_FAILURE: 'GET_ROADMAP_FAILURE',

    GET_CELL_GROWTH_REQUEST: 'GET_CELL_GROWTH_REQUEST',
    GET_CELL_GROWTH_SUCCESS: 'GET_CELL_GROWTH_SUCCESS',
    GET_CELL_GROWTH_FAILURE: 'GET_CELL_GROWTH_FAILURE',

    GET_CELL_CAGR_REQUEST: 'GET_CELL_CAGR_REQUEST',
    GET_CELL_CAGR_SUCCESS: 'GET_CELL_CAGR_SUCCESS',
    GET_CELL_CAGR_FAILURE: 'GET_CELL_CAGR_FAILURE',

    POST_CELL_VALUE_REQUEST: 'POST_CELL_VALUE_REQUEST',
    POST_CELL_VALUE_SUCCESS: 'POST_CELL_VALUE_SUCCESS',
    POST_CELL_VALUE_FAILURE: 'POST_CELL_VALUE_FAILURE',

    DELETE_CELL_VALUE_REQUEST: 'DELETE_CELL_VALUE_REQUEST',
    DELETE_CELL_VALUE_SUCCESS: 'DELETE_CELL_VALUE_SUCCESS',
    DELETE_CELL_VALUE_FAILURE: 'DELETE_CELL_VALUE_FAILURE',

    PATCH_CELL_VALUE_REQUEST: 'PATCH_CELL_VALUE_REQUEST',
    PATCH_CELL_VALUE_SUCCESS: 'PATCH_CELL_VALUE_SUCCESS',
    PATCH_CELL_VALUE_FAILURE: 'PATCH_CELL_VALUE_FAILURE',

    GET_CHART_DATA_REQUEST: 'GET_CHART_DATA_REQUEST',
    GET_CHART_DATA_SUCCESS: 'GET_CHART_DATA_SUCCESS',
    GET_CHART_DATA_FAILURE: 'GET_CHART_DATA_FAILURE',
};
