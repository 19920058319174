import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, themepk: null };

const modalSlice = createSlice({
  name: "themeModalToggle",
  initialState,
  reducers: {
    openThemeModal(state, action) {
      state.isOpen = true;
      state.themepk = action.payload;
    },
    closeThemeModal(state) {
      state.isOpen = false;
      state.themepk = null;
    },
  },
});

export const { openThemeModal, closeThemeModal } = modalSlice.actions;

export default modalSlice.reducer;

//const selectState = (state) => state.themesModal;

// export const selectThemesModalIsOpen = createSelector(
//   selectState,
//   (state) => state.isOpen
// );

// export const selectThemesModalThemepk = createSelector(
//   selectState,
//   (state) => state.themepk
// );
