import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class ChartrowsService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/roadmap-chartrows/`;
    }

    async getChartrows() {
        const chartrows = await this.getAll();
        return chartrows.results;
    }
}


export const chartrowsService = new ChartrowsService();
