import { combineReducers } from "redux";

import { alerts } from "./alerts";
import { login } from "./login";
import { users } from "./users";
import { tenants } from "./tenants";
import { menu } from "./menu";
import { purpose } from "./purpose";
//import { ideas } from "./idea";
import values from "../toolkit/features/values";
import { behaviors } from "./behaviors";
import { roadmaps } from "./roadmaps";
import { sections } from "./sections";
import { company } from "./company";
import { timelines } from "./timelines";
import { facts } from "./facts";
import { sprints } from "./sprints";
import { sprintcycle } from "./sprintcycle";
import { success } from "./success";
import { organization } from "./organization";
import { aboutModal } from "./aboutModal";
import { menuModal } from "./menuModal";
import { positionModal } from "./positionModal";
import { newtaskModal } from "./newtaskModal";
import { newcommentModal } from "./newcommentModal";
//import { peopleModal } from "./peopleModal";
import { titleModal } from "./titleModal";
import { businessunitModal } from "./businessunitsModal";
import { helpModal } from "./helpModal";
import { investments } from "./investments";
import { cellgrowth } from "./roadmapsgrowth";
import { cellcagr } from "./roadmapscagr";
import { linkedroadmaps } from "./linkedroadmaps";
import { competencies } from "./competencies";
import { people } from "./people";
import stakeholders from "../toolkit/features/stakeholders";
import categories from "../toolkit/features/categories";
import matrices from "../toolkit/features/matrices";
import stakeholderModal from "../toolkit/features/modals/stakeholderModal";
import matrixModal from "../toolkit/features/modals/matrixModal";
import categoryModal from "../toolkit/features/modals/categoryModal";
import commentModal from "../toolkit/features/modals/commentModal";
import taskModal from "../toolkit/features/modals/taskModal";
import dateModal from "../toolkit/features/modals/dateModal";
//import themeModal from "../toolkit/features/modals/themeModal";
//import ideaModal from "../toolkit/features/modals/ideaModal";
//import valueModal from "../toolkit/features/modals/valueModal";
import themes from "../toolkit/features/themes";
import ideas from "../toolkit/features/ideas";
//import ideas from "../toolkit/features/ideas";
import roadmapsectionModal from "../toolkit/features/modals/roadmapsectionModal";
import companyheroModal from "../toolkit/features/modals/companyheroModal";
import purposeModal from "../toolkit/features/modals/purposeModal";
import factsModal from "../toolkit/features/company/factsModal";
import valuesModal from "../toolkit/features/company/valuesModal";
import factsRecordModal from "../toolkit/features/company/factsRecordModal";
import valuesRecordModal from "../toolkit/features/company/valuesRecordModal";
import timelineModal from "../toolkit/features/company/timelineModal";
import timelineRecordModal from "../toolkit/features/company/timelineRecordModal";
import ideaModalToggle from "../toolkit/features/innovation/ideaModalToggle";
import themeModalToggle from "../toolkit/features/innovation/themeModalToggle";
import quoteModal from "../toolkit/features/modals/quoteModal";
import deleteConfirmModal from "../toolkit/features/modals/deleteConfirmModal";
import colors from "../toolkit/features/colors";
import roadmapSectionsModal from "../toolkit/features/roadmaps/sectionsModal";
import roadmapChartModal from "../toolkit/features/roadmaps/chartDetailModal";
import roadmapTimelineModal from "../toolkit/features/roadmaps/timelineDetailModal";
import roadmapRowModal from "../toolkit/features/roadmaps/rowDetailModal";
import competenciesModal from "../toolkit/features/people/competenciesModal";
import peopleModal from "../toolkit/features/people/peopleModal";
import sprintsModalToggle from "../toolkit/features/sprints/sprintsModalToggle";

const rootReducer = combineReducers({
  alerts,
  login,
  users,
  tenants,
  menu,
  purpose,
  values,
  behaviors,
  roadmaps,
  cellgrowth,
  cellcagr,
  competencies,
  people,
  sections,
  company,
  ideas,
  timelines,
  facts,
  sprints,
  sprintcycle,
  success,
  themes,
  organization,
  aboutModal,
  menuModal,
  positionModal,
  newtaskModal,
  newcommentModal,
  peopleModal,
  titleModal,
  businessunitModal,
  helpModal,
  investments,
  stakeholders,
  categories,
  matrices,
  stakeholderModal,
  matrixModal,
  categoryModal,
  commentModal,
  taskModal,
  dateModal,
  themeModalToggle,
  ideaModalToggle,
  roadmapsectionModal,
  companyheroModal,
  valuesModal,
  valuesRecordModal,
  timelineModal,
  timelineRecordModal,
  purposeModal,
  quoteModal,
  factsModal,
  factsRecordModal,
  linkedroadmaps,
  deleteConfirmModal,
  colors,
  roadmapSectionsModal,
  roadmapChartModal,
  roadmapTimelineModal,
  roadmapRowModal,
  competenciesModal,
  sprintsModalToggle,
});

export default rootReducer;
