import { tenantConstants } from "../constants/tenants";
import { tenantsService } from "../../services/TenantsService";
import { alerts } from "./alerts";

export const tenantsActions = {
  getCurrentTenant,
  saveTenant,
};

function getCurrentTenant() {
  return async (dispatch) => {
    dispatch(request());

    try {
      const response = await tenantsService.getCurrentTenant();
      dispatch(success(response));
    } catch (err) {
      dispatch(alerts.error(err.toString()));
      dispatch(failure(err.toString()));
    }
  };

  function request() {
    return { type: tenantConstants.GET_CURRENT_TENANT_REQUEST };
  }
  function success(tenant) {
    return { type: tenantConstants.GET_CURRENT_TENANT_SUCCESS, tenant };
  }
  function failure(error) {
    return { type: tenantConstants.GET_CURRENT_TENANT_FAILURE, error };
  }
}

function saveTenant(id, data) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const tenant = await tenantsService.patch(id, data);
      dispatch(success(tenant));
    } catch (err) {
      dispatch(alerts.error(err.toString()));
      dispatch(failure(err.toString()));
    }
  };

  function request() {
    return { type: tenantConstants.SAVE_CURRENT_TENANT_REQUEST };
  }
  function success(tenant) {
    return { type: tenantConstants.SAVE_CURRENT_TENANT_SUCCESS, tenant };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_CURRENT_TENANT_FAILURE, error };
  }
}
