import { investmentsConstants } from "../constants/investments";

const initialState = {
  loading: false,
  investments: [],
  error: null,
};

export function investments(state = initialState, action) {
  switch (action.type) {
    case investmentsConstants.GET_INVESTMENTS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case investmentsConstants.GET_INVESTMENTS_SUCCESS:
      return {
        ...initialState,
        investments: action.investments,
      };
    case investmentsConstants.GET_INVESTMENTS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}
