const getBaseURL = () => {
  //TODO: use env
  //return "http://localhost:8000/api";
  return "https://growthbasket-service-3ebb4rmoiq-uc.a.run.app/api";
};

const apiUtils = {
  getBaseURL,
};

export default apiUtils;
