import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isCreatingNew: true, category: null };

const modalSlice = createSlice({
  name: "dateModal",
  initialState,
  reducers: {
    openForNewDate(state) {
      state.isOpen = true;
      state.isCreatingNew = true;
      state.comment = null;
    },
    close(state) {
      state.isOpen = false;
      state.isCreatingNew = true;
    },
    openForEditingDate(state, action) {
      state.isOpen = true;
      state.isCreatingNew = false;
      state.comment = action.payload;
    },
  },
});

export const {
  openForNewDate,
  close,
  openForEditingDate,
} = modalSlice.actions;
export default modalSlice.reducer;

const selectState = (state) => state.dateModal;

export const selectDateModalIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectDateModalIsCreatingNew = createSelector(
  selectState,
  (state) => state.isCreatingNew
);

export const selectDateModalDates = createSelector(
  selectState,
  (state) => state.comment
);
