import React, { Fragment, useState, useEffect, useContext} from "react";
import {filter} from "lodash"
import { sectionsService } from "./../../services/SectionsService";
import {roadmapsrowService} from "../../services/RoadmapsRowService";
import {roadmapsService} from "../../services/RoadmapsService";
import {cellValuesService} from "../../services/CellValuesService"
import { baselineValuesService} from "../../services/BaselineValuesService"
import { baselineDeltaService } from "../../services/BaselineDeltaService";
import { BaselineValue} from "./SuccessBaselineValue"
import { FormatString } from "./../common/FormatCell";
import { successdataset } from "./../Success";

function SuccessRoadmapActions(props) {

    const { test, test1, test2} = useContext(successdataset);
    const { roadmapVisible, hideRoadmapsSection, showRoadmapsSection, linkedrows, success_id, onCellDataChanged } = props; 
    const linkedrowsfiltered = filter(linkedrows.results, {success: success_id})
    const linkedrowsfilteredcount = linkedrowsfiltered.length 
    const [sections,setSection] = useState(null);
    const list = [];
    const [roadmap,setRoadmap] = useState(null);
    const [rows,setRows] = useState(null);
    const [cellvalues, setCellvalues] = useState(null);
    const [baselinevalues, setBaselineValues] = useState(null);
    const [baselinedelta,setBaselineDelta] = useState(null);
    const [numericValue, setNumericValue] = useState(null);   

    async function fetchBaseline() {
        const baselinevalues = await baselineValuesService.getAll();
        setBaselineValues(baselinevalues);     
    }

    async function fetchRoadmaps() {
        const cellvalues = await cellValuesService.getCells();
        setCellvalues(cellvalues);  
        const sections = await sectionsService.getSections();
        setSection(sections);  
        const roadmap = await roadmapsService.getRoadmap();
        setRoadmap(roadmap);  
        const rows = await roadmapsrowService.getRows();
        setRows(rows);    
        const baselinedelta = await baselineDeltaService.getCells();
        setBaselineDelta(baselinedelta);     
      }
    
      useEffect(() => {
          async function fetchData() {
              await fetchRoadmaps();
              await fetchBaseline();
          }
    
          fetchData();
      }, []);

      useEffect(() => {
        async function fetchDeltas() {
            await fetchRoadmaps();
        }
        fetchDeltas();
        console.log("At CV UE")
    }, onCellDataChanged);


      if (!roadmap || !sections || !rows || !cellvalues || !baselinedelta || !baselinevalues) {
        return null;
      }

      const minYear = (roadmap.results[0]).min_year
      const maxYear = (roadmap.results[0]).max_year

      function renderYearHeaders() {
        const headers = [];
        for (let year = minYear; year <= maxYear; year++) {
            if (year <  new Date().getFullYear()) {
            headers.push(<th className="has-text-centered" key={year}>{year} Actual</th>);
            } else {
                headers.push(<th className={'has-text-centered'} key={year}>{year}</th>);
            }
        }
        return headers;
    }

    function renderRoadmap(rowid, type, decimalplaces) {
        const roadmap = [];

        
        for (let year = minYear; year <= maxYear; year++) {
            let celldata = filter(cellvalues.results,{year: year, row_id: rowid.row_id})

            {celldata.length!==0 &&
            roadmap.push( <td className="has-text-centered"><input style={{textAlign:'center', border: 'none', background: 'transparent', userSelect:"none", pointerEvents: "none"}} value={FormatString(celldata[0].numeric_value,type, decimalplaces)} /></td>)}

            {celldata.length===0 &&
            roadmap.push( <td className="has-text-centered"><input style={{textAlign:'center', border: 'none', background: 'transparent', userSelect:"none", pointerEvents: "none"}} value={null} /></td>)}

        }
        return roadmap;
    }

    function renderBaselineValue(year, rowid, type, decimalplaces, mediumalert,severealert) {

        let data = filter(baselinevalues.results, {year: year, row_id: rowid.row_id});
        let numericcell = (data.length !== 0 ? data[0].numeric_value : null)
        return (
            <BaselineValue 
                key={`${rowid}_${year}`} 
                sectionPk={props.sectionPk} 
                rowPk={rowid.row_id} 
                baselinevalues={baselinevalues}
                numericcell={numericcell}
                baselinedelta={baselinedelta}
                year={year} 
                type={type} 
                mediumalert={mediumalert}
                severealert={severealert}
                decimalplaces={decimalplaces}
                onCellDataChanged={onCellDataChanged}
            />
        )
    }

    function renderBaseline(rowid, type, decimalplaces, mediumalert,severealert ) {
        const cells = [];
        for (let year = minYear; year <= maxYear; year++) {
            cells.push(renderBaselineValue(year, rowid, type, decimalplaces, mediumalert,severealert));
        }
        return cells;
    }

    function renderBaselineDelta(rowid, type, decimalplaces) {
        const baseline = [];
        for (let year = minYear; year <= maxYear; year++) {
            let baselinedata = filter(baselinedelta,{year: year, row_id: rowid.row_id})
            if (baselinedata[0]) {
            baseline.push( <td className="has-text-centered"><input style={{textAlign:'center', border: 'none', background: 'transparent'}} value={FormatString(baselinedata[0].difference,type, decimalplaces)} /></td>)
            } else {
                baseline.push( <td className="has-text-centered" style={{color: "transparent"}}>{'No Value'}</td>)
            }
        }
        return baseline;
    }

    function renderBaselinePercent(rowid) {
        const baseline = [];
        for (let year = minYear; year <= maxYear; year++) {
            let baselinedata = filter(baselinedelta,{year: year, row_id: rowid.row_id})
            if (baselinedata[0]) {
            baseline.push( <td className="pt-3" style={{textAlign:'center'}}> <input style={{textAlign:'center', border: 'none', background: 'transparent'}}  value={Number(baselinedata[0].growth).toFixed(2) + '%'} /></td>)
        } else {
            baseline.push( <td style={{textAlign: "center", color: "transparent"}}>{'No Value'}</td>)
        }
        }
        return baseline;
    }

    function renderTable(rowid) {
        let rowname=[]
        rowname=filter( rows, {pk: rowid.row_id})
        return (
            <Fragment>
                <div className="ml-3 mr-3 mb-4">
                <table className={'table is-striped is-fullwidth'}>
                    <tbody>
                        <tr>
                            <th>{rowname[0]?rowname[0].name:""}</th>
                            {renderYearHeaders()}
                        </tr>
                        <tr>
                            <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Baseline</th> 
                            {renderBaseline(rowid, rowname[0].type, rowname[0].decimalplaces, rowname[0].baselinealertmoderate,rowname[0].baselinealertsevere)}
                        </tr>
                        <tr>
                            <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Roadmap</th>
                            {renderRoadmap(rowid, rowname[0].type, rowname[0].decimalplaces)}
                        </tr>
                        <tr>
                            <td className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Delta Δ</td>
                            {renderBaselineDelta(rowid, rowname[0].type, rowname[0].decimalplaces)}
                        </tr>
                        <tr >
                            <td className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Delta %</td>
                            {renderBaselinePercent(rowid)}
                        </tr>
                    </tbody>
                </table>
                </div>
            </Fragment>
        )
    }

    function renderSection(sectionid, sectionlist) {
        if(sections) {
            let sectiondata=[]
            sectiondata=filter( sections.results, {pk: sectionid})
            let rowdata=[]
            rowdata=filter( linkedrowsfiltered, {section_id: sectionid})
            if (sectiondata.length !== 0) {
            return (
                <Fragment>
                    <section className="mb-5 pb-3 mb-6" style={{backgroundColor: "rgb(242,242,242)"}} >
                    <div className="pl-4 pr-4 pt-5 pb-0" style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}}>
                    {sectiondata[0].name}
                    </div>
                    <div  className="p-4" style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
                    {sectiondata[0].narrative}
                    </div>
                    { rowdata && rowdata.map(rowid => (
                        <Fragment>
                        {renderTable(rowid)}
                        </Fragment>
                    ))}
                    </section>
                </Fragment>
            )
            }
        } else {
            return (<p>No Linked Roadmaps</p>)
        }
    }

    function renderRoadmaps() {
        var sectionlist=[];
        if (linkedrowsfiltered.length !== 0) {
            sectionlist = [...new Set( linkedrowsfiltered.map(obj => obj.section_id)) ];
            //sectionlist=filter(sectionlist, (linkedrowsfiltered) => sectionlist.includes(linkedrowsfiltered[0].section_id)); 
            sectionlist = sectionlist.sort(); 

        return (
            <Fragment>
            { sectionlist && sectionlist.map(sectionid => (
                renderSection(sectionid, sectionlist)
            ))}
                </Fragment>
        )
            } else {
                return <p className="pl-2"><b>No Linked Roadmaps Found</b></p>
            }
    }

   
    
return (
    renderRoadmaps()
)
}
export { SuccessRoadmapActions };