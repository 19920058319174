import React, {Fragment} from "react";
import { useSelector } from 'react-redux'
import "bulma/css/bulma.css";
import {filter} from "lodash"


function SprintInvestmentModal(props) {
    const {minYear, maxYear, toggleModal, showModal, sprintinvestment, sprintinvestmentcell,sprintinvestmentKey, onSprintInvestmentChanged, onSprintInvestmentDelete } = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)

    let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }

    if (!sprintinvestment || !sprintinvestmentcell) {
        return null;
      }

    function renderTotals(sprint) {
        let sprintarray = filter(sprintinvestment.results, {sprint_id:sprintinvestmentKey})
        if (sprintarray.length > 0) {
        const totalinputs = [];
        for (let year = minYear; year <= maxYear; year++) {
            totalinputs.push(<td className="has-text-centered"><input style={{border:"none", textAlign: "center", backgroundColor: "transparent", userSelect:"none", pointerEvents: "none"}} type="text" value={'100,000'}/></td>);
        }
        return(
            <tr>
                <td>TOTALS</td>
                {totalinputs}
            </tr>
        )
        }
    }

    function renderHeader() {
        return (
            <Fragment>
                <tr>
                    <th key={'Tableheader'}>Expense Item</th>
                    {renderYearHeaders()}
                </tr>
            </Fragment>
        )
    }

    function renderRowsDetail(sprintrow) {

        const rowinputs = [];
        for (let year = minYear; year <= maxYear; year++) {
            let cellvalue = filter(sprintinvestmentcell.results, {year:year, sprintinvestment_id:sprintrow})
            console.log(sprintrow,sprintinvestmentcell.results, cellvalue)
            rowinputs.push(<td className="has-text-centered"><input type="text" style={{textAlign: "center"}} value={cellvalue[0]?cellvalue[0].value:null}/></td>);
        }
            return rowinputs
    }

    function renderRows(sprint) {
        return (
            <Fragment>
            <tr>
                <td>
                    <input type="text" value={sprint.item} />
                </td>
            {renderRowsDetail(sprint.pk)}
            <td style={{width:"20px"}} onClick={() => onSprintInvestmentDelete(sprint.pk)}>
            <button className="far fa-trash-alt" style={{color:"gray"}}></button>
            </td>
            </tr>
            </Fragment>
        )
    }

    function renderYearHeaders() {
        const headers = [];
        for (let year = minYear; year <= maxYear; year++) {
            if (year <  new Date().getFullYear()) {
            headers.push(<th className="has-text-centered" key={year}>{year} Actual</th>);
            } else {
                headers.push(<th className={'has-text-centered'} key={year}>{year}</th>);
            }
        }
            headers.push(<th onClick={() => onSprintInvestmentChanged(null, sprintinvestmentKey,null)} className={'has-text-centered'}><button className="fas fa-plus-circle" style={{color:"gray"}}></button></th>);
        return headers;
    }

    function renderForm() {
        let sprintarray = filter(sprintinvestment.results, {sprint:sprintinvestmentKey})
        let yearrange = maxYear-minYear+3
        return (
            
            <table className="table is-fullwidth">
                <tbody>
                    {renderHeader()}
                    {sprintarray && sprintarray.length > 0 && 
                      sprintarray.map((sprint) => (
                        renderRows(sprint)
                      ))}
                    {sprintarray && sprintarray.length === 0 && 
                    <Fragment>
                        <tr>
                        <td className="pt-5" colSpan={yearrange} style={{textAlign: "center", fontWeight:400}}>Click <a onClick={() => onSprintInvestmentChanged(null, sprintinvestmentKey,null)} style={{color:"gray"}}><i className="fas fa-plus-circle"/></a> to Add Investment </td>
                        </tr>
                        </Fragment>
                    }

                    {renderTotals(sprintarray)}
                </tbody>
            </table>
        );
    }

    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard" style={{width:"1400px"}}>
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title"style={{fontSize:"17px", color: textcolor}}>Required Investment</p>
                        <button className="delete" aria-label="close" onClick={() => toggleModal(false)}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body" key="modalBody" width="100%">
                        {renderForm()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>
                        <button className="button is-small" key="cancel" onClick={() => toggleModal(false)}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default SprintInvestmentModal;
